import React, { Component } from 'react';
import Contacts from 'services/Contacts';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import TagSearch from 'components/TagSearch';
import Options from 'components/Options';
import FileDownload from 'js-file-download';
import Spinner from 'components/Spinner';
import {
  buildPaginationOptions,
  deleteItemFromArray
} from 'services/Utilities';
import { pageMatcher } from 'constants/Global';

const DownloadIcon = () => (
  <small className="pl-3 pr-3 d-flex align-items-center justify-content-center">
    <i className="fal fa-arrow-to-bottom " />
  </small>
);

const DownloadButton = ({ downloadTable }) => (
  <button
    title={pageMatcher.admin.citizens.export.pageTitle}
    className="btn btn-outline-primary mr-2 btn-square-36"
    onClick={downloadTable}
  >
    <DownloadIcon />
  </button>
);

class CitizensList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      allContacts: [],
      loading: true
    };
    this.Contacts = new Contacts();
  }

  componentDidMount() {
    this.getContacts();
  }

  // TODO: Catch clause with a ErrorService displaying a toaster message
  getContacts = () => {
    this.Contacts.get().then(({ data: response }) => {
      const formattedResponse = response.map(item => ({
        ...item,
        personal_information: {
          ...item.personal_information,
          firstname: item.personal_information.firstname || '',
          lastname: item.personal_information.lastname || ''
        }
      }));
      this.setState({
        allContacts: formattedResponse,
        contacts: formattedResponse,
        loading: false
      });
    });
  };

  filterList = event => {
    const value = event.target.value;
    this.setState(({ allContacts }) => ({
      contacts: allContacts.filter(
        ({ personal_information: { lastname, firstname, email } }) =>
          lastname.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
          firstname.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
          email.indexOf(value) !== -1
      )
    }));
  };

  optionsFormatter = (cell, row) => (
    <Options
      row={row}
      delete={this.deleteArticle}
      label="ce citoyen"
      kind="citizens"
    />
  );

  deleteArticle = id => {
    this.Contacts.delete(id).then(() => {
      const { contacts } = this.state;
      const updatedContacts = deleteItemFromArray(contacts, id);
      this.setState({
        contacts: updatedContacts,
        allContacts: updatedContacts
      });
    });
  };

  genderFormatter = (cell, _row) => {
    if (cell) {
      const gender = cell === 'male' ? 'Monsieur' : 'Madame';
      return <span>{gender}</span>;
    }
  };

  downloadTable = event => {
    event.preventDefault();
    this.Contacts.get('export.csv').then(({ data: response }) => {
      FileDownload(response, 'base_de_donnee_des_citoyens.csv');
    });
  };

  render() {
    const optionsPagination = buildPaginationOptions();
    const columns = [
      {
        dataField: 'personal_information.gender',
        text: 'Civilité',
        formatter: this.genderFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'personal_information.lastname',
        text: 'Nom',
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'personal_information.firstname',
        text: 'Prénom',
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'personal_information.email',
        text: 'Adresse email',
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'personal_information.phone',
        text: 'Téléphone',
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'action',
        text: 'Actions',
        formatter: this.optionsFormatter,
        headerStyle: { fontSize: 11 },
        align: 'right'
      }
    ];

    const { contacts, loading } = this.state;

    if (loading)
      return (
        <div className="text-center">
          <Spinner />
        </div>
      );

    return (
      <div>
        <TagSearch
          handleSearch={this.filterList}
          buttonTitle={pageMatcher.admin.citizens.new.pageTitle}
          buttonPlusLien={pageMatcher.admin.citizens.new.path}
          extraButton={
            contacts.length ? (
              <DownloadButton downloadTable={this.downloadTable} />
            ) : null
          }
        />
        <BootstrapTable
          noDataIndication="Pas de citoyen actuellement."
          keyField="id"
          data={contacts}
          columns={columns}
          bordered={false}
          pagination={paginationFactory(optionsPagination)}
          classes="koba-array"
          filter={filterFactory()}
        />
      </div>
    );
  }
}

export default CitizensList;
