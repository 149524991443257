import React, { Component } from 'react';

class Guide extends Component {
  render() {
    return (
      <div className="text-center">
        <a href="https://storage.sbg.cloud.ovh.net/v1/AUTH_38735d24d130434f9f52db121406daab/kc-assetsv2/Guide%20d%E2%80%99utilisation%20du%20Back%20Office%20V2.pdf">
          <button
            type="button"
            className="btn btn-primary ml-2 align-items-center"
          >
            Guide d'utilisation du BO
            <i className="far fa-file-download ml-3" />
          </button>
        </a>
        <br />
        <br />
        <p>Vous avez une question ?</p>

        <p>Toute l'équipe du Succès Client vous répond :</p>

        <p>
          {/*tél : <a href="tel:+33185089758">+33 (0)1 85 08 97 58</a>*/}
          {/*<br />*/}
          email :{' '}
          <a href="mailto:succes.client@koba.com">succes.client@koba.com</a>
        </p>
      </div>
    );
  }
}

export default Guide;
