import React from 'react';
import Spinner from 'components/Spinner';

export default class Loading extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.loading !== false && (
          <div className="Loading text-center">
            <Spinner />
          </div>
        )}
      </React.Fragment>
    );
  }
}
