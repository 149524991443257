import React from 'react';
import './index.scss';

export default ({ loadNewQuestions, show }) => {
  return (
    <a href="#bottom-anchor">
      <div className={'NewMessageBar text-center ' + (show ? 'show' : '')}>
        <div className="NewMessageBar-btn-container">
          <button
            onClick={() => show && loadNewQuestions()}
            className="btn btn-primary"
          >
            Nouveaux messages disponibles
          </button>
        </div>
      </div>
    </a>
  );
};
