import React, { Component } from 'react';
import Notifications from '../Notifications';
import SubHeaderElements from './SubHeaderElements/';
import logo from 'assets/images/mon-site.svg';
import { pageMatcher, settingsSubHeader } from 'constants/Global/';
import './index.scss';

class Header extends Component {
  logout = () => {
    localStorage.clear();
  };

  provideUserName = (firstname, lastname) =>
    (firstname && lastname && `${firstname} ${lastname}`) ||
    firstname ||
    lastname ||
    'Mon compte';

  render() {
    const { firstname, lastname } = this.props;
    const townHallName = localStorage.getItem('townHallName');
    const frontofficeUrl = localStorage.getItem('frontofficeUrl');
    const townhallSlug =
      localStorage.getItem('townhallSlug') || localStorage.getItem('slug');

    return (
      <header
        className="header navbar navbar-expand navbar-light flex-column flex-md-row bd-navbar fixed-top"
        role="tablist"
      >
        <div
          className="d-flex align-items-center py-3 py-lg-4"
          role="presentation"
        >
          <a
            title="Retour à mes contacts"
            className="navbar-brand mr-0 mr-md-2"
            aria-label="Bootstrap"
            href={pageMatcher.admin.home.contacts.path}
            tabIndex={0}
          >
            <img height="45px" src={logo} alt="Retour à mes contacts" />
          </a>
        </div>
        {frontofficeUrl && (
          <a
            className="navbar-brand mr-0 ml-4 mr-md-2 fo-url"
            href={`${frontofficeUrl}`}
            rel="noopener noreferrer"
            target="_blank"
            tabIndex={0}
          >
            <i className="fa fa-external-link mr-2" />
            {townHallName}
          </a>
        )}
        <ul
          className="navbar-nav flex-row ml-md-auto d-none d-md-flex"
          role="presentation"
        >
          {townhallSlug && (
            <li className="nav-item" role="button">
              <Notifications />
            </li>
          )}
          <li
            className="nav-item dropdown"
            style={{ paddingTop: '0.4rem' }}
            role="button"
          >
            <a
              className="nav-item nav-link dropdown-toggle mr-md-2"
              href="#_"
              id="bd-versions"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              role="button"
              tabIndex={0}
            >
              {this.provideUserName(firstname, lastname)}
              <i className="fa fa-user ml-2" />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right border"
              aria-labelledby="user"
              role="presentation"
            >
              <SubHeaderElements data={settingsSubHeader} />
              <a
                href={`${process.env.REACT_APP_PORTAL_URL}logout`}
                className="dropdown-item"
                onClick={this.logout}
                role="button"
                tabIndex={0}
              >
                Déconnexion
              </a>
            </div>
          </li>
        </ul>
      </header>
    );
  }
}

export default Header;
