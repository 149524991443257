import React, { Component } from 'react';
import './index.scss';
import { pageMatcher, websiteSubMenu } from 'constants/Global/';
import SideBarSubElement from './SideBarSubElement/';
import SideBarCollapsingElement from './SideBarCollapsingElement/';

class SideBar extends Component {
  render() {
    return (
      <nav className="sidebar col-md-2 d-none d-md-block bg-light">
        <div className="sidebar-sticky">
          <ul className="nav flex-column menu-list" role="tablist">
            <React.Fragment>
              <SideBarSubElement
                data={pageMatcher.admin.home}
                icon="fal fa-home"
                isDefault={true}
              />
              <SideBarSubElement
                data={pageMatcher.admin.citizens}
                icon="fal fa-user"
              />
              <SideBarCollapsingElement
                subMenuElements={websiteSubMenu.elements}
                elTitle={websiteSubMenu.title}
              />
              {/*
              {workspaceUrl && (
                <SideBarExternalElement
                  url={workspaceFullUrl}
                  title="Espace collaboratif"
                  icon="fas fa-cogs"
                />
              )}
              {campaignUrl && (
                <SideBarExternalElement
                  url={campaignUrl}
                  title="Ma communication"
                  icon="fas fa-envelope"
                />
              )}
              */}
            </React.Fragment>
          </ul>
        </div>
      </nav>
    );
  }
}

export default SideBar;
