import Bubble from './Bubble';
import PropTypes from 'prop-types';
import React from 'react';
import { handleChange } from 'services/Utilities';
import './index.scss';

export default class BubbleContainer extends React.Component {
  state = { collapsed: false, exited: true };

  collapse = _ => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
      ...(prevState.collapsed && { exited: false })
    }));
  };

  handleExit = _ => {
    setTimeout(_ => {
      // fix lightning on closing
      this.setState({ exited: true });
    }, 100);
  };

  handleChange = e => {
    handleChange(e, this);
  };

  handleSubmit = e => {
    this.collapse();
    this.props.handleSubmit(e, this.state.answer);
  };

  render() {
    return (
      <Bubble
        {...this.props}
        {...this.state}
        handleExit={this.handleExit}
        collapse={this.collapse}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

Bubble.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  question: PropTypes.object,
  handleDelete: PropTypes.func,
  handleSubmit: PropTypes.func
};
