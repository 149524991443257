import React, { Component } from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import ModalDraw from 'components/ModalDraw';
import { capitalize } from 'services/Helpers';

class SocialNetworkForm extends Component {
  render() {
    const {
      index,
      socialNetwork: { kind, id, url }
    } = this.props;

    return (
      <div>
        <FormGroup row>
          <Label for="link" sm={2}>
            {`Page ${capitalize(kind)}`}
          </Label>
          <Col sm={6}>
            <Input
              type="text"
              name="url"
              aria-label="url"
              placeholder={`https://www.${kind}.com/ma_mairie`}
              onChange={event => this.props.handleChange(event, index)}
              value={url}
              required
            />
          </Col>

          {id && (
            <Col sm={1}>
              <ModalDraw
                id={id}
                header={`Supprimer ${kind}`}
                body={`Etes-vous sûr de vouloir supprimer ce compte ${kind}  ?`}
                label="Supprimer"
                ico={<i className="far fa-trash-alt " />}
                action={e => this.props.handleDelete(e, id, index, kind)}
                btnClass="table-action-btn btn-action mr-1"
                btnTitle={'Supprimer'}
              />
            </Col>
          )}
        </FormGroup>
      </div>
    );
  }
}

export default SocialNetworkForm;
