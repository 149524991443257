import ChatBase from '../ChatBase';
import ChatService, { validateChatForm } from 'services/Chat';
import PublishBar from 'components/PublishBar';
import React, { Component } from 'react';
import { parseResponseForPublishBar } from 'services/Utilities';
import { pageMatcher } from 'constants/Global';

export default class NewSection extends Component {
  state = {};
  chatService = new ChatService();

  get = async id => {
    const { data: response } = await this.chatService.get(id);
    if (response && response.id) this.setState(response);
  };

  put = async (id, data) => {
    const { data: response } = await this.chatService.put(id, data);
    parseResponseForPublishBar(response, this, 'marginBottom');
    if (response && response.id)
      this.props.history.push(pageMatcher.admin.chat.path);
  };

  handleChange = obj => this.setState(obj);

  handleSubmit = event => {
    event.preventDefault();
    const localError = validateChatForm(this.state);
    if (localError) {
      parseResponseForPublishBar(localError, this, 'marginBottom');
    } else {
      const { title, description, interlocutor_username } = this.state;
      this.put(this.props.match.params.id, {
        title,
        description,
        interlocutor_username
      });
    }
  };

  componentDidMount() {
    this.get(this.props.match.params.id);
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <ChatBase data={this.state} handleChange={this.handleChange} />
          <PublishBar
            state={'draft'}
            handleSubmit={this.handleSubmit}
            goBackLink={pageMatcher.admin.chat.path}
            goBackLinkTitle="Retour à la liste"
          />
        </form>
      </div>
    );
  }
}
