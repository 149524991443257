import ProcedureBase from '../ProcedureBase';
import ProcedureService from 'services/OnlineProcedures';
import PublishBar from 'components/PublishBar';
import React, { Component } from 'react';
import * as Utilities from 'services/Utilities';
import { pageMatcher } from 'constants/Global';

export default class NewSection extends Component {
  state = { data: {} };
  procedureService = new ProcedureService();

  post = () => {
    this.procedureService.post(this.state.data).then(({ data: response }) => {
      Utilities.parseResponseForPublishBar(response, this, 'marginBottom');
      if (response && response.id)
        this.props.history.push(
          pageMatcher.admin.onlineProcedures.procedures.path + response.id
        );
    });
  };

  handleChange = data => this.setState({ data });

  handleSubmit = event => {
    event.preventDefault();
    this.post(this.state.data);
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <ProcedureBase
          data={this.state.data}
          handleChange={this.handleChange}
        />
        <PublishBar
          state={'draft'}
          handleSubmit={this.handleSubmit}
          buttonDisabled={this.state.loading}
          goBackLink={pageMatcher.admin.onlineProcedures.procedures.path}
          goBackLinkTitle="Retour à la liste"
        />
      </form>
    );
  }
}
