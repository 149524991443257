import Ressource from 'services/Ressource';
import Axios from './Axios';

export default class Index extends Ressource {
  constructor() {
    super('most_consult');
  }

  save = data => Axios.put('/most_consults/save', { most_consult: data });
}
