// import Stats from './Stats';
import ContactInfos from './ContactInfos';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Submenu from 'components/Submenu';
import Layout from 'components/Layout';
import { pageMatcher } from 'constants/Global';

const Home = props => {
  const subMenuData = [
    // pageMatcher.admin.home.stats,
    pageMatcher.admin.home.contacts
  ];

  return (
    <div>
      <Layout pageTitle={'Accueil'} />
      <Submenu subMenuData={subMenuData} />
      <Switch>
        <Redirect
          from={pageMatcher.admin.home.path}
          exact={true}
          to={pageMatcher.admin.home.contacts.path}
        />
        {/* <Route
          path={pageMatcher.admin.home.stats.path}
          render={() => <Stats {...props} />}
        /> */}
        <Route
          path={pageMatcher.admin.home.contacts.path}
          render={() => <ContactInfos {...props} />}
        />
      </Switch>
    </div>
  );
};

export default Home;
