import PictogramLibrary from 'components/PictogramLibrary';
import React, { useEffect, useRef, useState } from 'react';
import Toggle from 'components/Toggle';
import ModalSelectLink from 'components/ModalSelectLink';
import { mapLinkDataOnApiWrite, mapLinkDataOnApiRead } from 'services/Link';
import { useDrag, useDrop } from 'react-dnd';
import { UncontrolledAlert } from 'reactstrap';
import ModalDraw from 'components/ModalDraw';

const Item = props => {
  const [selectedUrl, setSelectedUrl] = useState('');
  const [modalSelectLinkActive, setModalSelectLinkActive] = useState(false);
  const [{ opacity }, drag] = useDrag({
    item: { type: 'service', index: props.index, id: props.id },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'service',
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const { index: hoverIndex, moveItem } = props;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const handlePictogram = (_index, icon) => {
    const { handlePictogram, index } = props;

    handlePictogram(index, icon.className);
  };

  const setLink = data => {
    const { handleLinkable, index } = props;

    handleLinkable(index, data);
    setSelectedUrl((data.link && data.link.title) || data.url);
    setModalSelectLinkActive(!modalSelectLinkActive);
  };

  const toggleModalSelectLink = () =>
    setModalSelectLinkActive(!modalSelectLinkActive);

  useEffect(() => {
    if (props.item.newItem) return;

    const {
      item: { datable }
    } = props;
    setSelectedUrl(datable.title || datable.data || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { item, index, handleChange, removeItem } = props;
  const { id, font_class_name = '', error, title, active } = item;

  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity }}>
      <form className={`card-koba mb-4 ${error ? 'has-error' : ''}`}>
        <div className="d-inline-block drag-icon" ref={drag}>
          <i className="fas fa-lg fa-arrows alt" />
        </div>
        <div className="form-group mt-1">
          <label className="">Titre du service</label>
          <span style={{ fontSize: '0.7em', float: 'right' }} className="mt-1">
            (Maximum 40 caractères)
          </span>
          <input
            type="text"
            required
            className="form-control"
            maxLength="40"
            aria-describedby="titleHelp"
            placeholder="Titre"
            aria-label="Titre"
            name="title"
            value={title}
            onChange={event => handleChange(event, index)}
          />
        </div>
        <div className="form-group d-flex flex-column">
          <label>URL de la page associée</label>
          <div className="d-flex flex-row">
            <div>
              <button
                type="button"
                title="Ajouter un lien"
                onClick={toggleModalSelectLink}
                className="btn table-action-btn btn-action-md"
              >
                <i className="far fa-link " />
              </button>
            </div>

            <div className="ml-2 d-flex align-items-center text-truncate">
              {selectedUrl}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <label>Icône</label>
            <PictogramLibrary
              initPicto={font_class_name}
              handlePictogramChosen={handlePictogram}
            />
          </div>
          <div className="col-6">
            <label>Actif</label>
            <br />
            <Toggle
              checked={active}
              onChange={event => handleChange(event, index)}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-xl-6 mb-3 mb-xl-0">
            <ModalDraw
              header="Supprimer"
              body="Etes-vous sûr de vouloir supprimer ce service ?"
              label="Supprimer"
              action={() => removeItem(id)}
              btnClass="btn-block btn-outline-primary"
              buttonTitle="Supprimer"
              btnText="Supprimer"
            />
          </div>
          <div className="col-12 mt-3">
            {error && (
              <UncontrolledAlert color="danger">{error}</UncontrolledAlert>
            )}
          </div>
        </div>
      </form>
      <ModalSelectLink
        value={mapLinkDataOnApiRead(item)}
        onCancel={toggleModalSelectLink}
        isOpen={modalSelectLinkActive}
        onSelect={value => setLink(mapLinkDataOnApiWrite(value))}
      />
    </div>
  );
};

export default Item;
