import React, { Component } from 'react';
import DatePickerModule from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

export default class DatePickerSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null
    };
  }

  handleChange = date => {
    this.setState({
      startDate: date
    });
    let data = {
      start: date ? date.format() : null
    };
    this.props.handleDatePicker(data);
  };

  /*
   TODO : Delete this when the loading logic is updated on every pages
   If the DOM is returned while data is still fetched, during the
   mount phase of this component, the data will be unavailable, that's the reason
   why the previous unexperienced developers used this lifecycle.
   However, if you prevent the DOM from displaying anything unless the data is fetched,
   while mounting, the data will be available, therefore this won't will be useless.
   */
  componentWillReceiveProps(nextProps) {
    if (
      this.props.type === 'publish' &&
      nextProps.data &&
      this.state.startDate === null
    ) {
      const momentStart = moment(nextProps.data);
      this.setState({
        startDate: momentStart
      });
    }
  }

  // NB: The lifecycle above does not fire when a component is mount.
  // So logic is duplicated for component with a proper loading logic
  componentDidMount() {
    if (this.props.data && this.props.type === 'publish') {
      const startDate = moment(this.props.data);
      this.setState({ startDate });
    }
  }

  render() {
    return (
      <div className={'d-flex flex-row date-picker-single'}>
        <div className={this.props.className}>
          <DatePickerModule
            selected={this.state.startDate}
            onChange={this.handleChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={30}
            dateFormat="LLL"
            timeCaption="l'heure"
            locale="fr"
            className="form-control"
            placeholderText="sélectionnez une date"
            readOnly={this.props.readonly || false}
            required={this.props.required}
          />
        </div>
      </div>
    );
  }
}
