import React, { Component } from 'react';
import AlertInfo from 'components/AlertInfo';
import { createCampaign } from 'redux/actions/campaignActions';
import withCampaign from '../WithCampaign';

class NewCampaign extends Component {
  render() {
    return (
      <div className="card-koba col">
        <div className="row">
          <div>
            <h5>Créer une nouvelle alerte</h5>
          </div>
          <div>
            <AlertInfo info="Important : la publication de deux alertes en même temps n'est pas possible" />
          </div>
        </div>
        <br />
        {this.props.children}
      </div>
    );
  }
}

const mapDispatchToProps = { createCampaign };

export default withCampaign(NewCampaign, { mapDispatchToProps });
