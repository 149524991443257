import {
  DELETE_CAMPAIGN,
  FETCH_ONGOING_CAMPAIGN,
  FETCH_UPCOMING_CAMPAIGN,
  POST_CAMPAIGN,
  PUT_CAMPAIGN
} from './types/campaignTypes';
import moment from 'moment';

/*
 *
  Some cases within the switch statement are wrapped around curly
  braces, to treat them as blocks otherwise the destructuring on both the POST_CAMPAIGN
  and UPDATE_CAMPAIGN won't work and consider it as a redeclaration of the variables
 *
*/

const initialState = {
  onGoingCampaign: {},
  upcomingCampaign: {},
  onGoingCampaignLoading: true,
  upcomingCampaignLoading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_CAMPAIGN: {
      const { begin_at, end_at } = action.payload;
      return {
        ...state,
        ...(moment().isBetween(begin_at, end_at) && {
          onGoingCampaign: action.payload
        }),
        ...(moment().isBefore(begin_at) && {
          upcomingCampaign: action.payload
        })
      };
    }
    case FETCH_ONGOING_CAMPAIGN:
      return {
        ...state,
        onGoingCampaign: action.payload,
        onGoingCampaignLoading: false
      };
    case FETCH_UPCOMING_CAMPAIGN:
      return {
        ...state,
        upcomingCampaign: action.payload,
        upcomingCampaignLoading: false
      };
    case PUT_CAMPAIGN: {
      const { begin_at, end_at } = action.payload;
      return {
        ...state,
        ...(moment().isBetween(begin_at, end_at) && {
          onGoingCampaign: action.payload,
          upcomingCampaign: {}
        }),
        ...(moment().isBefore(begin_at) && {
          upcomingCampaign: action.payload,
          onGoingCampaign: {}
        })
      };
    }
    case DELETE_CAMPAIGN: {
      const { onGoingCampaign, upcomingCampaign } = initialState;
      return {
        ...state,
        ...(action.payload === 'onGoing' && { onGoingCampaign }),
        ...(action.payload === 'upcoming' && { upcomingCampaign })
      };
    }
    default:
      return state;
  }
};
