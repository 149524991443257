import Ressource from './Ressource';

export default class JobOffers extends Ressource {
  constructor() {
    super('job');
  }
}

export const validateJob = job => {
  const regExp = new RegExp(/^(<p>|<\/p>|&nbsp;|[\r\n])*$/);
  const isDescriptionEmpty = !job.description || regExp.test(job.description);
  const textInPdfTitle =
    job.document && job.document.title && job.document.title.trim() !== '';
  const isDocPdf =
    job.document && (job.document_url || typeof job.document.data === 'object');
  const isTitleEmpty = job.title.trim() === '';

  let localError;
  if (isTitleEmpty) {
    localError = Error('Erreur, veuillez renseigner le titre du poste.');
  } else if (!isDocPdf && textInPdfTitle) {
    localError = Error('Erreur, veuillez joindre un document au format .pdf');
  } else if (isDocPdf && !textInPdfTitle) {
    localError = Error('Erreur, veuillez renseigner le titre du document.');
  } else if (isDescriptionEmpty && !textInPdfTitle) {
    localError = Error(
      'Erreur, veuillez renseigner un document + titre ou une description.'
    );
  }

  return localError;
};
