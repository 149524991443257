import ChatBase from '../ChatBase';
import ChatService, { validateChatForm } from 'services/Chat';
import PublishBar from 'components/PublishBar';
import React, { Component } from 'react';
import { parseResponseForPublishBar } from 'services/Utilities';
import { pageMatcher } from 'constants/Global';

export default class NewSection extends Component {
  state = {};

  post = async data => {
    const chatService = new ChatService();
    const { data: response } = await chatService.post(data);
    parseResponseForPublishBar(response, this, 'marginBottom');
    if (response && response.id)
      this.props.history.push(pageMatcher.admin.chat.path);
  };

  handleChange = obj => this.setState(obj);

  handleSubmit = event => {
    event.preventDefault();
    const localError = validateChatForm(this.state);
    if (localError) {
      parseResponseForPublishBar(localError, this, 'marginBottom');
    } else {
      this.post(this.state);
    }
  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <ChatBase
            data={this.state}
            handleChange={this.handleChange}
            chat={true}
          />
          <PublishBar
            state={'draft'}
            handleSubmit={this.handleSubmit}
            goBackLink={pageMatcher.admin.chat.path}
            goBackLinkTitle="Retour à la liste"
          />
        </form>
      </div>
    );
  }
}
