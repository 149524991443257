import React, { Component } from 'react';
import './index.scss';
import serviceNotifications from 'services/Notifications';
import { Scrollbars } from 'react-custom-scrollbars';

// TODO: Catch clause on API calls.
class Notifications extends Component {
  state = {
    notifications: null,
    notSeen: null
  };

  notificationService = new serviceNotifications();

  componentWillMount() {
    if (localStorage.getItem('slug')) this.getNotifications();
  }

  getNotifications = () => {
    this.notificationService
      .getOnly('?scopes[pending]&sort[created_at]=desc')
      .then(({ data: response }) => {
        const lastTenNotifToSee = response.slice(0, 10);
        this.setState({
          notifications: response.length > 0 ? lastTenNotifToSee : null,
          notSeen: response.length === 0 ? null : response.length
        });
      })
      .then(() => {
        setTimeout(() => {
          this.getNotifications();
        }, 30000);
      });
  };

  markNotifAsRead = id => {
    this.notificationService.change(id, 'watch').then(({ data: _response }) => {
      this.getNotifications();
    });
  };

  render() {
    const { notSeen, notifications } = this.state;

    const lastNotifIndex = notifications ? notifications.length - 1 : 0;

    return (
      <div className="nav-item dropdown notifications" role="presentation">
        <a
          className="nav-item nav-link mr-md-2 d-flex flex-row"
          href="#_"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          role="button"
          tabIndex={0}
        >
          <div className="pl-2 d-flex flex-row text-center" role="button">
            <span className="circle-icon" title="Voir mes notifications">
              <i className="fas fa-bell" />
            </span>
            {notSeen && (
              <span
                className={
                  'label  bg-primary text-white ' +
                  (String(notSeen).length > 1 ? 'two-numbers' : 'one_number')
                }
              >
                {notSeen}
              </span>
            )}
          </div>
        </a>

        {notifications && (
          <ul
            className="dropdown-menu dropdown-menu-right box-width border"
            aria-labelledby="dropdownMenuButton"
            role="presentation"
          >
            <Scrollbars
              hideTracksWhenNotNeeded={true}
              autoHeight
              role="presentation"
            >
              {notifications.map((el, index) => {
                if (el.redirect_to_url) {
                  return (
                    <div className="pl-2 pr-2" key={index} role="presentation">
                      <a
                        role="button"
                        tabIndex={0}
                        className={
                          el.state === 'pending'
                            ? 'text-primary'
                            : ' text-notif '
                        }
                        href={el.redirect_to_url}
                        download="cool"
                        onClick={() => this.markNotifAsRead(el.id)}
                      >
                        <small>{el.event}</small>
                      </a>
                      {index !== lastNotifIndex && (
                        <div className="dropdown-divider" />
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div className="pl-2 pr-2" key={index} role="presentation">
                      <div className="text-notif" role="tab" tabIndex={0}>
                        <small>{el.event}</small>
                      </div>
                      {index !== lastNotifIndex && (
                        <div className="dropdown-divider" />
                      )}
                    </div>
                  );
                }
              })}
            </Scrollbars>
          </ul>
        )}
      </div>
    );
  }
}

export default Notifications;
