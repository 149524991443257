import React from 'react';
import Comarquage from 'services/Comarquage';

export default class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { comarquageFiles: [] };
    this.Comarquage = new Comarquage();
  }

  componentDidMount() {
    this.Comarquage.get().then(({ data: response }) => {
      this.setState({ comarquageFiles: response });
    });
  }

  render() {
    const { data } = this.props;
    return (
      <div>
        <label htmlFor="co_marking">Co-marquage</label>
        <select
          name="co_marking"
          value={data && data.id}
          className="form-control"
          onChange={e =>
            this.props.updateSubBlock('co_marking', e.target.value)
          }
        >
          <option value="">Veuillez choisir</option>
          {this.state.comarquageFiles.map(item => (
            <option key={item.id} value={item.id}>
              {item.title}
            </option>
          ))}
        </select>
      </div>
    );
  }
}
