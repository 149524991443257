// React
import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default class ModalKiosk extends Component {
  constructor(props) {
    super(props);

    // state
    this.state = {
      isVisible: false,
      id: this.props.id
    };

    // binding
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({ isVisible: !this.state.isVisible });
  }

  render() {
    const { image_url, file_url, description, title } = this.props;
    return (
      <React.Fragment>
        <button
          type="button"
          onClick={() => this.toggle()}
          className={'btn table-action-btn btn-action mr-1'}
          title="Voir kiosque"
        >
          <i className="fas fa-eye" />
        </button>
        <Modal isOpen={this.state.isVisible} toggle={this.toggle} size="lg">
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>
            <div className="row">
              <Col sm={4}>
                <img src={image_url} alt={title} className="img-fluid" />
              </Col>
              <Col sm={8}>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </Col>
            </div>
          </ModalBody>
          {file_url && (
            <ModalFooter className="d-flex flex-row justify-content-between">
              <div>
                Titre : <span className="required">{title}</span>
              </div>
              <a href={file_url} className="btn-link-white" download>
                <button className="btn btn-primary">
                  Télécharger le fichier PDF &nbsp;
                  <i className="fas fa-download " />
                </button>
              </a>
            </ModalFooter>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}
