import React from 'react';
import PropTypes from 'prop-types';
import { handleEnterKeyPress } from 'services/Utilities';

const RadioButton = ({
  onChange,
  value,
  checked,
  text,
  divClass,
  labelClass,
  inputClass,
  id
}) => (
  <div className={divClass}>
    <label className={labelClass} htmlFor={value || id}>
      <input
        onKeyPress={_ => handleEnterKeyPress(_, onChange)}
        type="radio"
        className={inputClass}
        id={value || id}
        name={value}
        aria-label={text}
        value={value}
        onChange={onChange}
        checked={checked}
      />{' '}
      {text}
    </label>
  </div>
);

RadioButton.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default RadioButton;
