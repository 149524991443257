import { NavLink } from 'react-router-dom';
import React from 'react';
import './index.scss';

const SideBarSubElement = ({ data, icon, isDefault }) => {
  const options = localStorage.getItem('options');
  const hasOption = !data.option || options.includes(data.option);
  const blockedClass = hasOption ? '  ' : ' blocked';
  return (
    <li className="nav-item" role="presentation">
      <NavLink
        to={data.path}
        title={data.pageTitle}
        className={'nav-link' + blockedClass}
        onClick={e => (hasOption ? () => {} : e.preventDefault())}
        role="button"
        tabIndex={0}
      >
        {icon && <i className={icon} />}
        {!hasOption && <i className="fa fa-lock" aria-hidden="true" />}
        {data.pageTitle}
        {isDefault && <span className="sr-only">(current)</span>}
      </NavLink>
    </li>
  );
};
export default SideBarSubElement;
