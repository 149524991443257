import React from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
  geocodeByAddress
} from 'react-places-autocomplete';

const LocationSearchInput = ({
  handleAddressChange,
  handleAddressSelection,
  value
}) => {
  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        handleAddressSelection(results);
      })
      .catch(error => console.log(error));
  };

  return (
    <PlacesAutocomplete
      value={value}
      onChange={value => handleAddressChange(value)}
      onSelect={handleSelect}
      debounce={1000}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="row m-0">
          <input
            {...getInputProps({
              placeholder: 'Adresse',
              className: 'form-control'
            })}
          />
          <div
            className="autocomplete-dropdown-container"
            style={{ width: '100%' }}
          >
            {loading && <div>Chargement...</div>}
            {suggestions.map(suggestion => (
              <div
                key={suggestion.id}
                {...getSuggestionItemProps(suggestion, {
                  className: 'card-koba'
                })}
              >
                <span>{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

LocationSearchInput.propTypes = {
  handleAddressChange: PropTypes.func.isRequired
};

export default LocationSearchInput;
