import React, { Component } from 'react';
import PhotoAlbumService from 'services/PhotoAlbums';
import Options from 'components/Options';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import TagSearch from 'components/TagSearch';
import moment from 'moment';
import Spinner from 'components/Spinner';
import { alertMessage, deleteItemFromArray } from 'services/Utilities';
import { Link } from 'react-router-dom';
import { buildPaginationOptions } from 'services/Utilities';
import { pageMatcher } from 'constants/Global';
import Toggle from 'components/Toggle';
import './index.scss';

export default class PhotoLibrairies extends Component {
  state = {
    allAlbums: [],
    filteredAlbums: [],
    showSaved: false,
    statusMessage:
      'Votre demande de téléchargement a été enregistrée et votre lien de téléchargement sera accessible dans les notifications',
    errorMessage: '',
    loading: true
  };

  photoAlbumService = new PhotoAlbumService();

  // TODO: Add try and catch clause
  getPhotoAlbums = async () => {
    const { data: draftAlbums } = await this.photoAlbumService.getOnly(
      '?scopes[draft]'
    );
    const { data: publishedAlbums } = await this.photoAlbumService.getOnly(
      '?scopes[published]'
    );
    const allAlbums = [...draftAlbums, ...publishedAlbums];

    this.setState({
      filteredAlbums: allAlbums,
      allAlbums,
      loading: false
    });
  };

  componentWillMount() {
    this.getPhotoAlbums();
  }

  deletePhotoAlbum = id => {
    this.photoAlbumService.delete(id).then(({ data: _response }) => {
      const filteredAlbums = deleteItemFromArray(
        [...this.state.filteredAlbums],
        id
      );
      this.setState({ filteredAlbums });
    });
  };

  archivePhotoLibrary = id => {
    this.photoAlbumService.change(id, 'archive').then(({ data: _response }) => {
      const filteredAlbums = deleteItemFromArray(
        [...this.state.filteredAlbums],
        id
      );
      this.setState({ filteredAlbums });
    });
  };

  optionsFormatter = (cell, row) => (
    <Options
      row={row}
      delete={this.deletePhotoAlbum}
      label="un album photo"
      kind="photo_librairies"
      addButton={false}
      download={this.download}
      archiving={this.archivePhotoLibrary}
      downloadDisabled={this.state.loading}
    />
  );

  download = id => {
    this.photoAlbumService
      .download(id, 'download')
      .then(({ data: response }) => {
        alertMessage('success', response.message, this, false);
      });
  };

  photoFormatter = (cell, _row) => (
    <img src={cell} alt="couverture" className="table-photo" />
  );

  dateFormatter = (cell, _row) => {
    if (cell) {
      return <span>{moment(cell).format('DD/MM/YYYY')}</span>;
    }
  };

  toggleState = ({ id, state }) => {
    const option = state === 'published' ? 'draft' : 'publish';

    this.photoAlbumService.change(id, option).then(({ data }) => {
      const updatedPhotos = this.state.allAlbums.map(news =>
        news.id === id ? { ...news, ...data } : news
      );

      this.setState(() => ({
        allAlbums: updatedPhotos,
        filteredAlbums: updatedPhotos
      }));
    });
  };

  activeFormatter = (cell, row) => (
    <Toggle
      checked={cell === 'published'}
      name="state"
      onChange={() => this.toggleState(row)}
    />
  );

  handleSearch = event => {
    const value = event.target.value.toLowerCase();
    this.setState(state => ({
      filteredAlbums: state.allAlbums.filter(item =>
        ['title', 'author'].some(
          colon => item[colon] && item[colon].toLowerCase().includes(value)
        )
      )
    }));
  };

  render() {
    const { loading, filteredAlbums } = this.state;
    const optionsPagination = buildPaginationOptions();
    let columns = this.photoAlbumService.buildColumns(
      this.photoFormatter,
      this.dateFormatter,
      this.activeFormatter,
      this.optionsFormatter
    );

    columns = columns.map(column =>
      column.dataField === 'state'
        ? { ...column, text: 'En ligne / Hors Ligne' }
        : column
    );

    return (
      <div className="photo_library">
        <TagSearch
          handleSearch={this.handleSearch}
          buttonTitle="Ajouter un nouvel album"
          buttonPlusLien={pageMatcher.admin.content.photoLibrairies.new.path}
          extraButton={
            <Link
              to={pageMatcher.admin.content.photoLibrairies.archived.path}
              title={
                pageMatcher.admin.content.photoLibrairies.archived.pageTitle
              }
              tabIndex={-1}
            >
              <button type="button" className="btn btn-secondary mr-2">
                Voir les albums archivés
              </button>
            </Link>
          }
        />
        <BootstrapTable
          keyField="id"
          data={filteredAlbums}
          columns={columns}
          hover
          bordered={false}
          pagination={paginationFactory(optionsPagination)}
          classes="koba-array"
          filter={filterFactory()}
        />
        {loading && (
          <div className="text-center">
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}
