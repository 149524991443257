import Ressource from 'services/Ressource';
import { reverseObjectKeyVals } from './Utilities';

export default class Menu extends Ressource {
  constructor() {
    super('menu', true);
  }
}

const getMenuLeaves = items =>
  items
    .map(
      ({ children = [], ...item }) =>
        children.length ? getMenuLeaves(children) : item
    )
    .flat();

export const validate = items => !getMenuLeaves(items).some(l => !l.page);

const apiTypeToResourceType = {
  articles: 'article',
  events: 'event',
  news: 'news',
  kiosks: 'kiosk',
  photo_albums: 'photo_album',
  annuaire: 'category',
  services: 'online_procedure',
  job: 'job',
  survey: 'survey'
};
const resourceTypeToApiType = reverseObjectKeyVals(apiTypeToResourceType);

const internalLinkRegex = /^\/(articles|events|news|kiosks|photo_albums|annuaire|job|survey|services)(\/.*)?$/;

export const mapLinkDataOnApiRead = ({ title, page: url }) => {
  if (!url) return null;
  const internal = url.startsWith('/');
  if (internal) {
    const [, apiType, slashWithIdOrSlug] = url.match(internalLinkRegex) || [];
    return {
      internal: true,
      resourceType: apiTypeToResourceType[apiType],
      ...(apiType === 'photo_albums' &&
        slashWithIdOrSlug &&
        slashWithIdOrSlug.length >= 2 && { id: slashWithIdOrSlug.slice(1) }),
      ...(apiType !== 'photo_albums' &&
        slashWithIdOrSlug &&
        slashWithIdOrSlug.length >= 2 && { slug: slashWithIdOrSlug.slice(1) })
    };
  } else {
    return {
      internal: false,
      url,
      title
    };
  }
};

export const mapLinkDataOnApiWrite = ({
  internal,
  resourceType,
  id,
  url,
  title,
  slug
}) => {
  let mappedUrl;
  if (internal && id) {
    mappedUrl = `/${resourceTypeToApiType[resourceType]}/${slug || id}`;
  } else if (internal) {
    mappedUrl = `/${resourceTypeToApiType[resourceType]}`;
  } else {
    mappedUrl = url;
  }
  return {
    text: title,
    page: mappedUrl
  };
};
