import React, { Fragment } from 'react';
import ButtonAction from 'components/ButtonAction';
import Button from 'components/Button';
import { FormGroup, Input, Select } from 'components/Form';
import { guid } from 'services/Utilities';
import { ChoiceForm } from './ChoiceForm';
import './index.scss';

const QuestionForm = props => {
  const addChoice = (form, path) => {
    const nextChoices = form.getValue(path).concat({
      id: guid(),
      label: null
    });
    form.handleChange(path, nextChoices);
  };

  const moveChoice = (form, path, fromIndex, toIndex) => {
    const choices = form.getValue(path);
    const nextChoices = choices.map((c, i) => {
      if (i === fromIndex) return choices[toIndex];
      if (i === toIndex) return choices[fromIndex];
      return c;
    });
    form.handleChange(path, nextChoices);
  };

  const deleteChoice = (form, path, choiceIndex) => {
    const choices = form.getValue(path);
    const nextChoices = choices.filter((_, i) => i !== choiceIndex);
    form.handleChange(path, nextChoices, true);
  };

  const handleChoicesKindChange = value => {
    const { form, path } = props;
    const choicesKindPath = [...path, 'choices_kind'];
    const choicesPath = [...path, 'choices'];
    if (value === 'free_text') {
      form.handleChange(choicesPath, []);
    }
    form.handleChange(choicesKindPath, value);
  };

  const {
    form,
    path,
    question: { choices }
  } = props;

  const questionPath = path || [];
  const titlePath = [...questionPath, 'title'];
  const subtitlePath = [...questionPath, 'subtitle'];
  const choicesKindPath = [...questionPath, 'choices_kind'];
  const choicesPath = [...questionPath, 'choices'];

  return (
    <>
      {form.getError(questionPath) && (
        <div className="alert alert-warning" role="alert">
          {form.getError(questionPath)}
        </div>
      )}
      <FormGroup path={titlePath} label="Texte" required={true} form={form}>
        <Input
          path={titlePath}
          placeholder="Que pensez-vous de ...?"
          aria-label="Que pensez-vous de ...?"
        />
      </FormGroup>
      <FormGroup path={subtitlePath} label="Description" form={form}>
        <Input path={subtitlePath} aria-label="Description" />
      </FormGroup>
      <FormGroup path={choicesKindPath} label="Type de choix" form={form}>
        <Select
          path={choicesKindPath}
          options={[
            { label: 'Choix simple', value: 'single' },
            { label: 'Choix multiples', value: 'multiple' },
            { label: 'Texte libre', value: 'free_text' }
          ]}
          onChange={handleChoicesKindChange}
        />
      </FormGroup>

      {form.getValue(choicesKindPath) !== 'free_text' && (
        <div className="question-form-choices-list">
          {choices.map(({ id, label, ...choice }, choiceIndex) => (
            <Fragment key={id}>
              <div className="float-right">
                {choiceIndex < choices.length - 1 && (
                  <ButtonAction
                    className="ml-1"
                    title="Déplacer vers le bas"
                    iconClassName="far fa-arrow-down"
                    onClick={() =>
                      moveChoice(
                        form,
                        choicesPath,
                        choiceIndex,
                        choiceIndex + 1
                      )
                    }
                  />
                )}
                {choiceIndex > 0 && (
                  <ButtonAction
                    className="ml-1"
                    title="Déplacer vers le haut"
                    iconClassName="far fa-arrow-up"
                    onClick={() =>
                      moveChoice(
                        form,
                        choicesPath,
                        choiceIndex,
                        choiceIndex - 1
                      )
                    }
                  />
                )}
                <ButtonAction
                  className="ml-1"
                  title="Supprimer"
                  iconClassName="far fa-trash-alt"
                  onClick={() => deleteChoice(form, choicesPath, choiceIndex)}
                />
              </div>
              <ChoiceForm
                form={form}
                path={choicesPath.concat(choiceIndex)}
                choice={choice}
              />
            </Fragment>
          ))}
          <Button
            title="Ajouter un choix"
            icon="plus"
            type="primary"
            onClick={() => addChoice(form, choicesPath)}
          >
            Ajouter un choix
          </Button>
        </div>
      )}
    </>
  );
};

export default QuestionForm;
