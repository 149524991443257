import ContactUsForm from 'services/ContactUsForm';
import ContactUs from 'services/ContactUs';
import DatePicker from 'components/DatePicker';
import ExceptionalClosingHours from 'services/ExceptionalClosingHours';
import { FormOpeningHours } from '../FormOpeningHours';
import InputPhoneNumber from 'components/InputPhoneNumber';
import InputCodePostal from 'components/InputCodePostal';
import OperatingHours from 'services/OperatingHours';
import React, { Component } from 'react';
import { parseResponseForPublishBar } from 'services/Utilities';
import { Form, FormGroup } from 'reactstrap';
import PublishBar from 'components/PublishBar';
import moment from 'moment';

const ExceptionalClosingHoursView = ({
  exceptionalClosingHour,
  loading,
  handleDatePicker,
  removeExceptionalHours
}) =>
  exceptionalClosingHour.map((el, index) => (
    <div className="form-group" key={el.id || index}>
      <div className="row">
        <div className="col-10 ">
          <DatePicker
            index={index}
            handleDatePicker={handleDatePicker}
            type="exceptional_closing_hour"
            data={el}
          />
        </div>
        <div className="col-1 px-0">
          <button
            title="Supprimer ce créneau exceptionnel"
            type="button"
            disabled={loading}
            onClick={() => removeExceptionalHours(el.id, index)}
            className="btn table-action-btn btn-action-md"
          >
            <i className="far fa-trash-alt" />
          </button>
        </div>
      </div>
    </div>
  ));

/* TODO : Separate presentation from container
    Clean overlapping logic with dates, etc.
 */
class ContactInfos extends Component {
  state = {
    showSaved: false,
    errorMessage: '',
    contactUs: {
      street: '',
      postal_code: '',
      city: '',
      phone: '',
      fax: '',
      email: '',
      free_text: ''
    },
    exceptionalClosingHours: [],
    operatingHours: [],
    exceptionalHoursToDelete: [],
    operatingHoursToDelete: [],
    freeTextLength: 100
  };

  ContactUs = new ContactUs();
  ExceptionalClosingHoursService = new ExceptionalClosingHours();
  OperatingHours = new OperatingHours();
  ContactUsForm = new ContactUsForm();

  indexOfDay = {
    monday: 0,
    tuesday: 1,
    wednesday: 2,
    thursday: 3,
    friday: 4,
    saturday: 5,
    sunday: 6
  };

  default_operating_hours = () => [
    {
      day_of_week: 'monday',
      ranges: [],
      title: 'Lundi',
      startAfterEndRange: [],
      overlappingRange: []
    },
    {
      day_of_week: 'tuesday',
      ranges: [],
      title: 'Mardi',
      startAfterEndRange: [],
      overlappingRange: []
    },
    {
      day_of_week: 'wednesday',
      ranges: [],
      title: 'Mercredi',
      startAfterEndRange: [],
      overlappingRange: []
    },
    {
      day_of_week: 'thursday',
      ranges: [],
      title: 'Jeudi',
      startAfterEndRange: [],
      overlappingRange: []
    },
    {
      day_of_week: 'friday',
      ranges: [],
      title: 'Vendredi',
      startAfterEndRange: [],
      overlappingRange: []
    },
    {
      day_of_week: 'saturday',
      ranges: [],
      title: 'Samedi',
      startAfterEndRange: [],
      overlappingRange: []
    },
    {
      day_of_week: 'sunday',
      ranges: [],
      title: 'Dimanche',
      startAfterEndRange: [],
      overlappingRange: []
    }
  ];

  componentWillMount() {
    this.getForm();
  }

  // TODO : Use try and catch clause to handle errors
  getForm = async () => {
    const { data: operatingHours } = await this.OperatingHours.get();
    let { data: contactUs = {} } = await this.ContactUs.get();
    contactUs = contactUs || {};
    const {
      data: exceptionalClosingHours
    } = await this.ExceptionalClosingHoursService.get();
    const defaultOperatingHours = this.default_operating_hours();

    operatingHours.forEach(operatingHour =>
      defaultOperatingHours[
        this.indexOfDay[operatingHour.day_of_week]
      ].ranges.push(operatingHour)
    );

    this.setState({
      operatingHours: defaultOperatingHours,
      contactUs,
      exceptionalClosingHours
    });
  };

  handleChange = e => {
    const { target } = e;
    const { name } = target;
    const value = target.value;
    const updatedContactUs = { ...this.state.contactUs };

    updatedContactUs[name] = value;
    this.setState({ contactUs: updatedContactUs });
  };

  handleDatePicker = (data, index) => {
    let updatedExceptionalClosingHour = [...this.state.exceptionalClosingHours];
    updatedExceptionalClosingHour[index] = {
      ...(data.start && { begin_at: data.start }),
      ...(data.end && { end_at: data.end })
    };

    this.setState({ exceptionalClosingHours: updatedExceptionalClosingHour });
  };

  setMomentYearMonthDate = momentObject =>
    momentObject
      .year(2000)
      .month(1)
      .date(1);

  handleTimePicker = data => {
    const { day_of_week, index, start, end } = data;
    let operatingHours = [...this.state.operatingHours];
    const operatingDay =
      operatingHours[this.indexOfDay[day_of_week]].ranges[index];

    operatingHours[this.indexOfDay[day_of_week]].ranges[index] = {
      ...(start && {
        ...operatingDay,
        open: this.setMomentYearMonthDate(start).format()
      }),
      ...(end && {
        ...operatingDay,
        close: this.setMomentYearMonthDate(end).format()
      })
    };

    this.setState({ operatingHours });
  };

  addNewRange = day_of_week => {
    const operatingHours = [...this.state.operatingHours];
    operatingHours[this.indexOfDay[day_of_week]].ranges.push({ day_of_week });
    this.setState({ operatingHours });
  };

  deleteRange = (day_of_week, index, id) => {
    let operatingHours = [...this.state.operatingHours],
      operatingHoursToDelete = [...this.state.operatingHoursToDelete];
    const { ranges, overlappingRange, startAfterEndRange } = operatingHours[
      this.indexOfDay[day_of_week]
    ];

    const newRange = ranges.filter((range, i) => i !== index);
    const newOverlappingRange = overlappingRange.filter(
      (range, i) => i !== index
    );
    const newStartAfterEndRange = startAfterEndRange.filter(
      (range, i) => i !== index
    );

    operatingHours[this.indexOfDay[day_of_week]] = {
      ...operatingHours[this.indexOfDay[day_of_week]],
      ranges: newRange,
      overlappingRange: newOverlappingRange,
      startAfterEndRange: newStartAfterEndRange
    };

    id && operatingHoursToDelete.push(id);

    this.setState({
      operatingHours,
      operatingHoursToDelete
    });
  };

  // TODO: Clean this mess
  // bruh, I don't even wanna understand what she tried to do smh
  overlappingFunction = item => {
    let isValidOperatingHour = true;
    const lengthRange = item.ranges.length;
    const overlappingRange = Array(lengthRange).fill(false);
    for (let i = 0; i < lengthRange - 1; i++) {
      // we have to compare hours and minutes
      // all the values of backend have : '2000-01-01'+ houresminu:minutes ....
      // new values in front have :       '2019-02-25'+ houresminu:minutes ....
      const rangeStart = item.ranges[i].open
        ? '2000-01-01' + item.ranges[i].open.slice(10)
        : null;
      const rangeEnd = item.ranges[i].close
        ? '2000-01-01' + item.ranges[i].close.slice(10)
        : null;

      for (let j = i + 1; j < lengthRange; j++) {
        const nextRangeStart = item.ranges[j].open
          ? '2000-01-01' + item.ranges[j].open.slice(10)
          : null;
        const nextRangeEnd = item.ranges[j].close
          ? '2000-01-01' + item.ranges[j].close.slice(10)
          : null;

        if (
          moment(rangeStart).isBetween(nextRangeStart, nextRangeEnd) ||
          moment(rangeEnd).isBetween(nextRangeStart, nextRangeEnd)
        ) {
          overlappingRange[i] = true;
          overlappingRange[j] = true;
          isValidOperatingHour = false;
        } else if (
          moment(nextRangeStart).isBetween(rangeStart, rangeEnd) ||
          moment(nextRangeEnd).isBetween(rangeStart, rangeEnd)
        ) {
          overlappingRange[i] = true;
          overlappingRange[j] = true;
          isValidOperatingHour = false;
        }
      }
    }
    return { overlappingRange, isValidOperatingHour };
  };

  removeExceptionalHours = (id, index) => {
    const { exceptionalClosingHours } = this.state;
    let exceptionalHoursToDelete = [...this.state.exceptionalHoursToDelete];

    id && exceptionalHoursToDelete.push(id);

    const newExceptionalClosingHour = exceptionalClosingHours.filter(
      (range, i) => i !== index
    );

    this.setState({
      exceptionalClosingHours: newExceptionalClosingHour,
      exceptionalHoursToDelete
    });
  };

  addExceptionalHours = () => {
    this.setState(state => ({
      exceptionalClosingHours: [
        ...state.exceptionalClosingHours,
        { begin_at: null, end_at: null }
      ]
    }));
  };

  handleSubmit = event => {
    event.preventDefault();

    let {
      contactUs,
      exceptionalClosingHours,
      operatingHours,
      exceptionalHoursToDelete,
      operatingHoursToDelete
    } = this.state;

    let isValidOverlappingOperatingHours = true;
    let isValidStartAfterEndOperatingHours = true;

    const operating_hours_valid = operatingHours.map(operatingHour => {
      const {
        overlappingRange,
        isValidOperatingHour
      } = this.overlappingFunction(operatingHour);
      if (!isValidOperatingHour)
        isValidOverlappingOperatingHours = isValidOperatingHour;
      return {
        ...operatingHour,
        startAfterEndRange: operatingHour.ranges.map(item => {
          // we have to compare hours and minutes
          const formattedOpen = moment(item.open).format('HH:mm');
          const formattedClose = moment(item.close).format('HH:mm');
          const reply =
            moment(formattedOpen, 'HH:mm').isAfter(
              moment(formattedClose, 'HH:mm')
            ) ||
            formattedOpen === formattedClose ||
            !item.close ||
            !item.open;
          if (reply) isValidStartAfterEndOperatingHours = false;
          return reply;
        }),
        overlappingRange
      };
    });

    this.setState({ operatingHours: operating_hours_valid });

    const operating_hours_format_put = operatingHours.map(obj => obj.ranges);
    if (
      isValidOverlappingOperatingHours &&
      isValidStartAfterEndOperatingHours
    ) {
      this.ContactUsForm.saveMultiple(
        contactUs,
        exceptionalClosingHours,
        operating_hours_format_put.flat(),
        exceptionalHoursToDelete,
        operatingHoursToDelete
      ).then(({ data: response }) => {
        parseResponseForPublishBar(response, this, 'marginBottom');
        this.getForm();
      });
    } else {
      const localError = Error('Vérifier la cohérence des horaires.');
      parseResponseForPublishBar(localError, this, 'marginBottom');
    }
  };

  render() {
    const {
      freeTextLength,
      contactUs,
      loading,
      operatingHours,
      exceptionalClosingHours
    } = this.state;
    const {
      street,
      city,
      phone,
      email,
      fax,
      postal_code,
      free_text
    } = contactUs;

    return (
      <Form className="" onSubmit={this.handleSubmit}>
        <div className="card-koba">
          <FormGroup className="row">
            <label
              htmlFor="street"
              className="col-sm-2 col-form-label text-nowrap"
            >
              Adresse <span className="required">*</span>
            </label>
            <div className="col-sm-10 col-xl-8">
              <input
                required
                type="text"
                className="form-control"
                id="street"
                aria-label="rue"
                name="street"
                value={street}
                onChange={this.handleChange}
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <label
              htmlFor="postal_code"
              className="col-sm-2 col-form-label text-nowrap"
            >
              Code postal <span className="required">*</span>
            </label>
            <div className="col-sm-10 col-xl-8">
              <InputCodePostal
                required
                type="text"
                className="form-control"
                id="postal_code"
                name="postal_code"
                aria-label="code postal"
                value={postal_code}
                onChange={this.handleChange}
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <label
              htmlFor="city"
              className="col-sm-2 col-form-label text-nowrap"
            >
              Ville <span className="required">*</span>
            </label>
            <div className="col-sm-10 col-xl-8">
              <input
                required
                type="text"
                className="form-control"
                id="city"
                aria-label="ville"
                name="city"
                value={city}
                onChange={this.handleChange}
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <label htmlFor="" className="col-sm-2 col-form-label text-nowrap">
              Horaires
            </label>
          </FormGroup>
          {operatingHours.map((item, index) => (
            <FormOpeningHours
              key={index}
              handleTimePicker={this.handleTimePicker}
              title={item.title}
              day_of_week={item.day_of_week}
              data={item.ranges}
              startAfterEndRange={item.startAfterEndRange}
              overlappingRange={item.overlappingRange}
              deleteRange={this.deleteRange}
              addNewRange={this.addNewRange}
            />
          ))}

          <FormGroup className="row">
            <label htmlFor="" className="col-sm-2 col-form-label">
              Fermeture exceptionnelle
            </label>
            <div className="col-sm-10 col-xl-8">
              {exceptionalClosingHours.length > 0 && (
                <ExceptionalClosingHoursView
                  exceptionalClosingHour={exceptionalClosingHours}
                  loading={loading}
                  handleDatePicker={this.handleDatePicker}
                  removeExceptionalHours={this.removeExceptionalHours}
                />
              )}
              <button
                type="button"
                disabled={loading}
                className="btn btn-sm btn-outline-primary mt-3 mb-3"
                onClick={this.addExceptionalHours}
              >
                Ajouter fermeture exceptionnelle
              </button>
            </div>
          </FormGroup>
          <span style={{ fontSize: '0.7em' }} className="offset-sm-2">
            {`(Maximum ${freeTextLength} caractères)`}
          </span>
          <FormGroup className="row">
            <label htmlFor="free_text" className="col-sm-2 col-form-label">
              Information(s) supplémentaire(s)
            </label>
            <div className="col-sm-10 col-xl-8">
              <textarea
                className="form-control"
                id="free_text"
                aria-label="Info"
                placeholder="Informations supplémentaires"
                name="free_text"
                maxLength={freeTextLength}
                value={free_text}
                onChange={this.handleChange}
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <label
              htmlFor="phone"
              className="col-sm-2 col-form-label text-nowrap"
            >
              Téléphone <span className="required">*</span>
            </label>
            <div className="col-sm-10 col-xl-8">
              <InputPhoneNumber
                required
                type="text"
                className="form-control"
                id="phone"
                aria-label="Téléphone"
                placeholder="Téléphone"
                name="phone"
                value={phone}
                onChange={this.handleChange}
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <label
              htmlFor="fax"
              className="col-sm-2 col-form-label text-nowrap"
            >
              Fax
            </label>
            <div className="col-sm-10 col-xl-8">
              <InputPhoneNumber
                type="text"
                className="form-control"
                id="fax"
                name="fax"
                placeholder="Fax"
                aria-label="Fax"
                value={fax}
                onChange={this.handleChange}
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <label
              htmlFor="email"
              className="col-sm-2 col-form-label text-nowrap"
            >
              Email
            </label>
            <div className="col-sm-10 col-xl-8">
              <input
                type="text"
                name="email"
                className="form-control"
                id="email"
                aria-label="email"
                placeholder="Email"
                value={email}
                onChange={this.handleChange}
              />
            </div>
          </FormGroup>
        </div>
        <PublishBar
          state={'published'}
          handleSubmit={() => {}}
          buttonDisabled={loading}
        />
      </Form>
    );
  }
}

export default ContactInfos;
