import Block from '../../Blocks/Block';
import FlipMove from 'react-flip-move';
import React, { Component } from 'react';
import { guid, moveArrayItemToPosition } from 'services/Utilities';

class Blocks extends Component {
  state = {};

  moveUp = i => {
    const { blocks, updateLocalBlocks } = this.props;
    const orderedBlocks = moveArrayItemToPosition(blocks, i, i - 1);
    updateLocalBlocks(orderedBlocks);
  };

  moveTop = i => {
    const { blocks, updateLocalBlocks } = this.props;
    const orderedBlocks = moveArrayItemToPosition(blocks, i, 0);
    updateLocalBlocks(orderedBlocks);
  };

  moveDown = i => {
    const { blocks, updateLocalBlocks } = this.props;
    const orderedBlocks = moveArrayItemToPosition(blocks, i, i + 1);
    updateLocalBlocks(orderedBlocks);
  };

  moveBottom = i => {
    const { blocks, updateLocalBlocks } = this.props;
    const orderedBlocks = moveArrayItemToPosition(blocks, i, blocks.length - 1);
    updateLocalBlocks(orderedBlocks);
  };

  deleteInFront = id => {
    const { blocks, updateLocalBlocks } = this.props;
    const blockIndex = blocks.findIndex(block => block.id === id);
    blocks.splice(blockIndex, 1);
    updateLocalBlocks(blocks);
  };

  duplicateBlock = block => {
    // We have to recursively delete the id of the block / subblocks
    const deleteId = b => {
      delete b.id;
      b.sub_blocks = b.sub_blocks.map(subBlock => {
        return deleteId(subBlock);
      });
      return b;
    };

    const newBlock = { ...block };
    return deleteId(newBlock);
  };

  duplicate = id => {
    const { blocks, addBlock } = this.props;
    const blockIndex = blocks.findIndex(block => block.id === id);
    const blockToDuplicate = blocks[blockIndex];

    const clone = this.duplicateBlock(blockToDuplicate);
    addBlock(clone, blockIndex + 1);
  };

  render() {
    const { blocks } = this.props;

    return (
      <FlipMove>
        {blocks &&
          blocks.map((block, i) => (
            <div className="card-koba mb-4" key={block.id || guid()}>
              <div>
                <div className="float-right">
                  {i > 0 && (
                    <>
                      <button
                        type="button"
                        onClick={() => this.moveTop(i)}
                        className="btn table-action-btn btn-action mr-1"
                      >
                        <i
                          className="fas fa-angle-double-up ml-0 mr-0"
                          title="Monter le block en premier"
                        />
                      </button>
                      <button
                        type="button"
                        onClick={() => this.moveUp(i)}
                        className="btn table-action-btn btn-action mr-1"
                      >
                        <i
                          className="far fa-arrow-up ml-0 mr-0"
                          title="Monter le block"
                        />
                      </button>
                    </>
                  )}
                  {i < blocks.length - 1 && (
                    <>
                      <button
                        type="button"
                        onClick={() => this.moveBottom(i)}
                        className="btn table-action-btn btn-action mr-1"
                      >
                        <i
                          className="fas fa-angle-double-down ml-0 mr-0"
                          title="Descendre le block en dernier"
                        />
                      </button>
                      <button
                        type="button"
                        onClick={() => this.moveDown(i)}
                        className="btn table-action-btn btn-action mr-1"
                      >
                        <i
                          className="far fa-arrow-down ml-0 mr-0"
                          title="Descendre le block"
                        />
                      </button>
                    </>
                  )}
                  <button
                    type="button"
                    onClick={() => this.duplicate(block.id)}
                    className="btn table-action-btn btn-action mr-1"
                  >
                    <i className="far fa-copy" title="Dupliquer le block" />
                  </button>
                  <button
                    type="button"
                    onClick={() => this.deleteInFront(block.id)}
                    className="btn table-action-btn btn-action mr-1"
                  >
                    <i
                      className="far fa-trash-alt"
                      title="Supprimer le block"
                    />
                  </button>
                </div>
                <h4>
                  Editer un Bloc
                  <br />
                </h4>
                <br />
              </div>
              <Block
                block={block}
                getPageable={this.props.getPageable}
                updateLocalBlock={this.props.updateLocalBlock}
              />
            </div>
          ))}
      </FlipMove>
    );
  }
}

export default Blocks;
