import React, { Component } from 'react';
import Filedrop from 'components/Filedrop';
import PhotoPreview from 'components/PhotoPreview';
import { Input } from 'reactstrap';
import EditorConvertToHTML from 'components/Editor';
import DatePickerSingle from 'components/DatePickerSingle';
import { imageUpload } from 'services/Utilities';
import './index.scss';
import Spinner from 'components/Spinner';

export const PhotoLibrary = ({
  photos,
  deleteImage,
  handleFileChange,
  coverPhotoUrl
}) => {
  return (
    photos.length > 0 &&
    photos.map((image, index) => {
      const isSelected = image.url === coverPhotoUrl;
      return (
        <div
          key={index}
          className={
            'col-12 col-md-6 col-lg-4 pb-4 pt-4 ' +
            (isSelected ? 'imageSelected' : '')
          }
        >
          <PhotoPreview image={image} className={'form-control'} />
          <div className="form-check mt-2">
            {!isSelected && (
              <button
                title="Supprimer"
                type="button"
                onClick={() => {
                  deleteImage(image);
                }}
                className="btn float-right far fa-trash-alt"
              />
            )}
            <input
              className="form-check-input"
              type="checkbox"
              checked={isSelected}
              onChange={() => handleFileChange(image)}
            />
            <label className="form-check-label" htmlFor="showPeriod">
              Choisir comme couverture
            </label>
          </div>
        </div>
      );
    })
  );
};

export default class FormBase extends Component {
  state = {
    loadingImage: false,
    loadMoreThenTenPhotos: false
  };

  maxLoadFiles = 10;

  handleFileChange = image => {
    const sameImage = image.url === this.props.cover_photo_url;
    if (sameImage) {
      this.props.handleChange('cover_photo_url', null);
      this.props.handleChange('cover_photo', null);
    } else {
      this.props.handleChange('cover_photo_url', image.url);
      this.props.handleChange('cover_photo', image.signed_id);
    }
  };

  handleAddPhoto = async file => {
    this.setState({ FileDropKey: +new Date(), loadingImage: true });
    const { photos } = this.props;

    if (file.length > this.maxLoadFiles) {
      this.setState({ loadingImage: false, loadMoreThenTenPhotos: true });
      return null;
    }

    const promises = [];

    for (let i = 0; i < file.length; i++) {
      promises.push(await imageUpload(file[i]));
    }

    promises.forEach(imageData =>
      photos.push({
        url: imageData.url,
        signed_id: imageData.signed_id,
        attachment_id: null
      })
    );

    this.props.handleChange('photos', photos);
    this.setState({ loadingImage: false, loadMoreThenTenPhotos: false });
  };

  deleteImage = imageToDelete => {
    const { photos, cover_photo_url, handleChange } = this.props;
    const new_photos = photos.filter(
      image => image.signed_id !== imageToDelete.signed_id
    );
    this.props.handleChange('photos', new_photos);
    if (imageToDelete.url === cover_photo_url) {
      handleChange('cover_photo_url', null);
      handleChange('cover_photo', null);
    }
    if (imageToDelete.attachment_id) {
      this.props.deletePhoto(imageToDelete.attachment_id);
    }
  };

  render() {
    const { FileDropKey, loadingImage, loadMoreThenTenPhotos } = this.state;
    const {
      title,
      handleChange,
      handleChangePicker,
      publication_date,
      handleChangeTextArea,
      description,
      photos,
      cover_photo_url
    } = this.props;
    const template = localStorage.getItem('template');
    const ratio = localStorage.getItem('ratio');
    const ratioPhoto = JSON.parse(ratio)[template].photo;

    return (
      <>
        <div className="card-koba mb-4 mt-4">
          <>
            <div className="row">
              <div className="col-sm-6">
                <label htmlFor="title" className="col-form-label">
                  Titre de l'album <span className="required">*</span>
                </label>
                <Input
                  className="form-control"
                  type="text"
                  name="title"
                  aria-label="titre"
                  autoComplete="off"
                  id="title"
                  value={title}
                  onChange={event =>
                    handleChange(event.target.name, event.target.value)
                  }
                  required
                />
              </div>
              <div className="col-sm-6 date-picker">
                <label htmlFor="publication_date" className="col-form-label">
                  Date de publication <span className="required">*</span>
                </label>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text text-secondary">
                      <i className="fal fa-calendar-alt" />
                    </span>
                  </div>
                  <DatePickerSingle
                    handleDatePicker={handleChangePicker}
                    type="publish"
                    data={publication_date}
                    single={true}
                    className="full-width"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <label htmlFor="description" className="col-form-label">
                  Description de l'album
                </label>
                <EditorConvertToHTML
                  onHTMLChange={handleChangeTextArea}
                  initValue={description}
                />
              </div>
            </div>
          </>
        </div>

        <div className="card-koba mb-4">
          <div className="row">
            <div className="col-12">
              <label htmlFor="image" className="col-form-label">
                Liste des photos
              </label>
            </div>
          </div>

          <div className="mt-2 mb-4">
            <label htmlFor="image" className="col-form-label">
              Ajouter une photo supplémentaire
              <br />
              <span className="text-muted  blockquote-footer">
                Poids: 3Mo(max) | Type: jpg/png |
                <span className={loadMoreThenTenPhotos ? 'redColor' : ''}>
                  {' '}
                  Max: {this.maxLoadFiles} photos à la fois{' '}
                </span>
              </span>
            </label>
            {loadingImage && (
              <div className="text-center">
                <Spinner />
              </div>
            )}
            <Filedrop
              disabled={loadingImage}
              withCropper
              name="image"
              accepts="images"
              key={FileDropKey}
              onFileChange={this.handleAddPhoto}
              ratio={ratioPhoto}
              multiple={true}
            />
          </div>
          <div className="row">
            <PhotoLibrary
              handleFileChange={this.handleFileChange}
              photos={photos}
              coverPhotoUrl={cover_photo_url}
              deleteImage={this.deleteImage}
            />
          </div>
        </div>
      </>
    );
  }
}
