import React, { Component } from 'react';
import { Popconfirm } from 'antd';
import Input from 'components/Input';
import Filedrop from 'components/Filedrop';
import 'antd/lib/popconfirm/style/css.js';
import './index.scss';

export default class Slide extends Component {
  render() {
    const {
      slide,
      deleteSlide,
      updateSlide,
      updateImage,
      deleteImage,
      deleteLink,
      moveSlide,
      ratio,
      index,
      toggleModalSelectLink,
      totalSlide
    } = this.props;

    return (
      <div className="card-koba mb-4">
        <div className="float-right">
          {index !== totalSlide - 1 && (
            <button
              type="button"
              onClick={() => {
                moveSlide(index, index + 1);
              }}
              className="btn table-action-btn btn-action mr-1"
            >
              <i
                className="far fa-arrow-down ml-0 mr-0"
                title="Descendre l'image"
              />
            </button>
          )}
          {index > 0 && (
            <button
              type="button"
              onClick={() => {
                moveSlide(index, index - 1);
              }}
              className="btn table-action-btn btn-action mr-1"
            >
              <i className="far fa-arrow-up ml-0 mr-0" title="Monter l'image" />
            </button>
          )}
          <Popconfirm
            title="Etes-vous sûr de vouloir supprimer?"
            onConfirm={() => deleteSlide(index)}
            onCancel={() => {}}
            okText="Oui"
            cancelText="Annuler"
          >
            <button
              type="button"
              className="btn table-action-btn btn-action mr-1"
            >
              <i className="far fa-trash-alt" title="Supprimer l'image" />
            </button>
          </Popconfirm>
        </div>
        <div className="clearfix" />
        <div className="row">
          <div className="col-sm-12">
            <Input
              name="title"
              label="Titre de la publication"
              aria-label="Titre de la publication"
              value={slide.title || ''}
              onChange={event => updateSlide(event, index)}
              maxLength="40"
            />
            <Input
              textarea
              rows="3"
              name="description"
              label="Description de la publication"
              aria-label="Description de la publication"
              value={slide.description || ''}
              onChange={event => updateSlide(event, index)}
              maxLength="40"
            />
          </div>
          <div className="col-sm-12">
            <Filedrop
              required
              withCropper
              aria-label="image"
              name="image"
              accepts="images"
              initValue={(slide.imageable && slide.imageable.media_url) || ''}
              onFileChange={(file, croppedImage) =>
                updateImage(file, croppedImage, index)
              }
              deleteImage={() => deleteImage(index)}
              ratio={ratio}
            />
          </div>
        </div>
        <div>
          <label htmlFor="content" className="col-form-label">
            URL de la page associée
          </label>
          <div className="d-flex flex-row justify-content-between">
            <button
              type="button"
              onClick={() => toggleModalSelectLink(index)}
              className="btn table-action-btn btn-action-md mr-2"
              style={{ width: '2.9rem', height: '2.9rem' }}
            >
              <i className="far fa-link" />
            </button>
            <div className="link-field alert mb-0 target text-truncate flex-fill ">
              <div onClick={() => deleteLink(index)}>
                <i
                  className="fal fa-times float-right"
                  title="Supprimer le lien"
                />
              </div>
              <div className="text-truncate text">
                {slide.url ||
                  (slide.link && slide.link.data) ||
                  (slide.linkable && slide.linkable.data) ||
                  ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
