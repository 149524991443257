import AddressesList from './AddressesList';
import AddressLayout from './Layout';
import Submenu from 'components/Submenu';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { pageMatcher } from 'constants/Global';
import NewSection from '../Sections/New';
import EditSection from '../Sections/Edit';
import Sections from '../Sections';
import ProcedureService from 'services/Adresses';

class Adressess extends Component {
  subMenuData = [
    pageMatcher.admin.addresses.annuaire,
    pageMatcher.admin.addresses.categories
  ];

  render() {
    return (
      <>
        <Submenu subMenuData={this.subMenuData} />
        <Switch>
          <Redirect
            exact={true}
            from={pageMatcher.admin.addresses.path}
            to={pageMatcher.admin.addresses.annuaire.path}
          />
          <Route
            path={[
              pageMatcher.admin.addresses.annuaire.new.path,
              pageMatcher.admin.addresses.annuaire.single.path
            ]}
            render={props => <AddressLayout {...this.props} {...props} />}
          />
          <Route
            path={pageMatcher.admin.addresses.annuaire.path}
            render={() => <AddressesList {...this.props} />}
          />

          <Route
            path={pageMatcher.admin.addresses.categories.new.path}
            render={() => (
              <NewSection
                {...this.props}
                url={pageMatcher.admin.addresses.categories.path}
                kind="directory_address"
              />
            )}
          />
          <Route
            path={pageMatcher.admin.addresses.categories.single.path}
            render={props => (
              <EditSection
                {...this.props}
                url={pageMatcher.admin.addresses.categories.path}
                {...props}
              />
            )}
          />
          <Route
            path={pageMatcher.admin.addresses.categories.path}
            render={() => (
              <Sections
                {...this.props}
                url="addresses/categories/new"
                ProcedureService={ProcedureService}
                kind="category_addresses"
              />
            )}
          />
        </Switch>
      </>
    );
  }
}

export default Adressess;
