import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import SideBarSubElement from '../SideBarSubElement/';
import { slugify } from 'services/Utilities';

class SideBarCollapsingElement extends Component {
  /*
   * elTitle once slugified should be unique in a very Menu for this to work.
   * */

  state = {
    elTitleSlug: slugify(this.props.elTitle)
  };

  render() {
    const { subMenuElements, elTitle } = this.props;

    return (
      <>
        <li
          role="button"
          className="nav-item"
          data-toggle="collapse"
          href={`#${this.state.elTitleSlug}`}
          aria-expanded="false"
          aria-controls={this.state.elTitleSlug}
        >
          <NavLink
            to="#"
            role="button"
            tabIndex={0}
            title={elTitle}
            className="nav-link"
            activeClassName=""
          >
            <i className="fal fa-align-center" />
            {elTitle}
            <span className="ml-1">
              <i className="fas fa-caret-down" />
            </span>
          </NavLink>
        </li>
        <div
          role="presentation"
          className="collapse multi-collapse h200px"
          id={this.state.elTitleSlug}
        >
          {subMenuElements &&
            subMenuElements.map(subMenuEl => (
              <SideBarSubElement data={subMenuEl} key={subMenuEl.path} />
            ))}
        </div>
      </>
    );
  }
}
export default SideBarCollapsingElement;
