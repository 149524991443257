import CategoryService from 'services/Category';
import Editor from 'components/Editor';
import InputPhoneNumber from 'components/InputPhoneNumber';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormButtons from 'components/FormButtons';
import LocationSearchInput from 'components/LocationSearchInput';
import './index.scss';

const TelephoneFields = ({ telephones, handler }) =>
  telephones.map((telephone, index) => (
    <InputPhoneNumber
      key={index}
      className="mb-4"
      id="phone_numbers"
      name="phone_numbers"
      value={telephone}
      onChange={event => handler(event, index)}
    />
  ));

const Options = ({ options }) =>
  options.map(el => (
    <option key={el.id} value={el.id}>
      {el.name}
    </option>
  ));

class AddressForm extends Component {
  state = { categories: null };

  categoryService = new CategoryService();

  componentDidMount() {
    this.getCategoryList();
  }

  getCategoryList = () => {
    this.categoryService
      .get(null, '?kind=directory_address')
      .then(({ data: response }) => {
        this.setState({ categories: response });
      });
  };

  render() {
    const {
      id,
      gender,
      company_name,
      category_id,
      first_name,
      last_name,
      street_name,
      handleChange,
      handleAddressSelection,
      handleAddressChange,
      handleSubmit,
      handlePhoneNumbers,
      handleHTML,
      addField,
      removeField,
      fax,
      email,
      phone_numbers,
      description,
      website,
      goBackLinkTitle,
      goBackLink
    } = this.props;

    const { categories } = this.state;

    return (
      <form onSubmit={handleSubmit}>
        <div className="card-koba address_base">
          <div className="form-group d-flex flex-sm-row flex-column">
            <label
              htmlFor="company_name"
              className=" col-12 col-sm-2 col-form-label"
            >
              Nom <span className="required">*</span>
            </label>
            <div className=" col-12 col-sm-10">
              <input
                required
                placeholder="commerce, entreprise, équipement, association ... "
                autoComplete="off"
                type="text"
                className="form-control"
                id="company_name"
                aria-label="nom de société"
                name="company_name"
                aria-describedby="titleHelp"
                value={company_name}
                onChange={event => handleChange(event)}
              />
            </div>
          </div>

          <div className="form-group d-flex flex-sm-row flex-column">
            <label
              htmlFor="category_id"
              className="col-12 col-sm-2 col-form-label text-nowrap"
            >
              Catégorie <span className="required">*</span>
            </label>
            <div className=" col-12 col-sm-10">
              <select
                className="custom-select"
                id="category_id"
                name="category_id"
                value={category_id}
                onChange={event => handleChange(event)}
                required
              >
                <option value="">Choisir...</option>
                {categories && <Options options={categories} />};
              </select>
            </div>
          </div>

          <div className="form-group d-flex flex-sm-row flex-column">
            <label
              htmlFor="category_id"
              className="col-12 col-sm-2 col-form-label"
            >
              Civilité
            </label>
            <div className="col-12 col-sm-10">
              <div>
                <div className="custom-control custom-radio form-check-inline">
                  <input
                    className="custom-control-input form-check-input"
                    type="checkbox"
                    id="female"
                    value="female"
                    name="gender"
                    aria-label="genre femme"
                    checked={gender === 'female'}
                    onChange={handleChange}
                    required={!company_name}
                  />
                  <label
                    className="custom-control-label form-check-label"
                    htmlFor="female"
                  >
                    Madame
                  </label>
                </div>
                <div className="custom-control custom-radio form-check-inline">
                  <input
                    className="custom-control-input form-check-input"
                    type="checkbox"
                    id="male"
                    value="male"
                    name="gender"
                    aria-label="genre homme"
                    checked={gender === 'male'}
                    onChange={handleChange}
                  />
                  <label
                    className="custom-control-label  form-check-label"
                    htmlFor="male"
                  >
                    Monsieur
                  </label>
                </div>
              </div>
            </div>
          </div>

          {gender !== 'unset' && (
            <div className="form-group d-flex flex-sm-row flex-column">
              <label
                htmlFor="last_name"
                className=" col-12 col-sm-2 col-form-label "
              >
                Nom <span className="required">*</span>
              </label>
              <div className=" col-12 col-sm-4">
                <input
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  id="last_name"
                  aria-label="nom"
                  name="last_name"
                  aria-describedby="titleHelp"
                  value={last_name}
                  onChange={event => handleChange(event)}
                  required
                />
              </div>
              <label
                htmlFor="first_name"
                className=" col-12 col-sm-2 col-md-1 col-form-label"
                style={{ display: 'flex' }}
              >
                Prénom <span className="required">*</span>
              </label>
              <div className="col-12 col-sm-4">
                <input
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  id="first_name"
                  name="first_name"
                  aria-label="prénom"
                  aria-describedby="titleHelp"
                  value={first_name}
                  onChange={event => handleChange(event)}
                  required
                />
              </div>
            </div>
          )}
          <div className="form-group d-flex flex-sm-row flex-column">
            <label
              htmlFor="Adresse"
              className=" col-12 col-sm-2 col-form-label"
            >
              Adresse
            </label>
            <div className=" col-12 col-sm-10">
              <LocationSearchInput
                value={street_name}
                handleAddressChange={handleAddressChange}
                handleAddressSelection={handleAddressSelection}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex flex-sm-row flex-column">
              <label
                htmlFor="phone_numbers"
                className=" col-12 col-sm-2 col-form-label"
              >
                Téléphone principal
              </label>
              <div className=" col-12 col-sm-10">
                {phone_numbers.length === 0 ? (
                  <InputPhoneNumber
                    className="mb-4"
                    id="phone_numbers"
                    name="phone_numbers"
                    value={phone_numbers[0]}
                    onChange={event => handlePhoneNumbers(event, 0)}
                  />
                ) : (
                  <TelephoneFields
                    telephones={phone_numbers}
                    handler={handlePhoneNumbers}
                  />
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end mr-3">
              {phone_numbers.length > 1 && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={removeField}
                >
                  <i
                    className="fal fa-minus"
                    title="Supprimer le dernier numéro"
                  />
                </button>
              )}
              <button
                type="button"
                className="btn btn-primary ml-2"
                onClick={addField}
              >
                <i
                  className="fal fa-plus"
                  title="Ajouter un numéro de téléphone"
                />
              </button>
            </div>
          </div>
          <div className="form-group d-flex flex-sm-row flex-column">
            <label htmlFor="fax" className=" col-12 col-sm-2 col-form-label">
              Fax
            </label>
            <div className=" col-12 col-sm-10">
              <InputPhoneNumber
                autoComplete="off"
                type="text"
                className="form-control"
                id="fax"
                name="fax"
                aria-label="fax"
                aria-describedby="titleHelp"
                placeholder=""
                value={fax}
                onChange={event => handleChange(event)}
              />
            </div>
          </div>
          <div className="form-group d-flex flex-sm-row flex-column">
            <label htmlFor="email" className=" col-12 col-sm-2 col-form-label">
              Adresse mail
            </label>
            <div className=" col-12 col-sm-10">
              <input
                autoComplete="off"
                type="text"
                className="form-control"
                id="email"
                name="email"
                aria-label="email"
                aria-describedby="titleHelp"
                value={email}
                onChange={event => handleChange(event)}
              />
            </div>
          </div>
          <div className="form-group d-flex flex-sm-row flex-column">
            <label
              htmlFor="website"
              className=" col-12 col-sm-2 col-form-label"
            >
              Site internet
            </label>
            <div className=" col-12 col-sm-10">
              <input
                autoComplete="off"
                type="text"
                className="form-control"
                id="website"
                name="website"
                aria-label="website"
                aria-describedby="titleHelp"
                value={website}
                onChange={event => handleChange(event)}
              />
            </div>
          </div>
          <div className="form-group d-flex flex-sm-row flex-column">
            <label
              htmlFor="description"
              className=" col-12 col-sm-2 col-form-label d-flex flex-column"
            >
              Description
              <br />
              <span className="text-muted  blockquote-footer">
                567 caractères espaces compris
              </span>
            </label>
            <div className=" col-12 col-sm-10">
              <Editor
                onHTMLChange={html => handleHTML(html)}
                initValue={description}
              />
            </div>
          </div>
        </div>
        <FormButtons
          id={id}
          goBackLink={goBackLink}
          goBackLinkTitle={goBackLinkTitle}
        />
      </form>
    );
  }
}

AddressForm.propTypes = {
  gender: PropTypes.string,
  company_name: PropTypes.string,
  category_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  street_name: PropTypes.string,
  handleChange: PropTypes.func,
  handleAddressSelection: PropTypes.func,
  handleSubmit: PropTypes.func,
  handlePhoneNumbers: PropTypes.func,
  handleHTML: PropTypes.func,
  city: PropTypes.string,
  fax: PropTypes.string,
  email: PropTypes.string,
  phone_numbers: PropTypes.array,
  description: PropTypes.string,
  website: PropTypes.string,
  postal_code: PropTypes.string
};

AddressForm.defaultProps = {
  gender: '',
  company_name: '',
  first_name: '',
  last_name: '',
  street_name: '',
  city: '',
  fax: '',
  email: '',
  phone_numbers: [],
  description: '',
  website: '',
  postal_code: ''
};

export default AddressForm;
