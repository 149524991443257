import React, { Component } from 'react';
import AccessibilitiesBase from '../../Accessibilite/AccessibilitiesBase';
import MentionLegalesService from 'services/MentionLegales';
import Submenu from 'components/Submenu';
import * as Utilities from 'services/Utilities';
import PublishBar from 'components/PublishBar';
import { pageMatcher } from 'constants/Global';

class MentionsLegales extends Component {
  state = {
    mentionsLegales: {
      title: '',
      content: ''
    }
  };

  subMenuData = [pageMatcher.admin.legal];
  mentionLegalesService = new MentionLegalesService();

  getMentionsLegales = () => {
    this.mentionLegalesService.get().then(response => {
      if (!response.data) return;

      const { title, content } = response.data;

      this.setState({
        mentionsLegales: { title: title, content: content },
        editMode: true
      });
    });
  };

  postLegality = data => {
    this.mentionLegalesService.post(data).then(({ data: response }) => {
      Utilities.parseResponseForPublishBar(response, this, 'marginBottom');
    });
  };

  editLegality = data => {
    this.mentionLegalesService.put(null, data).then(({ data: response }) => {
      Utilities.parseResponseForPublishBar(response, this, 'marginBottom');
    });
  };

  componentDidMount() {
    this.getMentionsLegales();
  }

  handleChangeTextArea = html => {
    const mentionsLegales = { ...this.state.mentionsLegales };
    mentionsLegales.content = html;
    this.setState({ mentionsLegales });
  };

  handleChange = event => {
    const newState = { ...this.state };
    newState.mentionsLegales[event.target.name] = event.target.value;
    this.setState({ newState });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { editMode, mentionsLegales } = this.state;

    editMode
      ? this.editLegality(mentionsLegales)
      : this.postLegality(mentionsLegales);
  };

  render() {
    return (
      <>
        <Submenu subMenuData={this.subMenuData} />
        <AccessibilitiesBase
          {...this.state.mentionsLegales}
          handleChangeTextArea={this.handleChangeTextArea}
          handleChange={this.handleChange}
          editeMode={this.state.editMode}
        />
        <PublishBar
          state={'published'}
          handleSubmit={this.handleSubmit}
          buttonDisabled={this.state.loading}
        />
      </>
    );
  }
}

export default MentionsLegales;
