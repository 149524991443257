import React from 'react';

export default class index extends React.Component {
  render() {
    return (
      <div>
        <p>
          Saisissez ici les informations de facturation ainsi que votre
          courriel.
        </p>
        <p>
          Cliquez ensuite sur le bouton de paiement pour être redirigé(e) vers
          un site gouvermental entièrement sécurisé. <br />
        </p>
        <h3>Informations facture</h3>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="payfip-code">
                Numéro de facture <span className="required">*</span>
              </label>
              <input required className="form-control" disabled />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="payfip-year">
                Année <span className="required">*</span>
              </label>
              <input required className="form-control" disabled />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="payfip-account" style={{ display: 'block' }}>
                Montant <span className="required">*</span>
              </label>
              <input
                required
                style={{ width: '75%', display: 'inline' }}
                className="form-control"
                disabled
              />
              <p
                style={{
                  width: '5%',
                  display: 'inline',
                  marginLeft: '5px',
                  marginRight: '5px'
                }}
              >
                ,
              </p>
              <input
                required
                style={{ width: '15%', display: 'inline' }}
                className="form-control"
                disabled
              />
              <p
                style={{
                  width: '5%',
                  display: 'inline',
                  marginLeft: '5px',
                  marginRight: '5px'
                }}
              >
                €
              </p>
            </div>
          </div>
        </div>
        <h3>Informations personnelles</h3>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="payfip-email">
                Adresse e-mail <span className="required">*</span>
              </label>
              <input required className="form-control" disabled />
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-primary mt-3 btn-sm float-right"
          disabled
        >
          Envoyer
        </button>
      </div>
    );
  }
}
