import RssFlows from 'services/RssFlows';

class RSSFlowForm {
  constructor(type) {
    this.type = type;
    this.RssFlows = new RssFlows();
  }

  saveMultiple = async (rssFlows, rssFlowsToDelete) => {
    let promises = [];

    if (rssFlowsToDelete !== []) {
      const arr = rssFlowsToDelete;
      for (let i = 0; i < arr.length; i++) {
        let id = arr[i];
        promises.push(this.RssFlows.deleteInPromiseAll(id));
      }
    }

    if (rssFlows !== []) {
      const arr = rssFlows;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id instanceof Date) {
          delete arr[i].id;
        }
      }
      for (let i = 0; i < arr.length; i++) {
        let id = arr[i].id;
        let data = arr[i];
        if (id) {
          delete data.id;
          promises.push(this.RssFlows.putInPromiseAll(id, data));
        } else {
          promises.push(this.RssFlows.postInPromiseAll(data));
        }
      }
    }

    return await Promise.all(promises);
  };
}

export default RSSFlowForm;
