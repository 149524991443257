import Axios from 'services/Axios';
import Ressource from 'services/Ressource';

export default class OperatingHours extends Ressource {
  constructor() {
    super('operating_hours');
  }

  post = (type, data) => Axios.post(type, { operating_hours: data });

  put = (type, data, option) =>
    Axios.put(`${type}/${option}`, { operating_hours: data });
}
