import React, { Component } from 'react';
import AddressService from 'services/Adresses';
import AddressForm from '../AdresseForm';
import { parseResponseForPublishBar } from 'services/Utilities';
import { pageMatcher } from 'constants/Global';
import { ModalLoading } from 'components/ModalLoading';
import { findAddressComponent } from 'services/GooglePlace';

class AddressLayout extends Component {
  state = {
    loading: true,
    directory_address: {
      first_name: '',
      last_name: '',
      street_name: '',
      postal_code: '',
      gender: 'unset',
      city: '',
      location: {},
      category_id: 0,
      fax: '',
      phone_numbers: [''],
      email: '',
      website: '',
      description: '',
      opening_hours: '',
      pro: true,
      company_name: '',
      photos: []
    }
  };

  addressService = new AddressService();
  id = this.props.match.params.id;

  getAddress = id => {
    if (!id) return this.setState({ loading: false });

    this.addressService.get(id).then(({ data: response }) => {
      const directory_address = {
        ...response,
        category_id: response.category.id
      };
      this.setState({ directory_address, loading: false });
    });
  };

  addField = () => {
    this.setState(({ directory_address }) => ({
      directory_address: {
        ...directory_address,
        phone_numbers: [...directory_address.phone_numbers, '']
      }
    }));
  };

  removeField = () => {
    this.setState(({ directory_address }) => {
      directory_address.phone_numbers.splice(-1, 1);

      return {
        directory_address: {
          ...directory_address,
          phone_numbers: [...directory_address.phone_numbers]
        }
      };
    });
  };

  postAddress = () =>
    this.addressService
      .post(this.state.directory_address)
      .then(({ data: response }) => {
        parseResponseForPublishBar(response, this, true);
        this.props.history.push(pageMatcher.admin.addresses.path);
      })
      .catch(e => {
        parseResponseForPublishBar(e, this, true);
      });

  putAddress = id => {
    const { category, ...data } = this.state.directory_address;
    this.addressService
      .put(id, data)
      .then(({ data: response }) => {
        parseResponseForPublishBar(response, this, true);
        this.props.history.push(pageMatcher.admin.addresses.path);
      })
      .catch(e => {
        parseResponseForPublishBar(e, this, true);
      });
  };

  handleChange = event => {
    const { gender } = this.state.directory_address;
    const { name, type } = event.target;
    let { value } = event.target;
    if (type === 'checkbox') value = gender === value ? 'unset' : value;

    this.setState(({ directory_address }) => ({
      directory_address: {
        ...directory_address,
        [name]: value,
        ...(value === 'unset' && {
          first_name: '',
          last_name: ''
        })
      }
    }));
  };

  handleAddressChange = value => {
    this.setState(({ directory_address }) => ({
      directory_address: {
        ...directory_address,
        street_name: value
      }
    }));
  };

  handleAddressSelection = ([addressObject]) => {
    if (!addressObject) return;
    /*
     If the selected address is a valid Google "address",
     it will have a route and possibly a street number
     Else if it is a "place",
     we fallback on the addressObject.name (ex: Mairie de Molineuf)
     and put it in the street field
    */
    const streetNumber = findAddressComponent(
      'street_number',
      addressObject.address_components
    );
    const route = findAddressComponent(
      'route',
      addressObject.address_components
    );
    const street_name =
      [streetNumber, route].filter(id => id).join(' ') || addressObject.name;

    this.setState(({ directory_address }) => ({
      directory_address: {
        ...directory_address,
        location: {
          latitude: addressObject.geometry.location.lat(),
          longitude: addressObject.geometry.location.lng()
        },
        street_name,
        postal_code: findAddressComponent(
          'postal_code',
          addressObject.address_components
        ),
        city: findAddressComponent('locality', addressObject.address_components)
      }
    }));
  };

  handlePhoneNumbers = (event, index) => {
    const { value } = event.target;
    this.setState(({ directory_address }) => ({
      directory_address: {
        ...directory_address,
        phone_numbers: directory_address.phone_numbers.map(
          (item, position) => (position === index ? value : item)
        )
      }
    }));
  };

  handleHTML = html => {
    this.setState(({ directory_address }) => ({
      directory_address: {
        ...directory_address,
        description: html
      }
    }));
  };

  handleSubmit = event => {
    event.preventDefault();

    this.id ? this.putAddress(this.id) : this.postAddress();
  };

  componentDidMount() {
    this.getAddress(this.id);
  }

  render() {
    const { directory_address, loading } = this.state;

    if (loading) return <ModalLoading loading={loading} />;

    return (
      <AddressForm
        {...directory_address}
        addField={this.addField}
        removeField={this.removeField}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        handleAddressChange={this.handleAddressChange}
        handleAddressSelection={this.handleAddressSelection}
        handlePhoneNumbers={this.handlePhoneNumbers}
        handleHTML={this.handleHTML}
        goBackLink={pageMatcher.admin.addresses.path}
        goBackLinkTitle="Retourner à la liste"
      />
    );
  }
}

export default AddressLayout;
