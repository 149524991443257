import React, { Component } from 'react';
import Contacts from 'services/Contacts';
import ContactBase from '../ContactBase';
import * as Utilities from 'services/Utilities';
import PublishBar from 'components/PublishBar';
import { pageMatcher } from 'constants/Global';
import { ModalLoading } from 'components/ModalLoading';

class LayoutContact extends Component {
  state = {
    contact: {
      personal_information_attributes: {
        gender: '',
        firstname: '',
        lastname: '',
        phone: '',
        fax: '',
        address: '',
        city: '',
        country: '',
        birthdate: null,
        email: null,
        zipcode: '',
        newsletter: false,
        sms_newsletter: false
      }
    },
    loading: true
  };

  Contacts = new Contacts();
  id = this.props.match.params.id;

  componentDidMount() {
    this.getContact();
  }

  getContact = () => {
    if (!this.id) return this.setState({ loading: false });

    this.Contacts.get(this.id).then(({ data: response }) => {
      this.setState({
        contact: {
          ...(response && {
            personal_information_attributes: response.personal_information
          })
        },
        loading: false
      });
    });
  };

  handleChange = e => {
    const {
      target,
      target: { name }
    } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState(({ contact }) => ({
      contact: {
        ...contact,
        personal_information_attributes: {
          ...contact.personal_information_attributes,
          [name]: value
        }
      }
    }));
  };

  handleDatePicker = date => {
    if (date === null) return;

    this.setState(({ contact }) => ({
      contact: {
        ...contact,
        personal_information_attributes: {
          ...contact.personal_information_attributes,
          birthdate: date
        }
      }
    }));
  };

  handleSubmit = event => {
    event.preventDefault();

    const { history } = this.props;
    const { contact } = this.state;
    const promise = this.id
      ? this.Contacts.put(this.id, contact)
      : this.Contacts.post(contact);

    promise.then(response => {
      Utilities.parseResponseForPublishBar(response, this, true);
      history.push(pageMatcher.admin.citizens.path);
    });
  };

  render() {
    const { contact, loading } = this.state;

    if (loading) return <ModalLoading loading={loading} />;

    return (
      <form onSubmit={this.handleSubmit}>
        <ContactBase
          {...contact.personal_information_attributes}
          editMode={true}
          handleChange={this.handleChange}
          handleDatePicker={this.handleDatePicker}
        />
        <PublishBar
          state="published"
          buttonDisabled={loading}
          goBackLink={pageMatcher.admin.citizens.path}
          goBackLinkTitle="Retourner à la liste"
          showSubmitButton
        />
      </form>
    );
  }
}

export default LayoutContact;
