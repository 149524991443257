import React, { Component } from 'react';
import PhotoAlbumService from 'services/PhotoAlbums';
import Options from 'components/Options';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import TagSearch from 'components/TagSearch';
import moment from 'moment';
import Spinner from 'components/Spinner';
import { Link } from 'react-router-dom';
import {
  buildPaginationOptions,
  deleteItemFromArray
} from 'services/Utilities';
import { pageMatcher } from 'constants/Global';
import '../index.scss';

export default class ArchivedList extends Component {
  state = {
    allAlbums: [],
    filteredAlbums: [],
    users: [],
    loading: true
  };

  photoAlbumService = new PhotoAlbumService();

  getPhotoAlbums = () => {
    this.photoAlbumService
      .getOnly('?scopes[archived]')
      .then(({ data: response }) => {
        this.setState({
          filteredAlbums: response,
          allAlbums: response,
          loading: false
        });
      });
  };

  componentDidMount() {
    this.getPhotoAlbums();
  }

  deletePhotoAlbum = id => {
    this.photoAlbumService.delete(id).then(({ data: _response }) => {
      const filteredAlbums = deleteItemFromArray(
        [...this.state.filteredAlbums],
        id
      );
      this.setState({ filteredAlbums });
    });
  };

  unarchivePhotoAlbum = id => {
    this.photoAlbumService
      .change(id, 'unarchive')
      .then(({ data: _response }) => {
        const filteredAlbums = deleteItemFromArray(
          [...this.state.filteredAlbums],
          id
        );
        this.setState({ filteredAlbums });
      });
  };

  optionsFormatter = (cell, row) => (
    <Options
      row={row}
      delete={this.deletePhotoAlbum}
      label="un album photo"
      kind="photo_librairies"
      addButton={false}
      unarchiving={this.unarchivePhotoAlbum}
    />
  );

  photoFormatter = (cell, _row) => (
    <img src={cell} alt="couverture" className="table-photo" />
  );

  dateFormatter = (cell, _row) => {
    if (cell) {
      return <span>{moment(cell).format('MM/YYYY')}</span>;
    }
  };

  stateFormatter = (cell, _row) => {
    if (cell) {
      if (cell === 'archived') {
        return <span>archivé</span>;
      }
    }
  };

  filterList = event => {
    const updatedList = this.state.allAlbums.filter(
      item =>
        item.title.toLowerCase().indexOf(event.target.value.toLowerCase()) !==
          -1 ||
        item.author.toLowerCase().indexOf(event.target.value.toLowerCase()) !==
          -1
    );

    this.setState({ photos: updatedList });
  };

  render() {
    const { loading, filteredAlbums } = this.state;
    const optionsPagination = buildPaginationOptions();
    const columns = this.photoAlbumService.buildColumns(
      this.photoFormatter,
      this.dateFormatter,
      this.stateFormatter,
      this.optionsFormatter
    );

    return (
      <div className="photo_library">
        <TagSearch
          handleSearch={this.filterList}
          buttonTitle="Ajouter un nouvel album"
          buttonPlusLien={pageMatcher.admin.content.photoLibrairies.new.path}
          extraButton={
            <Link
              to={pageMatcher.admin.content.photoLibrairies.path}
              title={pageMatcher.admin.content.photoLibrairies.pageTitle}
              tabIndex={-1}
            >
              <button type="button" className="btn btn-secondary mr-2">
                Voir les albums non archivés
              </button>
            </Link>
          }
        />
        <BootstrapTable
          keyField="id"
          data={filteredAlbums}
          columns={columns}
          bordered={false}
          pagination={paginationFactory(optionsPagination)}
          classes="koba-array"
          filter={filterFactory()}
        />
        {loading && (
          <div className="text-center">
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}
