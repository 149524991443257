import Ressource from 'services/Ressource';
import Axios from 'services/Axios';

class Adresses extends Ressource {
  constructor() {
    super('directory_address');
  }

  importAddresses = data =>
    Axios.post(`directory_addresses/import`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

  export = () => Axios.get('directory_addresses/export');
}

export default Adresses;
