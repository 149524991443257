import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import data from './fontawesome_picto';
import Pictograms from './Pictograms';
import './index.scss';

class PictogramLibrary extends Component {
  state = {
    modal: false,
    data: data
  };

  toggle = () => {
    this.setState(prevState => ({ modal: !prevState.modal }));
  };

  handlePictogramChosen = (index, icon) => {
    this.setState({
      selected: icon.className
    });
    this.toggle();
    this.props.handlePictogramChosen(index, icon);
  };

  componentDidMount() {
    if (this.props.initPicto) {
      this.setState({
        selected: this.props.initPicto
      });
    }
  }

  render() {
    const { selected, data, modal } = this.state;
    const { className } = this.props;

    return (
      <div>
        {selected && (
          <div className="carre-white" onClick={this.toggle}>
            <i title="Modifier l'icône" className={`icon-carre ${selected}`} />
          </div>
        )}
        {!selected && (
          <Button
            type="button"
            className="btn-block"
            color="light"
            onClick={this.toggle}
          >
            Choisir une icône
          </Button>
        )}
        <Modal
          isOpen={modal}
          size="lg"
          toggle={this.toggle}
          className={className}
        >
          <ModalHeader toggle={this.toggle}>
            Bibliothèque de pictogrammes
          </ModalHeader>
          <ModalBody>
            {data.icons.length} icônes disponibles
            {data.categories.map((category, index) => (
              <React.Fragment key={index}>
                <p className="picto-category mt-3">{category.libelle}</p>
                <div className="row">
                  {data.icons.map(
                    (icon, index) =>
                      category.libelle === icon.category ? (
                        <Pictograms
                          key={index}
                          iconClass={icon.className}
                          divClass={
                            selected === icon.className ? 'carre-orange' : ''
                          }
                          category={icon.category}
                          handlePictogramChosen={() =>
                            this.handlePictogramChosen(index, icon)
                          }
                        />
                      ) : null
                  )}
                </div>
              </React.Fragment>
            ))}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default PictogramLibrary;
