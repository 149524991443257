import Input from 'components/Input';
import React, { Component } from 'react';

export default class ChatBase extends Component {
  handleChange = async event => {
    this.props.handleChange({ [event.target.name]: event.target.value });
  };

  render() {
    const { title, interlocutor_username } = this.props.data || {};
    return (
      <div className="card-koba mt-4">
        <Input
          required
          label="Titre du chat"
          aria-label="Titre du chat"
          type="text"
          name="title"
          value={title || ''}
          onChange={this.handleChange}
        />
        <Input
          required
          label="Nom de l'interlocuteur"
          aria-label="Nom de l'interlocuteur"
          type="text"
          name="interlocutor_username"
          value={interlocutor_username || ''}
          onChange={this.handleChange}
        />
        {/*<div className="form-group">*/}
        {/*<label htmlFor="description">Description</label>*/}
        {/*<textarea*/}
        {/*name="description"*/}
        {/*aria-label="description"*/}
        {/*className="form-control"*/}
        {/*value={description || ''}*/}
        {/*onChange={this.handleChange}*/}
        {/*/>*/}
        {/*</div>*/}
      </div>
    );
  }
}
