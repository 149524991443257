import React, { Component } from 'react';
import JobOfferService from 'services/JobOffers';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import Options from 'components/Options';
import TagSearch from 'components/TagSearch';
import { parseResponseForPublishBar } from 'services/Utilities';
import moment from 'moment';
import Spinner from 'components/Spinner';
import { pageMatcher } from 'constants/Global';
import { Link } from 'react-router-dom';

class JobOffer extends Component {
  state = {
    filteredJobOffers: [],
    allJobOffers: [],
    loading: true
  };

  jobOfferService = new JobOfferService();

  componentDidMount() {
    this.getJobOffers();
  }

  getJobOffers = () => {
    this.jobOfferService.get().then(({ data }) => {
      this.setState({
        filteredJobOffers: data,
        allJobOffers: data,
        loading: false
      });
    });
  };

  copyJobOffer = (id, title) => {
    this.jobOfferService.duplicate(id, title).then(({ data: response }) => {
      parseResponseForPublishBar(response, this);
      if (response && response.id) {
        this.props.history.push(
          pageMatcher.admin.content.jobOffers.path + response.id
        );
      }
    });
  };

  dateFormatter = (cell, _row) => {
    if (cell) {
      return <span>{moment(cell).format('DD/MM/YYYY')}</span>;
    }
  };

  handleDelete = id => {
    this.jobOfferService.delete(id).then(({ data: _response }) => {
      let filteredJobOffers = [...this.state.filteredJobOffers];
      const jobOfferIndex = filteredJobOffers.findIndex(
        jobOffer => jobOffer.id === id
      );
      filteredJobOffers.splice(jobOfferIndex, 1);
      this.setState({ filteredJobOffers });
    });
  };

  optionsFormatter = (cell, row) => (
    <Options
      row={row}
      delete={this.handleDelete}
      label="offre d'emploi"
      kind="jobOffers"
      copy={this.copyJobOffer}
    />
  );

  titleFormatter = (cell, row) => (
    <Link to={pageMatcher.admin.content.jobOffers.path + row.id}>{cell}</Link>
  );

  handleSearch = event => {
    const value = event.target.value.toLowerCase();
    this.setState(state => ({
      filteredJobOffers: state.allJobOffers.filter(item =>
        ['title', 'author'].some(
          column => item[column] && item[column].toLowerCase().includes(value)
        )
      )
    }));
  };

  render() {
    const optionsPagination = {
      page: 1,
      paginationSize: 4,
      pageStartIndex: 1,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true,
      nextPageTitle: 'Prochaine page',
      prePageTitle: 'Page précédente'
    };

    const columns = [
      {
        dataField: 'title',
        text: "Titre de l'offre",
        formatter: this.titleFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'created_at',
        text: 'Date de création',
        formatter: this.dateFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'kind',
        sort: true,
        text: 'Type',
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'creator',
        sort: true,
        text: 'Auteur',
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'actions',
        text: 'Actions',
        formatter: this.optionsFormatter,
        headerStyle: { fontSize: 11 },
        align: 'center'
      }
    ];

    const { filteredJobOffers, loading } = this.state;

    return (
      <>
        <TagSearch
          handleSearch={this.handleSearch}
          buttonTitle="Ajouter une offre d'emploi"
          buttonPlusLien={pageMatcher.admin.content.jobOffers.new.path}
        />
        <BootstrapTable
          keyField="id"
          noDataIndication="Aucune offre d'emploi créée pour le moment"
          columns={columns}
          hover
          data={filteredJobOffers}
          bordered={false}
          pagination={paginationFactory(optionsPagination)}
          classes="koba-array"
          filter={filterFactory()}
        />
        {loading && (
          <div className="text-center">
            <Spinner />
          </div>
        )}
      </>
    );
  }
}

export default JobOffer;
