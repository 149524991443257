import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

class AlertInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  showInfo = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    // pr-2
    return (
      <div>
        <div className="ml-2 mt-1">
          <span href="#" id="info" className="text-secondary pr-2">
            <i className="fal fa-info-square fa-lg" />
          </span>
          <UncontrolledTooltip placement="right" target="info">
            {this.props.info}
            {this.props.info2 ? this.props.info2 : null}
          </UncontrolledTooltip>
        </div>
      </div>
    );
  }
}
export default AlertInfo;
