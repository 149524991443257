import React, { Component } from 'react';
import DatePickerModule from 'react-datepicker';
import moment from 'moment';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

export default class DatePicker extends Component {
  handleChangeStart = datetime => {
    const {
      handleDatePicker,
      data: { end_at },
      index
    } = this.props;
    let data = {
      start: datetime ? datetime.format() : null,
      end: end_at ? end_at : null
    };
    handleDatePicker(data, index);
  };

  handleChangeEnd = datetime => {
    const {
      handleDatePicker,
      data: { begin_at },
      index
    } = this.props;
    let data = {
      start: begin_at ? begin_at : null,
      end: datetime ? datetime.format() : null
    };
    handleDatePicker(data, index);
  };

  render() {
    const {
      data: { begin_at, end_at },
      readonly
    } = this.props;
    const newBeginAt = _.isEmpty(begin_at) ? null : moment(begin_at);
    const newEndAt = _.isEmpty(end_at) ? null : moment(end_at);

    return (
      <div>
        <div className="row date-picker">
          <div className="col-6 pr-0 full-width">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text text-secondary">
                  <i className="fal fa-calendar-alt" />
                </span>
              </div>
              <div className="date-picker-single">
                <DatePickerModule
                  selected={newBeginAt}
                  onChange={this.handleChangeStart}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  dateFormat="LLL"
                  timeCaption="l'heure"
                  locale="fr"
                  className="form-control no-border-right text-center"
                  placeholderText="sélectionnez une date"
                  readOnly={readonly || false}
                  dropdownMode={'select'}
                />
              </div>
            </div>
          </div>
          <div className="col-1 px-0">
            <div className="form-control no-border-left no-border-right text-light-gray">
              <i className="far fa-arrow-right" />
            </div>
          </div>
          <div className="col-5 pl-0 full-width">
            <div className="input-group">
              <div className="date-picker-single">
                <DatePickerModule
                  selected={newEndAt}
                  onChange={this.handleChangeEnd}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  dateFormat="LLL"
                  timeCaption="l'heure"
                  locale="fr"
                  className="form-control no-border-left text-center"
                  placeholderText="sélectionnez une date"
                  readOnly={readonly || false}
                  dropdownMode={'select'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
