import Axios from 'axios';
import cookie from 'react-cookies';

export const refreshToken = _ =>
  Axios.get(`${process.env.REACT_APP_AUTH_API}refresh`, {
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookie.load('token')}`
    },
    timeout: 60000
  });
