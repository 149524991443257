import store from 'redux/Store';
import { matchPath } from 'react-router-dom';
import { pageMatcher } from 'constants/Global';
import Axios from './Axios';

// TODO: This file really need some help smh.

export function translate(key) {
  const translationsMap = {
    title: 'titre'
  };
  return translationsMap[key] || key;
}

export function parseError(Error) {
  // const _this = {};

  // parseError = Error => {

  //return Error.response.data;
  // let errors;
  // if (Error.response.data && Error.response.data.errors) {
  //   errors = Error.response.data.errors;
  // } else if (Error.response.data) errors = Error.response.data;

  // if (errors && Object.keys(errors)[0]) {
  //   return `Le champ ${translate(Object.keys(errors)[0])} ${
  //     Object.values(errors)[0][0]
  //   }`;
  if (Error.response && Error.response.data && Error.response.data.error) {
    return Error.response.data.error;
  } else if (
    Error.response &&
    Error.response.data &&
    Error.response.data.campaign
  ) {
    return Error.response.data.campaign.join('\r\n');
  } else if (Error.message) {
    return Error.message;
  } else {
    return 'Erreur ';
  }
}
// TODO : Refacto all of this mess
export function parseResponseForPublishBar(
  response,
  parentThis,
  marginBottom = false
) {
  if (!(response instanceof Error)) {
    alertMessage('success', 'Enregistré', parentThis, marginBottom);
  } else {
    alertMessage('warning', parseError(response), parentThis, marginBottom);
  }
}

export function alertMessage(type, message, parentThis, marginBottom) {
  if (parentThis.timeout) {
    clearTimeout(parentThis.timeout);
    parentThis.timeout = null;
  }
  store.dispatch(setStatusMessage(true, type, message, marginBottom));
  parentThis.setState({ loading: false });
  parentThis.timeout = setTimeout(() => {
    store.dispatch(setStatusMessage(false, type, message, marginBottom));
  }, 5000);
}

function setStatusMessage(
  statusIsVisible,
  statusType,
  statusMessage,
  marginBottom
) {
  return {
    type: 'SET_MESSAGE',
    statusIsVisible,
    statusType,
    statusMessage,
    marginBottom
  };
}

export function setPublishBarLoading(value) {
  store.dispatch({ type: 'SET_LOADING', loading: value });
}

export function parseResponse(response, parentThis, id = null) {
  const ERROR_MESSAGE_TIMEOUT = 5000;
  const SUCCESS_MESSAGE_TIMEOUT = 5000;
  if (response instanceof Error) {
    if (id) {
      parentThis.setState({
        ['errorMessage' + id]: parseError(response),
        ['showSaved' + id]: '',
        ['loading' + id]: true
      });
      setTimeout(function() {
        parentThis.setState({
          ['errorMessage' + id]: '',
          ['showSaved' + id]: false,
          ['loading' + id]: false
        });
      }, ERROR_MESSAGE_TIMEOUT);
    } else {
      parentThis.setState({
        errorMessage: parseError(response),
        showSaved: '',
        loading: true
      });
      setTimeout(() => {
        parentThis.setState({
          errorMessage: '',
          showSaved: false,
          loading: false
        });
      }, ERROR_MESSAGE_TIMEOUT);
    }
  } else if (response && response.id) {
    if (id) {
      parentThis.setState({
        ['showSaved' + id]: true,
        ['errorMessage' + id]: '',
        ['loading' + id]: true
      });
      setTimeout(function() {
        parentThis.setState({
          ['showSaved' + id]: false,
          ['errorMessage' + id]: '',
          ['loading' + id]: false
        });
      }, SUCCESS_MESSAGE_TIMEOUT);
    } else {
      parentThis.setState({ showSaved: true, errorMessage: '', loading: true });
      setTimeout(function() {
        parentThis.setState({
          errorMessage: '',
          showSaved: false,
          loading: false
        });
      }, SUCCESS_MESSAGE_TIMEOUT);
    }
  } else if (Object.prototype.toString.call(response) === '[object Array]') {
    if (allResponsesGood(response)) {
      parentThis.setState({ showSaved: true, errorMessage: '', loading: true });
      setTimeout(function() {
        parentThis.setState({
          errorMessage: '',
          showSaved: false,
          loading: false
        });
      }, SUCCESS_MESSAGE_TIMEOUT);
    }
  } else if (response && response.message) {
    parentThis.setState({
      showSaved: true,
      errorMessage: '',
      loading: true,
      statusMessage: response.message
    });
    setTimeout(function() {
      parentThis.setState({
        errorMessage: '',
        showSaved: false,
        loading: false,
        statusMessage: ''
      });
    }, SUCCESS_MESSAGE_TIMEOUT);
  } else if (
    (response && response.status === 200) ||
    response.status === 201 ||
    response.status === 204
  ) {
    parentThis.setState({
      showSaved: true,
      errorMessage: '',
      loading: true,
      statusMessage: response.message
    });
    setTimeout(function() {
      parentThis.setState({
        errorMessage: '',
        showSaved: false,
        loading: false,
        statusMessage: ''
      });
    }, SUCCESS_MESSAGE_TIMEOUT);
  }
}

const allResponsesGood = response => {
  let Accepted = 0;
  for (let i = 0; i < response.length; i++) {
    let condition =
      response[i].status === 200 ||
      response[i].status === 201 ||
      response[i].status === 204;
    if (condition) {
      Accepted = Accepted + 1;
    }
  }
  if (Accepted === response.length) {
    return true;
  }
};

export function parseUrl(url) {
  let urlArray = url.split('/');
  if (urlArray.length === 3) {
    switch (urlArray[1]) {
      case 'articles':
        return 'mairie/articles/' + urlArray[2];
      case 'news':
        return 'mairie/actualites/' + urlArray[2];
      case 'events':
        return 'mairie/evenements/' + urlArray[2];
      default:
        break;
    }
  }
}

export async function handleChange(event, parentThis, affectToObject = null) {
  let newState = Object.assign({}, parentThis.state);
  affectToObject
    ? (newState[affectToObject][event.target.name] = event.target.value)
    : (newState[event.target.name] = event.target.value);
  return await parentThis.setState(newState);
}

export function createEventObject(name, value) {
  return {
    target: {
      name: name,
      value: value
    }
  };
}

export function slugify(string) {
  const a = 'àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  const b = 'aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function buildPaginationOptions(
  page = 1,
  paginationSize = 4,
  pageStartIndex = 1,
  hideSizePerPage = true,
  hidePageListOnlyOnePage = true,
  nextPageTitle = 'Prochaine page',
  prePageTitle = 'Page précédente'
) {
  return {
    page: page,
    paginationSize: paginationSize,
    pageStartIndex: pageStartIndex,
    hideSizePerPage: hideSizePerPage,
    hidePageListOnlyOnePage: hidePageListOnlyOnePage,
    nextPageTitle: nextPageTitle,
    prePageTitle: prePageTitle
  };
}

export const handleEnterKeyPress = (event, handler, data = {}) => {
  if (event.key === 'Enter') {
    if (Object.keys(data).length) handler(event, data);
    else handler(event);
  }
};

export const guid = () =>
  Math.random()
    .toString(36)
    .substr(2, 9) +
  '-' +
  new Date().getTime();

export const isNew = item => !item.created_at;

export const providePageData = (parentThis, matcher = pageMatcher) => {
  for (let [pageKey, pageValues] of Object.entries(matcher)) {
    if (!['path', 'link', 'pageTitle'].includes(pageKey)) {
      if (
        matchPath(parentThis.props.location.pathname, {
          path: matcher[pageKey].path,
          exact: true,
          strict: false
        })
      ) {
        let pageData = matcher[pageKey];
        return {
          pageTitle: pageData.pageTitle
        };
      } else {
        let result = providePageData(parentThis, pageValues);
        if (result) return result;
      }
    }
  }
  return {};
};

export const moveArrayItemToPosition = (
  array,
  originalPosition,
  newPosition
) => {
  array.splice(newPosition, 0, array.splice(originalPosition, 1)[0]);
  return array;
};

export const imageUpload = async image => {
  const formData = new FormData();
  formData.append('upload', image);
  const { data: response } = await Axios.post(`upload`, formData);
  return response;
};

export const reverseObjectKeyVals = o =>
  Object.entries(o).reduce(
    (acc, [k, v]) => ({
      ...acc,
      [v]: k
    }),
    {}
  );

export const removeFalsyValueFromObject = obj => {
  for (let key in obj) {
    !obj[key] && delete obj[key]; // delete [null, "", undefined, 0]
  }
};

export const deleteItemFromArray = (array, id) =>
  array.filter(item => item.id !== id);

export const sortByTitle = (a, b) => {
  const titleA = a.title || a.name;
  const titleB = b.title || b.name;

  if (titleA.toUpperCase() > titleB.toUpperCase()) {
    return 1;
  } else if (titleA.toUpperCase() < titleB.toUpperCase()) {
    return -1;
  }

  return 0;
};
