import CategoryService from 'services/Category';
import SectionBase from '../SectionBase';
import PublishBar from 'components/PublishBar';
import React, { Component } from 'react';
import { parseResponseForPublishBar } from 'services/Utilities';

export default class EditSection extends Component {
  state = {};
  categoryService = new CategoryService();

  get = async id => {
    const { data: response } = await this.categoryService.get(id);
    if (response && response.id) {
      this.setState({ data: response });
    }
  };

  // TODO: parameter never used ...
  put = data => {
    this.categoryService
      .put(this.props.match.params.id, this.state.data)
      .then(({ data: response }) => {
        parseResponseForPublishBar(data.response, this, 'marginBottom');
        this.props.history.push(this.props.url);
      })
      .catch(() =>
        parseResponseForPublishBar(
          Error("Le nom spécifié n'est pas valable"),
          this,
          'marginBottom'
        )
      );
  };

  handleChange = data => this.setState({ data });

  handleSubmit = event => {
    event.preventDefault();
    const { data } = this.state;
    this.put(data);
  };

  componentDidMount() {
    this.get(this.props.match.params.id);
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <SectionBase data={this.state.data} handleChange={this.handleChange} />
        <PublishBar
          state={'draft'}
          handleSubmit={this.handleSubmit}
          buttonDisabled={this.state.loading}
          goBackLink={this.props.url}
          goBackLinkTitle="Retour à la liste"
        />
      </form>
    );
  }
}
