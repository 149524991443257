import React, { Component } from 'react';
import { imageUpload, parseResponseForPublishBar } from 'services/Utilities';
import { fetchTownHallInformations, saveTownHall } from 'services/TownHall';
import PublishBar from 'components/PublishBar';
import Filedrop from 'components/Filedrop';

const defaultImage = { url: '', signed_id: null };

class InteractiveMapImage extends Component {
  state = {
    image: defaultImage,
    loading: true
  };

  onFileChange = file => {
    this.setState({ loading: true });
    imageUpload(file)
      .then(response => {
        response.url &&
          response.signed_id &&
          this.setState({ image: response, loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
        parseResponseForPublishBar(
          Error("L'image chargée n'est pas conforme"),
          this,
          'marginBottom'
        );
      });
  };

  handleSubmit = e => {
    const { image } = this.state;
    const params = { town_hall: { directory_address_image: image.signed_id } };

    saveTownHall(params)
      .then(({ data }) => {
        parseResponseForPublishBar(data, this, 'marginBottom');
      })
      .catch(() =>
        parseResponseForPublishBar(
          Error("L'image chargée n'est pas conforme"),
          this,
          'marginBottom'
        )
      );
  };

  getImage = () => {
    fetchTownHallInformations().then(({ data }) => {
      data &&
        data.directory_address_image &&
        this.setState({ image: data.directory_address_image, loading: false });
    });
  };

  componentDidMount() {
    this.getImage();
  }

  render() {
    const { image, loading } = this.state;

    return (
      <>
        <div className="card-koba mt-2">
          <div className="form-group">
            <label htmlFor="title">
              Image d'illustration pour la section carte interactive
            </label>
            <div className="col-sm-12">
              <Filedrop
                aria-label="image"
                name="image"
                accepts="images"
                initValue={image.url || ''}
                fileUrl={image.url || ''}
                onFileChange={this.onFileChange}
                deleteImage={() => this.setState({ image: defaultImage })}
              />
            </div>
          </div>
        </div>
        <PublishBar
          state="published"
          handleSubmit={this.handleSubmit}
          loading={loading}
        />
      </>
    );
  }
}

export default InteractiveMapImage;
