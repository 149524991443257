import React from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import ModalDraw from 'components/ModalDraw';

export const RssForm = props => {
  const {
    rssFlow: { id, title, link },
    isValid,
    handleChange,
    handleDelete
  } = props;

  return (
    <div className="mb-3">
      <FormGroup row>
        <Label forhtml="link" sm={2}>
          URL du flux <span className="required">*</span>
        </Label>
        <Col sm={10}>
          <Input
            className={isValid ? ' ' : ' border-danger '}
            required
            type="text"
            name="link"
            aria-label="lien"
            id="link"
            value={link.data}
            onChange={event => handleChange(event, id)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label forhtml="title" sm={2}>
          Titre du flux <span className="required">*</span>
        </Label>
        <Col sm={10}>
          <Input
            required
            type="text"
            aria-label="titre"
            name="title"
            id="title"
            value={title}
            onChange={event => handleChange(event, id)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <div className="col-12">
          <div className="d-flex justify-content-end">
            {id && (
              <ModalDraw
                id={id}
                header={`Supprimer ${title}`}
                body={`Etes-vous sûr de vouloir supprimer ${title} ?`}
                label="Supprimer"
                ico={<i className="far fa-trash-alt " />}
                action={e => handleDelete(e, id)}
                btnClass="table-action-btn btn-action mr-1"
                btnTitle={title ? `Supprimer ${title}` : 'Supprimer'}
              />
            )}
          </div>
        </div>
      </FormGroup>
    </div>
  );
};
