import React, { Component } from 'react';
import _ from 'lodash';
import CampaignForm from '../Form';
import { connect } from 'react-redux';

const withCampaign = (
  WrappedComponent,
  { type = null, mapStateToProps = null, mapDispatchToProps = null }
) => {
  const desiredCampaign = `${type}Campaign`; //onGoingCampaign | upcomingCampaign
  const getInitialState = (readonly = false) => ({
    campaign: {
      message: '',
      begin_at: '',
      end_at: '',
      banner: true
    },
    readonly
  });

  class WithCampaign extends Component {
    initialState = getInitialState(this.props.readonly);

    state = this.initialState;

    static getDerivedStateFromProps(nextProps, prevState) {
      const { message, begin_at, end_at } = prevState.campaign;
      if (
        message === '' &&
        begin_at === '' &&
        end_at === '' &&
        !_.isEmpty(nextProps[desiredCampaign])
      ) {
        const { [desiredCampaign]: campaign } = nextProps;
        return { campaign };
      }

      return null;
    }

    toggleReadonly = e => {
      e.preventDefault();

      this.setState(prevState => ({
        readonly: !prevState.readonly
      }));
    };

    handleSubmit = event => {
      event.preventDefault();

      this.props.createCampaign(this.state.campaign);
      this.setState(this.initialState);
    };

    handleUpdate = event => {
      event.preventDefault();

      const { id, ...campaign } = this.state.campaign;
      this.props.updateCampaign(id, campaign);
      this.toggleReadonly(event);
    };

    handleChange = e => {
      const message = e.target.value;
      this.setState(prevState => ({
        campaign: { ...prevState.campaign, message }
      }));
    };

    handleDates = data => {
      const begin_at = data.start,
        end_at = data.end;

      this.setState(prevState => ({
        campaign: { ...prevState.campaign, begin_at, end_at }
      }));
    };

    handleDelete = _ => {
      const { deleteCampaign } = this.props;
      const {
        campaign: { id }
      } = this.state;
      deleteCampaign(id, type); // onGoing | upcoming
      this.setState(this.initialState);
    };

    getInitialState = _ => {
      const { [desiredCampaign]: campaign } = this.props;
      this.setState({ campaign });
    };

    render() {
      const { campaign, readonly } = this.state;

      return (
        <WrappedComponent {...this.props}>
          <CampaignForm
            campaign={campaign}
            readonly={readonly}
            toggleReadonly={this.toggleReadonly}
            handleApply={this.handleDates}
            handleUpdate={type ? this.handleUpdate : undefined}
            handleSubmit={!type ? this.handleSubmit : undefined}
            handleChange={this.handleChange}
            handleDelete={this.handleDelete}
            getInitState={this.getInitialState}
          />
        </WrappedComponent>
      );
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithCampaign);
};

export default withCampaign;
