import React from 'react';
import { Helmet } from 'react-helmet';
import { defaultPageTitle } from 'constants/Global/';

const Layout = ({ pageTitle }) => {
  /*
  *   NB: You need to edit pageTitleMatcher from constants when you add a new page.
  */
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageTitle || defaultPageTitle}</title>
    </Helmet>
  );
};
export default Layout;
