import React from 'react';
import MaskedInput from 'react-text-mask';

const InputCodePostal = props => (
  <MaskedInput
    {...props}
    guide={false}
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
    onFocus={e => {
      e.target.setAttribute('autocomplete', 'nope');
    }}
  />
);
export default InputCodePostal;
