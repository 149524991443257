import jwtDecode from 'jwt-decode';
import cookie from 'react-cookies';
import queryString from 'query-string';

export const getUrlSlug = () => queryString.parse(window.location.search).slug;

const setSessionSlug = slug => localStorage.setItem('slug', slug);

export const getSessionSlug = () => localStorage.getItem('slug');

const setSessionSlugIfUrlParam = () => {
  const urlSlug = getUrlSlug();
  if (urlSlug) {
    setSessionSlug(urlSlug);
  }
};

export const decodeTokenClaims = token => {
  try {
    return jwtDecode(token);
  } catch (e) {
    return {};
  }
};

/**
 * Check is the user session is valid against the current slug scope
 * Note that if a slug is provided as a query param, it overrides the one in the local storage
 * @returns {string} null if allowed, a string describing the error otherwise
 */
export const checkSlugIsAuthorized = () => {
  // 1 - Is there a valid token is cookies
  const token = cookie.load('token');
  if (!token) return 'No token can be found in the cookies';
  const claims = decodeTokenClaims(token);
  if (Object.keys(claims).length === 0) return 'The cookie token is invalid';
  if (
    !claims ||
    !claims.authUserId ||
    (!Array.isArray(claims.clients) && !claims.kind)
  )
    return 'The cookie token is structurally invalid';
  // 2 - Is there a slug in the session
  setSessionSlugIfUrlParam();
  const slug = getSessionSlug();
  if (!slug) return 'No slug can be found in the session';
  localStorage.setItem('authUserId', claims.authUserId);
  // 3 - Check if we have been authorized to access this slug
  if (claims.kind) return null;
  if (!claims.clients.find(c => c.service === 'site' && c.slug === slug))
    return `This user (${
      claims.authUserId
    }) is forbidden on this slug (${slug})`;
  return null;
};
