import React, { Component } from 'react';
import { Form, FormGroup, Input } from 'components/Form';
import PublishBar from 'components/PublishBar';
import { parseResponseForPublishBar } from 'services/Utilities';
import { fetchTownHallInformations, saveTownHall } from 'services/TownHall';
import { required, minLength, isEmail } from 'services/Validation';
import Spinner from 'components/Spinner';

const realTimeValidations = [
  {
    path: ['name'],
    validators: [required, minLength(3)]
  },
  {
    path: ['email'],
    validators: [required, isEmail]
  }
];

export default class Settings extends Component {
  state = {
    townHall: {}
  };

  componentDidMount() {
    fetchTownHallInformations().then(({ data: townHall }) => {
      this.setState({ townHall });
    });
  }

  handleSubmit = form => {
    form.handleSave(value => {
      saveTownHall(value).then(({ data: townHall }) => {
        this.setState({ townHall });
        parseResponseForPublishBar(townHall, this, 'marginBottom');
      });
    });
  };

  render() {
    const { townHall } = this.state;

    if (!Object.keys(townHall).length) return <Spinner />;

    const { name, email } = townHall;

    return (
      <div>
        <h2 style={{ paddingBottom: '2rem' }}>Configuration du site</h2>
        <Form
          value={{ name, email }}
          realTimeValidations={realTimeValidations}
          saveValidations={[]}
        >
          {form => (
            <>
              <div className="card-koba mb-4">
                <FormGroup
                  path={['name']}
                  label="Nom"
                  required={true}
                  form={form}
                >
                  <Input path={['name']} aria-label="Nom" />
                </FormGroup>
                <FormGroup
                  path={['email']}
                  label="Email de notification (formulaire de contact, candidatures, ...)"
                  required={true}
                  form={form}
                >
                  <Input path={['email']} aria-label="Email de notification" />
                </FormGroup>
              </div>
              <PublishBar
                buttonDisabled={false}
                handleSubmit={() => this.handleSubmit(form)}
              />
            </>
          )}
        </Form>
      </div>
    );
  }
}
