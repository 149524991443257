import React from 'react';
import TimePicker from 'components/TimePicker';
import { FormGroup } from 'reactstrap';
import './index.scss';

export const FormOpeningHours = ({
  title,
  handleTimePicker,
  deleteTime,
  data,
  day_of_week,
  addNewRange,
  deleteRange,
  startAfterEndRange,
  overlappingRange
}) => {
  return (
    <FormGroup className="row">
      <label htmlFor="" className="col-sm-2 col-form-label">
        {title}
      </label>
      <div className="col-lg-10 col-xl-8 mb-lg-0 mb-0">
        {data &&
          data.map((range, i) => (
            <div key={i}>
              <TimePicker
                isRangeValide={startAfterEndRange[i] || overlappingRange[i]}
                key={i}
                handleTimePicker={handleTimePicker}
                index={i}
                day_of_week={day_of_week}
                times={range}
                deleteTime={deleteTime}
                addNewRange={addNewRange}
                deleteRange={deleteRange}
                id={range.id || null}
              />
              <div className={'error-message'}>
                {startAfterEndRange[i] && (
                  <p className={'mb-0'}>
                    L'heure de début doit être avant l'heure de fin.
                  </p>
                )}
                {overlappingRange[i] && <p>Les horaires se chevauchent.</p>}
              </div>
            </div>
          ))}
        <button
          type="button"
          className="btn btn-sm btn-outline-primary mb-3"
          onClick={() => addNewRange(day_of_week)}
        >
          Ajouter une plage horaire
        </button>
      </div>
    </FormGroup>
  );
};
