import EditProcedure from './Procedures/Edit';
import EditSection from '../Sections/Edit';
import NewProcedure from './Procedures/New';
import NewSection from '../Sections/New';
import Procedures from './Procedures';
import React, { Component } from 'react';
import Sections from '../Sections';
import Submenu from 'components/Submenu';
import { Route, Switch, Redirect } from 'react-router-dom';
import { pageMatcher } from 'constants/Global';
import ProcedureService from 'services/OnlineProcedures';

class OnlineProcedures extends Component {
  subMenuData = [
    pageMatcher.admin.onlineProcedures.procedures,
    pageMatcher.admin.onlineProcedures.categories
  ];

  render() {
    return (
      <>
        <Submenu subMenuData={this.subMenuData} />
        <Switch>
          <Redirect
            exact={true}
            from={pageMatcher.admin.onlineProcedures.path}
            to={pageMatcher.admin.onlineProcedures.procedures.path}
          />
          <Route
            path={pageMatcher.admin.onlineProcedures.procedures.new.path}
            render={() => <NewProcedure {...this.props} />}
          />
          <Route
            path={pageMatcher.admin.onlineProcedures.procedures.single.path}
            render={props => <EditProcedure {...this.props} {...props} />}
          />
          <Route
            path={pageMatcher.admin.onlineProcedures.procedures.path}
            render={() => <Procedures {...this.props} />}
          />
          <Route
            path={pageMatcher.admin.onlineProcedures.categories.new.path}
            render={() => (
              <NewSection
                {...this.props}
                url={pageMatcher.admin.onlineProcedures.categories.path}
                kind="online_procedure"
              />
            )}
          />
          <Route
            path={pageMatcher.admin.onlineProcedures.categories.single.path}
            render={props => (
              <EditSection
                {...this.props}
                {...props}
                url={pageMatcher.admin.onlineProcedures.categories.path}
              />
            )}
          />
          <Route
            path={pageMatcher.admin.onlineProcedures.categories.path}
            render={() => (
              <Sections
                {...this.props}
                url="online_procedures/categories/new"
                ProcedureService={ProcedureService}
                kind="category_procedures"
              />
            )}
          />
        </Switch>
      </>
    );
  }
}

export default OnlineProcedures;
