import React from 'react';
import Editor from 'components/Editor';

export default class Highlight extends React.Component {
  render() {
    return (
      <div>
        <p className="text-muted mb-3">
          Le bloc Hightlight est encadré pour être plus visible.
        </p>
        <Editor
          initValue={this.props.initValue}
          onHTMLChange={this.props.onHTMLChange}
        />
      </div>
    );
  }
}
