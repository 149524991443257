import React from 'react';

export default class index extends React.Component {
  handleChangeLink = e => {
    const url = e.target.value;
    const name = e.target.name;
    // fake event = pas bien :)
    const fakeEvent = {
      target: { name, value: [{ url }] }
    };
    this.props.handleChange(fakeEvent);
  };

  render() {
    const { data } = this.props;
    return (
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Titre</label>
            <input
              value={data.title}
              onChange={this.props.handleChange}
              name="title"
              type="text"
              className="form-control"
              aria-describedby="titleHelp"
              placeholder="Titre"
              aria-label="Titre"
            />
            <small id="titleHelp" className="form-text text-muted">
              Titre de votre lien.
            </small>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">URL</label>
            <input
              value={data.links[0].url}
              onChange={this.handleChangeLink}
              name="links"
              type="text"
              className="form-control"
              aria-describedby="urlHelp"
              placeholder="URL"
              aria-label="URL"
            />
          </div>
        </div>
      </div>
    );
  }
}
