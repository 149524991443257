import Input from 'components/Input';
import PictogramLibrary from 'components/PictogramLibrary';
import React from 'react';
import { createEventObject } from 'services/Utilities';

export default class SectionBase extends React.Component {
  state = {
    data: { kind: this.props.kind },
    updated: false
  };

  componentDidMount() {
    if (this.props.data && this.props.data.isNew)
      this.setState({ updated: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data && !prevProps.data) this.setState({ updated: true });
  }

  handlePictogramChosen = (index, icon) => {
    const pictoData = createEventObject('font_class_name', icon.className);
    this.handleChange(pictoData);
  };

  handleChange = async event => {
    const newState = { ...this.state };
    newState['data'][event.target.name] = event.target.value;

    this.setState(newState, () => {
      this.props.handleChange(this.state.data);
    });
  };

  render() {
    const { data, kind } = this.props;
    const isOnlineProcedure = kind === 'online_procedure';
    const initPicto =
      data && data.font_class_name ? data.font_class_name : null;

    return (
      <div className="card-koba">
        <Input
          required
          label="Choisissez le nom de votre rubrique"
          type="text"
          name="name"
          aria-label="nom de la rubrique"
          value={data && data.name ? data.name : ''}
          onChange={this.handleChange}
        />
        {isOnlineProcedure && (
          <div>
            <label>Icône</label>
            {this.state.updated && (
              <PictogramLibrary
                initPicto={initPicto}
                handlePictogramChosen={this.handlePictogramChosen}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
