import React from 'react';
import EditJobOffer from './JobOffers/Edit';
import KioskLayout from './Kiosks/Layout';
import EditNews from './News/Edit';
import EditPhoto from './PhotoLibrairies/Edit';
import Events from './Events';
import JobOffer from './JobOffers';
import Kiosk from './Kiosks';
import Articles from './Articles';
import NewArticle from './Articles/New';
import EditArticle from './Articles/Edit';
import EventLayout from './Events/Layout';
import News from './News';
import NewJobOffer from './JobOffers/New';
import NewNews from './News/New';
import NewPhoto from './PhotoLibrairies/New';
import PhotoLibrairies from './PhotoLibrairies';
import PhotoLibrairiesArchive from './PhotoLibrairies/ArchivedList';
import Submenu from 'components/Submenu';
import { Route, Switch, Redirect } from 'react-router-dom';
import { pageMatcher } from 'constants/Global';

const Home = props => {
  const subMenuData = [
    pageMatcher.admin.content.articles,
    pageMatcher.admin.content.news,
    pageMatcher.admin.content.events,
    pageMatcher.admin.content.kiosks,
    pageMatcher.admin.content.jobOffers,
    pageMatcher.admin.content.photoLibrairies
  ];

  return (
    <>
      <Submenu subMenuData={subMenuData} />
      <Switch>
        <Redirect
          from={pageMatcher.admin.content.path}
          exact={true}
          to={pageMatcher.admin.content.articles.path}
        />
        <Route
          path={pageMatcher.admin.content.articles.new.path}
          render={() => <NewArticle {...props} />}
        />
        <Route
          path={pageMatcher.admin.content.articles.single.path}
          render={routeProps => <EditArticle {...props} {...routeProps} />}
        />
        <Route
          path={pageMatcher.admin.content.articles.path}
          render={() => <Articles {...props} />}
        />
        <Route
          path={pageMatcher.admin.content.news.new.path}
          render={() => <NewNews {...props} />}
        />
        <Route
          path={pageMatcher.admin.content.news.single.path}
          render={routeProps => <EditNews {...props} {...routeProps} />}
        />
        <Route
          path={pageMatcher.admin.content.news.path}
          render={() => <News {...props} />}
        />
        <Route
          path={[
            pageMatcher.admin.content.events.new.path,
            pageMatcher.admin.content.events.single.path
          ]}
          render={routeProps => <EventLayout {...props} {...routeProps} />}
        />
        <Route
          path={pageMatcher.admin.content.events.path}
          render={() => <Events {...props} />}
        />
        <Route
          path={[
            pageMatcher.admin.content.kiosks.new.path,
            pageMatcher.admin.content.kiosks.single.path
          ]}
          render={routeProps => <KioskLayout {...props} {...routeProps} />}
        />
        <Route
          path={pageMatcher.admin.content.kiosks.path}
          render={() => <Kiosk {...props} />}
        />
        <Route
          path={pageMatcher.admin.content.jobOffers.new.path}
          render={() => <NewJobOffer {...props} />}
        />
        <Route
          path={pageMatcher.admin.content.jobOffers.single.path}
          render={routeProps => <EditJobOffer {...props} {...routeProps} />}
        />
        <Route
          path={pageMatcher.admin.content.jobOffers.path}
          render={() => <JobOffer {...props} />}
        />
        <Route
          path={pageMatcher.admin.content.photoLibrairies.archived.path}
          render={() => <PhotoLibrairiesArchive {...props} />}
        />
        <Route
          path={pageMatcher.admin.content.photoLibrairies.new.path}
          render={() => <NewPhoto {...props} />}
        />
        <Route
          path={pageMatcher.admin.content.photoLibrairies.single.path}
          render={routeProps => <EditPhoto {...props} {...routeProps} />}
        />
        <Route
          path={pageMatcher.admin.content.photoLibrairies.path}
          render={() => <PhotoLibrairies {...props} />}
        />
      </Switch>
    </>
  );
};

export default Home;
