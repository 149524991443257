import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

/*
 * This component is passed the prop 'component'
 * but will actually use the react router 'render' prop
 * take care the component is not reinit each time the route is reached this way
 */
class SessionProtectedRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areTokensStillFresh: true
    };
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          return window.newAuthEnabled || this.state.areTokensStillFresh ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          );
        }}
      />
    );
  }
}

export default SessionProtectedRoute;
