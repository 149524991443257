import React from 'react';
import { Link } from 'react-router-dom';

const LinkButton = props => (
  <Link to={props.to} title={props.children} tabIndex={-1}>
    <button type="button" className={'btn ' + props.className}>
      {props.children}
    </button>
  </Link>
);
export default LinkButton;
