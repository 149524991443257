import React from 'react';
import Filedrop from 'components/Filedrop';

const BlockImage = props => (
  <Filedrop
    withCropper
    name="image"
    accepts="images"
    initValue={props.imageInitValue}
    onFileChange={props.handleFileChange}
  />
);

export default BlockImage;
