import React, { Component } from 'react';
import ArticleService from 'services/Articles';
import ArticleBase from '../ArticleBase';
import * as Utilities from 'services/Utilities';
import PublishBar from 'components/PublishBar';
import { pageMatcher } from 'constants/Global';

class Article extends Component {
  state = {
    article: {
      title: '',
      published_at: new Date(),
      category_id: 1
    },
    errorMessage: '',
    showSaved: false
  };

  articleService = new ArticleService();

  postArticle = data => {
    this.articleService.postForm(data).then(({ data: response }) => {
      Utilities.parseResponseForPublishBar(response, this, 'marginBottom');
      if (response && response.id)
        this.props.history.push(
          pageMatcher.admin.content.articles.path + response.id
        );
    });
  };

  handleChange = event => {
    const newState = { ...this.state };
    newState.article[event.target.name] = event.target.value;
    this.setState(newState);
  };

  handleChangePicker = data => {
    const newState = { ...this.state };
    newState.article.published_at = data.start;
    this.setState(newState);
  };

  handleSubmit = event => {
    event.preventDefault();
    this.postArticle(this.state.article);
  };

  closeStatusMessage = () => {
    this.setState({ errorMessage: '', showSaved: false, loading: false });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <ArticleBase
          {...this.state.article}
          handleChange={this.handleChange}
          handleChangePicker={this.handleChangePicker}
        />
        <PublishBar
          state={'draft'}
          showSubmitButton={true}
          // goBackLink can be found dynamicaly
          goBackLink={pageMatcher.admin.content.articles.path}
        />
      </form>
    );
  }
}

export default Article;
