import React from 'react';
import DatePickerSingle from 'components/DatePickerSingle';

const ArticleBase = props => (
  <div className="card-koba">
    <div className="form-group">
      <label htmlFor="title">
        Titre de l'article <span className="required">*</span>
      </label>
      <input
        type="text"
        className="form-control"
        id="title"
        name="title"
        aria-label="titre"
        required
        aria-describedby="titleHelp"
        placeholder="La vie est belle"
        defaultValue={props.title}
        onChange={props.handleChange}
      />
    </div>
    <div className="form-group">
      <label htmlFor="published_at">
        Date de la publication <span className="required">*</span>
      </label>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text text-secondary">
            <i className="fal fa-calendar-alt" />
          </span>
        </div>
        <DatePickerSingle
          required
          handleDatePicker={props.handleChangePicker}
          type="publish"
          data={props.published_at}
          single={true}
          className="full-width"
        />
      </div>
    </div>
  </div>
);

export default ArticleBase;
