import React from 'react';
import { FormGroup, Input, Col, Label } from 'reactstrap';
import Filedrop from 'components/Filedrop';
import DatePickerSingle from 'components/DatePickerSingle';
import { Async } from 'react-select';
import KioskService from 'services/Kiosk';
import FormButtons from 'components/FormButtons';

const kioskService = new KioskService();

const KioskForm = props => {
  const getCategories = () =>
    kioskService.categories(true).then(response => response);

  const {
    kiosk,
    handleSubmit,
    handleCatChange,
    handleChange,
    handleChangePicker,
    handleFileChange,
    deleteImage,
    goBackLinkTitle,
    goBackLink
  } = props;
  const {
    id,
    title,
    publication_date,
    category_id,
    image_url,
    pdf_title,
    file,
    flipbook_link,
    file_url,
    flipbook_title
  } = kiosk;

  const template = localStorage.getItem('template');
  const ratio = localStorage.getItem('ratio');
  const ratioKiosk = JSON.parse(ratio)[template].kiosk;

  return (
    <form onSubmit={handleSubmit}>
      <div className="card-koba">
        <FormGroup>
          <Label forhtml="title">
            Titre <span className="required">*</span>
          </Label>
          <Input
            type="text"
            name="title"
            id="title"
            placeholder="Titre du kiosque"
            aria-label="Titre du kiosque"
            value={title}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <Label forhtml="publication_date">
          Date de publication <span className="required">*</span>
        </Label>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text text-secondary">
              <i className="fal fa-calendar-alt" />
            </span>
          </div>
          <DatePickerSingle
            required
            handleDatePicker={handleChangePicker}
            type="publish"
            data={publication_date}
            single={true}
            className="full-width"
          />
        </div>
        <FormGroup>
          <label htmlFor="category_id">Catégorie</label>{' '}
          <span className="required">*</span>
          <Async
            type="select"
            clearable={false}
            id="category_id"
            name="category_id"
            placeholder="Choisir..."
            value={category_id}
            onChange={e => handleCatChange(e)}
            loadOptions={getCategories}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label forhtml="image">
            Couverture de la publication ( format image uniquement )<br />
            Par défaut l'image de publication est la première page de votre
            document
          </Label>
          <Filedrop
            withCropper
            name="image"
            accepts="images"
            initValue={image_url}
            onFileChange={handleFileChange}
            deleteImage={deleteImage}
            ratio={ratioKiosk}
            backgroundSize={template === 'koba' ? 'contain' : 'cover'}
          />
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label forhtml="file">Titre du PDF </Label>
            {file && <span className="required">*</span>}
            <Input
              name="pdf_title"
              id="pdf_title"
              aria-label="titre du pdf"
              className="mb-2"
              value={pdf_title}
              onChange={handleChange}
              required={file}
            />
            <Label forhtml="file">
              Fichier PDF{' '}
              <span className="required">
                {!flipbook_link ? '* (si pas de flipbook)' : ''}
              </span>
            </Label>
            <Filedrop
              name="file"
              accepts="pdf"
              required={!flipbook_link}
              initValue={file_url}
              onFileChange={handleFileChange}
            />
          </Col>
          <Col sm={6}>
            <Label forhtml="file">Titre du flipbook</Label>
            <Input
              aria-label="titre du flipbook"
              name="flipbook_title"
              id="flipbook_title"
              value={flipbook_title}
              className="mb-3"
              onChange={handleChange}
              required={flipbook_link}
            />
            <Label forhtml="file">Lien du flipbook</Label>
            <Input
              name="flipbook_link"
              id="flipbook_link"
              value={flipbook_link}
              onChange={handleChange}
            />
          </Col>
        </FormGroup>
      </div>
      <FormButtons
        id={id}
        goBackLink={goBackLink}
        goBackLinkTitle={goBackLinkTitle}
      />
    </form>
  );
};

export default KioskForm;
