import React, { Component } from 'react';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import TimePickerModule from 'react-datepicker';
import './index.scss';
import MaskedInput from 'react-text-mask';

export default class TimePicker extends Component {
  onChangeStart = date => {
    let data = {
      start: date ? date : null,
      index: this.props.index,
      day_of_week: this.props.day_of_week
    };
    this.props.handleTimePicker(data);
  };

  onChangeEnd = date => {
    let data = {
      end: date ? date : null,
      index: this.props.index,
      day_of_week: this.props.day_of_week
    };
    this.props.handleTimePicker(data);
  };

  renderTimerPicker = (isStart = true, isRangeValide, times) => {
    const { open, close } = times;
    let classDiv = `col-lg-4 ${isStart ? 'pr-0' : 'pl-0'} full-width`;
    let classTimePicker = `form-control ${
      isStart ? 'no-border-right' : 'no-border-left'
    } text-center ${isRangeValide ? 'invalid-range' : ''}`;
    return (
      <div className={classDiv}>
        <div className="input-group">
          <div className="input-strech">
            <TimePickerModule
              selected={isStart ? open && moment(open) : close && moment(close)}
              onChange={isStart ? this.onChangeStart : this.onChangeEnd}
              showTimeSelect
              showTimeSelectOnly
              timeFormat="HH:mm"
              timeIntervals={30}
              dateFormat="LT"
              timeCaption="l'heure"
              locale="fr"
              className={classTimePicker}
              placeholderText="choisir"
              aria-label="choisir"
              minTime={moment()
                .hours(6)
                .minutes(0)}
              maxTime={moment()
                .hours(23)
                .minutes(0)}
              customInput={
                <MaskedInput
                  guide={false}
                  mask={rawValue => {
                    switch (rawValue[0]) {
                      case '0':
                      case '1':
                        return [/\d/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
                      case '2':
                        return [/\d/, /[0-3]/, ':', /[0-5]/, /[0-9]/];
                      default:
                        return [/[0-2]/, /\d/, ':', /[0-5]/, /[0-9]/];
                    }
                  }}
                />
              }
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      deleteRange,
      day_of_week,
      index,
      id,
      isRangeValide,
      times
    } = this.props;
    return (
      <div className="row d-flex flex-nowrap time-picker">
        {this.renderTimerPicker(true, isRangeValide, times)}
        <div className="col-1 px-0">
          <span
            className={
              'form-control no-border-left no-border-right text-light-gray ' +
              (isRangeValide ? 'invalid-range' : '')
            }
          >
            <i className="far fa-arrow-right" />
          </span>
        </div>
        {this.renderTimerPicker(false, isRangeValide, times)}
        <div className="col-2 px-0">
          <button
            title="Supprimer ce créneau"
            type="button"
            onClick={() => deleteRange(day_of_week, index, id)}
            className="btn table-action-btn btn-action-md"
          >
            <i className="far fa-trash-alt" />
          </button>
        </div>
      </div>
    );
  }
}
