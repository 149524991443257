import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default class DeleteConfirm extends Component {
  state = { isVisible: false };

  toggle = _ => {
    this.setState(prevState => ({ isVisible: !prevState.isVisible }));
  };

  render() {
    const { isVisible } = this.state;

    return (
      <>
        <div
          className="col pointer h-100 d-flex justify-content-end align-items-center flex-column"
          onClick={() => this.toggle()}
        >
          <i
            className="fal fa-comment-alt-times"
            title="Supprimer ce message"
          />
          <p>Supprimer</p>
        </div>
        <Modal isOpen={isVisible} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Confirmation</ModalHeader>
          <ModalBody>Etes-vous sûr de vouloir supprimer ce message?</ModalBody>
          <ModalFooter>
            <button
              className="btn btn-outline-primary mr-2"
              onClick={this.toggle}
            >
              Annuler
            </button>
            <Button color="primary" onClick={this.props.handleDelete}>
              Supprimer
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

DeleteConfirm.propTypes = {
  handleDelete: PropTypes.func
};
