import React from 'react';

export default ({ type, icon, children, onClick, title }) => {
  return (
    <button className={`btn btn-${type}`} onClick={onClick}>
      {children}
      {icon && <i className={`ml-2 fal fa-${icon}`} title={title} />}
    </button>
  );
};
