import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import ArticlesService from 'services/Articles';
import EventsService from 'services/Events';
import NewsService from 'services/News';
import PhotoAlbumsService from 'services/PhotoAlbums';
import Surveys from 'services/Surveys';
import OnlineProcedures from 'services/OnlineProcedures';
import JobOffers from 'services/JobOffers';
import Category from 'services/Category';
import RadioButton from 'components/RadioButton/';
import { sortByTitle } from 'services/Utilities';

const resourceServices = {
  article: new ArticlesService(),
  event: new EventsService(),
  news: new NewsService(),
  photo_album: new PhotoAlbumsService(),
  survey: new Surveys(),
  online_procedure: new OnlineProcedures(),
  job: new JobOffers(),
  category: new Category()
};

const resourceLabels = {
  article: 'Tous les articles',
  event: 'Tous les événements',
  news: 'Toutes les actualités',
  kiosk: 'Tous les kiosques',
  photo_album: 'Tous les albums',
  category: 'Tous les annuaires',
  online_procedure: 'Toutes les procédures en ligne',
  job: 'Tous les emplois',
  survey: 'Tous les sondages'
};

export default class ResourcePicker extends Component {
  static propTypes = {
    resourceType: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object
  };

  state = {
    items: [],
    loading: false
  };

  componentDidMount() {
    this.loadItems();
  }

  /**
   * This hook is important, the modal is often mounted on first page load,
   * then when updating props dynamically (like in the carousel slider)
   * we need to reload the items list depending on the the selected page type on the left
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    const { resourceType } = this.props;
    if (resourceType && resourceType !== prevProps.resourceType) {
      this.loadItems();
    }
  }

  loadItems() {
    const { resourceType } = this.props;
    if (resourceType === 'kiosk') {
      this.setState({ items: [] });
      return;
    }

    if (resourceType in resourceServices) {
      this.setState({ loading: true });

      let params;
      switch (resourceType) {
        case 'online_procedure':
          params = '';
          break;
        case 'category':
          params = '?kind=directory_address';
          break;
        default:
          params = '?scopes[published]';
      }

      resourceServices[resourceType].get(null, params).then(({ data }) => {
        const items = data.sort(sortByTitle);
        this.setState({
          items,
          loading: false
        });
      });
    }
  }

  handleChange = (title, id, slug) => {
    const { resourceType } = this.props;

    this.props.onChange({
      internal: true,
      title,
      id,
      slug,
      resourceType
    });
  };

  render() {
    const { resourceType, value } = this.props;
    const { loading, items } = this.state;

    if (loading) {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      );
    }

    return (
      <form className="top-border pt-4 pt-md-0">
        <div className="custom-control custom-radio mb-2">
          <RadioButton
            onChange={() =>
              this.handleChange(resourceLabels[resourceType], null)
            }
            value=""
            checked={!!(value && !value.id && !value.slug)}
            text={resourceLabels[resourceType]}
          />
        </div>
        {items.map(item => (
          <div className="custom-control custom-radio mb-2" key={item.id}>
            <RadioButton
              onChange={() =>
                this.handleChange(
                  item.title || item.name,
                  item.id,
                  item.slug || item.name
                )
              }
              value={item.id}
              checked={
                !!(
                  value &&
                  (value.id === item.id ||
                    (value.slug &&
                      (value.slug === item.slug ||
                        value.slug === item.name ||
                        value.slug === item.id)))
                )
              }
              text={item.title || item.name}
            />
          </div>
        ))}
      </form>
    );
  }
}
