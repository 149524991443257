// React
import React, { Component } from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default class ModalDraw extends Component {
  constructor(props) {
    super(props);

    // state
    this.state = {
      isVisible: false,
      id: this.props.id
    };

    // binding
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle() {
    this.setState({ isVisible: !this.state.isVisible });
  }

  handleChange(event) {
    let newState = Object.assign({}, this.state);
    newState[event.target.name] = event.target.value;
    this.setState(newState);
  }

  handleSubmit(event, title) {
    event.preventDefault();
    this.toggle();
    this.props.handleSubmit(title);
  }

  render() {
    return (
      <div style={{ display: 'inherit' }}>
        <button
          type="button"
          onClick={() => this.toggle()}
          className={'btn ' + this.props.btnClass}
          title="Dupliquer"
        >
          {this.props.btnText} {this.props.ico}
        </button>
        <Modal isOpen={this.state.isVisible} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Dupliquer</ModalHeader>
          <ModalBody>
            <label htmlFor="">Choisissez un titre</label>
            <input
              type="text"
              className="form-control"
              name="title"
              aria-describedby="titleHelp"
              aria-label="Titre"
              placeholder="Titre"
              value={this.state.title || ''}
              onChange={this.handleChange}
            />
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-outline-primary" onClick={this.toggle}>
              Annuler
            </button>{' '}
            <Button
              color="primary"
              onClick={event => this.handleSubmit(event, this.state.title)}
            >
              Enregistrer
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
