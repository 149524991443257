const currentUserId = localStorage.getItem('currentUserId');

let pageMatcher = {
  root: {
    path: '/',
    pageTitle: 'Accueil'
  },
  // Admin
  admin: {
    path: '/',
    pageTitle: 'Administration',

    account: {
      get path() {
        return pageMatcher.admin.path + 'account/';
      },
      pageTitle: 'Mon compte',
      pack: {
        get path() {
          return pageMatcher.admin.account.path + 'pack/';
        },
        pageTitle: 'Aide et contact' //'Packages et options'
      },
      invitation: {
        get path() {
          return pageMatcher.admin.account.path + 'invitations/';
        },
        pageTitle: 'Inviter'
      },
      users: {
        get path() {
          return pageMatcher.admin.account.path + 'users/';
        },
        pageTitle: 'Utilisateurs',
        single: {
          get path() {
            return pageMatcher.admin.account.users.path + ':id/';
          },
          pageTitle: 'Utilisateur'
        }
      }
    },
    home: {
      get path() {
        return pageMatcher.admin.path + 'home/';
      },
      pageTitle: 'Accueil',
      // stats: {
      //   get path() {
      //     return pageMatcher.admin.home.path + 'stats';
      //   },
      //   pageTitle: 'Statistiques'
      // },
      contacts: {
        get path() {
          return pageMatcher.admin.home.path + 'contacts';
        },
        pageTitle: 'Contacts mairie'
      }
      /*
      guide: {
        get path() {
          return pageMatcher.admin.home.path + 'guide';
        },
        pageTitle: "Guide d'utilisation du BO"
      }
      */
    },
    // Begin Website pages
    website: {
      get path() {
        return pageMatcher.admin.path + 'website/';
      },
      pageTitle: 'Mon Site'
    },
    homepage: {
      get path() {
        return pageMatcher.admin.website.path + 'homepage/';
      },
      pageTitle: "Page d'accueil",
      carousel: {
        get path() {
          return pageMatcher.admin.homepage.path + 'carousel/';
        },
        pageTitle: 'Carrousel'
      },
      campaigns: {
        get path() {
          return pageMatcher.admin.homepage.path + 'campaigns/';
        },
        pageTitle: "Bandeau d'alertes",
        option: 'campaign'
      },
      mostConsulted: {
        get path() {
          return pageMatcher.admin.homepage.path + 'most_consulted/';
        },
        pageTitle: 'Services les plus consultés'
      },
      interactive_map: {
        get path() {
          return pageMatcher.admin.homepage.path + 'interactive_map/';
        },
        pageTitle: 'Carte interactive'
      }
    },
    menu: {
      get path() {
        return pageMatcher.admin.website.path + 'menu/';
      },
      pageTitle: 'Menu'
    },
    citizens: {
      get path() {
        return pageMatcher.admin.path + 'citizens/';
      },
      pageTitle: 'BDD Citoyens',
      export: {
        get path() {
          return pageMatcher.admin.citizens.path + 'export/';
        },
        pageTitle: 'Télécharger toutes les fiches citoyens'
      },
      single: {
        get path() {
          return pageMatcher.admin.citizens.path + ':id/';
        },
        pageTitle: 'Citoyen'
      },
      new: {
        get path() {
          return pageMatcher.admin.citizens.path + 'new/';
        },
        pageTitle: 'Créer une fiche citoyen'
      }
    },
    accessibility: {
      get path() {
        return pageMatcher.admin.website.path + 'accessibility/';
      },
      pageTitle: 'Accessibilité'
    },
    legal: {
      get path() {
        return pageMatcher.admin.website.path + 'legal/';
      },
      pageTitle: 'Mentions Légales'
    },
    addresses: {
      get path() {
        return pageMatcher.admin.website.path + 'addresses/';
      },
      pageTitle: 'Annuaire',

      annuaire: {
        get path() {
          return pageMatcher.admin.addresses.path + 'address_book/';
        },
        pageTitle: 'Annuaires',
        single: {
          get path() {
            return pageMatcher.admin.addresses.annuaire.path + ':id/';
          },
          pageTitle: 'Annuaire'
        },
        new: {
          get path() {
            return pageMatcher.admin.addresses.annuaire.path + 'new/';
          },
          pageTitle: 'Nouvelle annuaire'
        }
      },
      categories: {
        get path() {
          return pageMatcher.admin.addresses.path + 'categories/';
        },
        pageTitle: 'Catégories',
        single: {
          get path() {
            return pageMatcher.admin.addresses.categories.path + ':id/';
          },
          pageTitle: 'Catégorie'
        },
        new: {
          get path() {
            return pageMatcher.admin.addresses.categories.path + 'new/';
          },
          pageTitle: 'Nouvelle catégorie'
        }
      }
    },
    surveys: {
      get path() {
        return pageMatcher.admin.website.path + 'surveys/';
      },
      pageTitle: 'Questionnaires',
      option: 'survey',
      single: {
        get path() {
          return pageMatcher.admin.surveys.path + ':id/';
        },
        pageTitle: 'Questionnaire'
      },
      archived: {
        get path() {
          return pageMatcher.admin.surveys.path + 'archived/';
        },
        pageTitle: 'Questionnaires archivés'
      },
      new: {
        get path() {
          return pageMatcher.admin.surveys.path + 'new/';
        },
        pageTitle: 'Nouveau questionnaire'
      }
    },
    onlineProcedures: {
      get path() {
        return pageMatcher.admin.website.path + 'online_procedures/';
      },
      pageTitle: 'Procédures en ligne',

      procedures: {
        get path() {
          return pageMatcher.admin.onlineProcedures.path + 'procedures/';
        },
        pageTitle: 'Services',

        single: {
          get path() {
            return pageMatcher.admin.onlineProcedures.procedures.path + ':id/';
          },
          pageTitle: 'Service'
        },
        new: {
          get path() {
            return pageMatcher.admin.onlineProcedures.procedures.path + 'new/';
          },
          pageTitle: 'Nouveau service'
        }
      },
      categories: {
        get path() {
          return pageMatcher.admin.onlineProcedures.path + 'categories/';
        },
        pageTitle: 'Catégories',
        single: {
          get path() {
            return pageMatcher.admin.onlineProcedures.categories.path + ':id/';
          },
          pageTitle: 'Catégorie'
        },
        new: {
          get path() {
            return pageMatcher.admin.onlineProcedures.categories.path + 'new/';
          },
          pageTitle: 'Nouvelle catégorie'
        }
      }
    },

    chat: {
      get path() {
        return pageMatcher.admin.website.path + 'chat/';
      },
      pageTitle: 'Chat',
      option: 'chat',
      single: {
        get path() {
          return pageMatcher.admin.chat.path + ':id/';
        },
        pageTitle: 'Chat'
      },
      new: {
        get path() {
          return pageMatcher.admin.chat.path + 'new/';
        },
        pageTitle: 'Nouveau chat'
      },
      live: {
        get path() {
          return pageMatcher.admin.chat.path + 'live/';
        },
        pageTitle: 'Live chat',
        single: {
          get path() {
            return pageMatcher.admin.chat.live.path + ':id/';
          },
          pageTitle: 'Live chat'
        }
      }
    },
    rss: {
      get path() {
        return pageMatcher.admin.website.path + 'rss/';
      },
      pageTitle: 'Flux RSS',
      option: 'rss'
    },
    socialNetworks: {
      get path() {
        return pageMatcher.admin.website.path + 'social_networks/';
      },
      pageTitle: 'Réseaux sociaux'
    },
    content: {
      get path() {
        return pageMatcher.admin.website.path + 'content/';
      },
      pageTitle: 'Pages de contenus',

      articles: {
        get path() {
          return pageMatcher.admin.content.path + 'articles/';
        },
        pageTitle: 'Articles',
        single: {
          get path() {
            return pageMatcher.admin.content.articles.path + ':id/';
          },
          pageTitle: 'Article'
        },
        new: {
          get path() {
            return pageMatcher.admin.content.articles.path + 'new/';
          },
          pageTitle: 'Nouvel article'
        }
      },
      news: {
        get path() {
          return pageMatcher.admin.content.path + 'news/';
        },
        pageTitle: 'Actualités',
        single: {
          get path() {
            return pageMatcher.admin.content.news.path + ':id/';
          },
          pageTitle: 'Actualité'
        },
        new: {
          get path() {
            return pageMatcher.admin.content.news.path + 'new/';
          },
          pageTitle: 'Nouvelle actualité'
        }
      },
      kiosks: {
        get path() {
          return pageMatcher.admin.content.path + 'kiosks/';
        },
        pageTitle: 'Kiosque',
        single: {
          get path() {
            return pageMatcher.admin.content.kiosks.path + ':id/';
          },
          pageTitle: 'Kiosque'
        },
        new: {
          get path() {
            return pageMatcher.admin.content.kiosks.path + 'new/';
          },
          pageTitle: 'Nouveau kiosque'
        }
      },
      events: {
        get path() {
          return pageMatcher.admin.content.path + 'events/';
        },
        pageTitle: 'Evénements',
        single: {
          get path() {
            return pageMatcher.admin.content.events.path + ':id/';
          },
          pageTitle: 'Evénement'
        },
        new: {
          get path() {
            return pageMatcher.admin.content.events.path + 'new/';
          },
          pageTitle: 'Nouvel événement'
        }
      },
      jobOffers: {
        get path() {
          return pageMatcher.admin.content.path + 'job_offers/';
        },
        pageTitle: "Offres d'emploi | stage",
        single: {
          get path() {
            return pageMatcher.admin.content.jobOffers.path + ':id/';
          },
          pageTitle: "Offre d'emploi | stage"
        },
        new: {
          get path() {
            return pageMatcher.admin.content.jobOffers.path + 'new/';
          },
          pageTitle: "Nouvelle offre d'emploi | stage"
        }
      },
      photoLibrairies: {
        get path() {
          return pageMatcher.admin.content.path + 'photo_librairies/';
        },
        pageTitle: 'Albums photos',
        single: {
          get path() {
            return pageMatcher.admin.content.photoLibrairies.path + ':id/';
          },
          pageTitle: 'Album photos'
        },
        archived: {
          get path() {
            return pageMatcher.admin.content.photoLibrairies.path + 'archived/';
          },
          pageTitle: 'Albums archivés'
        },
        new: {
          get path() {
            return pageMatcher.admin.content.photoLibrairies.path + 'new/';
          },
          pageTitle: 'Nouvel album photos'
        }
      }
    }
    // End Website
  },
  superAdmin: {
    path: '/admin/',
    users: {
      get path() {
        return pageMatcher.superAdmin.path + 'users/';
      },
      pageTitle: 'Gestion des utilisateurs',
      single: {
        get path() {
          return pageMatcher.superAdmin.users.path + ':id';
        },
        get link() {
          return pageMatcher.superAdmin.users.path + currentUserId;
        },
        pageTitle: 'Mon compte'
      }
    },
    settings: {
      get path() {
        return pageMatcher.superAdmin.path + 'settings/';
      },
      pageTitle: 'Configuration du site'
    }
  }
};

export default pageMatcher;
