import React from 'react';

export default class Title extends React.Component {
  render() {
    const { data, updateSubBlock } = this.props;
    return (
      <React.Fragment>
        <p className="text-muted mb-3">
          Déplacez ce bloc à l'endroit désiré pour votre ancre
        </p>
        <div className="row">
          {data.id && (
            <div className="col">
              <i className="fal fa-anchor text-secondary fa-6x" />
            </div>
          )}
          <div className="col">
            <div className="form-group">
              <label htmlFor="title">Titre</label>
              <input
                value={(data && data.text) || ''}
                onChange={e => updateSubBlock('text', e.target.value)}
                name="title"
                type="text"
                className="form-control"
                aria-describedby="titleHelp"
                placeholder="Titre"
                aria-label="Titre"
              />
              <small id="titleHelp" className="form-text text-muted">
                Titre.
              </small>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
