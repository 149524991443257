import Axios from 'services/Axios';
import Ressource from 'services/Ressource';

export default class Chat extends Ressource {
  constructor() {
    super('chat');
  }

  getQuestions = id => Axios.get(`${this.typePlurial}/${id}/chat_questions`);

  start = id => Axios.put(`${this.typePlurial}/${id}/start`);

  stop = id => Axios.put(`${this.typePlurial}/${id}/stop`);
}

export const validateChatForm = chat => {
  let localError;
  if (!chat.title) {
    localError = Error('Erreur, veuillez donner un titre au chat.');
  } else if (!chat.interlocutor_username) {
    localError = Error(
      "Erreur, veuillez renseigner le nom de l'interlocuteur."
    );
  }
  return localError;
};
