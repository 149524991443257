import Filedrop from 'components/Filedrop';
import NewsService from 'services/News';
import React, { Component } from 'react';
import { Async } from 'react-select';
import DatePickerSingle from 'components/DatePickerSingle';
import DatePickerModule from 'react-datepicker';
import moment from 'moment';

class ArticleBase extends Component {
  state = {
    showPeriod: false
  };

  newsService = new NewsService();

  componentDidMount() {
    const { highlight_begin_at, highlight_end_at } = this.props;

    this.setState({
      ...((highlight_begin_at || highlight_end_at) && { showPeriod: true })
    });
  }

  handleChangePickerHighlightStart = date => {
    const start = date ? date.format() : null;
    this.props.handleDatePickerHighlight(start, null);
  };

  handleChangePickerHighlightEnd = date => {
    const end = date ? date.format() : null;
    this.props.handleDatePickerHighlight(null, end);
  };

  toggleHighlight = () => {
    this.setState(({ showPeriod }) => {
      if (showPeriod) this.props.handleDatePickerHighlight(null, null, true);

      return { showPeriod: !showPeriod };
    });
  };

  getCategories = () => this.newsService.categories('formatForReactSelect');

  render() {
    const template = localStorage.getItem('template');
    const ratio = localStorage.getItem('ratio');
    const ratioNews = JSON.parse(ratio)[template].news;
    const {
      title,
      handleChange,
      handleChangePicker,
      category_id,
      handleCatChange,
      published_at,
      highlight_begin_at,
      highlight_end_at,
      image_url,
      handleFileChange
    } = this.props;

    const { showPeriod } = this.state;

    return (
      <div className="card-koba mt-4">
        <div className="form-group">
          <label htmlFor="title">
            Titre de l'actualité <span className="required">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            aria-label="titre"
            autoComplete="off"
            aria-describedby="titleHelp"
            value={title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="published_at">
            Date de la publication <span className="required">*</span>
          </label>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-secondary">
                <i className="fal fa-calendar-alt" />
              </span>
            </div>
            <DatePickerSingle
              handleDatePicker={handleChangePicker}
              type="publish"
              data={published_at}
              single={true}
              className={'full-width'}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="category_id">
            Catégorie <span className="required">*</span>
          </label>
          <Async
            required
            clearable={false}
            id="category_id"
            name="category_id"
            value={category_id}
            onChange={e => handleCatChange(e)}
            loadOptions={this.getCategories}
            placeholder="choisir..."
            autoload
          />
        </div>
        <div className="form-group">
          <label htmlFor="header">
            Photo <span className="required">*</span>
          </label>
          <div>
            <Filedrop
              required
              withCropper
              name="image"
              accepts="images"
              initValue={image_url}
              onFileChange={handleFileChange}
              ratio={ratioNews}
            />
          </div>
        </div>
        <hr />
        <div className="form-group">
          <div className="form-check">
            <input
              className="form-check-input"
              aria-label="checkbox"
              type="checkbox"
              value=""
              checked={showPeriod}
              id="showPeriod"
              onChange={this.toggleHighlight}
            />
            <label className="form-check-label" htmlFor="showPeriod">
              Choisir cette actualité comme mise en avant
            </label>
          </div>
        </div>
        {showPeriod && (
          <div className="form-group">
            <div className="row date-picker">
              <div className="col-6 full-width">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text text-secondary">
                      <i className="fal fa-calendar-alt" />
                    </span>
                  </div>
                  <div className="date-picker-single">
                    <DatePickerModule
                      selected={
                        highlight_begin_at ? moment(highlight_begin_at) : null
                      }
                      onChange={this.handleChangePickerHighlightStart}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      dateFormat="LLL"
                      timeCaption="l'heure"
                      locale="fr"
                      className="form-control"
                      placeholderText="sélectionnez une date"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 full-width">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text text-secondary">
                      <i className="fal fa-calendar-alt" />
                    </span>
                  </div>
                  <div className="date-picker-single">
                    <DatePickerModule
                      selected={
                        highlight_end_at ? moment(highlight_end_at) : null
                      }
                      onChange={this.handleChangePickerHighlightEnd}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      dateFormat="LLL"
                      timeCaption="l'heure"
                      locale="fr"
                      className="form-control"
                      placeholderText="sélectionnez une date"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ArticleBase;
