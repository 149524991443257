import React, { Component } from 'react';
import DatePickerSingle from 'components/DatePickerSingle';
import { Map, List, Set, getIn, setIn } from 'immutable';
import { validate, asImmutable } from 'services/Validation';

/*
 * The FormWrapper component helps you mutate your data trees with the following "state of the art" features:
 * - Mutate your data for you (use form.handleChange(path, value))
 * - Handle validations "the right way" (split your validations into `realTimeValidations` and `saveValidations`)
 *   and get back the error with form.getError(path)  (use services/Validation under the hood)
 * - Works on an internal copy (allow to `form.reset()` your modifications")
 *
 * The "view" part is entirely up to you, FormWrapper handles the data, nothing more
 * It injects a "form" object in your render structure which contains the following functions:
 * { getValue, getError, handleChange, handleSave, reset }
 * You should provide the following props to the FormWrapper component:
 * value={}, realTimeValidations = [], beforeSaveValidations = []
 * See usage example in the 'containers/Admin/Website/Surveys/SurveyForm/index.js' component
 */
export class Form extends Component {
  state = {
    value: this.props.value,
    errors: Map(),
    isFormTouched: false,
    fieldsTouched: Set()
  };

  reset = () => {
    this.setState({
      value: this.props.value,
      errors: Map(),
      isFormTouched: false,
      fieldsTouched: Set()
    });
  };

  validate = (forSave, onSuccess) => {
    const { realTimeValidations, saveValidations } = this.props;
    const { value } = this.state;
    let errors = asImmutable(validate(value, realTimeValidations));
    if (forSave) {
      errors = errors.merge(asImmutable(validate(value, saveValidations)));
    }
    this.setState(
      {
        errors,
        forSave
      },
      () => {
        if (errors.isEmpty() && onSuccess) onSuccess(value);
      }
    );
  };

  handleBlur = path => {
    this.setState(
      state => ({
        fieldsTouched: state.fieldsTouched.add(List(path))
      }),
      () => this.validate()
    );
  };

  getError = path => {
    const { fieldsTouched, forSave, errors } = this.state;
    const lPath = List(path);
    return (forSave || fieldsTouched.has(lPath)) && errors.get(lPath);
  };

  getValue = path => getIn(this.state.value, path);

  handleChange = (path, val, shouldValidate) => {
    this.setState(
      state => ({
        ...setIn(state, ['value', ...path], val),
        fieldsTouched: state.fieldsTouched.add(List(path)),
        isFormTouched: true
      }),
      () => {
        if (shouldValidate) {
          this.validate();
        }
      }
    );
  };

  handleSave = onSuccess => {
    this.validate(true, onSuccess);
  };

  render() {
    const { children } = this.props;

    return children({
      getValue: this.getValue,
      getError: this.getError,
      handleChange: this.handleChange,
      handleSave: this.handleSave,
      handleBlur: this.handleBlur,
      reset: this.reset
    });
  }
}

export const FormGroup = ({ path, label, required, children, form }) => {
  // automatically inject form into children
  const childrenWithForm = React.Children.map(children, child =>
    React.cloneElement(child, { form })
  );
  const name = path[path.length - 1];
  const error = form.getError(path);
  if (label) {
    return (
      <div>
        <div className="form-group row">
          <label className="col-12 col-sm-2 col-form-label" htmlFor={name}>
            {label}
            {required && <span className="required">*</span>}
          </label>
          <div className="col-12 col-sm-10">
            {childrenWithForm}
            {error && (
              <div className="form-row invalid-feedback">
                <div className="col">{error}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="form-group">{childrenWithForm}</div>;
  }
};

export const Input = ({ path, placeholder, form, onChange }) => {
  const name = path[path.length - 1];
  const error = form.getError(path);
  const value = form.getValue(path);
  const handleChange = onChange
    ? e => onChange(e.target.value)
    : e => form.handleChange(path, e.target.value);
  return (
    <input
      type="text"
      className={'form-control ' + (error ? 'is-invalid' : '')}
      id={name}
      name={name}
      aria-label={name}
      placeholder={placeholder}
      value={value || ''}
      onBlur={() => form.handleBlur(path)}
      onChange={handleChange}
    />
  );
};

export const Checkbox = ({ path, label, form, onChange }) => {
  const name = path[path.length - 1];
  const error = form.getError(path);
  const value = form.getValue(path);
  const handleChange = onChange
    ? e => onChange(e.target.checked)
    : e => form.handleChange(path, e.target.checked);
  return (
    <label htmlFor={name}>
      <input
        name={name}
        aria-label={name}
        className={'mr-2 ' + (error ? 'is-invalid' : '')}
        type="checkbox"
        checked={value || false}
        onBlur={() => form.handleBlur(path)}
        onChange={handleChange}
      />
      {label}
    </label>
  );
};

export const Select = ({ path, options, form, onChange }) => {
  const name = path[path.length - 1];
  const error = form.getError(path);
  const value = form.getValue(path);
  const handleChange = onChange
    ? e => onChange(e.target.value)
    : e => form.handleChange(path, e.target.value);
  return (
    <select
      name={name}
      className={'custom-select' + (error ? 'is-invalid' : '')}
      value={value || options[0].value}
      onBlur={() => form.handleBlur(path)}
      onChange={handleChange}
    >
      {options.map((option, i) => (
        <option key={i} value={option.value}>
          {option.label || option.value}
        </option>
      ))}
    </select>
  );
};

export const DateTimePicker = ({ path, form, onChange }) => {
  const value = form.getValue(path);
  const handleChange = onChange ? onChange : v => form.handleChange(path, v);
  return (
    <div className="input-group mb-3">
      <div className="input-group-prepend">
        <span className="input-group-text text-secondary">
          <i className="fal fa-calendar-alt" />
        </span>
      </div>
      <DatePickerSingle
        handleDatePicker={handleChange}
        type="publish"
        data={value}
        single={true}
        className="full-width"
      />
    </div>
  );
};
