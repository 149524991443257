import React, { Component } from 'react';
import ArticleService from 'services/Articles';
import ArticleBase from '../ArticleBase';
import NewBlock from '../../../Blocks/New';
import EditBlocks from '../../../Blocks/Edit';
import * as Utilities from 'services/Utilities';
import * as PageService from 'services/Page';
import PublishBar from 'components/PublishBar';
import { pageMatcher } from 'constants/Global';

// to do: Article extends EditPage
class Article extends Component {
  state = {
    article: {
      title: '',
      category_id: '',
      published_at: ''
    }
  };

  articleService = new ArticleService();

  getArticle = () => {
    this.articleService
      .get(this.props.match.params.id)
      .then(({ data: article }) => {
        this.setState({ article });
      });
  };

  putArticle = async () => {
    const { article } = this.state;
    const { data: response } = await this.articleService.put(
      this.props.match.params.id,
      article
    );
    Utilities.parseResponseForPublishBar(response, this, 'marginBottom');
    this.getArticle();
  };

  publishArticle = option => {
    this.articleService
      .change(this.state.article.id, option)
      .then(({ data: response }) => {
        Utilities.parseResponseForPublishBar(response, this, 'marginBottom');
      });
  };

  updateBlocks = blocks => {
    PageService.updateLocalBlocks(blocks, this, 'article');
  };

  updateBlock = block => {
    PageService.updateLocalBlock(block, this, 'article');
  };

  handleChange = event => {
    let newState = { ...this.state };
    newState.article[event.target.name] = event.target.value;
    this.setState(newState);
  };

  handleChangePicker = data => {
    let newState = { ...this.state };
    newState.article.published_at = data.start;
    this.setState(newState);
  };

  handleSubmit = event => {
    event.preventDefault();
    this.putArticle();
  };

  addBlock = (block, position) => {
    PageService.addBlock(block, this, 'article', position);
    this.putArticle();
  };

  componentDidMount() {
    this.getArticle();
  }

  render() {
    const { id } = this.props.match.params;
    const { article, statusIsVisible, statusType, statusMessage } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <ArticleBase
          {...article}
          handleChange={this.handleChange}
          handleChangePicker={this.handleChangePicker}
        />
        <EditBlocks
          id={id}
          blocks={article.blocks}
          getPageable={this.getArticle}
          updateLocalBlocks={this.updateBlocks}
          updateLocalBlock={this.updateBlock}
          addBlock={this.addBlock}
        />
        <NewBlock
          pageableId={id}
          getPageable={this.getArticle}
          pageableType="Article"
          addBlock={this.addBlock}
        />
        <PublishBar
          previewUrl={
            localStorage.getItem('frontofficeUrl') + '/mairie/articles/' + id
          }
          state={article.state}
          statusIsVisible={statusIsVisible}
          statusType={statusType}
          statusMessage={statusMessage}
          goBackLink={pageMatcher.admin.content.articles.path}
          showSubmitButton
        />
      </form>
    );
  }
}

export default Article;
