import React, { Component } from 'react';
import SocialNetworkForm from './Form';
import Submenu from 'components/Submenu';
import SocialNetworksService from 'services/SocialNetworks';
import SocialNetworksForm from 'services/SocialNetworksForm';
import * as Utilities from 'services/Utilities';
import PublishBar from 'components/PublishBar';
import { pageMatcher } from 'constants/Global';

class SocialNetworks extends Component {
  prefix = 'https://';
  mapping = { facebook: 0, twitter: 1, instagram: 2, youtube: 3 };
  state = {
    socialNetworks: [
      {
        kind: 'facebook',
        url: ''
      },
      {
        kind: 'twitter',
        url: ''
      },
      {
        kind: 'instagram',
        url: ''
      },
      {
        kind: 'youtube',
        url: ''
      }
    ]
  };

  subMenuData = [pageMatcher.admin.socialNetworks];
  socialNetworksService = new SocialNetworksService();
  socialNetworksForm = new SocialNetworksForm();

  componentDidMount() {
    this.getSocialNetworks();
  }

  handleChange = (event, index) => {
    const target = event.target;
    const { name, value } = target;
    const socialNetworks = [...this.state.socialNetworks];
    socialNetworks[index][name] = value;
    this.setState({ socialNetworks });
  };

  validate = socialNetworks => {
    const validated = socialNetworks.reduce((acc, { url, kind }) => {
      if (url.includes(kind)) return [...acc, true];

      return acc;
    }, []);

    return validated.length === socialNetworks.length;
  };

  handleSubmit = e => {
    e.preventDefault();

    const { socialNetworks } = this.state;
    const submittedSocialNetworks = socialNetworks.reduce(
      (acc, socialNetwork) => {
        const { id = null, kind, url } = socialNetwork;
        if (!url) return acc;

        return [
          ...acc,
          {
            ...(id && { id }),
            url: this.formatURL(socialNetwork.url),
            kind
          }
        ];
      },
      []
    );

    if (this.validate(submittedSocialNetworks)) {
      this.socialNetworksForm
        .saveMultiple(submittedSocialNetworks)
        .then(({ data: response }) => {
          Utilities.parseResponseForPublishBar(response, this, 'marginBottom');
          this.getSocialNetworks();
        });
    } else {
      const err = Error(`Vous devez insérer un lien valide`);
      Utilities.parseResponseForPublishBar(err, this, 'marginBottom');
    }
  };

  handleDelete = (e, id, index, kind) => {
    e.preventDefault();
    const socialNetworks = [...this.state.socialNetworks];
    if (id) {
      this.socialNetworksService.delete(id);
    }
    socialNetworks[index] = { kind: kind, url: '' };
    this.setState({ socialNetworks });
  };

  getSocialNetworks = () => {
    const socialNetworks = [...this.state.socialNetworks];
    this.socialNetworksService.get().then(({ data }) => {
      data.forEach(sns => {
        const index = this.mapping[sns.kind];
        socialNetworks[index] = sns;
      });
      this.setState({ socialNetworks });
    });
  };

  formatURL = url =>
    url.substr(0, this.prefix.length) !== this.prefix ? this.prefix + url : url;

  render() {
    const {
      socialNetworks,
      statusIsVisible,
      statusType,
      statusMessage,
      loading
    } = this.state;

    return (
      <>
        <Submenu subMenuData={this.subMenuData} />
        <div className="card-koba">
          {socialNetworks.map((socialNetwork, index) => (
            <SocialNetworkForm
              {...this.props}
              key={socialNetwork.kind}
              statusIsVisible={statusIsVisible}
              statusType={statusType}
              statusMessage={statusMessage}
              socialNetwork={socialNetwork}
              handleChange={this.handleChange}
              handleDelete={this.handleDelete}
              index={index}
            />
          ))}
        </div>
        <PublishBar
          state={'published'}
          handleSubmit={this.handleSubmit}
          buttonDisabled={loading}
        />
      </>
    );
  }
}

export default SocialNetworks;
