import React from 'react';

export default class Prices extends React.Component {
  // TODO : Add prop types and fix this mess
  handleAddPrice = () => {
    let { prices } = this.props;
    prices.push({
      title: '',
      amount: ''
    });
    const mockedEvent = { target: { name: 'prices', value: prices } };
    this.props.handleChange(mockedEvent);
  };

  handlePriceChange = (event, i) => {
    let prices = this.props.prices;
    prices[i][event.target.name] = event.target.value;
    const mockedEvent = { target: { name: 'prices', value: prices } };
    this.props.handleChange(mockedEvent);
  };

  handleRemovePrice = (event, i) => {
    let prices = this.props.prices;
    prices.splice(i, 1);
    const mockedEvent = { target: { name: 'prices', value: prices } };
    this.props.handleChange(mockedEvent);
  };

  render() {
    return (
      <div className="form-group">
        <label htmlFor="title">Tarifs de l'événement</label>
        {this.props.prices.map((price, i) => (
          <div className="row mb-2" key={i}>
            <div className="col">
              <input
                type="text"
                className="form-control"
                name="title"
                autoComplete="off"
                aria-describedby="titleHelp"
                aria-label="Tarif"
                placeholder="Tarif"
                value={price.title || ''}
                onChange={e => this.handlePriceChange(e, i)}
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                name="amount"
                autoComplete="off"
                aria-describedby="amountHelp"
                aria-label="Prix"
                placeholder="Prix"
                value={price.amount || ''}
                onChange={e => this.handlePriceChange(e, i)}
              />
            </div>
            <div className="col-auto">
              <button
                type="button"
                onClick={e => this.handleRemovePrice(e, i)}
                className="btn table-action-btn btn-action"
              >
                <i className="far fa-trash-alt" title="Supprimer" />
              </button>
            </div>
          </div>
        ))}
        <div>
          <button
            type="button"
            onClick={this.handleAddPrice}
            className="btn btn-outline-secondary btn-block mt-2"
          >
            Ajouter un tarif
          </button>
        </div>
      </div>
    );
  }
}
