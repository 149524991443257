import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';

// Reducers
import StatusMessageReducers from './StatusMessageReducers';
import PublishBarReducer from './PublishBarReducer';
import CampaignReducer from './CampaignReducer';

const reducers = combineReducers({
  publishBar: PublishBarReducer,
  statusMessage: StatusMessageReducers,
  campaign: CampaignReducer
});

const enhancer = window.__REDUX_DEVTOOLS_EXTENSION__
  ? compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  : applyMiddleware(thunk);
const store = createStore(reducers, {}, enhancer);

export default store;
