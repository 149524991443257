import React, { Component } from 'react';
import Slide from './Slide';
import AddNewSlide from './AddNewSlide';
import PublishBar from 'components/PublishBar';
import SliderService from 'services/Sliders';
import ModalSelectLink from 'components/ModalSelectLink';
import { mapLinkDataOnApiRead, mapLinkDataOnApiWrite } from 'services/Link';
import { parseResponseForPublishBar, guid } from 'services/Utilities';

export default class Slider extends Component {
  sliderService = new SliderService();

  state = { slides: [] };

  getSliders = () => {
    this.sliderService.get().then(({ data }) => {
      this.setState({ slides: data });
    });
  };

  patchSliders = () => {
    const { slides } = this.state;

    this.sliderService
      .saveSliders(slides)
      .then(({ data }) => {
        parseResponseForPublishBar(data, this, 'marginBottom');
        this.setState({ slides: data });
      })
      .catch(error => {
        parseResponseForPublishBar(error, this, 'marginBottom');
      });
  };

  handleUpdateSlide = (event, index) => {
    const { name, value } = event.target;

    this.setState(({ slides }) => ({
      slides: slides.map(
        (slide, position) =>
          position === index ? { ...slide, [name]: value } : slide
      )
    }));
  };

  handleAddSlide = _ => {
    this.setState(({ slides }) => ({
      slides: [...slides, { _key: guid(), title: '', description: '' }]
    }));
  };

  handleDeleteSlide = index => {
    this.setState(({ slides }) => ({
      slides: slides.filter((_, i) => i !== index)
    }));
  };

  handleUpdateImage = (file, originalFile, index) => {
    const image = {
      data: file,
      title: 'slider'
    };

    this.setState(({ slides }) => ({
      slides: slides.map(
        (slide, position) => (index === position ? { ...slide, image } : slide)
      )
    }));
  };

  handleDeleteImage = index => {
    this.setState(({ slides }) => ({
      slides: slides.map((slide, position) => {
        if (position === index) {
          const { image, imageable, ...filtered_slide } = slide;

          return filtered_slide;
        }
        return slide;
      })
    }));
  };

  handleDeleteLink = index => {
    this.setState(({ slides }) => ({
      slides: slides.map((slide, position) => {
        if (position === index) {
          slide.delete_link = true;
          const { linkable, link, ...filtered_slide } = slide;

          return filtered_slide;
        }
        return slide;
      })
    }));
  };

  handleCreateLink = value => {
    this.setState(prevState => {
      const { currentSlideIndex } = prevState;

      const {
        delete_link,
        linkable,
        linkable_type,
        link,
        url,
        article,
        event,
        news,
        kiosk,
        album,
        ...slide
      } = prevState.slides[currentSlideIndex];

      prevState.slides[currentSlideIndex] = { ...slide, ...value };

      return { slides: prevState.slides };
    });

    this.toggleModalSelectLink();
  };

  handleMove = (startIndex, targetIndex) => {
    this.setState(({ slides }) => {
      [slides[startIndex], slides[targetIndex]] = [
        slides[targetIndex],
        slides[startIndex]
      ];

      return { slides };
    });
  };

  toggleModalSelectLink = index => {
    this.setState(prevState => ({
      modalSelectLinkActive: !prevState.modalSelectLinkActive,
      currentSlideIndex: index
    }));
  };

  handleSubmit = e => {
    e.preventDefault();

    this.patchSliders();
  };

  componentDidMount() {
    this.getSliders();
  }

  render() {
    const { slides, currentSlideIndex, modalSelectLinkActive } = this.state;
    const currentSlide = slides[currentSlideIndex];
    const template = localStorage.getItem('template');
    const ratio = localStorage.getItem('ratio');
    const ratioCarousel = JSON.parse(ratio)[template].carousel;
    const totalSlides = slides.length;

    return (
      <form className="mt-2">
        {slides.map((slide, index) => (
          <Slide
            key={slide.id || slide._key}
            slide={slide}
            updateSlide={this.handleUpdateSlide}
            updateImage={this.handleUpdateImage}
            deleteSlide={this.handleDeleteSlide}
            deleteImage={this.handleDeleteImage}
            deleteLink={this.handleDeleteLink}
            moveSlide={this.handleMove}
            toggleModalSelectLink={this.toggleModalSelectLink}
            index={index}
            ratio={ratioCarousel}
            totalSlide={totalSlides}
          />
        ))}
        {slides.length < 6 && (
          <AddNewSlide handleAddSlide={this.handleAddSlide} />
        )}
        <ModalSelectLink
          value={currentSlide && mapLinkDataOnApiRead(currentSlide)}
          isOpen={modalSelectLinkActive}
          onSelect={value =>
            this.handleCreateLink(mapLinkDataOnApiWrite(value))
          }
          onCancel={this.toggleModalSelectLink}
        />
        <PublishBar state="published" handleSubmit={this.handleSubmit} />
      </form>
    );
  }
}
