import React from 'react';
import BarStatusMessage from 'components/BarStatusMessage';
import NewCampaign from './New/index';
import ShowCurrentCampaign from './ShowCurrent/index';
import ShowNextCampaign from './ShowNext/index';

export default () => (
  <>
    <div className="row ml-1 mt-4">
      <NewCampaign />
      <div className="col ml-2">
        <ShowCurrentCampaign readonly={true} />
        <ShowNextCampaign readonly={true} />
      </div>
      <BarStatusMessage />
    </div>
  </>
);
