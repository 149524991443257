import Axios from 'services/Axios';
import Ressource from 'services/Ressource';

export default class ContactUs extends Ressource {
  constructor() {
    super('contact_us');
  }

  post = (type, data) => Axios.post(type, { contact_us: data });

  put = (type, data) => Axios.put(type, { contact_us: data });
}
