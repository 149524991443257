import NewsBase from '../NewsBase';
import NewsService from 'services/News';
import React, { Component } from 'react';
import * as Utilities from 'services/Utilities';
import PublishBar from 'components/PublishBar';
import { pageMatcher } from 'constants/Global';

class News extends Component {
  state = {
    news: {
      title: '',
      category_id: '',
      published_at: new Date(),
      highlight_begin_at: null,
      highlight_end_at: null
    }
  };

  newsService = new NewsService();

  post = data => {
    this.newsService.postForm(data).then(({ data: response }) => {
      Utilities.parseResponseForPublishBar(response, this, 'marginBottom');
      if (response && response.id) {
        this.props.history.push(
          pageMatcher.admin.content.news.path + response.id
        );
      }
    });
  };

  handleChange = event => {
    const newState = { ...this.state };
    newState.news[event.target.name] = event.target.value;
    this.setState(newState);
  };

  handleChangePicker = data => {
    const newState = { ...this.state };
    newState.news.published_at = data.start;
    this.setState(newState);
  };

  handleDatePickerHighlight = (start, end, setClear) => {
    this.setState(({ news }) => ({
      news: {
        ...news,
        ...(start && { highlight_begin_at: start }),
        ...(end && { highlight_end_at: end }),
        ...(setClear && { highlight_begin_at: null, highlight_end_at: null })
      }
    }));
  };

  handleCatChange = selectedOption => {
    let event = { target: {} };
    event.target.name = 'category_id';
    event.target.value = selectedOption.id;
    this.handleChange(event);
  };

  handleFileChange = (file, _url) => {
    const imageSrc = file ? file : null;
    const newState = { ...this.state };
    newState.news.image = imageSrc;
    this.setState(newState);
  };

  handleSubmit = event => {
    event.preventDefault();
    this.post(this.state.news);
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <NewsBase
          {...this.state.news}
          handleChange={this.handleChange}
          handleChangePicker={this.handleChangePicker}
          handleDatePickerHighlight={this.handleDatePickerHighlight}
          handleSubmit={this.handleSubmit}
          handleCatChange={this.handleCatChange}
          handleFileChange={this.handleFileChange}
        />
        <PublishBar
          state={'draft'}
          showSubmitButton
          goBackLink={pageMatcher.admin.content.news.path}
        />
      </form>
    );
  }
}

export default News;
