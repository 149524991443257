import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Loading from 'components/Loading';
import Options from 'components/Options';
import moment from 'moment';
import ProcedureService from 'services/OnlineProcedures';
import paginationFactory from 'react-bootstrap-table2-paginator';
import React from 'react';
import TagSearch from 'components/TagSearch';
import * as Utilities from 'services/Utilities';
import { pageMatcher } from 'constants/Global';
import { deleteItemFromArray } from 'services/Utilities';
import { Link } from 'react-router-dom';

export default class Procedures extends React.Component {
  state = {
    allProcedures: [],
    procedures: [],
    loading: true
  };

  procedureService = new ProcedureService();

  get = () => {
    this.procedureService.get().then(({ data: procedures }) => {
      this.setState({ procedures, allProcedures: procedures, loading: false });
    });
  };

  delete = id => {
    this.procedureService.delete(id).then(() => {
      Utilities.parseResponseForPublishBar(undefined, this, 'marginBottom');
      const procedures = deleteItemFromArray([...this.state.procedures], id);
      this.setState({ procedures });
    });
  };

  filterList = event => {
    const value = event.target.value.toLowerCase();
    this.setState(prevState => ({
      procedures: prevState.allProcedures.filter(({ title }) =>
        title.toLowerCase().includes(value)
      )
    }));
  };

  dateFormatter = (cell, _row) => {
    if (cell) {
      return <span>{moment(cell).format('DD/MM/YYYY')}</span>;
    }
  };

  optionsFormatter = (cell, row) => (
    <Options
      row={row}
      delete={this.delete}
      label="le service"
      kind="procedure"
    />
  );

  titleFormatter = (cell, row) => (
    <Link to={pageMatcher.admin.onlineProcedures.procedures.path + row.id}>
      {cell}
    </Link>
  );

  componentDidMount() {
    this.get();
  }

  render() {
    const optionsPagination = Utilities.buildPaginationOptions();
    const columns = [
      {
        dataField: 'title',
        text: 'Titre',
        formatter: this.titleFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'created_at',
        text: 'Date de création',
        formatter: this.dateFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'author',
        text: 'Auteur',
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'url',
        text: 'URL',
        formatter: this.urlFormatter,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'action',
        text: 'Actions',
        formatter: this.optionsFormatter,
        sort: false,
        headerStyle: { fontSize: 11 },
        align: 'right'
      }
    ];

    return (
      <>
        <TagSearch
          handleSearch={this.filterList}
          buttonTitle="Ajouter un service"
          buttonPlusLien={
            pageMatcher.admin.onlineProcedures.procedures.new.path
          }
        />
        <BootstrapTable
          keyField="id"
          data={this.state.procedures}
          columns={columns}
          bordered={false}
          pagination={paginationFactory(optionsPagination)}
          classes="koba-array"
          filter={filterFactory()}
        />
        <Loading loading={this.state.loading} />
      </>
    );
  }
}
