import articlesService from 'services/Articles';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import moment from 'moment';
import Options from 'components/Options';
import paginationFactory from 'react-bootstrap-table2-paginator';
import React, { Component } from 'react';
import Spinner from 'components/Spinner';
import TagSearch from 'components/TagSearch';
import { parseResponseForPublishBar, parseUrl } from 'services/Utilities';
import { pageMatcher } from 'constants/Global';
import Toggle from 'components/Toggle';
import { Link } from 'react-router-dom';

// to do: articleService extends Page
export default class Articles extends Component {
  state = {
    allArticles: [],
    filteredArticles: [],
    loading: true
  };

  articleService = new articlesService();

  // TODO: Add catch clause
  getArticles = () => {
    this.articleService
      .getOnly('?scopes[by_updated_at]')
      .then(({ data: response }) => {
        this.setState({
          allArticles: response,
          filteredArticles: response,
          loading: false
        });
      });
  };

  deleteArticle = articleId => {
    this.articleService.delete(articleId).then(({ data: _response }) => {
      this.getArticles();
    });
  };

  copyArticle = (articleId, title) => {
    this.articleService
      .duplicate(articleId, title)
      .then(({ data: response }) => {
        parseResponseForPublishBar(response, this);
        if (response && response.slug) {
          this.props.history.push(
            pageMatcher.admin.content.articles.path + response.slug
          );
        }
      });
  };

  urlFormatter = (cell, row) => (
    <a
      href={
        localStorage.getItem('frontofficeUrl') +
        '/' +
        parseUrl('/articles/' + row.slug)
      }
      target="_blank"
      rel="noopener noreferrer"
    >
      {'/articles/' + row.slug}
    </a>
  );

  stateFormatter = (cell, _row) => {
    if (cell) {
      return cell === 'published' ? (
        <span>Publié</span>
      ) : (
        <span>Hors-ligne</span>
      );
    }
  };

  optionsFormatter = (cell, row) => (
    <Options
      row={row}
      delete={this.deleteArticle}
      label="l'article"
      kind="articles"
      copy={this.copyArticle}
    />
  );

  titleFormatter = (cell, row) => (
    <Link to={pageMatcher.admin.content.articles.path + row.id}>{cell}</Link>
  );

  componentDidMount() {
    this.getArticles();
  }

  dateFormatter = (cell, _row) => {
    if (cell) {
      return <span>{moment(cell).format('DD/MM/YYYY')}</span>;
    }
  };

  toggleState = ({ id, state }) => {
    const option = state === 'published' ? 'draft' : 'publish';

    this.articleService.change(id, option).then(({ data }) => {
      const updatedArticles = this.state.allArticles.map(article =>
        article.id === id ? { ...article, ...data } : article
      );

      this.setState(() => ({
        allArticles: updatedArticles,
        filteredArticles: updatedArticles
      }));
    });
  };

  activeFormatter = (cell, row) => (
    <Toggle
      checked={cell === 'published'}
      name="state"
      onChange={() => this.toggleState(row)}
    />
  );

  handleSearch = event => {
    const value = event.target.value.toLowerCase();

    this.setState(prevState => ({
      filteredArticles: prevState.allArticles.filter(item =>
        ['title', 'author'].some(
          column => item[column] && item[column].toLowerCase().includes(value)
        )
      )
    }));
  };

  render() {
    const optionsPagination = {
      page: 1,
      paginationSize: 4,
      pageStartIndex: 1,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true,
      nextPageTitle: 'Prochaine page',
      prePageTitle: 'Page précédente'
    };

    const columns = [
      {
        dataField: 'title',
        text: 'Titre',
        formatter: this.titleFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'created_at',
        text: 'Date de création',
        formatter: this.dateFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'author',
        text: 'Auteur',
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'url',
        text: 'URL',
        formatter: this.urlFormatter,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'state',
        text: 'En ligne / Hors ligne',
        formatter: this.activeFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'action',
        text: 'Actions',
        formatter: this.optionsFormatter,
        headerStyle: { fontSize: 11 },
        align: 'right'
      }
    ];

    const { filteredArticles, loading } = this.state;

    return (
      <>
        <TagSearch
          handleSearch={this.handleSearch}
          buttonTitle="Ajouter un article"
          buttonPlusLien={pageMatcher.admin.content.articles.new.path}
        />
        <BootstrapTable
          keyField="id"
          data={filteredArticles}
          columns={columns}
          hover
          bordered={false}
          pagination={paginationFactory(optionsPagination)}
          classes="koba-array"
          filter={filterFactory()}
        />
        {loading && (
          <div className="text-center">
            <Spinner />
          </div>
        )}
      </>
    );
  }
}
