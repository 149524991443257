import PropTypes from 'prop-types';
import React from 'react';
import { Input } from 'reactstrap';

export default class InputClass extends React.Component {
  render() {
    const {
      textarea,
      helpText,
      label,
      maxLength,
      required,
      ...cleanProps
    } = this.props;
    return (
      <>
        <div className="form-group">
          <label htmlFor={this.props.name || ''} style={{ width: '100%' }}>
            {label}
            {required && <span className="required"> *</span>}
            {maxLength && (
              <span
                className="pt-1 float-right"
                style={{ fontSize: '0.7em', color: '#5151f5' }}
              >
                Maximum 40 caractères
              </span>
            )}
          </label>
          <Input
            maxLength={maxLength ? maxLength : null}
            className="form-control"
            type={textarea ? 'textarea' : 'input'}
            {...cleanProps}
          />
        </div>
        {helpText && (
          <small className="form-text text-muted">{this.props.helpText}</small>
        )}
      </>
    );
  }
}

InputClass.propTypes = {
  textarea: PropTypes.bool,
  helpText: PropTypes.string,
  label: PropTypes.string
};
