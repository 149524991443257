/**
 * Root.js
 *
 * This is the entry file for the application
 */

// Import all the third party stuff
import React, { Component } from 'react';
import moment from 'moment/moment';
import 'moment/locale/fr';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-select/dist/react-select.css';

import { Provider } from 'react-redux';
import store from './redux/Store';

// App related
import App from 'containers/App';
import { checkSlugIsAuthorized } from 'services/Auth';
import { fetchTownHallInformations } from 'services/TownHall';

document.body.addEventListener('mousedown', function() {
  document.body.classList.add('using-mouse');
});
document.body.addEventListener('keydown', function() {
  document.body.classList.remove('using-mouse');
});

/*
 TODO:
  - Globally separate presentational components and their respective container
  - Determine whether it is an update or a creation by simply check if an id is there
  - Keep the code as DRY as possible ...
  - Get rid of useless packages (e.g things that can be done easily and natively)
  - Finally, implement linters with the same rules as portal
  - Create a single container component on multiple ressource
    and decide whether it is a post or an update
    based on a field (id) or the URL (/new)
 */

class Root extends Component {
  state = {
    loaded: false
  };

  componentDidMount() {
    moment.locale('fr'); // Global config
    const authError = checkSlugIsAuthorized();
    if (authError) {
      // Redirect to portal
      window.location.replace(
        `${process.env.REACT_APP_PORTAL_URL}?message=tokenExpired`
      );
    } else {
      // Fetch townhall informations
      fetchTownHallInformations().then(({ data: response }) => {
        const {
          name,
          url,
          options,
          template,
          maximum_number_of_users
        } = response;
        const optionsName = options && options.map(option => option.slug);
        const ratio = {
          koba: {
            carousel: 1342 / 479,
            events: 616 / 300,
            news: 616 / 300,
            photo: 1,
            kiosk: 341 / 400
          },
          maxi: {
            carousel: 1467 / 889,
            events: 616 / 300,
            news: 616 / 300,
            photo: 1,
            kiosk: 147 / 200
          }
        };
        localStorage.setItem('ratio', JSON.stringify(ratio));
        localStorage.setItem('template', template);
        localStorage.setItem('options', optionsName);
        localStorage.setItem('townHallName', name);
        localStorage.setItem('frontofficeUrl', url || '');
        localStorage.setItem('maxUsers', maximum_number_of_users);
        this.setState({ loaded: true });
      });
    }
  }

  render() {
    // TODO: return Loader instead of null smh
    if (!this.state.loaded) return null;

    return (
      <Provider store={store}>
        <App />
      </Provider>
    );
  }
}

export default Root;
