import React, { Component } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';
import ModalDraw from 'components/ModalDraw';
import DatePicker from 'components/DatePicker';
import classnames from 'classnames';
import './index.scss';

const EditButtons = props => {
  const {
    campaign: { id },
    handleDelete,
    toggleReadonly,
    getInitState,
    handleUpdate,
    readonly
  } = props;

  if (readonly) {
    return (
      <div className="row mt-4 mb-4">
        <div className="col-12 col-lg-6">
          <ModalDraw
            id={id}
            header={'Supprimer'}
            body={'Etes-vous sûr de vouloir supprimer cette alerte ?'}
            label="Supprimer"
            ico=""
            action={() => handleDelete()}
            btnText={'Supprimer'}
            btnClass={'btn-block btn-outline-primary'}
          />
        </div>
        <div className="col-12 mt-2 mt-lg-0 col-lg-6">
          <Button
            type="button"
            className={'btn-block btn-primary'}
            color="primary"
            onClick={toggleReadonly}
          >
            Modifier
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="row mt-4 mb-4">
        <div className="col-12 col-lg-6">
          <Button
            type="button"
            className="btn-block btn-outline-primary"
            onClick={e => {
              toggleReadonly(e);
              getInitState();
            }}
          >
            Annuler <span className="oi oi-circle-x" />
          </Button>
        </div>
        <div className="col-12 mt-2 mt-lg-0 col-lg-6 ">
          <Button
            type="button"
            className="btn-block btn-primary"
            color="primary"
            onClick={handleUpdate}
          >
            Mettre à jour <span className="oi oi-check" />
          </Button>
        </div>
      </div>
    );
  }
};

const FormButtons = props => {
  const {
    campaign: { id }
  } = props;

  if (id) return <EditButtons {...props} />;

  return (
    <div className="row mt-4 mb-4 ">
      <div className="col-12">
        <button type="submit" className="btn btn-primary float-right">
          Enregistrer
        </button>
      </div>
    </div>
  );
};

class CampaignForm extends Component {
  render() {
    const {
      handleSubmit,
      campaign: { id, message, begin_at, end_at },
      handleChange,
      readonly,
      handleApply
    } = this.props;

    // Props cannot be mutated by the child so cannot perform destructuring assignment
    const dates = { begin_at: begin_at || {}, end_at: end_at || {} };
    const count = message.length;

    return (
      <div>
        <Form onSubmit={handleSubmit}>
          <FormGroup className="row">
            <label htmlFor="text_alert" className="col-sm-6 col-form-label">
              Texte de l'alerte{' '}
              {id !== '' || <span className="required">*</span>}
            </label>
            <p className="text-right col-sm-6 primary-color m-auto">
              <small>Maximum 140 caractères</small>
            </p>
            <div className="col-sm-12">
              <textarea
                name="text_alert"
                id="text_alert"
                cols="10"
                rows="3"
                className="form-control"
                style={{ resize: 'none' }}
                maxLength={140}
                placeholder="Votre message"
                value={message || ''}
                onChange={handleChange}
                readOnly={readonly}
                autoFocus={!readonly}
                required
              />
              <p
                className={classnames(
                  'text-right',
                  (count === 0 || readonly) && 'd-none',
                  count >= 100 && count < 140 && 'text-warning',
                  count === 140 && 'text-danger'
                )}
              >
                {count}
                /140
              </p>
            </div>
          </FormGroup>
          <FormGroup className="row">
            <label htmlFor={'dates_alert'} className="col-sm-12 col-form-label">
              Dates de mise en ligne de l'alerte{' '}
              {id !== '' || <span className="required">*</span>}
            </label>
            <div className="col-sm-12">
              <DatePicker
                required
                handleDatePicker={handleApply}
                type="campaign"
                data={dates}
                readonly={readonly}
              />
            </div>
          </FormGroup>
          <div>
            <FormButtons {...this.props} />
          </div>
        </Form>
      </div>
    );
  }
}

export default CampaignForm;
