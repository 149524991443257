import React, { Component } from 'react';
import KioskService from 'services/Kiosk';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import Toggle from 'components/Toggle';
import Options from 'components/Options';
import TagSearch from 'components/TagSearch';
import moment from 'moment';
import Spinner from 'components/Spinner';
import { pageMatcher } from 'constants/Global';
import { Link } from 'react-router-dom';

// TODO: Add catch clause
class Kiosk extends Component {
  state = {
    filteredKiosks: [],
    allKiosks: [],
    totalSize: 0,
    pageNumber: 1,
    sizePerPage: 10,
    loading: true
  };

  kioskService = new KioskService();

  getKiosks = pageNumber => {
    const { sizePerPage, allKiosks } = this.state;
    /**
     * without parameters the function get all the kiosks
     * with parameters the function get a limited number of kiosks
     *  @type {{pageNumber: number, dataParPage: number}}
     *  koba-civique.fr/kiosks?page=${pageNumber}&per_page=${dataParPage}&_slug=...
     */
    this.kioskService.getKiosks(pageNumber, sizePerPage).then(response => {
      const totalSize = parseInt(response.headers['pagination-total']);
      const tempAllKiosks = allKiosks.length ? allKiosks : new Array(totalSize);

      const debut = pageNumber === 1 ? 0 : (pageNumber - 1) * sizePerPage;
      const data = this.kioskService.formatKiosk(response.data);

      for (let i = 0; i < sizePerPage; i++) {
        if (data[i]) tempAllKiosks[debut + i] = data[i];
      }

      this.setState({
        filteredKiosks: tempAllKiosks,
        allKiosks: tempAllKiosks,
        loading: false,
        totalSize: parseInt(response.headers['pagination-total']),
        pageNumber: pageNumber
      });
    });
  };

  handleDelete = id => {
    this.kioskService.delete(id).then(({ data: _response }) => {
      let filteredKiosks = [...this.state.filteredKiosks];
      const kioskIndex = filteredKiosks.findIndex(kiosk => kiosk.id === id);
      filteredKiosks.splice(kioskIndex, 1);
      this.setState({ filteredKiosks });
    });
  };

  handleChange = (id, state) => {
    const filteredKiosks = [...this.state.filteredKiosks];
    const index = filteredKiosks.findIndex(kiosk => kiosk.id === id);

    this.kioskService.toggleActive(id, state).then(({ data: res }) => {
      filteredKiosks[index] = this.kioskService.formatKiosk(res);
      this.setState({ filteredKiosks });
    });
  };

  optionsFormatter = (cell, row) => (
    <Options
      row={row}
      delete={this.handleDelete}
      label="le kiosque"
      kind="kiosks"
      addButton={false}
      see={true}
      preview={true}
    />
  );

  titleFormatter = (cell, row) => {
    if (row) {
      return <Link to={pageMatcher.admin.content.kiosks.path + row.id}>{cell}</Link>
    }
  };

  dateFormatter = cell => {
    if (cell) {
      return <span>{moment(cell).format('DD/MM/YYYY')}</span>;
    }
  };

  activeFormatter = (cell, row) => (
    <Toggle
      checked={cell}
      name="state"
      onChange={event => this.handleChange(row.id, cell, event)}
    />
  );

  componentDidMount() {
    this.getKiosks(1);
  }

  handleSearch = event => {
    const value = event.target.value.toLowerCase();
    const filteredKiosks = this.state.allKiosks.filter(item =>
      ['title', 'author'].some(
        column => item[column] && item[column].toLowerCase().includes(value)
      )
    );
    this.setState({
      filteredKiosks: filteredKiosks,
      totalSize: filteredKiosks.length,
      pageNumber: 1
    });
  };

  render() {
    const {
      sizePerPage,
      pageNumber,
      totalSize,
      loading,
      filteredKiosks
    } = this.state;
    const optionsPagination = {
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPage,
      page: pageNumber,
      totalSize,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true,
      nextPageTitle: 'Page suivante',
      prePageTitle: 'Page précédente',
      onPageChange: (page, _sizePerPage) => {
        if (pageNumber !== page) {
          this.getKiosks(page);
        }
      }
    };

    const columns = [
      {
        dataField: 'title',
        text: 'Titre',
        formatter: this.titleFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'publication_date',
        text: 'Date de publication',
        formatter: this.dateFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'author',
        text: 'Auteur',
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'state',
        text: 'Activer/Désactiver',
        formatter: this.activeFormatter,
        headerStyle: { fontSize: 11 },
        align: 'left'
      },
      {
        dataField: 'actions',
        text: 'Actions',
        formatter: this.optionsFormatter,
        headerStyle: { fontSize: 11 },
        align: 'center'
      }
    ];

    if (loading)
      return (
        <div className="text-center">
          <Spinner />
        </div>
      );

    return (
      <>
        <TagSearch
          handleSearch={this.handleSearch}
          buttonTitle="Ajouter un kiosque"
          buttonPlusLien={pageMatcher.admin.content.kiosks.new.path}
        />
        <BootstrapTable
          keyField="id"
          noDataIndication="Aucun kiosque créé pour le moment"
          columns={columns}
          data={filteredKiosks}
          hover
          bordered={false}
          pagination={paginationFactory(optionsPagination)}
          classes="koba-array"
          filter={filterFactory()}
        />
      </>
    );
  }
}

export default Kiosk;
