import EventBase from '../EventBase';
import EventService, { validateEvents } from 'services/Events';
import React, { Component } from 'react';
import * as PageService from 'services/Page';
import { imageUpload, parseResponseForPublishBar } from 'services/Utilities';
import { pageMatcher } from 'constants/Global';
import { ModalLoading } from 'components/ModalLoading';
import { findAddressComponent } from 'services/GooglePlace';
import NewBlock from '../../../Blocks/New';
import EditBlocks from '../../../Blocks/Edit';
import PublishBar from 'components/PublishBar';

class EventLayout extends Component {
  state = {
    periodType: '',
    event: {
      title: '',
      category_id: '',
      state: 'draft',
      street: '',
      image_url: '',
      prices: [],
      published_at: new Date(),
      highlight_begin_at: null,
      highlight_end_at: null,
      begin_at: null,
      end_at: null,
      blocks: []
    },
    loading: false
  };

  eventService = new EventService();

  getEvent = () => {
    const { id } = this.props.match.params;

    if (!id) return this.setState({ loading: false });

    this.eventService.get(id).then(({ data: response }) => {
      const { begin_at, end_at } = response;
      let periodType;
      if (begin_at && end_at) {
        periodType = 'period';
      } else if (begin_at) {
        periodType = 'unic';
      } else {
        periodType = 'until';
      }
      const event = { ...response, category_id: response.category.id };
      this.setState({ periodType, event, loading: false });
    });
  };

  postEvent = () => {
    const { event } = this.state;
    if (event.prices.length === 0) {
      event.prices.push({
        title: '',
        amount: ''
      });
    }
    this.eventService.post(event).then(({ data: response }) => {
      parseResponseForPublishBar(response, this, true);
      this.props.history.push(
        pageMatcher.admin.content.events.path + response.id
      );
      this.setState({ event: response });
    });
  };

  putEvent = () => {
    const { event } = this.state;
    if (event.prices.length === 0) {
      event.prices.push({
        title: '',
        amount: ''
      });
    }
    this.eventService.put(event.id, event).then(({ data: response }) => {
      parseResponseForPublishBar(response, this, true);
      this.getEvent();
    });
  };

  publishEvent = option => {
    const { event } = this.state;
    this.eventService.change(event.id, option).then(({ data: response }) => {
      parseResponseForPublishBar(response, this, true);
      this.setState(({ event }) => ({
        event: {
          ...event,
          state: response.state
        }
      }));
    });
  };

  addBlock = (block, position) => {
    PageService.addBlock(block, this, 'event', position);
    this.putEvent();
  };

  updateBlocks = blocks => {
    PageService.updateLocalBlocks(blocks, this, 'event');
  };

  updateBlock = block => {
    PageService.updateLocalBlock(block, this, 'event');
  };

  handleChange = e => {
    const { value, name } = e.target;
    this.setState(({ event }) => ({
      event: {
        ...event,
        [name]: value
      }
    }));
  };

  handleFileChange = async file => {
    this.setState({ loading: true });
    const imageData = await imageUpload(file);
    this.setState(({ event }) => ({
      event: {
        ...event,
        image_url: imageData.url,
        image: imageData.signed_id
      },
      loading: false
    }));
  };

  handleAddressChange = value => {
    this.setState(({ event }) => ({
      event: {
        ...event,
        street: value
      }
    }));
  };

  handleAddressSelection = ([addressObject]) => {
    if (!addressObject) return;

    const streetNumber = findAddressComponent(
      'street_number',
      addressObject.address_components
    );
    const route = findAddressComponent(
      'route',
      addressObject.address_components
    );
    const street =
      [streetNumber, route].filter(id => id).join(' ') || addressObject.name;

    this.setState(({ event }) => ({
      event: {
        ...event,
        location: {
          latitude: addressObject.geometry.location.lat(),
          longitude: addressObject.geometry.location.lng()
        },
        street,
        zip_code: findAddressComponent(
          'postal_code',
          addressObject.address_components
        ),
        city: findAddressComponent('locality', addressObject.address_components)
      }
    }));
  };

  changePeriodType = e => {
    const { value, name } = e.target;
    this.setState(({ event }) => ({
      event: {
        ...event,
        ...(value === 'unic' && { end_at: null }),
        ...(value === 'until' && { begin_at: null })
      },
      [name]: value
    }));
  };

  handleChangePickerPublish = data => {
    this.setState(({ event }) => ({
      event: {
        ...event,
        published_at: data.start
      }
    }));
  };

  handleDatePickerEventDate = (start, end) => {
    this.setState(({ event }) => ({
      event: {
        ...event,
        ...(start && { begin_at: start }),
        ...(end && { end_at: end })
      }
    }));
  };

  handleDatePickerHighlight = (start, end, setClear) => {
    this.setState(({ event }) => ({
      event: {
        ...event,
        ...(start && { highlight_begin_at: start }),
        ...(end && { highlight_end_at: end }),
        ...(setClear && { highlight_begin_at: null, highlight_end_at: null })
      }
    }));
  };

  handleCatChange = selectedOption => {
    this.setState(({ event }) => ({
      event: {
        ...event,
        category_id: selectedOption.id
      }
    }));
  };

  handleSubmit = e => {
    e.preventDefault();
    const { event } = this.state;
    const localError = validateEvents(event, this.post);

    if (localError) {
      parseResponseForPublishBar(localError, this, true);
    } else {
      event.id ? this.putEvent() : this.postEvent();
    }
  };

  componentDidMount() {
    this.getEvent();
  }

  render() {
    const { periodType, event, loading } = this.state;

    if (loading) return <ModalLoading loading={loading} />;

    return (
      <form onSubmit={this.handleSubmit}>
        <EventBase
          event={event}
          getEvent={this.getEvent}
          periodType={periodType}
          changePeriodType={this.changePeriodType}
          handleChange={this.handleChange}
          handleFileChange={this.handleFileChange}
          handleAddressChange={this.handleAddressChange}
          handleAddressSelection={this.handleAddressSelection}
          handleChangePicker={this.handleChangePickerPublish}
          handleDatePickerEventDate={this.handleDatePickerEventDate}
          handleDatePickerHighlight={this.handleDatePickerHighlight}
          handleCatChange={this.handleCatChange}
        />
        {event.id && (
          <>
            <NewBlock
              pageableId={event.id}
              getPageable={this.getEvent}
              pageableType="Event"
              addBlock={this.addBlock}
            />
            <EditBlocks
              id={event.id}
              blocks={event.blocks}
              getPageable={this.getEvent}
              updateLocalBlocks={this.updateBlocks}
              updateLocalBlock={this.updateBlock}
              addBlock={this.addBlock}
            />
          </>
        )}
        <PublishBar
          previewUrl={
            localStorage.getItem('frontofficeUrl') +
            '/mairie/evenements/' +
            event.id
          }
          state={event.state}
          goBackLink={pageMatcher.admin.content.events.path}
          showSubmitButton
        />
      </form>
    );
  }
}

export default EventLayout;
