import Input from 'components/Input';
import ProcedureService from 'services/OnlineProcedures';
import React, { Component } from 'react';
import { handleChange, createEventObject } from 'services/Utilities';

export default class ProcedureBase extends Component {
  state = { data: {}, categories: [] };
  procedureService = new ProcedureService();

  handleChangePicker = data => {
    this.handleChange(createEventObject('published_at', data.start));
  };

  getCategories = async () => {
    const categories = await this.procedureService.categories();
    this.setState({ categories });
  };

  handleChange = async event => {
    await handleChange(event, this, 'data');
    this.props.handleChange(this.state.data);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && nextProps.data !== this.props.data) {
      this.setState({ data: nextProps.data });
    }
  }

  componentDidMount() {
    this.getCategories();
  }

  render() {
    const { data } = this.props;
    const categoryId =
      (data.category ? data.category.id : data.category_id) || '';

    return (
      <div className="card-koba">
        <Input
          required
          label="Titre du service"
          aria-label="Titre du service"
          type="text"
          name="title"
          value={(data && data.title) || ''}
          onChange={this.handleChange}
        />
        <div className="form-group">
          <label htmlFor="category_id">
            Catégorie <span className="required">*</span>
          </label>
          <div>
            <select
              required
              name="category_id"
              className="form-control"
              value={categoryId}
              onChange={this.handleChange}
            >
              <option value="">Veuillez choisir</option>
              {this.state.categories.map((item, i) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}
