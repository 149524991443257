import React, { Component } from 'react';
import { Collapse } from 'reactstrap';

export default class CardCollapse extends Component {
  state = { isOpen: true };

  openOrCollapse = () => {
    this.setState(state => ({
      isOpen: !state.isOpen
    }));
  };

  render() {
    const { actions, title, children } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="card-koba mb-4" style={{ height: 'auto' }}>
        {actions && <div className="float-right">{actions}</div>}
        <h5 className="pointer" onClick={this.openOrCollapse}>
          <span className="mr-2">
            <i
              className={'fas fa-caret-' + (isOpen ? 'down' : 'right')}
              title={isOpen ? 'Ouvrir' : 'Fermer'}
            />
          </span>
          {title}
        </h5>
        <Collapse isOpen={isOpen}>
          <div className="pt-4">{children}</div>
        </Collapse>
      </div>
    );
  }
}
