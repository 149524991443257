import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { pageMatcher } from 'constants/Global';
import Guide from 'containers/Admin/Home/Guide/index';

class Account extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            path={pageMatcher.admin.account.pack.path}
            render={() => <Guide {...this.props} />}
            // render={() => <Pack {...this.props} />}
          />
        </Switch>
      </div>
    );
  }
}

export default Account;
