import Axios from 'services/Axios';
import Ressource from 'services/Ressource';

export default class ExceptionalClosingHours extends Ressource {
  constructor() {
    super('exceptional_closing_hour');
  }

  post = (type, data) => {
    return Axios.post('exceptional_closing_hours', {
      exceptional_closing_hour: data
    });
  };

  delete = id => {
    return Axios.delete(`exceptional_closing_hours/${id}`);
  };

  put = (type, data, option) => {
    return Axios.put(`exceptional_closing_hours/${option}`, {
      exceptional_closing_hour: data
    });
  };
}
