import EventService from 'services/Events';
import Options from 'components/Options';
import BootstrapTable from 'react-bootstrap-table-next';
import React, { Component } from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import TagSearch from 'components/TagSearch';
import { parseResponseForPublishBar, parseUrl } from 'services/Utilities';
import moment from 'moment';
import Spinner from 'components/Spinner';
import Toggle from 'components/Toggle';
import { pageMatcher } from 'constants/Global';
import { Link } from 'react-router-dom';

// to do: eventService extends Page
// TODO: Add catch clause
class Events extends Component {
  state = {
    filteredEvents: [],
    allEvents: [],
    loading: true
  };

  eventService = new EventService();

  getEvents = () => {
    this.eventService
      .get(null, '?scopes[by_updated_at]')
      .then(({ data: response }) => {
        this.setState({
          filteredEvents: response,
          allEvents: response,
          loading: false
        });
      });
  };

  deleteEvent = id => {
    this.eventService.delete(id).then(({ data: _response }) => {
      this.getEvents();
    });
  };

  copyEvent = (id, title) => {
    this.eventService.duplicate(id, title).then(({ data: response }) => {
      parseResponseForPublishBar(response, this);
      if (response && response.slug) {
        this.props.history.push(
          pageMatcher.admin.content.events.path + response.slug
        );
      }
    });
  };

  urlFormatter = (cell, row) => (
    <a
      href={
        localStorage.getItem('frontofficeUrl') +
        '/' +
        parseUrl('/events/' + row.slug)
      }
      target="_blank"
      rel="noopener noreferrer"
    >
      {'/events/' + row.slug}
    </a>
  );

  optionsFormatter = (cell, row) => (
    <Options
      row={row}
      delete={this.deleteEvent}
      label="l'événement"
      kind="events"
      copy={this.copyEvent}
    />
  );

  componentDidMount() {
    this.getEvents();
  }

  dateFormatter = (cell, _row) => {
    if (cell) {
      return <span>{moment(cell).format('DD/MM/YYYY')}</span>;
    }
  };

  toggleState = ({ id, state }) => {
    const option = state === 'published' ? 'draft' : 'publish';

    this.eventService.change(id, option).then(({ data }) => {
      const updatedEvents = this.state.allEvents.map(news =>
        news.id === id ? { ...news, ...data } : news
      );

      this.setState(() => ({
        allEvents: updatedEvents,
        filteredEvents: updatedEvents
      }));
    });
  };

  activeFormatter = (cell, row) => (
    <Toggle
      checked={cell === 'published'}
      name="state"
      onChange={() => this.toggleState(row)}
    />
  );

  titleFormatter = (cell, row) => (
    <Link to={pageMatcher.admin.content.events.path + row.id}>{cell}</Link>
  );

  handleSearch = event => {
    const value = event.target.value.toLowerCase();
    this.setState(state => ({
      filteredEvents: state.allEvents.filter(item =>
        ['title', 'author'].some(
          colon => item[colon] && item[colon].toLowerCase().includes(value)
        )
      )
    }));
  };

  render() {
    const optionsPagination = {
      page: 1,
      paginationSize: 4,
      pageStartIndex: 1,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true,
      nextPageTitle: 'Prochaine page',
      prePageTitle: 'Page précédente'
    };

    const columns = [
      {
        dataField: 'title',
        text: 'Titre',
        formatter: this.titleFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'created_at',
        text: 'Date de création',
        formatter: this.dateFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'author',
        text: 'Auteur',
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'url',
        text: 'URL',
        formatter: this.urlFormatter,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'state',
        text: 'En ligne / Hors Ligne',
        formatter: this.activeFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'action',
        text: 'Actions',
        formatter: this.optionsFormatter,
        headerStyle: { fontSize: 11 },
        align: 'right'
      }
    ];

    const { filteredEvents, loading } = this.state;

    return (
      <>
        <TagSearch
          handleSearch={this.handleSearch}
          buttonTitle="Ajouter un événement"
          buttonPlusLien={pageMatcher.admin.content.events.new.path}
        />
        <BootstrapTable
          keyField="id"
          data={filteredEvents}
          columns={columns}
          hover
          bordered={false}
          pagination={paginationFactory(optionsPagination)}
          classes="koba-array"
          filter={filterFactory()}
        />
        {loading && (
          <div className="text-center">
            <Spinner />
          </div>
        )}
      </>
    );
  }
}

export default Events;
