import Accessibilite from './Accessibilite/New';
import Adresses from './Adresses';
import { Surveys } from './Surveys';
import Content from './Content';
import HomePage from './HomePage';
import MentionsLegales from './MentionsLegales/New';
import Menu from './Menu';
import OnlineProcedures from './OnlineProcedures';
import Chat from './Chat';
import Rss from './Rss';
import SocialNetworks from './SocialNetworks';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { pageMatcher } from 'constants/Global';

const Home = props => {
  const options = localStorage.getItem('options');

  return (
    <div>
      <Switch>
        <Route
          path={pageMatcher.admin.homepage.path}
          render={() => <HomePage {...props} />}
        />
        <Route
          path={pageMatcher.admin.menu.path}
          render={() => <Menu {...props} />}
        />
        <Route
          path={pageMatcher.admin.addresses.path}
          render={() => <Adresses {...props} />}
        />
        <Route
          path={pageMatcher.admin.accessibility.path}
          render={() => <Accessibilite {...props} />}
        />
        <Route
          path={pageMatcher.admin.legal.path}
          render={() => <MentionsLegales {...props} />}
        />
        <Route
          path={pageMatcher.admin.surveys.path}
          render={
            // if you type the URL yourself
            () =>
              options.includes(pageMatcher.admin.surveys.option) ? (
                <Surveys {...props} />
              ) : (
                <div> </div>
              )
          }
        />
        <Route
          path={pageMatcher.admin.content.path}
          render={() => <Content {...props} />}
        />
        <Route
          path={pageMatcher.admin.onlineProcedures.path}
          render={() => <OnlineProcedures {...props} />}
        />
        <Route
          path={pageMatcher.admin.chat.path}
          render={
            // if you type the URL yourself
            props =>
              options.includes(pageMatcher.admin.chat.option) ? (
                <Chat {...props} {...props} />
              ) : (
                <div> </div>
              )
          }
        />
        <Route
          path={pageMatcher.admin.socialNetworks.path}
          render={() => <SocialNetworks {...props} />}
        />
        <Route
          path={pageMatcher.admin.rss.path}
          render={
            // if you type the URL yourself
            () =>
              options.includes(pageMatcher.admin.rss.option) ? (
                <Rss {...props} />
              ) : (
                <div> </div>
              )
          }
        />
      </Switch>
    </div>
  );
};

export default Home;
