import React from 'react';
import Editor from 'components/Editor';

export default class index extends React.Component {
  render() {
    return (
      <Editor
        initValue={this.props.initValue}
        onHTMLChange={this.props.onHTMLChange}
      />
    );
  }
}
