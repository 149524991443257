import React from 'react';
import ButtonAction from 'components/ButtonAction';

export default ({
  fileUrl,
  withCropper,
  handleUploadClick,
  handleRemoveFile,
  toggleModalCropImage
}) => {
  return (
    <>
      {fileUrl && (
        <div className="ml-4">
          <ButtonAction
            title="upload"
            onClick={handleUploadClick}
            iconClassName="fal fa-upload"
          />
          {withCropper && (
            <div className="mt-2">
              <ButtonAction
                title="recadrer"
                onClick={toggleModalCropImage}
                iconClassName="fal fa-crop-alt"
              />
            </div>
          )}
          <div className="mt-2">
            <ButtonAction
              title="supprimer"
              onClick={handleRemoveFile}
              iconClassName="fal fa-trash-alt"
            />
          </div>
        </div>
      )}
    </>
  );
};
