import CategoryService from 'services/Category';
import PublishBar from 'components/PublishBar';
import React, { Component } from 'react';
import SectionBase from '../SectionBase';
import { parseResponseForPublishBar } from 'services/Utilities';

export default class NewSection extends Component {
  state = {
    data: {
      isNew: true
    }
  };

  categoryService = new CategoryService();

  post = () => {
    this.categoryService
      .post(this.state.data)
      .then(({ data }) => {
        parseResponseForPublishBar(data.response, this, 'marginBottom');
        this.props.history.push(this.props.url);
      })
      .catch(() =>
        parseResponseForPublishBar(
          Error("Le nom spécifié n'est pas valable"),
          this,
          'marginBottom'
        )
      );
  };

  handleChange = data => this.setState({ data });

  handleSubmit = event => {
    event.preventDefault();
    const { data } = this.state;
    this.post(data);
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <SectionBase
          data={this.state.data}
          handleChange={this.handleChange}
          kind={this.props.kind}
        />
        <PublishBar
          state={'draft'}
          handleSubmit={this.handleSubmit}
          buttonDisabled={this.state.loading}
          goBackLink={this.props.url}
          goBackLinkTitle="Retour à la liste"
        />
      </form>
    );
  }
}
