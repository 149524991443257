import React from 'react';
import { Alert } from 'reactstrap';

const UsersFeedback = ({ currentNumber, maxNumber }) => {
  // Worth saying that it's only working in production
  const launchChat = e => {
    e.preventDefault();
    window.hubspot.messages.EXPERIMENTAL_API.requestWidgetOpen();
  };

  const isPlurials = value => (value > 1 ? 's' : '');

  if (currentNumber < maxNumber) {
    return (
      <Alert color="primary">
        <p className="text-dark">
          {currentNumber} utilisateur{isPlurials(currentNumber)} sur les{' '}
          {maxNumber} utilisateur{isPlurials(maxNumber)} offert
          {isPlurials(maxNumber)} gratuitement ont actuellement accès à
          l'administration de votre mairie. <br />
          Pour ajouter plus d'utilisateurs, veuillez{' '}
          <a href="" onClick={launchChat}>
            contacter le service commercial Koba
          </a>
        </p>
      </Alert>
    );
  } else if (currentNumber == maxNumber) {
    return (
      <Alert color="warning">
        <p>
          Vous avez atteint la limite de{isPlurials(maxNumber)} {maxNumber}{' '}
          utilisateur{isPlurials(maxNumber)} offert{isPlurials(maxNumber)}{' '}
          gratuitement pour l'accès à l'administration de votre mairie. <br />
          Pour ajouter plus d'utilisateurs, veuillez{' '}
          <a href="" onClick={launchChat}>
            contacter le service commercial Koba
          </a>
        </p>
      </Alert>
    );
  }
  return null;
};

export default UsersFeedback;
