import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './index.scss';

class Submenu extends Component {
  render() {
    const options = localStorage.getItem('options');
    return (
      <ul className="nav nav-pills" id="submenu">
        {this.props.subMenuData &&
          this.props.subMenuData.map((object, i) => (
            <li className="nav-item" key={i}>
              {object.children &&
                !object.notDisponible && (
                  <NavLink
                    tabIndex={0}
                    to={object.path}
                    title={object.pageTitle}
                    className="nav-link dropdown-toggle"
                    activeClassName="active"
                    data-toggle="dropdown"
                    aria-haspopup={true}
                    aria-expanded={false}
                  >
                    {object.pageTitle}{' '}
                  </NavLink>
                )}
              {!object.children &&
                !object.notDisponible && (
                  <NavLink
                    tabIndex={0}
                    to={object.path}
                    title={object.pageTitle}
                    className="nav-link"
                    activeClassName="active"
                    onClick={e =>
                      object.option && !options.includes(object.option)
                        ? e.preventDefault()
                        : () => {}
                    }
                  >
                    {object.option &&
                      !options.includes(object.option) && (
                        <i className="fa fa-lock" />
                      )}
                    {' ' + object.pageTitle}
                  </NavLink>
                )}
              {object.children &&
                !object.notDisponible && (
                  <div
                    className="dropdown-menu rounded-0 border-0"
                    aria-labelledby="dropdownMenu2"
                  >
                    {object.children.map((item, i) => (
                      <Link
                        to={item.path}
                        title={item.pageTitle}
                        key={i}
                        tabIndex={-1}
                      >
                        <button
                          className="dropdown-item"
                          type="button"
                          tabIndex={0}
                        >
                          {item.pageTitle}
                        </button>
                      </Link>
                    ))}
                  </div>
                )}
              {object.notDisponible && (
                <div className="nav-link not-disponible">
                  {object.pageTitle}
                  <i className="fas fa-lock-alt pl-2" />
                </div>
              )}
            </li>
          ))}
      </ul>
    );
  }
}

export default Submenu;
