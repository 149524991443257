import React from 'react';
import ButtonAction from 'components/ButtonAction';
import CardCollapse from 'components/Card/Collapse';
import QuestionForm from '../QuestionForm';

const moveQuestion = (form, fromIndex, toIndex) => {
  const questions = form.getValue(['questions']);
  const nextQuestions = questions.map((q, i) => {
    if (i === fromIndex) return questions[toIndex];
    if (i === toIndex) return questions[fromIndex];
    return q;
  });
  form.handleChange(['questions'], nextQuestions);
};

const deleteQuestion = (form, questionIndex) => {
  const nextQuestions = form
    .getValue(['questions'])
    .filter((_, i) => i !== questionIndex);
  form.handleChange(['questions'], nextQuestions, true);
};

export default ({
  form,
  question: { id, title, ...question },
  questionIndex
}) => (
  <CardCollapse
    key={id}
    title={title || `question ${questionIndex + 1}`}
    actions={
      <>
        {questionIndex < form.getValue(['questions']).length - 1 && (
          <ButtonAction
            title="déplacer vers le bas"
            className="ml-1"
            iconClassName="far fa-arrow-down"
            handleClick={() =>
              moveQuestion(form, questionIndex, questionIndex + 1)
            }
          />
        )}
        {questionIndex > 0 && (
          <ButtonAction
            title="déplacer vers le haut"
            className="ml-1"
            iconClassName="far fa-arrow-up"
            handleClick={() =>
              moveQuestion(form, questionIndex, questionIndex - 1)
            }
          />
        )}
        <ButtonAction
          title="supprimer"
          className="ml-1"
          iconClassName="far fa-trash-alt"
          handleClick={() => deleteQuestion(form, questionIndex)}
        />
      </>
    }
  >
    <QuestionForm
      form={form}
      path={['questions', questionIndex]}
      question={question}
    />
  </CardCollapse>
);
