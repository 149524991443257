import React, { Component } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { inviteUser } from 'services/Invitation';
import { alertMessage } from 'services/Utilities';

export default class InvitationModal extends Component {
  state = {
    email: ''
  };

  handleChange = e => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { email } = this.state;

    const data = {
      invitation: { email },
      instance: {
        kind: 'site',
        slug: localStorage.getItem('slug')
      }
    };

    this.props.onClose();
    inviteUser(data)
      .then(_ => alertMessage('success', 'Invitation envoyée !', this))
      .catch(_ =>
        alertMessage(
          'warning',
          "Echec de l'invitation. Peut-être avez-vous déjà invité cette personne ?",
          this
        )
      );
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Modal size="lg" isOpen={isOpen} className="modalSelectLink">
        <ModalHeader toggle={onClose}>Inviter un collaborateur</ModalHeader>
        <ModalBody>
          <p className="bg-gradient-info">
            Vous avez la possibilité d'inviter un nouveau collaborateur à
            rejoindre l'administration de votre mairie. <br />
            Ainsi, il sera capable d'interagir avec l'interface en fonction des
            droits qui lui seront conférés.
          </p>
          <Form onSubmit={this.handleSubmit}>
            <Label for="email">Adresse e-mail</Label>
            <FormGroup className="mr-2">
              <Input
                name="email"
                type="email"
                className="col-6"
                placeholder="exemple@domaine.fr"
                onChange={this.handleChange}
                required
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-outline-primary mr-2" onClick={onClose}>
            Annuler
          </button>
          <Button color="primary" onClick={this.handleSubmit}>
            Envoyer l'invitation
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
