import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './index.scss';

export default class PreviewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    return (
      <React.Fragment>
        <button
          onClick={this.toggle}
          type="button"
          className={
            'btn ml-2 ' +
            (this.props.state === 'published'
              ? 'btn-light'
              : 'btn-outline-primary')
          }
        >
          Aperçu
        </button>
        <Modal
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          className="preview-modal rounded-0"
        >
          <ModalHeader>
            <button
              onClick={this.toggle}
              className="btn btn-outline-primary pointer"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              Revenir à l'édition
            </button>
          </ModalHeader>
          <ModalBody>
            <iframe
              title="preview"
              src={this.props.previewUrl}
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen=""
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
