import React, { Component } from 'react';

import Document from './Document';
import Highlight from './Highlight';
import Image from './Image';
import Link from './Link';
import Text from './Text';
import Title from './Title';
import Comarquage from './Comarquage';
import Consultation from './Consultation';
import Video from './Video';
import Payfip from './Payfip';

import Spinner from 'components/Spinner';

import { imageUpload, setPublishBarLoading } from 'services/Utilities';
import './index.scss';

export default class SubBlock extends Component {
  //for Document
  state = { showDocument: false, loading: false };

  addDocument = () => {
    this.setState({ showDocument: true });
  };

  updateSubBlock = (type, value) => {
    let subBlock = this.props.subBlock;
    subBlock.updated = true;
    subBlock[type] = value;
    this.props.updateSubBlock(subBlock);
  };

  updateSubBlockImage = file => {
    const { setLoading } = this.props;

    setPublishBarLoading(true);
    this.setState({ loading: true });
    setLoading && setLoading(true);

    imageUpload(file)
      .then(imageData => {
        this.updateSubBlock('image', {
          signed_id: imageData.signed_id
        });
      })
      .finally(() => {
        setPublishBarLoading(false);
        this.setState({ loading: false });
        setLoading && setLoading(false);
      });
  };

  updateSubBlockFile = file => {
    const { subBlock, setLoading } = this.props;
    this.setState({ loading: true });
    setLoading && setLoading(true);
    setPublishBarLoading(true);

    imageUpload(file)
      .then(fileData => {
        const documents = subBlock.documents
          ? [
              ...subBlock.documents,
              { signed_id: fileData.signed_id, url: fileData.url }
            ]
          : [{ signed_id: fileData.signed_id, url: fileData.url }];

        this.updateSubBlock('documents', documents);
        this.setState({ showDocument: false });
      })
      .finally(() => {
        setPublishBarLoading(false);
        this.setState({ loading: false });
        setLoading && setLoading(false);
      });
  };

  deleteDocSubBlockFile = index => {
    const { subBlock } = this.props;
    subBlock.documents.splice(index, 1);
    this.updateSubBlock('documents', subBlock.documents);
  };

  render() {
    const { subBlock } = this.props;
    const { loading } = this.state;

    return (
      <div className={'sub-block ' + (this.props.vertical ? 'col-12 ' : 'col')}>
        {subBlock &&
          subBlock.type === 'block_text' &&
          !this.props.highlight && (
            <Text
              initValue={subBlock.text}
              onHTMLChange={data => this.updateSubBlock('text', data)}
            />
          )}
        {subBlock && subBlock.type === 'block_image' && (
          <Image
            imageInitValue={
              (subBlock.image && subBlock.image.url) || subBlock.image_url
            }
            handleFileChange={this.updateSubBlockImage}
          />
        )}
        {subBlock && subBlock.type === 'block_links' && (
          <Link
            tabIndex={0}
            role="button"
            data={subBlock}
            handleChange={event =>
              this.updateSubBlock(event.target.name, event.target.value)
            }
          />
        )}
        {subBlock && subBlock.type === 'block_documents' && (
          <Document
            data={subBlock}
            handleChange={event =>
              this.updateSubBlock(event.target.name, event.target.value)
            }
            handleFileChange={this.updateSubBlockFile}
            deleteDocSubBlockFile={this.deleteDocSubBlockFile}
            showDocument={this.state.showDocument}
            addDocument={this.addDocument}
          />
        )}
        {subBlock && subBlock.type === 'block_highlight' && (
          <Highlight
            initValue={subBlock.text}
            onHTMLChange={data => this.updateSubBlock('text', data)}
          />
        )}
        {subBlock && subBlock.type === 'block_title' && (
          <Title data={subBlock} updateSubBlock={this.updateSubBlock} />
        )}
        {subBlock && subBlock.type === 'block_comarking' && (
          <Comarquage
            updateSubBlock={this.updateSubBlock}
            data={subBlock.co_marking}
          />
        )}
        {subBlock && subBlock.type === 'block_consultation' && (
          <Consultation updateSubBlock={this.updateSubBlock} data={subBlock} />
        )}
        {subBlock && subBlock.type === 'block_video' && (
          <Video updateSubBlock={this.updateSubBlock} data={subBlock} />
        )}
        {subBlock && subBlock.type === 'block_payfip' && <Payfip />}
        {loading && (
          <div className="text-center">
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}
