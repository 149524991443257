import Ressource from 'services/Ressource';
import Axios from 'services/Axios';

export default class Slider extends Ressource {
  constructor() {
    super('slider');
  }

  saveSliders = data =>
    Axios.patch('sliders/save', this.toFormData(data), {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

  toFormData = slides => {
    const formData = new FormData();

    // Recursive function to add data to the formData object
    const objectToFormData = (object, currentKey) => {
      for (let [key, value] of Object.entries(object)) {
        const newCurrentKey = currentKey + '[' + key + ']';

        if (value && typeof value === 'object') {
          // Binary data (image) should be added as is
          if (key === 'data') {
            formData.append(newCurrentKey, value);
          } else {
            objectToFormData(value, newCurrentKey);
          }
        } else {
          formData.append(newCurrentKey, value);
        }
      }
    };

    slides.forEach(slide => {
      objectToFormData(slide, 'slider[]');
    });

    return formData;
  };
}
