import FileDropButtons from './FileDropButtons';
import ModalCropImage from 'components/ModalCropImage';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { acceptance } from 'constants/Global';
import './index.scss';

export default class Filedrop extends Component {
  state = {
    fileUrl: '',
    image: null,
    modalCropImageActive: false
  };

  setImageData(fileUrl = '', file = null) {
    const imgStyle = { backgroundImage: "url('" + encodeURI(fileUrl) + "')" };
    this.setState({ fileUrl, imgStyle, image: file });
  }

  onRemoveFile = () => {
    const { deleteDocSubBlockFile, index } = this.props;

    this.setImageData(null);
    deleteDocSubBlockFile && deleteDocSubBlockFile(index);
  };

  handleFileChange = event => {
    const { onFileChange, multiple } = this.props;
    const files = event.target.files;
    if (files.length > 0) {
      const name = event.target.name;
      const file = files[0];
      const fileUrl = URL.createObjectURL(file);

      // image case
      if (file && file.type.includes('image')) this.setImageData(fileUrl, file);
      // file case
      else this.setState({ fileUrl: event.target.name });

      const fileToComponent = multiple ? files : files[0];
      if (onFileChange) onFileChange(fileToComponent, fileUrl, name);
    }
  };

  toggleModalCropImage = () =>
    this.setState(state => ({
      modalCropImageActive: !state.modalCropImageActive
    }));

  handleSubmitCrop = (croppedImage, file) => {
    this.toggleModalCropImage();
    this.setImageData(croppedImage);
    return this.props.onFileChange(file, croppedImage, this.props.name);
  };

  componentWillMount() {
    this.setImageData(this.props.initValue);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.initValue && prevProps.initValue !== this.props.initValue) {
      if (this.props.initValue === null) {
        this.setImageData('');
      } else {
        this.setImageData(this.props.initValue);
      }
    }
  }

  render() {
    const { fileUrl, imgStyle, image, modalCropImageActive } = this.state;
    const {
      required,
      name,
      withCropper,
      deleteImage,
      disabled,
      ratio,
      backgroundSize,
      multiple,
      accepts
    } = this.props;
    const accept = acceptance[accepts] || null;

    return (
      <div className="d-flex flex-row filedrop">
        <div
          className={
            'filedrop-body border ' + (!fileUrl ? 'filedrop-border' : '')
          }
          style={{ height: '17vw', width: `calc(17vw * ${ratio})` }}
        >
          <div className="tb">
            <div
              className={'tbc text-center ' + (!fileUrl ? 'bg-white' : '')}
              style={
                backgroundSize
                  ? { backgroundSize: backgroundSize, ...imgStyle }
                  : imgStyle
              }
            >
              {fileUrl && (
                <>
                  <i className="fal fa-check" />
                  <br />
                  <br />
                  <span className="bg-white p-2 rounded">
                    ... {fileUrl.slice(-10)}
                  </span>
                </>
              )}
              {!fileUrl && <i className="fal fa-upload " />}
            </div>
          </div>
          <input
            disabled={disabled}
            type="file"
            required={!fileUrl && required}
            name={name}
            onChange={this.handleFileChange}
            accept={accept}
            ref={input => (this.inputElement = input)}
            multiple={multiple}
          />
        </div>
        <FileDropButtons
          fileUrl={fileUrl}
          withCropper={withCropper}
          toggleModalCropImage={this.toggleModalCropImage}
          handleRemoveFile={_ => {
            this.inputElement.value = '';
            this.onRemoveFile();
            deleteImage && deleteImage();
          }}
          handleUploadClick={_ => {
            this.inputElement.value = '';
            this.inputElement.click();
          }}
        />
        {modalCropImageActive && (
          <ModalCropImage
            ratio={ratio}
            image={image}
            imageSource={fileUrl}
            toggleModalCropImage={this.toggleModalCropImage}
            modalCropImageActive={modalCropImageActive}
            handleSubmitCrop={this.handleSubmitCrop}
          />
        )}
      </div>
    );
  }
}

Filedrop.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  withCropper: PropTypes.bool,
  accepts: PropTypes.oneOf(['pdf', 'images'])
};
