import React from 'react';
import './index.scss';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { pageMatcher } from 'constants/Global/';

export default props => {
  const { status, id } = props;

  return (
    <div className="row align-items-center mb-4">
      <div className="col">
        {status === 'scheduled' && (
          <h5>
            <i className="fas fa-circle text-warning mr-2" />
            Chat à venir
          </h5>
        )}
        {status === 'started' && (
          <h5>
            <i className="fas fa-circle text-success mr-2" />
            Chat en Ligne
          </h5>
        )}
        {status === 'finished' && (
          <h5>
            <i className="fas fa-circle text-alert mr-2" />
            Chat Fermé
          </h5>
        )}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={localStorage.getItem('frontofficeUrl') + '/chat/' + id}
        >
          <button className="btn-sm btn-outline-secondary pointer">
            Voir le chat côté utilisateurs
          </button>
        </a>
      </div>
      <Link
        to={pageMatcher.admin.chat.path}
        title="Retour à la liste des chats"
        tabIndex={-1}
      >
        <Button type="cancel" color="secondary">
          Retour
        </Button>
      </Link>
      <div className="col">
        {status === 'scheduled' && (
          <button
            onClick={e => props.handleClickStart(e, 'start')}
            className="btn btn-block btn-primary"
          >
            Commencez le chat
          </button>
        )}
        {status === 'started' && (
          <button
            onClick={e => props.handleClickStart(e, 'stop')}
            className="btn btn-block btn-primary"
          >
            Terminer le chat
          </button>
        )}
        {status === 'finished' && (
          <div className="alert alert-danger" role="alert">
            Chat terminé
          </div>
        )}
      </div>
    </div>
  );
};
