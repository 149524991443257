import Filedrop from 'components/Filedrop';
import EventService from 'services/Events';
import React, { Component } from 'react';
import { Async } from 'react-select';
import DatePickerSingle from 'components/DatePickerSingle';
import DatePickerModule from 'react-datepicker';
import moment from 'moment';
import Prices from './Prices';
import LocationSearchInput from 'components/LocationSearchInput';
import { ModalLoading } from 'components/ModalLoading';

class EventBase extends Component {
  state = { showPeriod: false };

  eventService = new EventService();

  componentDidMount() {
    const { highlight_begin_at, highlight_end_at } = this.props.event;

    this.setState({
      ...((highlight_begin_at || highlight_end_at) && { showPeriod: true })
    });
  }

  handleChangePickerStart = date => {
    const start = date ? date.format() : null;
    this.props.handleDatePickerEventDate(start, null);
  };

  handleChangePickerEnd = date => {
    const end = date ? date.format() : null;
    this.props.handleDatePickerEventDate(null, end);
  };

  handleChangePickerHighlightStart = date => {
    let start = date ? date.format() : null;
    this.props.handleDatePickerHighlight(start, null);
  };

  handleChangePickerHighlightEnd = date => {
    let end = date ? date.format() : null;
    this.props.handleDatePickerHighlight(null, end);
  };

  toggleHighlight = () => {
    this.setState(({ showPeriod }) => {
      if (showPeriod) this.props.handleDatePickerHighlight(null, null, true);

      return { showPeriod: !showPeriod };
    });
  };

  getCategories = () =>
    this.eventService
      .categories('formatForReactSelect')
      .then(response => response);

  render() {
    const {
      event,
      handleChange,
      handleFileChange,
      handleAddressChange,
      handleAddressSelection,
      handleChangePicker,
      periodType,
      changePeriodType,
      handleCatChange
    } = this.props;
    const {
      title,
      published_at,
      begin_at,
      end_at,
      street,
      prices,
      category_id,
      image_url,
      highlight_begin_at,
      highlight_end_at
    } = event;
    const { showPeriod, loading } = this.state;
    const template = localStorage.getItem('template');
    const ratio = localStorage.getItem('ratio');
    const ratioEvents = JSON.parse(ratio)[template].events;

    if (loading) return <ModalLoading loading={loading} />;

    return (
      <div className="card-koba mt-4">
        <div className="form-group">
          <label htmlFor="title">
            Titre de l'événement <span className="required">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            aria-label="titre"
            autoComplete="off"
            aria-describedby="titleHelp"
            placeholder="Mon évènement"
            value={title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="published_at">
            Date de la publication <span className="required">*</span>
          </label>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-secondary">
                <i className="fal fa-calendar-alt" />
              </span>
            </div>
            <DatePickerSingle
              required
              handleDatePicker={handleChangePicker}
              type="publish"
              data={published_at}
              single={true}
              className="full-width"
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="periodType">
            Date de l'événement <span className="required">*</span>
          </label>
          <select
            required
            className="form-control"
            id="periodType"
            name="periodType"
            onChange={changePeriodType}
            value={periodType}
          >
            <option value="period">Période</option>
            <option value="unic">Date unique</option>
            {/* <option value="until">Jusqu'àu</option> Request to hide this by ticket */}
          </select>
        </div>
        <div className="form-group">
          <div className="row date-picker">
            <div className="col-6 full-width">
              {periodType !== 'until' && (
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text text-secondary">
                      <i className="fal fa-calendar-alt" />
                    </span>
                  </div>
                  <div className="date-picker-single">
                    <DatePickerModule
                      required
                      selected={begin_at ? moment(begin_at) : null}
                      onChange={this.handleChangePickerStart}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      dateFormat="LLL"
                      timeCaption="l'heure"
                      locale="fr"
                      className="form-control"
                      placeholderText="sélectionnez une date"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="col-6 full-width">
              {periodType !== 'unic' && (
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text text-secondary">
                      <i className="fal fa-calendar-alt" />
                    </span>
                  </div>
                  <div className="date-picker-single">
                    <DatePickerModule
                      required
                      selected={end_at ? moment(end_at) : null}
                      onChange={this.handleChangePickerEnd}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      dateFormat="LLL"
                      timeCaption="l'heure"
                      locale="fr"
                      className="form-control"
                      placeholderText="sélectionnez une date"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="title">Adresse de l'événement</label>
          <LocationSearchInput
            value={street}
            handleAddressChange={handleAddressChange}
            handleAddressSelection={handleAddressSelection}
          />
        </div>
        <Prices prices={prices} handleChange={handleChange} />
        <div className="form-group">
          <label htmlFor="category_id">Catégorie</label>{' '}
          <span className="required">*</span>
          <Async
            clearable={false}
            id="category_id"
            name="category_id"
            placeholder="Choisir..."
            value={category_id}
            onChange={e => handleCatChange(e)}
            loadOptions={this.getCategories}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="header">
            Photo <span className="required">*</span>
          </label>
          <Filedrop
            required
            withCropper
            name="image"
            aria-label="image"
            accepts="images"
            initValue={image_url}
            onFileChange={handleFileChange}
            ratio={ratioEvents}
          />
        </div>
        <hr />
        <div className="form-group">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={showPeriod}
              id="showPeriod"
              onChange={this.toggleHighlight}
            />
            <label className="form-check-label" htmlFor="showPeriod">
              Choisir cet événement comme mis en avant
            </label>
          </div>
        </div>
        {showPeriod && (
          <div className="form-group">
            <div className="row date-picker">
              <div className="col-6 full-width">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text text-secondary">
                      <i className="fal fa-calendar-alt" />
                    </span>
                  </div>
                  <div className="date-picker-single">
                    <DatePickerModule
                      selected={
                        highlight_begin_at ? moment(highlight_begin_at) : null
                      }
                      onChange={this.handleChangePickerHighlightStart}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      dateFormat="LLL"
                      timeCaption="l'heure"
                      locale="fr"
                      className="form-control"
                      placeholderText="sélectionnez une date"
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 full-width">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text text-secondary">
                      <i className="fal fa-calendar-alt" />
                    </span>
                  </div>
                  <div className="date-picker-single">
                    <DatePickerModule
                      selected={
                        highlight_end_at ? moment(highlight_end_at) : null
                      }
                      onChange={this.handleChangePickerHighlightEnd}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      dateFormat="LLL"
                      timeCaption="l'heure"
                      locale="fr"
                      className="form-control"
                      placeholderText="sélectionnez une date"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default EventBase;
