import Campaign from './Campaign';
import Carousel from './Carousel';
import MostConsulted from './MostConsulted';
import InteractiveMapImage from './InteractiveMapImage';
import React, { Component } from 'react';
import Submenu from 'components/Submenu';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from 'components/Layout/';
import { providePageData } from 'services/Utilities/';
import { pageMatcher } from 'constants/Global/';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.subMenuData = [
      pageMatcher.admin.homepage.carousel,
      pageMatcher.admin.homepage.campaigns,
      pageMatcher.admin.homepage.mostConsulted,
      pageMatcher.admin.homepage.interactive_map
    ];
  }

  render() {
    const options = localStorage.getItem('options');
    return (
      <>
        <Layout pageTitle={providePageData(this).pageTitle} />
        <>
          <Submenu subMenuData={this.subMenuData} />
          <Switch>
            <Redirect
              from={pageMatcher.admin.homepage.path}
              exact={true}
              to={pageMatcher.admin.homepage.carousel.path}
            />
            <Route
              path={pageMatcher.admin.homepage.campaigns.path}
              render={
                // if you type the URL yourself
                () =>
                  options.includes(
                    pageMatcher.admin.homepage.campaigns.option
                  ) ? (
                    <Campaign />
                  ) : (
                    <div> </div>
                  )
              }
            />
            <Route
              path={pageMatcher.admin.homepage.carousel.path}
              render={() => <Carousel {...this.props} />}
            />
            <Route
              path={pageMatcher.admin.homepage.mostConsulted.path}
              render={() => <MostConsulted {...this.props} />}
            />
            <Route
              path={pageMatcher.admin.homepage.interactive_map.path}
              render={() => <InteractiveMapImage {...this.props} />}
            />
          </Switch>
        </>
      </>
    );
  }
}

export default Home;
