import React from 'react';
import DatePicker from 'react-date-picker';
import './index.scss';

const GenderField = ({ label, value, gender, handleChange }) => (
  <div className="custom-control custom-radio form-check-inline">
    <input
      className="custom-control-input form-check-input"
      type="radio"
      id={value}
      defaultValue={value}
      name="gender"
      checked={gender === value}
      onChange={handleChange}
      required
    />
    <label className="custom-control-label form-check-label" htmlFor={value}>
      {label}
    </label>
  </div>
);

const InputField = ({ label, name, value = '', handleChange }) => (
  <div className="form-group d-flex flex-sm-row flex-column">
    <label htmlFor={name} className=" col-12 col-sm-2 col-form-label">
      {label}
    </label>
    <div className=" col-12 col-sm-10">
      <input
        type="text"
        className="form-control"
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        onFocus={e => {
          e.target.setAttribute('autocomplete', 'nope');
        }}
      />
    </div>
  </div>
);

const CheckboxField = ({ label, name, value, handleChange }) => (
  <div className="form-group form-check">
    <input
      defaultChecked={value}
      onChange={handleChange}
      type="checkbox"
      className="form-check-input"
      id={name}
      name={name}
    />
    <label className="form-check-label" htmlFor={name}>
      {label}
    </label>
  </div>
);

const ContactBase = props => {
  const {
    id,
    firstname,
    lastname,
    address,
    zipcode,
    city,
    country,
    phone,
    fax,
    email,
    newsletter,
    sms_newsletter,
    birthdate,
    gender,
    handleChange,
    handleDatePicker
  } = props;

  const birthDate = id ? new Date(birthdate) : birthdate;

  return (
    <div className="card-koba address_base">
      <div className="form-group d-flex flex-sm-row flex-column">
        <label htmlFor="category_id" className="col-12 col-sm-2 col-form-label">
          Civilité *
        </label>
        <div className="col-12 col-sm-10">
          <div>
            <GenderField
              label="Madame"
              value="female"
              gender={gender}
              handleChange={handleChange}
            />
            <GenderField
              label="Monsieur"
              value="male"
              gender={gender}
              handleChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="form-group d-flex flex-sm-row flex-column">
        <label htmlFor="country" className=" col-12 col-sm-2 col-form-label">
          Date de naissance
        </label>
        <div className=" col-12 col-sm-10">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-secondary">
                <i className="fal fa-calendar-alt" />
              </span>
            </div>
            <DatePicker
              onChange={handleDatePicker}
              value={birthDate}
              format={'dd/MM/yyyy'}
              locale="fr-FR"
            />
          </div>
        </div>
      </div>
      <div className="form-group d-flex flex-sm-row flex-column">
        <label htmlFor="lastname" className=" col-12 col-sm-2 col-form-label">
          Nom *
        </label>
        <div className=" col-12 col-sm-4">
          <input
            autoComplete="off"
            required
            type="text"
            className="form-control"
            id="lastname"
            name="lastname"
            aria-label="nom"
            aria-describedby="titleHelp"
            value={lastname}
            onChange={handleChange}
          />
        </div>
        <label
          htmlFor="firstname"
          className=" col-12 col-sm-2 col-md-2  col-lg-1 text-nowrap col-form-label "
        >
          Prénom *
        </label>
        <div className="col-12 col-sm-4 pl-4">
          <input
            autoComplete="off"
            required
            type="text"
            className="form-control"
            id="firstname"
            name="firstname"
            aria-label="prénom"
            aria-describedby="titleHelp"
            value={firstname}
            onChange={handleChange}
          />
        </div>
      </div>

      <InputField
        label="Adresse"
        name="address"
        value={address}
        handleChange={handleChange}
      />

      <div className="form-group d-flex flex-sm-row flex-column">
        <label htmlFor="zipcode" className=" col-12 col-sm-2 col-form-label">
          Code postal
        </label>
        <div className=" col-12 col-sm-10">
          <input
            type="text"
            autoComplete="nope"
            className="form-control"
            id="zipcode"
            name="zipcode"
            defaultValue={zipcode}
            onChange={handleChange}
            maxLength="5"
          />
        </div>
      </div>

      <InputField
        label="Ville"
        name="city"
        value={city}
        handleChange={handleChange}
      />
      <InputField
        label="Pays"
        name="country"
        value={country}
        handleChange={handleChange}
      />

      <div className="form-group">
        <div className="d-flex flex-sm-row flex-column">
          <label htmlFor="phone" className=" col-12 col-sm-2 col-form-label">
            Téléphone principal
          </label>
          <div className=" col-12 col-sm-10">
            <input
              type="tel"
              name="phone"
              id="phone"
              pattern="0\d{9}"
              maxLength="10"
              defaultValue={phone}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="form-group d-flex flex-sm-row flex-column">
        <label htmlFor="fax" className=" col-12 col-sm-2 col-form-label">
          Fax
        </label>
        <div className=" col-12 col-sm-10">
          <input
            type="tel"
            name="fax"
            id="fax"
            pattern="0\d{9}"
            maxLength="10"
            defaultValue={fax}
            onChange={handleChange}
          />
        </div>
      </div>
      <InputField
        label="Adresse email"
        name="email"
        value={email}
        handleChange={handleChange}
      />
      <CheckboxField
        label="Je souhaite recevoir des informations par SMS"
        name="sms_newsletter"
        value={sms_newsletter}
        handleChange={handleChange}
      />
      <CheckboxField
        label="Je souhaite recevoir des informations par email"
        name="newsletter"
        value={newsletter}
        handleChange={handleChange}
      />
    </div>
  );
};

export default ContactBase;
