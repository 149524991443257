import Block from '../../Blocks/Block';
import React, { Component } from 'react';
import { Collapse, UncontrolledTooltip } from 'reactstrap';
import './index.scss';
import { parseResponseForPublishBar } from 'services/Utilities';
const options = localStorage.getItem('options');

const ACCEPTED_HOSTS = ['youtube', 'youtu'];
const PREFIX = 'https://';

class NewBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateType: 'text-only',
      loading: false
    };
    this.setType = this.setType.bind(this);
    this.blockAdded = this.blockAdded.bind(this);
    this.updateNewBlock = this.updateNewBlock.bind(this);
  }

  setType(e) {
    this.setState({
      templateType: e.currentTarget.name,
      collapse: true
    });

    let newBlock = {
      sub_blocks: []
    };

    switch (e.currentTarget.name) {
      case 'comarquage':
        newBlock.type = 'block_comarking';
        newBlock.text = '';
        break;
      case 'consultation':
        newBlock.type = 'block_consultation';
        newBlock.consultation_id = '';
        break;
      case 'text-only':
        newBlock.type = 'block_text';
        newBlock.text = '';
        break;
      case 'image-only':
        newBlock.type = 'block_image';
        newBlock.image = '';
        newBlock.caption = '';
        break;
      case 'image-right':
        newBlock.type = 'block_container';
        newBlock.sub_blocks.push(
          this.createSubBlock('block_text'),
          this.createSubBlock('block_image')
        );
        break;
      case 'image-left':
        newBlock.type = 'block_container';
        newBlock.sub_blocks.push(
          this.createSubBlock('block_image'),
          this.createSubBlock('block_text')
        );
        break;
      case 'documents':
        newBlock.type = 'block_documents';
        newBlock.title = '';
        newBlock[e.currentTarget.name] = [];
        break;
      case 'links':
        newBlock.type = 'block_links';
        newBlock.title = '';
        newBlock[e.currentTarget.name] = [{}];
        break;
      case 'highlight':
        newBlock.type = 'block_highlight';
        newBlock.title = '';
        newBlock.text = '';
        break;
      case 'title':
        newBlock.type = 'block_title';
        newBlock.text = '';
        newBlock.level = 1;
        newBlock.is_anchor = false;
        break;
      case 'video':
        newBlock.type = 'block_video';
        newBlock.url = '';
        break;
      case 'payfip':
        newBlock.type = 'block_payfip';
        newBlock.url = '';
        break;
      default:
        newBlock.type = 'block_' + e.currentTarget.name;
    }
    this.updateNewBlock(newBlock);
  }

  createSubBlock(kind) {
    return {
      type: kind
    };
  }

  add_Block = async () => {
    const { newBlock, templateType } = this.state;
    if (
      newBlock.sub_blocks.some(
        block => block.type === 'block_image' && !block.image
      ) ||
      (templateType === 'image-only' && !newBlock.image)
    ) {
      const localError = Error("Erreur, le bloc ne contient pas d'image.");
      parseResponseForPublishBar(localError, this, 'marginBottom');
      return null;
    }

    if (templateType === 'comarquage' && !newBlock.co_marking) {
      const localError = Error('Erreur, sélectionnez un type de co-marquage.');
      await parseResponseForPublishBar(localError, this, 'marginBottom');
      return null;
    }

    if (templateType === 'video') {
      let { url } = newBlock;
      url = url.substr(0, PREFIX.length) !== PREFIX ? PREFIX + url : url;
      if (!ACCEPTED_HOSTS.some(host => url.includes(host))) {
        const localError = Error(
          `Erreur, le lien ne comporte pas de lien ${ACCEPTED_HOSTS.join(
            ' ou '
          )}.`
        );
        parseResponseForPublishBar(localError, this, true);
        return;
      }
    }

    this.props.addBlock(newBlock);
    this.blockAdded();
  };

  blockAdded() {
    this.setState({ collapse: false, newBlock: null });
  }

  updateNewBlock(newBlock) {
    this.setState({ newBlock });
  }

  scrollToBottom = () => {
    const domElement = document.getElementById('ajouter');
    domElement && domElement.scrollIntoView(true);
  };

  render() {
    const { loading } = this.state;

    return (
      <div
        id="add-block"
        className="card-koba mt-4 mb-4"
        onSubmit={this.props.handleSubmit}
      >
        <h4>
          Ajouter un Bloc
          <br />
          <small className="text-muted">Choisir la disposition</small>
        </h4>
        <br />
        <div className="form-group">
          {this.props.pageableType === 'Procedure' && (
            <div className="row">
              <div className="col-12">
                <button
                  type="button"
                  id="comarquage"
                  name="comarquage"
                  className="btn-block btn-light text-primary mb-3"
                  onClick={this.setType}
                >
                  Co-marquage
                </button>
              </div>
              <UncontrolledTooltip target="comarquage">
                Comarquage
              </UncontrolledTooltip>
            </div>
          )}

          {this.props.pageableType === 'Article' &&
            options.split(',').includes('copernic') && (
              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    id="consultation"
                    name="consultation"
                    className="btn-block btn-light text-primary mb-3"
                    onClick={this.setType}
                  >
                    Consultation
                  </button>
                </div>
                <UncontrolledTooltip target="consultation">
                  Consultation
                </UncontrolledTooltip>
              </div>
            )}

          <div className="row">
            <div className="col-6">
              <button
                type="button"
                id="text-only"
                name="text-only"
                className="btn-block btn-light text-primary"
                onClick={this.setType}
              >
                <i className="fal fa-lg fa-align-center" />
              </button>
            </div>
            <UncontrolledTooltip target="text-only">
              Texte seulement
            </UncontrolledTooltip>

            <div className="col-6">
              <button
                type="button"
                id="image-right"
                name="image-right"
                className="btn-block btn-light text-primary"
                onClick={this.setType}
              >
                <i className="fal fa-lg fa-align-center mr-2" />{' '}
                <i className="fal fa-lg fa-file-image" />
              </button>
            </div>
            <UncontrolledTooltip target="image-right">
              Image à droite
            </UncontrolledTooltip>
          </div>

          <div className="row mt-3">
            <div className="col-6">
              <button
                type="button"
                id="image-left"
                name="image-left"
                className="btn-block btn-light text-primary"
                onClick={this.setType}
              >
                <i className="fal fa-lg fa-file-image mr-2" />{' '}
                <i className="fal fa-lg fa-align-center" />
              </button>
            </div>
            <UncontrolledTooltip target="image-left">
              Image à gauche
            </UncontrolledTooltip>

            <div className="col-6">
              <button
                type="button"
                id="image-only"
                name="image-only"
                className="btn-block btn-light text-primary"
                onClick={this.setType}
              >
                <i className="fal fa-lg fa-file-image" />
              </button>
            </div>
            <UncontrolledTooltip target="image-only">
              Image seulement
            </UncontrolledTooltip>
          </div>

          <div className="row mt-3">
            <div className="col-6">
              <button
                type="button"
                id="document-only"
                name="documents"
                className="btn-block btn-light text-primary"
                onClick={this.setType}
              >
                Document
              </button>
            </div>
            <UncontrolledTooltip target="document-only">
              Document
            </UncontrolledTooltip>
            <div className="col-6">
              <button
                type="button"
                id="link-only"
                name="links"
                className="btn-block btn-light text-primary"
                onClick={this.setType}
              >
                Lien
              </button>
            </div>
            <UncontrolledTooltip target="link-only">Lien</UncontrolledTooltip>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <button
                type="button"
                id="title"
                name="title"
                className="btn-block btn-light text-primary"
                onClick={this.setType}
              >
                Titre
              </button>
            </div>
            <UncontrolledTooltip target="title">Titre</UncontrolledTooltip>
            <div className="col-6">
              <button
                type="button"
                id="highlight"
                name="highlight"
                className="btn-block btn-light text-primary"
                onClick={this.setType}
              >
                Texte encadré
              </button>
            </div>
            <UncontrolledTooltip target="highlight">
              Texte encadré
            </UncontrolledTooltip>
          </div>
          {options.split(',').includes('video') && (
            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  id="video"
                  name="video"
                  className="btn-block btn-light text-primary mt-3"
                  onClick={this.setType}
                >
                  Lien Vidéo
                </button>
              </div>
              <UncontrolledTooltip target="video">
                Lien Vidéo
              </UncontrolledTooltip>
            </div>
          )}
          {options.split(',').includes('payfip') && (
            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  id="payfip"
                  name="payfip"
                  className="btn-block btn-light text-primary mt-3"
                  onClick={this.setType}
                >
                  Paiement via Payfip
                </button>
              </div>
              <UncontrolledTooltip target="payfip">
                Paiement via Payfip
              </UncontrolledTooltip>
            </div>
          )}
        </div>
        <Collapse isOpen={this.state.collapse} onEntered={this.scrollToBottom}>
          <Block
            block={this.state.newBlock}
            pageableId={this.props.pageableId}
            pageableType={this.props.pageableType}
            templateType={this.state.templateType}
            updateNewBlock={this.updateNewBlock}
            setLoading={isLoading => this.setState({ loading: isLoading })}
          />
          <button
            id="ajouter"
            type="button"
            disabled={loading}
            onClick={this.add_Block}
            className={'btn btn-outline-primary btn-block mt-3'}
          >
            Valider le bloc
          </button>
        </Collapse>
      </div>
    );
  }
}

export default NewBlock;
