import EditBlocks from '../../../Blocks/Edit';
import NewBlock from '../../../Blocks/New';
import ProcedureBase from '../ProcedureBase';
import ProcedureService from 'services/OnlineProcedures';
import PublishBar from 'components/PublishBar';
import React, { Component } from 'react';
import * as Utilities from 'services/Utilities';
import { pageMatcher } from 'constants/Global';
import * as PageService from 'services/Page';

/*
 TODO:
  - Catch clause
  - Business logic doesn't allow users to edit icons, is that normal ?
*/

export default class EditSection extends Component {
  state = { procedure: {} };
  procedureService = new ProcedureService();

  get = (id = this.props.match.params.id) => {
    this.procedureService.get(id).then(({ data: procedure }) => {
      if (procedure && procedure.id) this.setState({ procedure });
    });
  };

  putProcedure = () => {
    this.procedureService
      .put(this.props.match.params.id, this.state.procedure)
      .then(({ data: procedure }) => {
        Utilities.parseResponseForPublishBar(procedure, this, 'marginBottom');
        this.setState({ procedure });
      });
  };

  updateBlocks = blocks => {
    PageService.updateLocalBlocks(blocks, this, 'procedure');
  };

  updateBlock = block => {
    PageService.updateLocalBlock(block, this, 'procedure');
  };

  handleChange = procedure => this.setState({ procedure });

  handleSubmit = event => {
    event.preventDefault();
    this.putProcedure();
  };

  addBlock = (block, position) => {
    PageService.addBlock(block, this, 'procedure', position);
    this.putProcedure();
  };

  componentDidMount() {
    this.get();
  }

  render() {
    const {
      procedure,
      statusIsVisible,
      statusType,
      statusMessage
    } = this.state;
    const { id } = this.props.match.params;

    return (
      <form onSubmit={this.handleSubmit}>
        <ProcedureBase data={procedure} handleChange={this.handleChange} />
        <NewBlock
          pageableId={id}
          getPageable={this.get}
          pageableType="Procedure"
          addBlock={this.addBlock}
        />
        <EditBlocks
          id={id}
          blocks={procedure.blocks}
          getPageable={this.get}
          updateLocalBlocks={this.updateBlocks}
          updateLocalBlock={this.updateBlock}
          addBlock={this.addBlock}
        />
        <PublishBar
          previewUrl={
            localStorage.getItem('frontofficeUrl') + '/mairie/services/' + id
          }
          handleStatusChange={this.handleStatusChange}
          state={procedure.state}
          statusIsVisible={statusIsVisible}
          statusType={statusType}
          statusMessage={statusMessage}
          goBackLink={pageMatcher.admin.onlineProcedures.procedures.path}
          showSubmitButton
        />
      </form>
    );
  }
}
