import EditBlocks from '../../../Blocks/Edit';
import NewBlock from '../../../Blocks/New';
import NewsBase from '../NewsBase';
import NewsService from 'services/News';
import React, { Component } from 'react';
import * as PageService from 'services/Page';
import { imageUpload, parseResponseForPublishBar } from 'services/Utilities';
import PublishBar from 'components/PublishBar';
import { pageMatcher } from 'constants/Global';
import { ModalLoading } from 'components/ModalLoading';

// to do: newsService extends EditPage
// TODO: Add catch clause
class News extends Component {
  state = {
    news: {
      title: '',
      category_id: '',
      published_at: '',
      highlight_begin_at: null,
      highlight_end_at: null,
      image: null
    },
    loading: true
  };

  newsService = new NewsService();

  getNews = () => {
    this.newsService
      .get(this.props.match.params.id)
      .then(({ data: response }) => {
        response.category_id = response.category.id;
        this.setState({ news: response, loading: false });
      });
  };

  putNews = async () => {
    const { news } = this.state;
    const { data: response } = await this.newsService.put(
      this.props.match.params.id,
      news
    );
    parseResponseForPublishBar(response, this, 'marginBottom');
    this.getNews();
  };

  publishNews = option => {
    this.newsService
      .change(this.props.match.params.id, option)
      .then(({ data: response }) => {
        parseResponseForPublishBar(response, this, 'marginBottom');
      });
  };

  updateBlocks = blocks => {
    PageService.updateLocalBlocks(blocks, this, 'news');
  };

  updateBlock = block => {
    PageService.updateLocalBlock(block, this, 'news');
  };

  handleChange = event => {
    const newState = { ...this.state };
    newState.news[event.target.name] = event.target.value;
    this.setState(newState);
  };

  handleChangePicker = data => {
    const newState = { ...this.state };
    newState.news.published_at = data.start;
    this.setState(newState);
  };

  handleDatePickerHighlight = (start, end, setClear) => {
    this.setState(({ news }) => ({
      news: {
        ...news,
        ...(start && { highlight_begin_at: start }),
        ...(end && { highlight_end_at: end }),
        ...(setClear && { highlight_begin_at: null, highlight_end_at: null })
      }
    }));
  };

  handleFileChange = async file => {
    const imageData = await imageUpload(file);
    this.setState(prevState => ({
      news: {
        ...prevState.news,
        image_url: imageData.url,
        image: imageData.signed_id
      }
    }));
  };

  handleCatChange = selectedOption => {
    let news = { target: {} };
    news.target.name = 'category_id';
    news.target.value = selectedOption.id;
    this.handleChange(news);
  };

  handleSubmit = event => {
    event.preventDefault();
    this.putNews();
  };

  addBlock = (block, position) => {
    PageService.addBlock(block, this, 'news', position);
    this.putNews();
  };

  componentDidMount() {
    this.getNews();
  }

  render() {
    const { news, loading, statusIsVisible } = this.state;
    const { id } = this.props.match.params;

    if (loading) return <ModalLoading loading />;

    return (
      <form onSubmit={this.handleSubmit}>
        <NewsBase
          {...news}
          // better to have only one 'handleChange' and format the data before, in the subcomponent
          handleCatChange={this.handleCatChange}
          handleFileChange={this.handleFileChange}
          handleChange={this.handleChange}
          handleChangePicker={this.handleChangePicker}
          handleDatePickerHighlight={this.handleDatePickerHighlight}
          handleSubmit={this.handleSubmit}
          loading={loading}
        />
        <NewBlock
          pageableId={id}
          getPageable={this.getNews}
          pageableType="News"
          addBlock={this.addBlock}
        />
        <EditBlocks
          id={id}
          blocks={news.blocks}
          getPageable={this.getNews}
          updateLocalBlocks={this.updateBlocks}
          updateLocalBlock={this.updateBlock}
          addBlock={this.addBlock}
        />
        <PublishBar
          previewUrl={
            localStorage.getItem('frontofficeUrl') +
            '/mairie/actualites/' +
            this.props.match.params.id
          }
          state={news.state}
          statusIsVisible={statusIsVisible}
          goBackLink={pageMatcher.admin.content.news.path}
          showSubmitButton
        />
      </form>
    );
  }
}

export default News;
