import React from 'react';

const InputPhoneNumber = ({ className = '', ...remainingProps }) => (
  <input
    type="tel"
    autoComplete="off"
    className={'form-control ' + className}
    aria-label="téléphone"
    aria-describedby="titleHelp"
    {...remainingProps}
  />
);

export default InputPhoneNumber;
