import React from 'react';
import EditorConvertToHTML from 'components/Editor';

const AccessibilitiesBase = props => (
  <>
    <div className="card-koba mt-2">
      <div className="form-group">
        <label htmlFor="title">Titre de la page</label>
        <input
          type="text"
          className="form-control"
          id="title"
          name="title"
          aria-label="titre"
          autoComplete="off"
          aria-describedby="titleHelp"
          placeholder="Mon titre"
          value={props.title}
          onChange={props.handleChange}
        />
      </div>
      <label htmlFor="content">Corps du texte</label>
      <EditorConvertToHTML
        onHTMLChange={props.handleChangeTextArea}
        initValue={props.content}
      />
    </div>
  </>
);

export default AccessibilitiesBase;
