import Axios from './Axios';
import Ressource from './Ressource';
import moment from 'moment';

export default class Campaign extends Ressource {
  constructor() {
    super('campaign');
  }

  getOnGoingCampaign = (date = moment().format('YYYY-MM-DD HH:mm:ss')) => {
    return Axios.get(`campaigns?begin_at[leq]="${date}"&end_at[geq]="${date}"`);
  };

  getUpcomingCampaign = (date = moment().format('YYYY-MM-DD HH:mm:ss')) => {
    return Axios.get(`campaigns?begin_at[geq]="${date}"`);
  };
}
