import Axios from './Axios';
import Ressource from 'services/Ressource';
import React from 'react';

class Surveys extends Ressource {
  constructor() {
    super('survey');
  }

  archive = id => Axios.patch(`surveys/${id}/archive`);
  unarchive = id => Axios.patch(`surveys/${id}/unarchive`);
  download = id => Axios.get(`/surveys/${id}/download`);

  statusFormatter = cell => {
    switch (cell) {
      case 'draft':
        return <span>Hors-Ligne</span>;
      case 'published':
        return <span>En ligne</span>;
      case 'archived':
        return <span>Archivé</span>;
      default:
        return null;
    }
  };
}

export default Surveys;
