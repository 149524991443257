import ChatQuestionService from 'services/ChatQuestions';
import ChatService from 'services/Chat';
import EmptyMessages from './EmptyMessages';
import NewMessageBar from './NewMessageBar';
import Question from './Question';
import React from 'react';
import StatusButton from './StatusButton';
import './index.scss';

export default class Live extends React.Component {
  state = { chat: {}, questions: [], newQuestions: [] };
  chatService = new ChatService();
  chatQuestionService = new ChatQuestionService();

  get = async id => {
    const { data: chat } = await this.chatService.get(id);
    if (chat && chat.id) this.setState({ chat });
  };

  getQuestions = async id => {
    const { data: questions } = await this.chatService.getQuestions(id);
    if (questions && questions.reverse()) this.setState({ questions });
  };

  pullQuestions = async _ => {
    const { data: newQuestions } = await this.chatService.getQuestions(
      this.props.match.params.id
    );
    if (
      newQuestions.length > this.state.questions.length &&
      newQuestions.reverse()
    )
      this.setState({ newQuestions });
  };

  put = async (id, data) => await this.chatQuestionService.put(id, data);

  handleClickStart = async (e, kind) => {
    await this.chatService[kind](this.props.match.params.id);
    this.get(this.props.match.params.id);
  };

  handleDelete = async id => {
    await this.chatQuestionService.delete(id);
    this.getQuestions(this.props.match.params.id);
  };

  loadNewQuestions = _ =>
    this.setState({ questions: this.state.newQuestions, newQuestions: [] });

  handleSubmit = async (e, answer, id) => {
    e.preventDefault();
    await this.put(id, { answer });
    this.getQuestions(this.props.match.params.id);
  };

  componentDidMount = _ => {
    this.get(this.props.match.params.id);
    this.getQuestions(this.props.match.params.id);
    // get the new questions every 5sec
    this.interval = setInterval(this.pullQuestions, 5000);
  };

  componentWillUnmount = _ => {
    clearInterval(this.interval);
  };

  render() {
    const { questions, newQuestions, chat } = this.state;

    return (
      <div className="Chat-live">
        <StatusButton
          status={chat.status}
          id={this.props.match.params.id}
          handleClickStart={this.handleClickStart}
        />
        <div className="Chat-messages-container border p-4">
          {questions.map((item, i) => (
            <Question
              type="left"
              key={item.id}
              question={item}
              handleSubmit={this.handleSubmit}
              handleDelete={this.handleDelete}
            />
          ))}
          {!questions.length && <EmptyMessages />}
          <div id="bottom-anchor" />
        </div>
        <NewMessageBar
          show={newQuestions.length}
          loadNewQuestions={this.loadNewQuestions}
        />
      </div>
    );
  }
}
