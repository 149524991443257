import React, { Component } from 'react';
import MostConsultedService from 'services/MostConsulted';
import MostConsultedItem from './Item';
import {
  deleteItemFromArray,
  guid,
  parseResponseForPublishBar
} from 'services/Utilities';
import { Spinner } from 'reactstrap';
import { DndProvider } from 'react-dnd';
import PublishBar from 'components/PublishBar';
import Backend from 'react-dnd-html5-backend';
import './index.scss';

export default class MostConsulted extends Component {
  state = {
    mostConsulted: [],
    loading: true,
    saving: false
  };

  mostConsultedService = new MostConsultedService();

  addItem = () => {
    this.setState(prevState => ({
      mostConsulted: [
        ...prevState.mostConsulted,
        { id: guid(), newItem: true, title: '', active: false }
      ]
    }));
  };

  removeItem = id => {
    this.setState(({ mostConsulted }) => ({
      mostConsulted: deleteItemFromArray(mostConsulted, id)
    }));
  };

  cleanNewItems = array =>
    array.map(item => {
      if (!item.newItem) return item;

      const { id, newItem, ...data } = item;

      return data;
    });

  // Injecting one error at a time ...
  // Several messages make the design uglier than expected
  validateData = item => {
    switch (true) {
      case !item.title:
        item.error = 'Vous devez fournir un titre.';
        break;
      case !item.datable_type:
        item.error = 'Vous devez fournir un lien.';
        break;
      case !item.font_class_name:
        item.error = 'Vous devez fournir une icône.';
        break;
      default:
        delete item.error;
    }

    return item;
  };

  handleChange = (event, index) => {
    const {
      target,
      target: { name, type, checked }
    } = event;
    const value = type === 'checkbox' ? checked : target.value;
    this.setState(({ mostConsulted }) => ({
      mostConsulted: mostConsulted.map((item, position) =>
        position === index ? { ...item, [name]: value } : item
      )
    }));
  };

  handlePictogram = (index, className) => {
    this.setState(({ mostConsulted }) => ({
      mostConsulted: mostConsulted.map((item, position) =>
        position === index ? { ...item, font_class_name: className } : item
      )
    }));
  };

  handleLinkable = (index, data) => {
    this.setState(({ mostConsulted }) => ({
      mostConsulted: mostConsulted.map((item, position) =>
        position === index ? { ...item, ...data } : item
      )
    }));
  };

  /*
   HTML5 input with `required` attribute
   won't fire since we don't use the form's "submit" event ...
   */
  handleSubmit = () => {
    this.setState({ saving: true });
    const data = this.state.mostConsulted.map(this.validateData);
    const valid = !data.some(ms => ms.error);

    if (valid) {
      this.mostConsultedService
        .save(this.cleanNewItems(data))
        .then(({ data }) => {
          this.setState({ mostConsulted: data, saving: false });
          parseResponseForPublishBar(data, this, true);
        })
        .finally(() => {
          this.setState({ saving: false });
        });
    } else {
      this.setState({ mostConsulted: data, saving: false });
    }
  };

  getMostConsulted = () => {
    this.mostConsultedService.get().then(({ data: response }) => {
      this.setState({ mostConsulted: response, loading: false });
    });
  };

  moveItem = (dragIndex, hoverIndex) => {
    this.setState(({ mostConsulted }) => {
      [mostConsulted[dragIndex], mostConsulted[hoverIndex]] = [
        mostConsulted[hoverIndex],
        mostConsulted[dragIndex]
      ];

      return { mostConsulted };
    });
  };

  componentDidMount() {
    this.getMostConsulted();
  }

  render() {
    const { mostConsulted, loading, saving } = this.state;

    if (loading) return <Spinner />;

    return (
      <>
        <div className="text-right">
          <p className="font-italic text-primary help-info d-inline-block mb-0">
            <i className="fa fa-info-circle" /> &nbsp;
            <small>
              Cliquez sur Enregistrer pour confirmer la suppression des
              services.
            </small>
          </p>
        </div>
        <div className="most-consulted row mt-4">
          <DndProvider backend={Backend}>
            {mostConsulted.map((item, index) => (
              <div className="col-md-6 col-sm-12 col-lg-4" key={item.id}>
                <MostConsultedItem
                  item={item}
                  index={index}
                  handleChange={this.handleChange}
                  handlePictogram={this.handlePictogram}
                  handleLinkable={this.handleLinkable}
                  moveItem={this.moveItem}
                  removeItem={this.removeItem}
                />
              </div>
            ))}
            {mostConsulted.length < 6 && (
              <div className="col-6 mb-4 col-lg-4 bg-gray add-most-consulted-container">
                <div className="card-koba mb-4 add-most-consulted bg-white text-center">
                  <div
                    className="y-middle pointer text-muted"
                    onClick={this.addItem}
                  >
                    <i className="far fa-plus fa-5x" />
                    <h5 className="mt-3 text-muted">Ajouter</h5>
                  </div>
                </div>
              </div>
            )}
          </DndProvider>
        </div>
        <PublishBar
          state={'published'}
          handleSubmit={this.handleSubmit}
          loading={saving}
        />
      </>
    );
  }
}
