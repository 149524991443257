import ContactUs from 'services/ContactUs';
import ExceptionalClosingHours from 'services/ExceptionalClosingHours';
import OperatingHours from 'services/OperatingHours';

class ContacUsForm {
  constructor(type) {
    this.type = type;
    this.ContactUs = new ContactUs();
    this.ExceptionalClosingHours = new ExceptionalClosingHours();
    this.OperatingHours = new OperatingHours();
  }

  saveMultiple = async (
    contact_us,
    exceptional_closing_hour,
    operating_hours,
    exceptional_hour_to_delete,
    operating_hours_to_delete
  ) => {
    let promises = [];

    if (exceptional_hour_to_delete !== []) {
      const arr = exceptional_hour_to_delete;
      for (let i = 0; i < arr.length; i++) {
        let id = arr[i];
        promises.push(this.ExceptionalClosingHours.delete(id));
      }
    }

    if (operating_hours_to_delete !== []) {
      const arr = operating_hours_to_delete;
      for (let i = 0; i < arr.length; i++) {
        let id = arr[i];
        promises.push(this.OperatingHours.delete(id));
      }
    }

    if (contact_us && contact_us.id) {
      let dataWithoutId = contact_us;
      delete dataWithoutId.id;
      promises.push(this.ContactUs.put('contact_us', dataWithoutId));
    } else if (contact_us && !contact_us.id) {
      promises.push(this.ContactUs.post('contact_us', contact_us));
    }

    exceptional_closing_hour.forEach(item => {
      if (item.id) {
        promises.push(
          this.ExceptionalClosingHours.put(
            'exceptional_closing_hour',
            item,
            item.id
          )
        );
      } else {
        promises.push(
          this.ExceptionalClosingHours.post('exceptional_closing_hour', item)
        );
      }
    });

    if (operating_hours.length) {
      promises.push(
        this.OperatingHours.put(
          'operating_hours',
          operating_hours,
          'update_all'
        )
      );
    }
    return await Promise.all(promises);
  };
}

export default ContacUsForm;
