// React
import React, { Component } from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default class ModalDraw extends Component {
  constructor(props) {
    super(props);

    // state
    this.state = {
      isVisible: false,
      id: this.props.id
    };

    // binding
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle() {
    this.setState({ isVisible: !this.state.isVisible });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.action(event);
    this.toggle();
  }

  render() {
    return (
      <div style={{ display: 'inherit' }}>
        <button
          type="button"
          onClick={() => this.toggle()}
          className={'btn ' + this.props.btnClass}
          title={'Supprimer' || `Supprimer ${this.props.btnTitle}`}
        >
          {this.props.btnText} {this.props.ico}
        </button>
        <Modal isOpen={this.state.isVisible} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>{this.props.header}</ModalHeader>
          <ModalBody>{this.props.body}</ModalBody>
          <ModalFooter>
            <button className="btn btn-outline-primary" onClick={this.toggle}>
              Annuler
            </button>{' '}
            <Button color="primary" onClick={this.handleSubmit}>
              {this.props.label}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
