import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

export const Toggle = props => (
  <label className="switch mb-0">
    <input
      type="checkbox"
      aria-label="bouton checkbox"
      name={props.name}
      checked={props.checked}
      onChange={event => props.onChange(event)}
    />
    <span className="slider round" />
  </label>
);

Toggle.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

Toggle.defaultProps = {
  name: 'active',
  checked: true
};

export default Toggle;
