import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactCrop from 'react-image-crop';
import AlertInfo from 'components/AlertInfo';
import 'react-image-crop/dist/ReactCrop.css';
import './index.scss';

class ModalCropImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: null,
      iamge: null,
      loading: false,
      crop: {
        x: 0,
        y: 0,
        width: 100,
        height: 100
      }
    };
  }

  componentWillMount() {
    let file;
    if (!this.props.image) {
      let name = Date.now().toString() + '.jpeg';
      file = new File([this.props.imageSource], name, {
        type: 'image/jpeg',
        lastModified: Date.now()
      });
    } else {
      file = this.props.image;
    }
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () =>
        this.setState({
          src: this.props.imageSource
        }),
      false
    );
    reader.readAsDataURL(file);
  }

  onCropChange = crop => {
    this.setState({ crop });
  };

  handleOpened = _ => {
    const { width, height } = this.refImageCrop;
    const { ratio } = this.props;
    const crop = {
      x: ratio > 1 ? 0 : (1 - (height * ratio) / width) * 50,
      y: ratio > 1 ? (1 - width / (ratio * height)) * 50 : 0,
      // width: ratio > 1 ? 100 : (100 * height * ratio) / width,
      height: ratio > 1 ? (100 * width) / (ratio * height) : 100,
      aspect: ratio
    };
    this.setState({ crop });
  };

  handleCropValidate = () => {
    let { crop } = this.state;
    this.setState({ loading: true });
    this.addImageProcess(this.state.src, this.refImageCrop, crop).then(
      canvas => {
        canvas.toBlob(
          blob => {
            const croppedImage = canvas.toDataURL('image/jpeg');
            this.setState({ loading: false });
            const name = Date.now().toString() + '.jpeg';
            const file = new File([blob], name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            this.props.handleSubmitCrop(croppedImage, file, this.props.id);
          },
          'image/jpeg',
          0.95
        );
      }
    );
  };

  addImageProcess = (url, srcImage, pixelCrop) => {
    return new Promise((resolve, reject) => {
      let img = new Image();

      img.crossOrigin = 'Anonymous';
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const targetX = (srcImage.width * pixelCrop.x) / 100;
      const targetY = (srcImage.height * pixelCrop.y) / 100;
      const targetWidth = (srcImage.width * pixelCrop.width) / 100;
      const targetHeight = (srcImage.height * pixelCrop.height) / 100;
      img.onload = function() {
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        ctx.drawImage(
          img,
          targetX,
          targetY,
          targetWidth,
          targetHeight,
          0,
          0,
          targetWidth,
          targetHeight
        );
        resolve(canvas);
      };

      img.src = url;
    });
  };

  render() {
    const info = (
      <p className="mb-0">{`Veuillez noter qu'afin de recadrer votre photo vous devrez déplacer les points bleus`}</p>
    );
    return (
      <div>
        <Modal
          isOpen={this.props.modalCropImageActive}
          toggle={() => {}}
          size="xl"
          className="modal-crop"
          onOpened={this.handleOpened}
        >
          <ModalHeader toggle={() => this.props.toggleModalCropImage(null)}>
            <div className="d-flex flex-row">
              Recadrer l'image
              <AlertInfo info={info} />
            </div>
          </ModalHeader>
          <ModalBody className="px-0 py-0 block">
            {this.state.src && (
              <ReactCrop
                src={this.state.src}
                crop={this.state.crop}
                onImageLoaded={() => {}}
                onComplete={() => {}}
                onChange={this.onCropChange}
              />
            )}
            <img
              src={this.state.src}
              style={{ display: 'none' }}
              ref={img => {
                this.refImageCrop = img;
              }}
              alt="recadrée"
            />
          </ModalBody>
          <ModalFooter className="flex-md-row flex-column">
            <div className=" mt-4 mt-md-0">
              <button
                type="button"
                onClick={() => this.props.toggleModalCropImage(null)}
                className="btn btn-outline-primary float-left mr-2 "
              >
                ANNULER
              </button>
              <button
                type="submit"
                onClick={this.handleCropValidate}
                className="btn btn-primary float-right ml-2"
                disabled={this.state.loading}
              >
                VALIDER
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalCropImage;
