import React, { Component } from 'react';

class Pictograms extends Component {
  render() {
    return (
      <div className="col-4 col-md-3 col-lg-2 mb-1">
        <div
          className={'carre-white ' + this.props.divClass}
          onClick={this.props.handlePictogramChosen}
        >
          <i
            className={'icon-carre ' + this.props.iconClass}
            data-category={this.props.category}
          />
        </div>
      </div>
    );
  }
}

export default Pictograms;
