import React, { Component } from 'react';
import { getConsultations } from 'services/Consultation';

const slug = localStorage.getItem('slug');

class Consultation extends Component {
  state = {
    consultations: [],
    loading: true
  };

  componentDidMount() {
    getConsultations(slug).then(({ data: { data: consultations } }) => {
      this.setState({
        consultations,
        loading: false
      });
    });
  }

  render() {
    const { data, updateSubBlock } = this.props;
    const { consultations, loading } = this.state;

    return (
      <div>
        <label htmlFor="consultation">Consultation</label>
        <select
          name="consultation"
          id="consultation"
          className="form-control"
          value={data.consultation_id}
          disabled={loading}
          onChange={e => updateSubBlock('consultation_id', e.target.value)}
        >
          <option value="">
            {loading
              ? 'Chargement des consultations ...'
              : 'Choisissez votre consultation ...'}
          </option>
          {consultations.map(({ title, consultation_str_id }) => (
            <option key={consultation_str_id} value={consultation_str_id}>
              {title}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default Consultation;
