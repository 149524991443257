import React from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';

const TagSearch = props => {
  return (
    <div className="tag_search mt-2">
      {props.title && <h5 className="mb-3">{props.title}</h5>}
      <div className="row mb-2 mt-2">
        {!props.hideButton && (
          <div className="col">
            {props.buttonPlusLien && (
              <Link
                to={props.buttonPlusLien}
                title={props.buttonTitle ? props.buttonTitle : ''}
                tabIndex={-1}
              >
                <button type="button" className="btn btn-primary mr-2">
                  {props.buttonTitle} <i className="ml-2 fal fa-plus " />
                </button>
              </Link>
            )}
            {props.extraButton}
          </div>
        )}
        <div className="col input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              <i className="far fa-search" />
            </span>
          </div>
          <Input
            onChange={props.handleSearch}
            className="border-left-0 form-control"
            type="text"
            placeholder="Rechercher"
            aria-label="rechercher"
          />
        </div>
      </div>
    </div>
  );
};

export default TagSearch;
