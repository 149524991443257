import React from 'react';
import { handleEnterKeyPress } from 'services/Utilities/';

export default props => {
  return (
    <div
      onKeyPress={_ => handleEnterKeyPress(_, props.handleAddSlide)}
      onClick={props.handleAddSlide}
      className="card-koba mb-4 text-center text-secondary pointer bigger-on-hover"
      tabIndex={0}
      role="button"
    >
      <div className="mb-4 new-slide">
        <i className="fal fa-plus fa-7x" />
      </div>
      <div className="new-slide">ajouter une image</div>
    </div>
  );
};
