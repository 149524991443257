import { Link } from 'react-router-dom';
import React from 'react';

const SubHeaderElement = ({ data }) => {
  return (
    <React.Fragment>
      <Link
        to={data.link ? data.link : data.path}
        title={data.pageTitle}
        className="dropdown-item"
        role="button"
        tabIndex={0}
      >
        {data.pageTitle}
      </Link>
      <div className="dropdown-divider" />
    </React.Fragment>
  );
};

const SubHeaderElements = ({ data }) => {
  return (
    data &&
    data.map((subHeaderEl, index) => {
      return <SubHeaderElement data={subHeaderEl} key={index} />;
    })
  );
};
export default SubHeaderElements;
