import ModalKiosk from 'components/ModalKiosk';
import ModalDraw from 'components/ModalDraw';
import ModalDuplicate from 'components/ModalDuplicate';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { pageMatcher } from 'constants/Global';

export default class Options extends Component {
  parseUrl = () => {
    if (this.props.row) {
      let link;
      switch (this.props.kind) {
        case 'citizens':
          link = pageMatcher.admin.citizens.path + this.props.row.id;
          break;
        case 'addresses':
          link = pageMatcher.admin.addresses.annuaire.path + this.props.row.id;
          break;
        case 'users':
          link = pageMatcher.superAdmin.users.path + this.props.row.id;
          break;
        case 'kiosks':
          link = pageMatcher.admin.content.kiosks.path + this.props.row.id;
          break;
        case 'jobOffers':
          link = pageMatcher.admin.content.jobOffers.path + this.props.row.id;
          break;
        case 'procedure':
          link =
            pageMatcher.admin.onlineProcedures.procedures.path +
            this.props.row.id;
          break;
        case 'category_procedures':
          link =
            pageMatcher.admin.onlineProcedures.categories.path +
            this.props.row.id;
          break;
        case 'category_addresses':
          link =
            pageMatcher.admin.addresses.categories.path + this.props.row.id;
          break;
        case 'chat':
          link = pageMatcher.admin.chat.path + this.props.row.id;
          break;
        case 'surveys':
          link = pageMatcher.admin.surveys.path + this.props.row.id;
          break;
        default:
          link =
            pageMatcher.admin.content.path +
            `${this.props.kind}/${this.props.row.id}`;
      }
      return link;
    }
  };

  render() {
    const {
      downloadLabel,
      label,
      row,
      unarchiving,
      archiving,
      noDelete,
      add,
      copy,
      preview,
      see,
      download,
      downloadDisabled
    } = this.props;
    return (
      <>
        {row && (
          <div>
            <ul
              className="action-list d-flex flex-wrap justify-content-end"
              role="tablist"
            >
              {see && (
                <li className="pb-1" role="presentation">
                  {preview && <ModalKiosk {...row} />}
                  {!preview && (
                    <button
                      type="button"
                      tabIndex={0}
                      title="Accéder au dashboard"
                      className="btn table-action-btn btn-action mr-1"
                      onClick={() => see(row.slug)}
                    >
                      <i className="fal fa-eye " />
                    </button>
                  )}
                </li>
              )}

              <li className="pb-1" role="presentation">
                <Link
                  to={this.parseUrl()}
                  title={'Modifier ' + (label || '')}
                  role="presentation"
                  tabIndex={-1}
                >
                  <button
                    type="button"
                    tabIndex={0}
                    className="btn table-action-btn btn-action mr-1 "
                  >
                    <i className="far fa-edit " />
                  </button>
                </Link>
              </li>
              {copy && (
                <li className="pb-1" role="presentation">
                  <ModalDuplicate
                    id={row.id}
                    ico={<i className="far fa-copy " title="Dupliquer" />}
                    handleSubmit={title => copy(row.id, title)}
                    btnClass="btn table-action-btn btn-action mr-1"
                  />
                </li>
              )}
              {add && (
                <li className="pb-1" role="presentation">
                  <button
                    type="button"
                    tabIndex={0}
                    title={'Ajouter ' + (label || '')}
                    className="btn table-action-btn btn-action mr-1"
                    onClick={() => add(row.id)}
                  >
                    <i className="far fa-plus " />
                  </button>
                </li>
              )}
              {download && (
                <li className="pb-1">
                  <button
                    disabled={downloadDisabled}
                    tabIndex={0}
                    title={'Télécharger ' + (downloadLabel || label || '')}
                    type="button"
                    className="btn table-action-btn btn-action mr-1"
                    onClick={() => download(row.id)}
                  >
                    <i className="far fa-arrow-to-bottom   ml-0 mr-0" />
                  </button>
                </li>
              )}

              {archiving && (
                <li className="pb-1">
                  <button
                    title={'Archiver ' + (label || '')}
                    tabIndex={0}
                    type="button"
                    className="btn table-action-btn btn-action mr-1"
                    onClick={() => archiving(row.id)}
                  >
                    <i className="far fa-file-archive   ml-0 mr-0" />
                  </button>
                </li>
              )}
              {unarchiving && (
                <li className="pb-1">
                  <button
                    type="button"
                    tabIndex={0}
                    title={'Désarchiver ' + (label || '')}
                    className="btn table-action-btn btn-action mr-1"
                    onClick={() => unarchiving(row.id)}
                  >
                    <i className="far fa-file-import   ml-0 mr-0" />
                  </button>
                </li>
              )}
              {!noDelete && (
                <li className="pb-1">
                  <ModalDraw
                    id={row.id}
                    header={'Supprimer ' + label}
                    body={'Etes-vous sûr de vouloir supprimer ' + label + ' ?'}
                    label="Supprimer"
                    ico={<i className="far fa-trash-alt " />}
                    action={() => this.props.delete(row.id)}
                    btnClass="btn table-action-btn btn-action mr-1"
                    buttonTitle={'Supprimer' + (label || '')}
                  />
                </li>
              )}
            </ul>
          </div>
        )}
      </>
    );
  }
}
