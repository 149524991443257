import CitizensList from './CitizensList';
import LayoutContact from './Layout';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Submenu from 'components/Submenu';
import { pageMatcher } from 'constants/Global';

const subMenuData = [pageMatcher.admin.citizens];
const Home = props => (
  <div>
    <Submenu subMenuData={subMenuData} />
    <Switch>
      <Route
        path={[
          pageMatcher.admin.citizens.new.path,
          pageMatcher.admin.citizens.single.path
        ]}
        render={routerProps => <LayoutContact {...props} {...routerProps} />}
      />
      <Route
        path={pageMatcher.admin.citizens.path}
        render={() => <CitizensList {...props} />}
      />
    </Switch>
  </div>
);

export default Home;
