import React, { Component } from 'react';
import SubBlock from './SubBlock';
import { isNew } from 'services/Utilities';

class Block extends Component {
  updateSubBlock = updatedSubBlock => {
    let { block } = this.props;
    if (block) {
      if (!isNew(block)) {
        for (let subBlock of block.sub_blocks) {
          if (updatedSubBlock.id === subBlock.id) {
            subBlock = updatedSubBlock;
          }
        }
        this.props.updateLocalBlock(block);
      } else {
        this.props.updateNewBlock(block);
      }
    }
  };

  render() {
    const { block, setLoading } = this.props;
    return (
      <>
        {block && (
          <>
            <div className="row">
              {block.type !== 'block_container' && (
                <SubBlock
                  highlight={block.type === 'highlight'}
                  key={block.id}
                  subBlock={block}
                  updateSubBlock={this.updateSubBlock}
                  setLoading={setLoading}
                />
              )}
              {block.type === 'block_container' && (
                <>
                  {block.sub_blocks.map((sub_block, i) => (
                    <SubBlock
                      highlight={block.type === 'highlight'}
                      key={sub_block.id || i}
                      subBlock={sub_block}
                      updateSubBlock={this.updateSubBlock}
                      setLoading={setLoading}
                    />
                  ))}
                </>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

export default Block;
