import SocialNetworks from 'services/SocialNetworks';

class SocialNetworksForm {
  constructor(type) {
    this.type = type;
    this.SocialNetworks = new SocialNetworks();
  }

  saveMultiple = async social_networks => {
    let promises = [];

    if (social_networks !== []) {
      const arr = social_networks;

      for (let i = 0; i < arr.length; i++) {
        let id = arr[i].id;
        let data = arr[i];
        if (id) {
          delete data.id;
          promises.push(this.SocialNetworks.putInPromiseAll(id, data));
        } else {
          promises.push(this.SocialNetworks.postInPromiseAll(data));
        }
      }
    }

    return await Promise.all(promises);
  };
}

export default SocialNetworksForm;
