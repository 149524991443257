import Ressource from 'services/Ressource';
import moment from 'moment';

export default class Events extends Ressource {
  constructor() {
    super('event');
  }
}

export const validateEvents = event => {
  let localError;
  if (moment(event.end_at).isBefore(moment(event.begin_at))) {
    localError = Error(
      "Erreur, la date de fin de l'événement doit être supérieure à la date de début."
    );
  }
  return localError;
};
