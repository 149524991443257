import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

export default class ExternalLinkForm extends Component {
  static propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    noTitle: PropTypes.bool
  };

  handleChangeForField(field) {
    return e =>
      this.props.onChange({
        internal: false,
        ...this.props.value,
        [field]: e.target.value
      });
  }

  render() {
    const { url, title } = this.props.value || {};
    return (
      <div>
        {!this.props.noTitle && (
          <div>
            <label htmlFor="title" className="col-form-label">
              Saisissez un nom pour votre lien :
            </label>
            <Input
              placeholder="Title"
              className="form-control"
              type="text"
              aria-label="url"
              name="title"
              value={title || ''}
              onChange={this.handleChangeForField('title')}
            />
          </div>
        )}
        <div>
          <label htmlFor="title" className="col-form-label">
            Saisissez l'URL :
          </label>
          <Input
            placeholder="https://example.com"
            className="form-control"
            type="url"
            aria-label="url"
            name="data"
            value={url || ''}
            onChange={this.handleChangeForField('url')}
          />
        </div>
      </div>
    );
  }
}
