import BootstrapTable from 'react-bootstrap-table-next';
import ChatService from 'services/Chat';
import filterFactory from 'react-bootstrap-table2-filter';
import LinkButton from 'components/LinkButton';
import Loading from 'components/Loading';
import moment from 'moment';
import Options from 'components/Options';
import paginationFactory from 'react-bootstrap-table2-paginator';
import React from 'react';
import TagSearch from 'components/TagSearch';
import * as Utilities from 'services/Utilities';
import { pageMatcher } from 'constants/Global';
import { Link } from 'react-router-dom';

export default class Chat extends React.Component {
  state = {
    allChats: [],
    chats: [],
    loading: true
  };

  chatService = new ChatService();

  get = async _ => {
    const { data: chats } = await this.chatService.get();

    this.setState({ chats, allChats: chats, loading: false });
  };

  delete = async id => {
    const { data: response } = await this.chatService.delete(id);
    Utilities.parseResponseForPublishBar(response, this, 'marginBottom');
    if (!(response instanceof Error)) this.get();
  };

  filterList = event => {
    const value = event.target.value;
    this.setState(({ allChats }) => ({
      chats: allChats.filter(chat =>
        chat.title.toLowerCase().includes(value.toLowerCase())
      )
    }));
  };

  toggleFormatter = (cell, row) => (
    <LinkButton
      className="btn-sm btn-outline-secondary"
      to={pageMatcher.admin.chat.live.path + row.id}
    >
      Live
    </LinkButton>
  );

  dateFormatter = (cell, _row) =>
    cell && <span>{moment(cell).format('DD/MM/YYYY')}</span>;

  optionsFormatter = (cell, row) => (
    <Options row={row} delete={this.delete} label="le service" kind="chat" />
  );

  titleFormatter = (cell, row) => (
    <Link to={pageMatcher.admin.chat.path + row.id}>{cell}</Link>
  );

  stateFormatter = cell => {
    switch (cell) {
      case 'started':
        return <span>En cours</span>;
      case 'finished':
        return <span>Terminé</span>;
      case 'scheduled':
        return <span>Programmé</span>;
      default:
        return null;
    }
  };

  componentDidMount() {
    this.get();
  }

  render() {
    const optionsPagination = Utilities.buildPaginationOptions();
    const columns = [
      {
        dataField: 'title',
        text: 'Titre',
        formatter: this.titleFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'created_at',
        text: 'Date de création',
        formatter: this.dateFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'interlocutor_username',
        text: 'Interlocuteur',
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        formatter: this.stateFormatter,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'live',
        text: 'Direct',
        formatter: this.toggleFormatter,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'action',
        text: 'Actions',
        formatter: this.optionsFormatter,
        sort: false,
        headerStyle: { fontSize: 11 },
        align: 'right'
      }
    ];
    const { loading, chats } = this.state;

    return (
      <div>
        <TagSearch
          handleSearch={this.filterList}
          buttonTitle="Ajouter un chat"
          buttonPlusLien={pageMatcher.admin.chat.new.path} // buttonPlusLien ?? naming
        />
        <BootstrapTable
          keyField="id"
          data={chats}
          noDataIndication="Aucun chat renseigné pour le moment"
          columns={columns}
          bordered={false}
          pagination={paginationFactory(optionsPagination)}
          classes="koba-array"
          filter={filterFactory()}
        />
        <Loading loading={loading} />
      </div>
    );
  }
}
