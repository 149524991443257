import Chat from './Chat';
import ChatEdit from './Chat/Edit';
import ChatNew from './Chat/New';
import ChatLive from './Live';
import React, { Component } from 'react';
import Submenu from 'components/Submenu';
import { Route, Switch } from 'react-router-dom';
import { pageMatcher } from 'constants/Global';

export default class ChatRouter extends Component {
  subMenuData = [pageMatcher.admin.chat];

  render() {
    return (
      <>
        <Submenu subMenuData={this.subMenuData} />
        <Switch>
          <Route
            path={pageMatcher.admin.chat.new.path}
            render={() => <ChatNew {...this.props} />}
          />
          <Route
            path={pageMatcher.admin.chat.live.single.path}
            render={props => <ChatLive {...this.props} {...props} />}
          />
          <Route
            path={pageMatcher.admin.chat.single.path}
            render={props => <ChatEdit {...this.props} {...props} />}
          />
          <Route
            path={pageMatcher.admin.chat.path}
            render={() => <Chat {...this.props} />}
          />
        </Switch>
      </>
    );
  }
}
