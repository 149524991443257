export function findAddressComponent(componentName, addressComponents) {
  for (let item of addressComponents) {
    for (let typesItemName of item.types) {
      if (typesItemName === componentName) {
        return item.long_name;
      }
    }
  }
  return '';
}
