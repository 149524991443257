import React, { Component, Fragment } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import AppsService from 'services/Apps';
import CategoryService from 'services/Category';
import RoleService from 'services/Role';
import PublishBar from 'components/PublishBar';
import { pageMatcher } from 'constants/Global';
import { parseResponseForPublishBar } from 'services/Utilities';

const appsService = new AppsService();
const categoriesService = new CategoryService();
const rolesService = new RoleService();

const adminRoleName = 'Admin';

export default class UserForm extends Component {
  state = {
    user: {},
    categories: [],
    roles: [],
    me: {}
  };

  // TODO: Catch clause with a ErrorService displaying a toaster message
  componentDidMount() {
    const { match } = this.props;
    const tokenAuthUserId = parseInt(localStorage.getItem('authUserId'));
    appsService.get().then(({ data: apps }) => {
      this.setState({
        user: apps.find(app => app.id === match.params.id),
        me: apps.find(app => app.auth_user_id === tokenAuthUserId)
      });
    });
    categoriesService
      .get(null, '?kind=affected_object')
      .then(({ data: categories }) => this.setState({ categories }));
    rolesService.get().then(({ data: roles }) => this.setState({ roles }));
  }

  handleSelectRole = e => {
    const { roles } = this.state;
    const roleId = e.target.value;
    const selectedRole = roles.find(r => r.id === roleId);
    this.setState(({ user }) => ({
      user: { ...user, roles: selectedRole ? [selectedRole] : [] }
    }));
  };

  handleSelectCategory = e => {
    const { categories } = this.state;
    const clickedCategoryId = e.target.name;
    const isChecked = e.target.checked;
    this.setState(({ user }) => {
      const selectedCategories = [
        ...(user.categories || []).filter(c => c.id !== clickedCategoryId), // Removing the clicked category from the user categories every time
        ...(isChecked ? [categories.find(c => c.id === clickedCategoryId)] : []) // Adding it if it is checked
      ];
      return {
        user: { ...user, categories: selectedCategories }
      };
    });
  };

  handleSubmit = () => {
    const { history } = this.props;
    const {
      user: { id, categories, roles }
    } = this.state;
    appsService
      .patch(id, {
        ...(categories && { category_ids: categories.map(c => c.id) }),
        ...(roles && { role_ids: roles.map(r => r.id) })
      })
      .then(({ data: resp }) => {
        parseResponseForPublishBar(resp, this);
        history.push('/admin/users');
      });
  };

  render() {
    const { me, user, categories, roles } = this.state;

    if (!me || !user || !categories || !roles) return null;

    if (
      me.id !== user.id &&
      !(
        me.roles.some(r => !r.town_hall_id && r.name === adminRoleName) ||
        me.kind === 'koba'
      )
    ) {
      return (
        <h2 className="mt-2">
          Vous n'avez pas les droits d'accéder à cette page
        </h2>
      );
    }

    return (
      <div className="no-tabs">
        <h1 className="mt-2 mb-3">
          Edition de {user.firstname} {user.lastname}
        </h1>
        <div className="card-koba">
          <FormGroup>
            <Label>Role</Label>
            <Input
              type="select"
              name="role"
              id="role"
              onChange={this.handleSelectRole}
              value={
                user.roles && user.roles.length > 0 ? user.roles[0].id : ''
              }
            >
              <option value={''} hidden>
                Choisir un rôle
              </option>
              {roles.length > 0 &&
                roles.map(role => (
                  <option value={role.id} key={role.id}>
                    {role.name}
                  </option>
                ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Rubriques de notifications assignées</Label>
            {categories.length > 0 &&
              categories.map(category => (
                <FormGroup key={category.id} check>
                  <Label style={{ color: 'black' }} check>
                    <Fragment>
                      <Input
                        type="checkbox"
                        name={category.id}
                        key={category.id}
                        checked={
                          !!(user.categories || []).find(
                            c => c.id === category.id
                          )
                        }
                        onChange={this.handleSelectCategory}
                      />
                      {category.name}
                    </Fragment>
                  </Label>
                </FormGroup>
              ))}
          </FormGroup>
        </div>
        <PublishBar
          handleSubmit={this.handleSubmit}
          goBackLink={pageMatcher.superAdmin.users.path}
        />
      </div>
    );
  }
}
