import { connect } from 'react-redux';
import React from 'react';
import './index.scss';

class BarStatusMessage extends React.Component {
  render() {
    return (
      <div
        className={
          'd-flex align-items-center info p-4 ' +
          (this.props.statusType === 'success'
            ? 'bg-info '
            : this.props.statusType === 'warning'
              ? 'bg-warning '
              : '') +
          (this.props.statusIsVisible ? 'show ' : 'hide ') +
          (this.props.marginBottom ? 'margin-bottom ' : '')
        }
      >
        {this.props.statusType === 'success' && (
          <i className="fal fa-comment-check mr-3" />
        )}
        {this.props.statusType === 'warning' && (
          <i className="fal fa-exclamation-circle mr-3" />
        )}
        {this.props.statusMessage}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    statusIsVisible: state.statusMessage.statusIsVisible,
    statusType: state.statusMessage.statusType,
    statusMessage: state.statusMessage.statusMessage,
    marginBottom: state.statusMessage.marginBottom
  };
};

export default connect(
  mapStateToProps,
  null
)(BarStatusMessage);
