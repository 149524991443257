import {
  DELETE_CAMPAIGN,
  FETCH_ONGOING_CAMPAIGN,
  FETCH_UPCOMING_CAMPAIGN,
  POST_CAMPAIGN,
  PUT_CAMPAIGN
} from '../types/campaignTypes';
import CampaignService from 'services/Campaign';

const campaignService = new CampaignService();

/*
 *
  Back-end returns an array of one object on fetching,
  Reason why you will see `res[0]` sometimes
 *
*/

/*
  TODO: On catch clause, dispatch to alert message
      So the bottom bar can display an error message
 */
export const createCampaign = campaign => dispatch => {
  campaignService.post(campaign).then(({ data: res }) => {
    dispatch({
      type: POST_CAMPAIGN,
      payload: res
    });
  });
};

export const updateCampaign = (id, campaign) => dispatch => {
  campaignService.put(id, campaign).then(({ data: res }) => {
    dispatch({
      type: PUT_CAMPAIGN,
      payload: res
    });
  });
};

export const fetchOngoingCampaign = _ => dispatch => {
  campaignService.getOnGoingCampaign().then(({ data: res }) => {
    const payload = res.length ? res[0] : {};
    dispatch({
      type: FETCH_ONGOING_CAMPAIGN,
      payload
    });
  });
};

export const fetchUpcomingCampaign = _ => dispatch => {
  campaignService.getUpcomingCampaign().then(({ data: res }) => {
    const payload = res.length ? res[0] : {};
    dispatch({
      type: FETCH_UPCOMING_CAMPAIGN,
      payload
    });
  });
};

export const deleteCampaign = (id, type) => dispatch => {
  campaignService.delete(id).then(_ => {
    dispatch({
      type: DELETE_CAMPAIGN,
      payload: type
    });
  });
};
