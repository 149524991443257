import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Axios from 'services/Axios';
import Spinner from 'components/Spinner';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Input } from 'reactstrap';
import InvitationModal from './InvitationModal';
import Options from 'components/Options';
import UsersFeedback from 'components/UsersFeedback';

const adminRoleName = 'Admin';
const isAdmin = u =>
  u.kind === 'koba' ||
  (u.roles || []).some(r => !r.town_hall_id && r.name === adminRoleName);
const getColumns = me => [
  {
    dataField: 'lastname',
    text: 'Nom',
    sort: true,
    headerStyle: { fontSize: 11 }
  },
  {
    dataField: 'firstname',
    text: 'Prénom',
    sort: true,
    headerStyle: { fontSize: 11 }
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: true,
    headerStyle: { fontSize: 11 }
  },
  {
    dataField: 'role',
    text: 'Rôle',
    sort: true,
    headerStyle: { fontSize: 11 }
  },
  {
    dataField: 'action',
    text: 'Actions',
    formatter: (cell, row) => {
      if (isAdmin(me) || row.id === me.id) {
        return (
          <Options
            row={row}
            label="l'utilisateur"
            kind="users"
            noDelete={true}
          />
        );
      } else {
        return null;
      }
    },
    headerStyle: { fontSize: 11 },
    align: 'right'
  }
];

const optionsPagination = {
  page: 1,
  paginationSize: 4,
  pageStartIndex: 1,
  hideSizePerPage: true,
  hidePageListOnlyOnePage: true,
  nextPageTitle: 'Prochaine page',
  prePageTitle: 'Page précédente'
};

export default class Users extends Component {
  state = {
    filteredUsers: [],
    allUsers: [],
    loading: true
  };

  handleSearch = event => {
    const value = event.target.value.toLowerCase();
    this.setState(state => ({
      filteredUsers: state.allUsers.filter(item =>
        ['lastname', 'firstname'].some(
          colon => item[colon] && item[colon].toLowerCase().includes(value)
        )
      )
    }));
  };

  getUserList = () => {
    const tokenAuthUserId = parseInt(localStorage.getItem('authUserId'));
    Axios.get(`${process.env.REACT_APP_BACKOFFICE_API}apps`).then(response => {
      const newList = response.data.map(
        ({
          id,
          auth_user_id: authUserId,
          kind,
          firstname,
          lastname,
          email,
          roles
        }) => ({
          id,
          authUserId,
          kind,
          firstname,
          lastname,
          email,
          roles,
          role: (roles[0] && roles[0].name) || ''
        })
      );
      this.setState({
        loading: false,
        allUsers: newList,
        filteredUsers: newList,
        me: newList.find(u => u.authUserId === tokenAuthUserId)
      });
    });
  };

  componentDidMount() {
    this.getUserList();
  }

  render() {
    // TODO: return spinner instead
    const {
      isInvitationModalOpen,
      me,
      filteredUsers,
      allUsers,
      loading
    } = this.state;
    if (!me) return null;

    return (
      <div className="no-tabs">
        <h1 className="mt-2 mb-3">Gestion des utilisateurs</h1>
        {isAdmin(me) && (
          <>
            <InvitationModal
              isOpen={isInvitationModalOpen}
              onClose={() => {
                this.setState({ isInvitationModalOpen: false });
              }}
            />
            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={() => {
                    this.setState({ isInvitationModalOpen: true });
                  }}
                >
                  Inviter un collaborateur{' '}
                  <i className="ml-2 fal fa-user-plus " />
                </button>
              </div>
              <div className="col-6 input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="far fa-search" />
                  </span>
                </div>
                <Input
                  onChange={this.handleSearch}
                  className="border-left-0 form-control"
                  type="text"
                  placeholder="Rechercher"
                  aria-label="rechercher"
                />
              </div>
            </div>
          </>
        )}
        <UsersFeedback
          currentNumber={allUsers.length}
          maxNumber={localStorage.getItem('maxUsers')}
        />
        <BootstrapTable
          keyField="id"
          data={filteredUsers}
          columns={getColumns(me)}
          hover
          bordered={false}
          pagination={paginationFactory(optionsPagination)}
          classes="koba-array"
        />
        {loading && (
          <div className="text-center">
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}
