import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import Editor from 'components/Editor';
import Filedrop from 'components/Filedrop';

const KINDS = [
  'Stage',
  'CDD',
  'CDI',
  'Alternant',
  'Vacataire',
  'Fonctionnaire',
  'Contractuel de droit public'
];

const JobKinds = ({ job, handleChange }) =>
  KINDS.map(kind => (
    <FormGroup check key={kind}>
      <Label check>
        <Input
          type="radio"
          name="kind"
          value={kind}
          aria-label={kind}
          onChange={handleChange}
          defaultChecked={kind === 'Stage'}
          checked={job.kind === kind}
        />{' '}
        {kind}
      </Label>
    </FormGroup>
  ));

const JobOfferForm = props => {
  const {
    job,
    job: { document }
  } = props;

  return (
    <div>
      <div className="card-koba mb-4">
        <FormGroup>
          <Label for="title">
            Titre du poste
            <span className="required"> *</span>
          </Label>
          <Input
            type="text"
            name="title"
            id="title"
            placeholder="Titre du job"
            aria-label="Titre du job"
            value={job.title}
            onChange={props.handleChange}
            required
          />
        </FormGroup>
        <FormGroup tag="fieldset">
          <Label>
            Type de contrat <span className="required"> *</span>
          </Label>
          <JobKinds job={job} handleChange={props.handleChange} />
        </FormGroup>
        <FormGroup>
          <Label for="description">
            Description du poste
            <span className="required"> **</span>
          </Label>
          <Editor
            initValue={job.description}
            onHTMLChange={props.onHTMLChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="document_title">Titre du document PDF</Label>
          <span className="required"> **</span>
          <Input
            type="text"
            name="title"
            aria-label="titre"
            id="document_title"
            value={(document && document.title) || ''}
            onChange={props.handleDocumentChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="document_data">Fichier PDF</Label>
          <span className="required"> **</span>
          <Filedrop
            id="document_data"
            name="document_data"
            accepts="pdf"
            initValue={job.document_url}
            required={props.required}
            onFileChange={event => props.handleFileChange(event)}
            handleDeletePDF={props.handleDeletePDF}
          />
          {document &&
            document.title && (
              <p className="mt-2 float-right">
                {document && document.title}
                .pdf
              </p>
            )}
        </FormGroup>
      </div>
    </div>
  );
};

export default JobOfferForm;
