import React, { Component } from 'react';
import PhotoAlbumService from 'services/PhotoAlbums';
import FormBase from '../FormBase';
import { parseResponseForPublishBar } from 'services/Utilities';
import PublishBar from 'components/PublishBar';
import { pageMatcher } from 'constants/Global';

export default class EditPhoto extends Component {
  state = {
    photo_album: {
      title: '',
      publication_date: null,
      description: '',
      cover_photo: null,
      cover_photo_url: '',
      photos: []
    },
    loadingPublishBar: false,
    showSavedPublishBar: false,
    errorMessagePublishBar: ''
  };

  photoAlbumService = new PhotoAlbumService();

  componentDidMount() {
    this.getPhotoAlbum();
  }

  getPhotoAlbum = () => {
    const { id } = this.props.match.params;
    this.photoAlbumService.get(id).then(({ data: photo_album }) => {
      this.setState({ photo_album });
    });
  };

  putStatus = status => {
    this.handleChange('state', status);
    const { id } = this.props.match.params;
    if (status === 'draft') {
      this.photoAlbumService.draft(id).then(({ data: response }) => {
        parseResponseForPublishBar(response, this, 'marginBottom');
      });
    } else {
      this.photoAlbumService.publish(id).then(({ data: response }) => {
        parseResponseForPublishBar(response, this, 'marginBottom');
      });
    }
  };

  handleChange = (name, value) => {
    const newState = { ...this.state };
    newState.photo_album[name] = value;
    this.setState(newState);
  };

  handleChangePicker = data => {
    const newState = { ...this.state };
    newState.photo_album.publication_date = data.start;
    this.setState(newState);
  };

  handleChangeTextArea = html => {
    const newState = { ...this.state };
    newState.photo_album.description = html;
    this.setState(newState);
  };

  handlePublish = status => {
    this.putStatus(status);
  };

  handleSubmit = event => {
    event.preventDefault();
    const photo_album = { ...this.state.photo_album };
    const localError = photo_album.cover_photo_url
      ? ''
      : Error('Veuillez sélectionner la photo de couverture');
    if (localError) {
      parseResponseForPublishBar(localError, this, 'marginBottom');
    } else {
      const photos = photo_album.photos.map(item => ({
        signed_id: item.signed_id,
        attachment_id: item.attachment_id
      }));
      this.photoAlbumService
        .put(photo_album.id, { ...photo_album, photos })
        .then(({ data: response }) => {
          parseResponseForPublishBar(response, this, 'marginBottom');
          this.props.history.push(
            pageMatcher.admin.content.photoLibrairies.path
          );
        });
    }
  };

  deletePhoto = attachment_id => {
    const { id } = this.state.photo_album;
    this.photoAlbumService.deleteAttachment(
      { attachment_id },
      id,
      'delete_image_attachment'
    );
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <FormBase
          {...this.state.photo_album}
          editMode={true}
          handleChange={this.handleChange}
          handleChangePicker={this.handleChangePicker}
          handleChangeTextArea={this.handleChangeTextArea}
          deletePhoto={this.deletePhoto}
          ref="myform"
        />
        {/* TODO : Add condition to unable toggle when archived. */}
        <PublishBar
          handleStatusChange={this.handlePublish}
          state={this.state.photo_album.state}
          showSubmitButton
          goBackLink={pageMatcher.admin.content.photoLibrairies.path}
          goBackLinkTitle="Retourner à la liste"
        />
      </form>
    );
  }
}
