import React, { Component } from 'react';
import Button from 'components/Button';
import { FormGroup, Input, Form } from 'components/Form';
import PublishBar from 'components/PublishBar';
import QuestionCard from './QuestionCard';
import SurveysService from 'services/Surveys';
import { guid, parseResponseForPublishBar } from 'services/Utilities';
import { required, minLength } from 'services/Validation';
import { pageMatcher } from 'constants/Global';
import * as Utilities from 'services/Utilities';

// validations supposed to be run when a field has been touched
const realTimeValidations = [
  {
    path: ['title'],
    validators: [required, minLength(3)]
  },
  {
    path: ['content'],
    validators: [minLength(3)]
  },
  {
    path: ['questions', [], 'title'],
    validators: [required, minLength(3)]
  },
  {
    path: ['questions', [], 'subtitle'],
    validators: [minLength(3)]
  },
  {
    path: ['questions', [], 'choices', [], 'label'],
    validators: [required, minLength(1)]
  }
];

// validations supposed to be run when submitting the form
const saveValidations = [
  {
    path: [],
    validators: [
      s => s.questions.length > 0 || 'vous devez proposer au moins une question'
    ]
  },
  {
    path: ['questions', []],
    validators: [
      q =>
        q.choices_kind === 'free_text' ||
        q.choices.length > 0 ||
        'vous devez proposer au moins un choix (ou configurer un type de choix en texte libre)'
    ]
  }
];

export default class SurveyForm extends Component {
  state = {
    survey: null
  };

  surveyService = new SurveysService();

  getSurvey = () => {
    const { id } = this.props.match.params;

    if (id) {
      this.surveyService.get(id).then(({ data: survey }) => {
        this.setState({ survey });
      });
    } else {
      this.setState({ survey: { questions: [] } });
    }
  };

  handleStatusChange = status => {
    const { id } = this.props.match.params;
    const statusForUpdate = status === 'published' ? 'publish' : 'draft';
    this.surveyService.change(id, statusForUpdate).then(({ data: survey }) => {
      Utilities.parseResponseForPublishBar(survey, this, 'marginBottom');
      this.setState({ survey });
    });
  };

  addQuestion = form => {
    const nextQuestions = form.getValue(['questions']).concat({
      id: guid(),
      title: null,
      subtitle: null,
      choices_kind: 'single',
      choices: []
    });
    form.handleChange(['questions'], nextQuestions);
  };

  componentDidMount() {
    this.getSurvey();
  }

  handleSubmit = form => {
    const { match, history } = this.props;

    form.handleSave(value => {
      if (match.params.id) {
        this.surveyService
          .put(match.params.id, value)
          .then(({ data: survey }) => {
            this.setState({ survey });
            parseResponseForPublishBar(survey, this, 'marginBottom');
            history.push(`${pageMatcher.admin.surveys.path}${match.params.id}`);
          });
      } else {
        this.surveyService.post(value).then(({ data: survey }) => {
          parseResponseForPublishBar(survey, this, 'marginBottom');
          history.push(`${pageMatcher.admin.surveys.path}${survey.id}`);
          this.getSurvey();
        });
      }
    });
  };

  render() {
    const { survey } = this.state;
    // TODO : Ajouter un loader.
    if (!survey) return null;

    return (
      <div>
        <Form
          value={survey}
          realTimeValidations={realTimeValidations}
          saveValidations={saveValidations}
        >
          {form => (
            <>
              <div className="card-koba mb-4" style={{ height: 'auto' }}>
                {form.getError([]) && (
                  <div className="alert alert-warning" role="alert">
                    {form.getError([])}
                  </div>
                )}
                <FormGroup
                  path={['title']}
                  label="Nom"
                  required={true}
                  form={form}
                >
                  <Input
                    path={['title']}
                    placeholder="Mon questionnaire"
                    aria-label="mon questionnaire"
                  />
                </FormGroup>
              </div>

              {form.getValue(['questions']).map((question, questionIndex) => (
                <QuestionCard
                  key={question.id}
                  form={form}
                  question={question}
                  questionIndex={questionIndex}
                />
              ))}

              <Button
                icon="plus"
                type="primary"
                onClick={() => this.addQuestion(form)}
              >
                Ajouter une question
              </Button>
              <PublishBar
                state={survey.state}
                buttonDisabled={false}
                goBackLinkTitle="Retourner à la liste"
                goBackLink={pageMatcher.admin.surveys.path}
                handleStatusChange={
                  survey.state ? this.handleStatusChange : null
                }
                handleSubmit={() => this.handleSubmit(form)}
              />
            </>
          )}
        </Form>
      </div>
    );
  }
}
