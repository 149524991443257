import * as Utilities from 'services/Utilities';

function checkUnsavedBlock(blocks) {
  for (let block of blocks) {
    if (block.updated) {
      return true;
    }
  }
  return false;
}

export function checkBeforeSave(parentThis, blocks) {
  let hasError = checkUnsavedBlock(blocks);
  if (hasError) {
    Utilities.alertMessage(
      'warning',
      "Attention, vous n'avez pas sauvegardé tous vos blocs",
      parentThis,
      true
    );
    return false;
  }
  return true;
}

export function addBlock(block, parentThis, type, position) {
  let blocks = parentThis.state[type].blocks;
  position ? blocks.splice(position, 0, block) : blocks.push(block);
  let newState = Object.assign({}, parentThis.state);
  newState[type].blocks = blocks;
  parentThis.setState(newState);
}

export function updateLocalBlocks(blocks, parentThis, type) {
  let newState = Object.assign({}, parentThis.state);
  newState[type].blocks = blocks;
  parentThis.setState(newState);
}

export function updateLocalBlock(block, parentThis, type) {
  let blocks = parentThis.state[type].blocks;
  if (blocks) {
    for (let [key, b] of Object.entries(blocks)) {
      if (b.id === block.id) {
        blocks[key] = block;
      }
    }
    let newState = Object.assign({}, parentThis.state);
    newState[type].blocks = blocks;
    parentThis.setState(newState);
  }
}
