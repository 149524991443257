import axios from 'axios';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import cookie from 'react-cookies';
import jwt_decode from 'jwt-decode';
import { getSessionSlug } from './Auth';
import { refreshToken } from './Session';
import { redirectToPortal } from './Helpers';
import { toast } from 'react-toastify';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKOFFICE_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With': 'XMLHttpRequest'
  },
  timeout: 60000
});

axiosRetry(instance, {
  retries: 3,
  retryDelay: exponentialDelay,
  retryCondition: axiosError =>
    // On network error, or HTTP status code between 500 and 599
    axiosError.code !== 'ECONNABORTED' &&
    (!axiosError.response ||
      (axiosError.response.status >= 500 && axiosError.response.status <= 599))
});

const requestHandler = async request => {
  const _slug = getSessionSlug();
  const TOKEN = cookie.load('token');
  const { exp } = jwt_decode(TOKEN);
  const now = (Date.now() / 1000) | 0; // Timestamp in seconds

  request.params
    ? (request.params._slug = _slug)
    : (request.params = { _slug });

  if (exp - now < 0) {
    try {
      await refreshToken();
    } catch (error) {
      redirectToPortal('?error=TokenExpired');
    }
  }

  request.headers.Authorization = `Bearer ${cookie.load('token')}`;
  return request;
};

const responseHandler = response => response;
const errorHandler = error => {
  if (error.response) {
    const { status } = error.response;
    if (status === 401 || status === 403) {
      /*
                      *Backoffice-api errors handling*
       TODO :
        Handle globally 422 errors using the server response as a feedback.
        A refactoring of the StatusBar component has to be done before, with proper redux
        or Context implementation. It will allow us to dispatch message to display the component
        with desired messages and className
       */

      status === 401
        ? redirectToPortal('?error=TokenExpired')
        : toast.warn("Vous n'êtes pas autorisé à modifier ce contenu.", {
            className: 'koba-danger-toast'
          });
    }
  }
  return Promise.reject(error);
};

// Add a request interceptor
instance.interceptors.request.use(requestHandler);
// Add a response interceptor
instance.interceptors.response.use(responseHandler, errorHandler);

export default instance;
