import surveysService from 'services/Surveys';
import Options from 'components/Options';
import BootstrapTable from 'react-bootstrap-table-next';
import React, { Component } from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import TagSearch from 'components/TagSearch';
import Spinner from 'components/Spinner';
import Axios from 'axios';
import {
  alertMessage,
  buildPaginationOptions,
  deleteItemFromArray
} from 'services/Utilities';
import { pageMatcher } from 'constants/Global';
import { Link } from 'react-router-dom';

const MAPPING = { true: 'archivedSurveys', false: 'publishedSurveys' };

class SurveysList extends Component {
  state = {
    publishedSurveys: [],
    archivedSurveys: [],
    filter: [],
    showArchived: false,
    loading: true
  };

  surveysService = new surveysService();

  getAllSurveys = () => {
    Axios.all([
      this.surveysService.getOnly('?scopes[published]'),
      this.surveysService.getOnly('?scopes[draft]'),
      this.surveysService.getOnly('?scopes[archived]')
    ]).then(
      Axios.spread(
        (
          { data: publishedSurveys },
          { data: draftSurveys },
          { data: archivedSurveys }
        ) => {
          const surveys = [...publishedSurveys, ...draftSurveys];
          this.setState({
            publishedSurveys: surveys,
            archivedSurveys,
            filter: surveys,
            loading: false
          });
        }
      )
    );
  };

  toggleData = () => {
    this.setState(prevState => ({
      filter: prevState[MAPPING[!prevState.showArchived]],
      showArchived: !prevState.showArchived
    }));
  };

  deleteSurvey = surveyId => {
    this.surveysService.delete(surveyId).then(({ data: _response }) => {
      const dataToHandle = MAPPING[this.state.showArchived];
      const surveys = deleteItemFromArray(
        [...this.state[dataToHandle]],
        surveyId
      );
      this.setState({ [dataToHandle]: surveys, filter: surveys });
      alertMessage('success', 'Le questionnaire a été supprimé', this);
    });
  };

  archiveSurvey = surveyId => {
    this.surveysService.archive(surveyId).then(({ data: response }) => {
      const publishedSurveys = this.state.publishedSurveys.filter(
        item => item.id !== surveyId
      );
      const archivedSurveys = [...this.state.archivedSurveys, response];

      this.setState({
        publishedSurveys,
        filter: publishedSurveys,
        archivedSurveys
      });
      alertMessage('success', 'Le questionnaire a été archivé', this);
    });
  };

  unarchiveSurvey = surveyId => {
    this.surveysService.unarchive(surveyId).then(({ data: response }) => {
      const archivedSurveys = this.state.archivedSurveys.filter(
        item => item.id !== surveyId
      );
      const publishedSurveys = [...this.state.publishedSurveys, response];

      this.setState({
        archivedSurveys,
        filter: archivedSurveys,
        publishedSurveys
      });
      alertMessage('success', 'Le questionnaire a été restauré', this);
    });
  };

  downloadSurveyResult = surveyId => {
    this.surveysService.download(surveyId).then(({ data: response }) => {
      alertMessage('success', response.message, this);
    });
  };

  optionsFormatter = (cell, row) => {
    const { showArchived } = this.state;

    return (
      <Options
        row={row}
        delete={this.deleteSurvey}
        label="les questions"
        downloadLabel="les réponses"
        kind="surveys"
        archiving={showArchived ? undefined : this.archiveSurvey}
        unarchiving={!showArchived ? undefined : this.unarchiveSurvey}
        download={this.downloadSurveyResult}
      />
    );
  };

  titleFormatter = (cell, row) => (
    <Link to={pageMatcher.admin.surveys.path + row.id}>{cell}</Link>
  );

  componentDidMount() {
    this.getAllSurveys();
  }

  filterList = event => {
    const eventTarget = event.target;
    const { showArchived } = this.state;
    const dataToFilter = MAPPING[showArchived];

    if (!eventTarget.value)
      this.setState(prevState => ({ filter: prevState[dataToFilter] }));

    this.setState(prevState => ({
      filter: prevState[dataToFilter].filter(item =>
        item.title.toLowerCase().includes(eventTarget.value.toLowerCase())
      )
    }));
  };

  render() {
    const optionsPagination = buildPaginationOptions();
    const columns = [
      {
        dataField: 'title',
        text: 'Nom',
        formatter: this.titleFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'state',
        text: 'Status',
        formatter: this.surveysService.statusFormatter,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'action',
        text: 'Actions',
        formatter: this.optionsFormatter,
        sort: false,
        headerStyle: { fontSize: 11 },
        align: 'right'
      }
    ];

    const { loading, showArchived, filter } = this.state;

    if (loading)
      return (
        <div className="text-center">
          <Spinner />
        </div>
      );

    return (
      <div>
        <TagSearch
          handleSearch={this.filterList}
          buttonTitle="Ajouter un questionnaire"
          buttonPlusLien={pageMatcher.admin.surveys.new.path}
          extraButton={
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={this.toggleData}
            >
              Voir les questionnaires {showArchived ? 'publiés' : 'archivés'}
            </button>
          }
        />
        <BootstrapTable
          keyField="id"
          data={filter}
          columns={columns}
          bordered={false}
          pagination={paginationFactory(optionsPagination)}
          classes="koba-array"
          filter={filterFactory()}
        />
      </div>
    );
  }
}

export default SurveysList;
