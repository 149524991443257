import React, { Component } from 'react';
import JobOfferForm from '../Form';
import JobOfferService, { validateJob } from 'services/JobOffers';
import {
  parseResponseForPublishBar,
  removeFalsyValueFromObject
} from 'services/Utilities';
import PublishBar from 'components/PublishBar';
import { pageMatcher } from 'constants/Global';

class EditJobOffer extends Component {
  state = {
    job: {
      title: '',
      kind: 'Stage',
      description: '',
      document: {
        title: '',
        data: ''
      }
    },
    showSaved: false,
    errorMessage: '',
    loading: false
  };

  jobOfferService = new JobOfferService();

  componentDidMount() {
    this.getJobOffer();
  }

  getJobOffer = () => {
    this.jobOfferService
      .get(this.props.match.params.id)
      .then(({ data: job }) => {
        removeFalsyValueFromObject(job);

        this.setState({ job });
      });
  };

  handleUpdate = e => {
    e.preventDefault();
    const { job } = this.state;

    // gestion des messages d'erreur.
    const localError = validateJob(job);

    if (localError) {
      parseResponseForPublishBar(localError, this, 'marginBottom');
    } else {
      if (job.delete_document) {
        delete job.document;
      }

      this.jobOfferService
        .putForm(this.props.match.params.id, job)
        .then(({ data: response }) => {
          parseResponseForPublishBar(response, this, 'marginBottom');
          this.props.history.push(pageMatcher.admin.content.jobOffers.path);
        });
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState(prevState => ({
      job: {
        ...prevState.job,
        [name]: value
      }
    }));
  };

  handleDocumentChange = event => {
    const target = event.target;
    const { name, value } = target.value;

    this.setState(({ job: { delete_document, ...job } }) => ({
      job: {
        ...job,
        document: {
          ...job.document,
          [name]: value
        }
      }
    }));
  };

  handleOnHTMLChange = html => {
    this.setState(prevState => ({
      job: {
        ...prevState.job,
        description: html
      }
    }));
  };

  handleFileChange = file => {
    this.setState(prevState => ({
      job: {
        ...prevState.job,
        document: {
          ...prevState.job.document,
          data: file
        }
      }
    }));
  };

  handleDeletePDF = () => {
    this.setState(state => ({
      job: {
        ...state.job,
        delete_document: true,
        document: { title: '', data: '' },
        document_url: ''
      }
    }));
  };

  render() {
    return (
      <>
        <JobOfferForm
          {...this.state}
          handleChange={this.handleChange}
          handleDocumentChange={this.handleDocumentChange}
          onHTMLChange={this.handleOnHTMLChange}
          handleFileChange={this.handleFileChange}
          required={false}
          handleDeletePDF={this.handleDeletePDF}
        />
        <PublishBar
          state={'published'}
          handleSubmit={this.handleUpdate}
          buttonDisabled={this.state.loading}
          goBackLink={pageMatcher.admin.content.jobOffers.path}
          goBackLinkTitle="Retourner à la liste"
        />
      </>
    );
  }
}

export default EditJobOffer;
