import moment from 'moment/moment';
import React from 'react';

export function humanizeDate(begin_at, end_at) {
  return `${moment(begin_at).format('DD MMMM YYYY HH:mm')} \u21FE ${moment(
    end_at
  ).format('DD MMMM YYYY HH:mm')}`;
}

export function monthOptions() {
  let months = [],
    i = 1;
  for (let month of moment.months()) {
    i = i < 10 ? `0${i}` : i;
    months.push(
      <option value={i} key={i}>
        {capitalize(month)}
      </option>
    );
    i++;
  }
  return months;
}

export function yearOptions() {
  let years = [];
  for (
    let i = moment()
      .subtract(9, 'years')
      .year();
    i <
    moment()
      .add(10, 'years')
      .year();
    i++
  ) {
    years.push(
      <option value={i} key={i}>
        {i}
      </option>
    );
  }
  return years;
}

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

export function redirectToPortal(queryParams = null) {
  window.location = `${process.env.REACT_APP_PORTAL_URL}${queryParams}`;
}
