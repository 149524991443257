import BubbleContainer from './BubbleContainer';
import React from 'react';

const Question = ({ question, handleDelete, handleSubmit }) => (
  <>
    <BubbleContainer
      type="left"
      text={question.text}
      question={question}
      handleDelete={_ => handleDelete(question.id)}
      handleSubmit={(e, answer) => handleSubmit(e, answer, question.id)}
    />
    {question.answer && (
      <BubbleContainer
        type="right"
        text={question.answer}
        handleSubmit={(e, answer) => handleSubmit(e, answer, question.id)}
      />
    )}
  </>
);

export default Question;
