/**
 *
 * App
 *
 * This component is app entry point
 */

// Import all the third party stuff
import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Helmet } from 'react-helmet';

// Import modules
import Admin from 'containers/Admin';
import SessionProtectedRoute from 'components/SessionProtectedRoute';
import BarStatusMessage from 'components/BarStatusMessage';

// Import css and js lib
import 'assets/styles/common.scss';
import 'assets/styles/toast.scss';
import 'bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { defaultPageTitle, pageMatcher } from 'constants/Global';

export const App = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{defaultPageTitle}</title>
    </Helmet>
    <Router>
      <Switch>
        <SessionProtectedRoute
          path={pageMatcher.admin.path}
          component={Admin}
          requiredSessions={['auth', 'api']}
        />
      </Switch>
    </Router>
    <ToastContainer />
    <BarStatusMessage />
  </>
);

export default App;
