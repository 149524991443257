import React, { Component } from 'react';
import PhotoAlbumService from 'services/PhotoAlbums';
import FormBase from '../FormBase';
import { parseResponseForPublishBar } from 'services/Utilities';
import PublishBar from 'components/PublishBar';
import { pageMatcher } from 'constants/Global';

export default class NewPhoto extends Component {
  state = {
    showSaved: false,
    loading: false,
    photo_album: {
      title: '',
      publication_date: null,
      description: '',
      cover_photo: null,
      photos: []
    }
  };

  photoAlbumService = new PhotoAlbumService();

  handleChange = (name, value) => {
    const newState = { ...this.state };
    newState.photo_album[name] = value;
    this.setState(newState);
  };

  handleChangePicker = data => {
    const newState = { ...this.state };
    newState.photo_album.publication_date = data.start;
    this.setState(newState);
  };

  handleChangeTextArea = html => {
    const newState = { ...this.state };
    newState.photo_album.description = html;
    this.setState(newState);
  };

  handleSubmit = event => {
    event.preventDefault();

    const { photo_album } = this.state;
    const localError = photo_album.cover_photo
      ? ''
      : Error('Veuillez séléctionner la photo de couverture');
    if (localError) {
      parseResponseForPublishBar(localError, this, 'marginBottom');
    } else {
      const photos = photo_album.photos.map(image => ({
        attachment_id: null,
        signed_id: image.signed_id
      }));

      this.postPhotoAlbum({
        ...photo_album,
        photos
      });
    }
  };

  postPhotoAlbum = data => {
    this.photoAlbumService.post(data).then(({ data: response }) => {
      parseResponseForPublishBar(response, this, 'marginBottom');
      if (response && response.id) {
        if (data.state === 'published') {
          this.photoAlbumService.publish(response.id).then(response => {
            this.props.history.push(
              pageMatcher.admin.content.photoLibrairies.path
            );
          });
        } else {
          this.props.history.push(
            pageMatcher.admin.content.photoLibrairies.path
          );
        }
      }
    });
  };

  handlePublish = status => {
    this.handleChange('state', status);
  };

  render() {
    const { photo_album, loading } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <FormBase
          {...photo_album}
          handleChange={this.handleChange}
          handleChangePicker={this.handleChangePicker}
          handleChangeTextArea={this.handleChangeTextArea}
        />
        <PublishBar
          handleStatusChange={this.handlePublish}
          state={photo_album.state}
          buttonDisabled={loading}
          goBackLink={pageMatcher.admin.content.photoLibrairies.path}
          goBackLinkTitle="Retourner à la liste"
          showSubmitButton
        />
      </form>
    );
  }
}
