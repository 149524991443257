import React, { Component } from 'react';
import AccessibilitiesBase from '../AccessibilitiesBase';
import Accessibility from 'services/Accessibility';
import Submenu from 'components/Submenu';
import * as Utilities from 'services/Utilities';
import PublishBar from 'components/PublishBar';
import { pageMatcher } from 'constants/Global';

export default class Accessibilite extends Component {
  state = {
    accessibility: {
      title: '',
      content: '',
      loading: true
    }
  };

  subMenuData = [pageMatcher.admin.accessibility];
  Accessibility = new Accessibility();

  componentDidMount() {
    this.getAccessibilite();
  }

  getAccessibilite = () => {
    this.Accessibility.get().then(({ data: response }) => {
      if (response) {
        this.setState({
          accessibility: {
            title: response.title,
            content: response.content
          },
          editMode: true,
          loading: false
        });
      }
    });
  };

  postAccessibility = data => {
    this.Accessibility.post(data).then(({ data: response }) => {
      Utilities.parseResponseForPublishBar(response, this, 'marginBottom');
    });
  };

  editAccessibility = data => {
    this.Accessibility.put(null, data).then(({ data: response }) => {
      Utilities.parseResponseForPublishBar(response, this, 'marginBottom');
    });
  };

  handleChangeTextArea = html => {
    let accessibility = { ...this.state.accessibility };
    accessibility.content = html;
    this.setState({ accessibility });
  };

  handleChange = event => {
    const newState = { ...this.state };
    newState.accessibility[event.target.name] = event.target.value;
    this.setState(newState);
  };

  handleSubmit = event => {
    event.preventDefault();
    this.state.editMode
      ? this.editAccessibility(this.state.accessibility)
      : this.postAccessibility(this.state.accessibility);
  };

  closeStatusMessage = () => {
    this.setState({ errorMessage: '', showSaved: false, loading: false });
  };

  render() {
    const { editMode, loading } = this.state;

    return (
      <>
        <Submenu subMenuData={this.subMenuData} />
        <AccessibilitiesBase
          {...this.state.accessibility}
          handleChangeTextArea={this.handleChangeTextArea}
          handleChange={this.handleChange}
          editeMode={editMode}
        />
        <PublishBar
          state={'published'}
          handleSubmit={this.handleSubmit}
          buttonDisabled={loading}
        />
      </>
    );
  }
}
