import Axios from './Axios';
import Ressource from 'services/Ressource';
import { Link } from 'react-router-dom';
import { pageMatcher } from '../constants/Global';
import React from 'react';

export default class PhotoAlbums extends Ressource {
  constructor() {
    super('photo_album');
  }

  publish = id => Axios.patch(`photo_albums/${id}/publish`);

  draft = id => Axios.patch(`photo_albums/${id}/draft`);

  titleFormatter = (cell, row) => (
    <Link to={pageMatcher.admin.content.photoLibrairies.path + row.id}>
      {cell}
    </Link>
  );

  buildColumns = (
    photoFormatter,
    dateFormatter,
    stateFormatter,
    optionsFormatter
  ) => [
    {
      dataField: 'cover_photo_url',
      text: 'Couverture',
      formatter: photoFormatter,
      headerStyle: { fontSize: 11 }
    },
    {
      dataField: 'title',
      text: `Titre de l'album`,
      formatter: this.titleFormatter,
      sort: true,
      headerStyle: { fontSize: 11 }
    },
    {
      dataField: 'created_at',
      text: 'Date de création',
      formatter: dateFormatter,
      sort: true,
      headerStyle: { fontSize: 11 }
    },
    {
      dataField: 'author',
      text: 'Auteur',
      sort: true,
      headerStyle: { fontSize: 11 }
    },
    {
      dataField: 'state',
      text: 'Etat',
      formatter: stateFormatter,
      sort: true,
      headerStyle: { fontSize: 11 }
    },
    {
      dataField: 'action',
      text: 'Actions',
      formatter: optionsFormatter,
      headerStyle: { fontSize: 11 },
      align: 'right'
    }
  ];
}
