import Axios from 'services/Axios';

class Ressource {
  constructor(type, singularRessource = false) {
    this.type = type;
    this.singularRessource = singularRessource;
    this.parseType();
  }

  parseType() {
    switch (true) {
      case this.singularRessource:
        this.typePlurial = this.type;
        break;
      case this.type === 'directory_address':
        this.typePlurial = 'directory_addresses';
        break;
      case this.type === 'category':
        this.typePlurial = 'categories';
        break;
      default:
        this.typePlurial =
          this.type.slice(-1) === 's' ? this.type : this.type + 's';
        break;
    }
  }

  get = (id = null, stringParams = null) =>
    Axios.get(this.typePlurial + (id ? '/' + id : '') + (stringParams || ''));

  getOnly = type => Axios.get(this.typePlurial + type);

  post = data => Axios.post(this.typePlurial, { [this.type]: data });

  deleteAttachment = (data, url_id, action) => {
    const formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const url = this.typePlurial + '/' + url_id + '/' + action;

    return Axios({
      url,
      method: 'delete',
      data: formData,
      headers: config
    });
  };

  postInPromiseAll = data =>
    Axios.post(this.typePlurial, { [this.type]: data });

  // TODO: Merge the 2 methods below into one.
  postForm(data) {
    const formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      if (Object.prototype.toString.call(value) === '[object Object]') {
        for (let [key2, value2] of Object.entries(value)) {
          formData.append(this.type + '[' + key + '][' + key2 + ']', value2);
        }
      } else if (Object.prototype.toString.call(value) === '[object Array]') {
        for (let value2 of value) {
          formData.append(this.type + '[' + key + '][]', value2);
        }
      } else {
        formData.append(this.type + '[' + key + ']', value);
      }
    }

    return Axios.post(this.typePlurial, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  putForm(id, data) {
    const formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      if (Object.prototype.toString.call(value) === '[object Object]') {
        for (let [key2, value2] of Object.entries(value)) {
          formData.append(this.type + '[' + key + '][' + key2 + ']', value2);
        }
      } else if (Object.prototype.toString.call(value) === '[object Array]') {
        for (let value2 of value) {
          formData.append(this.type + '[' + key + '][]', value2);
        }
      } else {
        formData.append(this.type + '[' + key + ']', value);
      }
    }

    return Axios.put(this.typePlurial + '/' + id, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  duplicate = (id, title = null) => {
    const data = {
      [this.type]: {
        title: title
      }
    };

    return Axios.post(
      this.typePlurial + (id ? '/' + id : '') + '/duplicate',
      data
    );
  };

  put = (id, data, option = null) =>
    Axios.put(
      this.typePlurial + (id ? '/' + id : '') + (option ? '/' + option : ''),
      { [this.type]: data }
    );

  patch = (id, data, option = null) =>
    Axios.patch(
      this.typePlurial + (id ? '/' + id : '') + (option ? '/' + option : ''),
      { [this.type]: data }
    );

  putInPromiseAll = (id, data, option = null) =>
    Axios.put(
      this.typePlurial + (id ? '/' + id : '') + (option ? '/' + option : ''),
      {
        [this.type]: data
      }
    );

  download = (id, option = null) =>
    Axios.post(
      this.typePlurial + (id ? '/' + id : '') + (option ? '/' + option : '')
    );

  change = (id, option = null) =>
    Axios.put(
      this.typePlurial + (id ? '/' + id : '') + (option ? '/' + option : '')
    );

  delete = id => Axios.delete(this.typePlurial + '/' + id);

  deleteInPromiseAll = id => Axios.delete(this.typePlurial + '/' + id);

  categories = (formatForReactSelect = false) =>
    Axios.get('categories?kind=' + this.type)
      .then(function(response) {
        if (!formatForReactSelect) {
          return response.data;
        } else {
          for (let cat of response.data) {
            cat.value = cat.id;
            cat.label = cat.name;
          }
          return { options: response.data };
        }
      })
      .catch(error => error);
}

export default Ressource;
