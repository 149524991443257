import React from 'react';
import Submenu from 'components/Submenu';
import { Route, Switch } from 'react-router-dom';
import SurveyForm from './SurveyForm';
import SurveyList from './SurveyList';
import { pageMatcher } from 'constants/Global';

export const Surveys = props => {
  const subMenuData = [pageMatcher.admin.surveys];

  return (
    <>
      <Submenu subMenuData={subMenuData} />
      <Switch>
        <Route
          path={pageMatcher.admin.surveys.new.path}
          render={() => <SurveyForm {...props} />}
        />
        <Route
          path={pageMatcher.admin.surveys.single.path}
          render={_props => <SurveyForm {...props} {..._props} />}
        />
        <Route
          path={pageMatcher.admin.surveys.path}
          render={() => <SurveyList {...props} />}
        />
      </Switch>
    </>
  );
};
