import React, { Component } from 'react';
import _ from 'lodash';
import Spinner from 'components/Spinner';
import withCampaign from '../WithCampaign';
import {
  fetchOngoingCampaign,
  updateCampaign,
  deleteCampaign
} from 'redux/actions/campaignActions';

class ShowCurrentCampaign extends Component {
  componentDidMount() {
    this.props.fetchOngoingCampaign();
  }

  render() {
    const { loading, onGoingCampaign } = this.props;

    if (loading) {
      return (
        <div className="col card-koba mb-4">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="col card-koba mb-4">
        {!loading && <h5>Alerte en cours</h5>}
        {!loading && _.isEmpty(onGoingCampaign) ? (
          <div>
            <br />
            <p className="text-muted">Il n'y a pas d'alerte en cours</p>
          </div>
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ campaign }) => ({
  onGoingCampaign: campaign.onGoingCampaign,
  loading: campaign.onGoingCampaignLoading
});

const mapDispatchToProps = {
  fetchOngoingCampaign,
  updateCampaign,
  deleteCampaign
};
const campaignParameters = {
  type: 'onGoing',
  mapStateToProps,
  mapDispatchToProps
};

export default withCampaign(ShowCurrentCampaign, campaignParameters);
