import draftToHtml from 'draftjs-to-html';
import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import { createFromBlockArray } from 'draft-js/lib/ContentState';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.scss';
import htmlToDraft from 'html-to-draftjs';

// recognize "https://google.com", "https://www.google.com" and "www.google.com"
// const regexUrl = new RegExp(
//   /(?<!"|">)(((https?:\/\/(www\.)?)|(www\.))\w+\.\w+)(?=[\s&<,.])/,
//   'g'
// );

export default class EditorConvertToHTML extends Component {
  state = {
    editorState: EditorState.createEmpty()
  };

  getEditorState = () => {
    const { initValue } = this.props;
    if (initValue) {
      const blocksFromHTML = htmlToDraft(initValue);
      const { contentBlocks, entityMap } = blocksFromHTML;
      const contentState = createFromBlockArray(contentBlocks, entityMap);

      this.setState({
        editorState: EditorState.createWithContent(contentState)
      });
    }
  };

  onEditorStateChange = editorState => {
    const contentState = editorState.getCurrentContent();
    const html = draftToHtml(convertToRaw(contentState));

    // recognize "https://google.com", "https://www.google.com" and "www.google.com"
    // const linkDetect = regexUrl.test(html);
    // let newHtml;
    // if (linkDetect) {
    //   newHtml = (html || '').replace(regexUrl, function(match) {
    //     const newUrl = /(https?:\/\/)/.test(match) ? match : `https://${match}`;
    //     return `<a href="${newUrl}" target="_self">${match}</a>`;
    //   });
    // }

    this.setState({ editorState });
    // this.props.onHTMLChange(newHtml ? newHtml : html);
    this.props.onHTMLChange(html);
  };

  /**
   * Handle the page.blocks case
   */
  componentDidMount() {
    this.getEditorState();
  }

  /**
   * Handle the accessibility, legal mentions, and directory addresses cases
   */
  componentDidUpdate(prevProps) {
    // re-compute editorState only in the case it was null before
    if (!prevProps.initValue && this.props.initValue) {
      this.getEditorState();
    }
  }

  render() {
    const { editorState } = this.state;
    return (
      <Editor
        toolbar={toolbarOptions}
        wrapperClassName="editor-wrapper"
        editorClassName="editor-editor"
        editorState={editorState}
        onEditorStateChange={this.onEditorStateChange}
        stripPastedStyles={true}
      />
    );
  }
}

const toolbarOptions = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'embedded',
    'emoji',
    'remove',
    'history'
  ],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'monospace',
      'superscript',
      'subscript'
    ]
  },
  blockType: {
    inDropdown: true,
    options: [
      'Normal',
      'H1',
      'H2',
      'H3',
      'H4',
      'H5',
      'H6',
      'Blockquote',
      'Code'
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered', 'indent', 'outdent']
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['left', 'center', 'right', 'justify']
  },
  colorPicker: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: [
      'rgb(97,189,109)',
      'rgb(26,188,156)',
      'rgb(84,172,210)',
      'rgb(44,130,201)',
      'rgb(147,101,184)',
      'rgb(71,85,119)',
      'rgb(204,204,204)',
      'rgb(65,168,95)',
      'rgb(0,168,133)',
      'rgb(61,142,185)',
      'rgb(41,105,176)',
      'rgb(85,57,130)',
      'rgb(40,50,78)',
      'rgb(0,0,0)',
      'rgb(247,218,100)',
      'rgb(251,160,38)',
      'rgb(235,107,86)',
      'rgb(226,80,65)',
      'rgb(163,143,132)',
      'rgb(239,239,239)',
      'rgb(255,255,255)',
      'rgb(250,197,28)',
      'rgb(243,121,52)',
      'rgb(209,72,65)',
      'rgb(184,49,47)',
      'rgb(124,112,107)',
      'rgb(209,213,216)'
    ]
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_self',
    options: ['link', 'unlink']
  },
  embedded: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    embedCallback: undefined,
    defaultSize: {
      height: 'auto',
      width: 'auto'
    }
  },
  emoji: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    emojis: [
      '😀',
      '😁',
      '😂',
      '😃',
      '😉',
      '😋',
      '😎',
      '😍',
      '😗',
      '🤗',
      '🤔',
      '😣',
      '😫',
      '😴',
      '😌',
      '🤓',
      '😛',
      '😜',
      '😠',
      '😇',
      '😷',
      '😈',
      '👻',
      '😺',
      '😸',
      '😹',
      '😻',
      '😼',
      '😽',
      '🙀',
      '🙈',
      '🙉',
      '🙊',
      '👼',
      '👮',
      '🕵',
      '💂',
      '👳',
      '🎅',
      '👸',
      '👰',
      '👲',
      '🙍',
      '🙇',
      '🚶',
      '🏃',
      '💃',
      '⛷',
      '🏂',
      '🏌',
      '🏄',
      '🚣',
      '🏊',
      '⛹',
      '🏋',
      '🚴',
      '👫',
      '💪',
      '👈',
      '👉',
      '👉',
      '👆',
      '🖕',
      '👇',
      '🖖',
      '🤘',
      '🖐',
      '👌',
      '👍',
      '👎',
      '✊',
      '👊',
      '👏',
      '🙌',
      '🙏',
      '🐵',
      '🐶',
      '🐇',
      '🐥',
      '🐸',
      '🐌',
      '🐛',
      '🐜',
      '🐝',
      '🍉',
      '🍄',
      '🍔',
      '🍤',
      '🍨',
      '🍪',
      '🎂',
      '🍰',
      '🍾',
      '🍷',
      '🍸',
      '🍺',
      '🌍',
      '🚑',
      '⏰',
      '🌙',
      '🌝',
      '🌞',
      '⭐',
      '🌟',
      '🌠',
      '🌨',
      '🌩',
      '⛄',
      '🔥',
      '🎄',
      '🎈',
      '🎉',
      '🎊',
      '🎁',
      '🎗',
      '🏀',
      '🏈',
      '🎲',
      '🔇',
      '🔈',
      '📣',
      '🔔',
      '🎵',
      '🎷',
      '💰',
      '🖊',
      '📅',
      '✅',
      '❎',
      '💯'
    ]
  },

  history: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['undo', 'redo']
  }
};
