import React, { Component } from 'react';
import ExternalLinkForm from './ExternalLinkForm';
import ResourcePicker from './ResourcePicker';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import RadioButton from 'components/RadioButton';
import './index.scss';

const resourceTypeTitles = {
  article: 'Articles',
  event: 'Evénements',
  news: 'Actualités',
  kiosk: 'Kiosque',
  photo_album: 'Albums photos',
  category: 'Annuaire',
  online_procedure: 'Procédures en ligne',
  job: 'Emplois',
  survey: 'Sondage'
};

/**
 * value example for internal links: {
 *   internal: true,
 *   resourceType: enum(article|event|news|kiosk|photo_album),
 *   id: ?String,
 *   title: ?String
 * }
 * value example for external links: {
 *   internal: false,
 *   title: ?String,
 *   url: Url
 * }
 */
export default class ModalSelectLink extends Component {
  static propTypes = {
    value: PropTypes.object,
    onSelect: PropTypes.func,
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool,
    noTitle: PropTypes.bool
  };

  state = {
    value: null,
    leftColumnSelected: null
  };

  /**
   * This hook is important, the modal is often mounted on first page load,
   * then when updating props dynamically (like in the carousel slider)
   * we need to set automatically the selected page type on the left by deriving a state from props
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    const { isOpen, value } = this.props;
    if (isOpen && isOpen !== prevProps.isOpen) {
      // Derive state each time the modal is opened
      this.setState({
        value,
        leftColumnSelected:
          value && (value.resourceType || (!value.internal && ''))
      });
    }
  }

  handleChange = value => {
    this.setState({ value });
  };

  handleSelect = () => {
    const { value } = this.state;

    this.props.onSelect(value);
    this.setState({
      value: null,
      leftColumnSelected: null
    });
  };

  selectLeftColumn = resourceType => {
    this.setState(state => ({
      value: null,
      leftColumnSelected: resourceType
    }));
  };

  render() {
    const { isOpen, noTitle } = this.props;
    const onCancel = () => {
      this.setState({ value: null, leftColumnSelected: null });
      this.props.onCancel();
    };
    const { value, leftColumnSelected } = this.state;
    return (
      <Modal
        className="modalSelectLink"
        isOpen={isOpen}
        size="lg"
        toggle={onCancel}
      >
        <ModalHeader toggle={onCancel}>Choisissez votre page</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-4 right-border">
              {Object.entries(resourceTypeTitles).map(([key, title]) => (
                <RadioButton
                  key={key}
                  id={value}
                  onChange={e => this.selectLeftColumn(e.target.value)}
                  value={key}
                  checked={leftColumnSelected === key}
                  text={title}
                  divClass="custom-radio mb-2"
                />
              ))}
              <RadioButton
                id="lien-externe"
                onChange={e => this.selectLeftColumn('')}
                value={''}
                checked={leftColumnSelected === ''}
                text="Lien externe"
                divClass="custom-radio mb-2"
              />
            </div>
            <div className="col-md-8">
              {leftColumnSelected === '' && (
                <ExternalLinkForm
                  value={value}
                  onChange={this.handleChange}
                  noTitle={noTitle}
                />
              )}
              {leftColumnSelected &&
                leftColumnSelected !== '' && (
                  <ResourcePicker
                    value={value}
                    resourceType={leftColumnSelected}
                    onChange={this.handleChange}
                  />
                )}
            </div>
          </div>
        </ModalBody>

        <ModalFooter className="flex-md-row flex-column">
          {value &&
            (value.title || value.url) && (
              <div className="mr-md-2 mr-lg-4">
                <span className="font-weight-bold">Vous avez choisi : </span>
                <span className="text-primary">
                  {value && (value.title || value.url)}
                </span>
              </div>
            )}
          <div className=" mt-4 mt-md-0">
            <button
              type="button"
              onClick={onCancel}
              className="btn btn-outline-primary float-left mr-2 "
            >
              ANNULER
            </button>
            <button
              type="submit"
              onClick={this.handleSelect}
              className="btn btn-primary float-right ml-2"
              disabled={!value}
            >
              VALIDER
            </button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}
