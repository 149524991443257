import React, { Component } from 'react';

class Video extends Component {
  render() {
    const {
      data: { url },
      updateSubBlock
    } = this.props;

    return (
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Lien de la vidéo</label>
            <input
              value={url}
              onChange={e => updateSubBlock('url', e.target.value)}
              name="url"
              type="url"
              className="form-control"
              aria-describedby="titleHelp"
              placeholder="Lien"
              aria-label="Lien"
            />
            <small id="titleHelp" className="form-text text-muted">
              Un lien doit etre préfixé de "http" ou "https" <br />
              Liens acceptés (youtube.com, youtu.be)
            </small>
          </div>
        </div>
      </div>
    );
  }
}

export default Video;
