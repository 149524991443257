import React, { Component } from 'react';
import KioskService from 'services/Kiosk';
import KioskForm from '../Form';
import {
  parseResponseForPublishBar,
  removeFalsyValueFromObject
} from 'services/Utilities';
import { pageMatcher } from 'constants/Global';
import { ModalLoading } from 'components/ModalLoading';

class KioskLayout extends Component {
  state = {
    kiosk: {
      id: '',
      title: '',
      publication_date: '',
      author: '',
      description: '',
      pdf_title: '',
      flipbook_title: '',
      flipbook_link: '',
      file: '',
      file_url: '',
      image: '',
      image_url: '',
      highlight_at: '',
      deleted_at: '',
      state: '',
      category_id: ''
    },
    loading: true
  };

  kioskService = new KioskService();
  id = this.props.match.params.id;

  getKiosk = id => {
    if (!id) return this.setState({ loading: false });

    this.kioskService.getKiosk(id).then(({ data: response }) => {
      let kiosk = this.kioskService.formatKiosk(response);
      kiosk.category_id = kiosk.category && kiosk.category.id;
      this.setState({ kiosk, loading: false });
    });
  };

  componentDidMount() {
    this.getKiosk(this.id);
  }

  handleCatChange = category => {
    /*
     Prevent an error from happening by doing so. If a user clicks on the input
     and proceeds to press on the delete key, the callback function returns null
     */
    if (category === null) return;

    this.setState(({ kiosk }) => ({
      kiosk: {
        ...kiosk,
        category_id: category.id
      }
    }));
  };

  handleFileChange = (file, url, name) => {
    this.setState(({ kiosk }) => ({
      kiosk: {
        ...kiosk,
        [name]: file
      }
    }));
  };

  deleteImage = () => {
    this.setState(({ kiosk }) => ({
      kiosk: {
        ...kiosk,
        image: null,
        delete_image: true
      }
    }));
  };

  handleChange = event => {
    const { name, value } = event.target;

    this.setState(({ kiosk }) => ({
      kiosk: {
        ...kiosk,
        [name]: value
      }
    }));
  };

  handleChangePicker = ({ start }) => {
    this.setState(({ kiosk }) => ({
      kiosk: {
        ...kiosk,
        publication_date: start
      }
    }));
  };

  handleSubmit = e => {
    e.preventDefault();
    const { id, ...kiosk } = this.state.kiosk;
    removeFalsyValueFromObject(kiosk);
    const promise = id
      ? this.kioskService.putForm(id, kiosk)
      : this.kioskService.postForm(kiosk);

    promise.then(({ data: response }) => {
      parseResponseForPublishBar(response, this, true);
      this.props.history.push(pageMatcher.admin.content.kiosks.path);
    });
  };

  render() {
    const { kiosk, loading } = this.state;

    if (loading) return <ModalLoading loading={loading} />;

    return (
      <KioskForm
        kiosk={kiosk}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        handleFileChange={this.handleFileChange}
        deleteImage={this.deleteImage}
        handleChangePicker={this.handleChangePicker}
        handleCatChange={this.handleCatChange}
        goBackLink={pageMatcher.admin.content.kiosks.path}
        goBackLinkTitle="Retourner à la liste"
      />
    );
  }
}

export default KioskLayout;
