import BootstrapTable from 'react-bootstrap-table-next';
import CategoryService from 'services/Category';
import filterFactory from 'react-bootstrap-table2-filter';
import Loading from 'components/Loading';
import Options from 'components/Options';
import paginationFactory from 'react-bootstrap-table2-paginator';
import React, { Component } from 'react';
import TagSearch from 'components/TagSearch';
import {
  parseResponseForPublishBar,
  buildPaginationOptions,
  deleteItemFromArray
} from 'services/Utilities';
import { pageMatcher } from 'constants/Global';

export default class Sections extends Component {
  state = {
    allCategories: [],
    categories: [],
    loading: true
  };

  categoryService = new CategoryService();
  procedureService = new this.props.ProcedureService();

  get = async () => {
    const categories = await this.procedureService.categories();
    this.setState({ categories, allCategories: categories, loading: false });
  };

  deleteCategory = async id => {
    try {
      const { data: response } = await this.categoryService.delete(id);
      parseResponseForPublishBar(response, this, 'marginBottom');
      const categories = deleteItemFromArray([...this.state.categories], id);
      this.setState({ categories });
    } catch (e) {
      parseResponseForPublishBar(
        Error(
          'Cette catégorie est rattachée à un ou plusieurs éléments. Pour la supprimer, veuillez les dissocier.'
        ),
        this,
        true
      );
    }
  };

  filterList = event => {
    const value = event.target.value.toLowerCase();
    this.setState(prevState => ({
      categories: prevState.allCategories.filter(item =>
        item.name.toLowerCase().includes(value)
      )
    }));
  };

  optionsFormatter = (_cell, row) => (
    <Options
      row={row}
      delete={this.deleteCategory}
      label="la catégorie"
      kind={this.props.kind}
    />
  );

  componentDidMount() {
    this.get();
  }

  render() {
    const optionsPagination = buildPaginationOptions();
    const columns = [
      {
        dataField: 'name',
        text: 'Section',
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'action',
        text: 'Actions',
        formatter: this.optionsFormatter,
        sort: false,
        headerStyle: { fontSize: 11 },
        align: 'right'
      }
    ];

    const isProcedure = this.props.kind === 'category_procedures';
    const { categories, loading } = this.state;

    return (
      <div>
        <TagSearch
          handleSearch={this.filterList}
          buttonTitle="Ajouter une catégorie"
          buttonPlusLien={
            isProcedure
              ? pageMatcher.admin.onlineProcedures.categories.new.path
              : pageMatcher.admin.addresses.categories.new.path
          }
        />
        <BootstrapTable
          keyField="id"
          data={categories}
          columns={columns}
          bordered={false}
          pagination={paginationFactory(optionsPagination)}
          classes="koba-array"
          filter={filterFactory()}
        />
        <Loading loading={loading} />
      </div>
    );
  }
}
