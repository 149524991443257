import pageMatcher from '../PageMatcher';
import contentTypes from '../ContentTypes';

export const defaultPageTitle = 'Koba Civique';
export { pageMatcher };
export { contentTypes };

export const websiteSubMenu = {
  title: pageMatcher.admin.website.pageTitle,
  elements: [
    pageMatcher.admin.menu,
    pageMatcher.admin.homepage,
    pageMatcher.admin.content,
    pageMatcher.admin.addresses,
    pageMatcher.admin.onlineProcedures,
    pageMatcher.admin.surveys,
    pageMatcher.admin.chat,
    pageMatcher.admin.socialNetworks,
    pageMatcher.admin.rss,
    pageMatcher.admin.accessibility,
    pageMatcher.admin.legal
  ]
};

export const settingsSubHeader = [
  // pageMatcher.superAdmin.users.single, => 'Mon compte'
  pageMatcher.admin.account.pack,
  pageMatcher.superAdmin.users,
  pageMatcher.superAdmin.settings
];

export const acceptance = {
  images: 'image/*',
  pdf: 'application/pdf'
};
