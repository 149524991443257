import React from 'react';
import './index.scss';

const PhotoPreview = props => {
  return (
    <div className="preview">
      <div className="tb">
        <div
          className="tbc text-center"
          style={{
            backgroundSize: 'contain',
            backgroundImage: "url('" + encodeURI(props.image.url) + "')"
          }}
        />
      </div>
    </div>
  );
};

export default PhotoPreview;
