import React from 'react';
import Filedrop from 'components/Filedrop';

export default class index extends React.Component {
  render() {
    const {
      data,
      handleChange,
      handleFileChange,
      deleteDocSubBlockFile,
      showDocument,
      addDocument
    } = this.props;

    return (
      <div className="row">
        <div className="col-md-6 col-xs-12">
          <div className="form-group">
            <label htmlFor="">Titre</label>
            <input
              value={data.title || ''}
              name="title"
              onChange={handleChange}
              type="title"
              className="form-control"
              aria-describedby="titleHelp"
              placeholder="Titre"
              aria-label="Titre"
            />
            <small id="titleHelp" className="form-text text-muted">
              Titre de votre document.
            </small>
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          {data.documents &&
            data.documents.map((doc, index) => (
              <Filedrop
                key={index}
                index={index}
                accepts="pdf"
                name="document"
                initValue={doc.url}
                onFileChange={handleFileChange}
                deleteDocSubBlockFile={deleteDocSubBlockFile}
              />
            ))}
          {showDocument && (
            <Filedrop name="document" onFileChange={handleFileChange} />
          )}
        </div>
        <button
          type="button"
          onClick={addDocument}
          className={'btn btn-outline-primary  m-3'}
        >
          Ajouter nouveau document
        </button>
      </div>
    );
  }
}
