import React, { Component } from 'react';
import Toggle from 'components/Toggle';
import PreviewModal from 'components/PreviewModal';
import './index.scss';
import { Link } from 'react-router-dom';

class PublishBar extends Component {
  handleStatusChange = () => {
    if (this.props.handleStatusChange) {
      let newStatus = this.props.state === 'published' ? 'draft' : 'published';
      this.props.handleStatusChange(newStatus);
    }
  };

  render() {
    const {
      loading,
      state,
      handleStatusChange,
      goBackLink,
      cancelEdition,
      buttonDisabled,
      handleSubmit,
      goBackLinkTitle,
      previewUrl,
      showSubmitButton
    } = this.props;
    const class_name =
      'btn ml-2 ' +
      (state === 'published' ? 'btn-light' : 'btn-outline-primary');
    return (
      <div className="publish-bar">
        <div
          className={
            'card-save p-2 p-md-3 d-flex flex-row justify-content-between align-items-center ' +
            (state === 'published' ? 'bg-published' : 'bg-draft')
          }
        >
          {handleStatusChange && (
            <div>
              <Toggle
                checked={state === 'published'}
                name="state"
                onChange={this.handleStatusChange}
              />
              <span className="font-italic align-middle ml-3">
                {state === 'published' ? 'En Ligne' : 'Hors Ligne'}
              </span>
            </div>
          )}
          <div className={loading ? 'disabled' : ''}>
            {goBackLink && (
              <Link to={goBackLink} tabIndex={-1}>
                <button className={class_name} type="button">
                  {goBackLinkTitle || 'Retour'}
                </button>
              </Link>
            )}
            {cancelEdition && (
              <button
                tabIndex={0}
                onClick={cancelEdition}
                disabled={buttonDisabled}
                className={class_name}
                type="button"
              >
                Annuler
              </button>
            )}
            {previewUrl && (
              <PreviewModal previewUrl={previewUrl} state={state} />
            )}
            {(handleSubmit || showSubmitButton) && (
              <button
                tabIndex={0}
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
                className={class_name}
              >
                Enregistrer
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PublishBar;
