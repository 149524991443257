import AddressService from 'services/Adresses';
import Options from 'components/Options';
import BootstrapTable from 'react-bootstrap-table-next';
import React, { Component } from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import TagSearch from 'components/TagSearch';
import Spinner from 'components/Spinner';
import { parseResponseForPublishBar, alertMessage } from 'services/Utilities';
import { pageMatcher } from 'constants/Global';
import { deleteItemFromArray } from 'services/Utilities';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const ImportDropDown = ({ onImport, onExport }) => (
  <>
    <button
      className="btn btn-outline-primary mr-2 dropdown-toggle"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Importer
    </button>
    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a className="dropdown-item" href="#" onClick={onImport}>
        Importer des adresses
      </a>
      <a
        className="dropdown-item"
        href={`${process.env.REACT_APP_BACKOFFICE_API}/import_annuaire.csv`}
      >
        Télécharger un fichier d'exemple
      </a>
    </div>
    <button className="btn btn-outline-primary mr-2" onClick={onExport}>
      Exporter
    </button>
  </>
);

class AddressesList extends Component {
  state = {
    allAddresses: [],
    addresses: [],
    loading: true
  };

  addressService = new AddressService();

  getAddresses = () => {
    this.addressService
      .getOnly('?sort[updated_at]')
      .then(({ data: response }) => {
        this.setState({
          addresses: response,
          allAddresses: response,
          loading: false
        });
      });
  };

  deleteAddress = id => {
    this.addressService.delete(id).then(({ data: _response }) => {
      const updatedAddresses = deleteItemFromArray(
        [...this.state.addresses],
        id
      );
      this.setState({ addresses: updatedAddresses });
    });
  };

  companyFormatter = (cell, row) => (
    <Link to={pageMatcher.admin.addresses.annuaire.path + row.id}>
      {row.company_name
        ? row.company_name +
          (row.last_name ? ` (${row.first_name} ${row.last_name})` : '')
        : `${row.first_name} ${row.last_name}`}
    </Link>
  );

  optionsFormatter = (cell, row) => (
    <Options
      row={row}
      delete={this.deleteAddress}
      label="l'adresse"
      kind="addresses"
      addButton={false}
    />
  );

  componentDidMount() {
    this.getAddresses();
  }

  filterList = event => {
    const updatedList = this.state.allAddresses.filter(item => {
      //a category can be deleted (item.category  can be null)
      return (
        item.company_name
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1 ||
        item.first_name
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1 ||
        item.last_name
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1 ||
        (item.category !== null &&
          item.category.name
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) !== -1)
      );
    });
    this.setState({
      addresses: updatedList
    });
  };

  importTable = e => {
    const file = e.target.files[0];

    if (file) {
      let data = new FormData();
      data.append('directory_address[import]', file);

      this.addressService
        .importAddresses(data)
        .then(_response =>
          alertMessage(
            'success',
            "L'import a été lancé. L'opération peut prendre plusieurs minutes.",
            this,
            'marginBottom'
          )
        )
        .catch(e => parseResponseForPublishBar(e, this, 'marginBottom'));
    }
  };

  render() {
    const optionsPagination = {
      page: 1,
      paginationSize: 4,
      pageStartIndex: 1,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true,
      nextPageTitle: 'Prochaine page',
      prePageTitle: 'Page précédente'
    };

    const columns = [
      {
        dataField: 'company_name',
        text: 'Nom ou raison sociale',
        formatter: this.companyFormatter,
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'street_name',
        text: 'Adresse postale',
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'category.name',
        text: 'Métier / Catégorie',
        sort: true,
        headerStyle: { fontSize: 11 }
      },
      {
        dataField: 'action',
        text: 'Actions',
        formatter: this.optionsFormatter,
        sort: false,
        headerStyle: { fontSize: 11 },
        align: 'right'
      }
    ];

    const onImport = _ => {
      const fileElem = document.getElementById('fileElem');
      if (fileElem) {
        fileElem.click();
      }
    };

    const onExport = () => {
      this.addressService
        .export()
        .then(response => {
          const {
            data: { message }
          } = response;
          toast.info(message);
        })
        .catch(e => {
          const {
            response: { data }
          } = e;
          toast.error(data.message);
        });
    };

    const { loading, addresses } = this.state;

    return (
      <>
        <input
          type="file"
          id="fileElem"
          accept="text/csv"
          style={{ display: 'none' }}
          onChange={this.importTable}
        />
        <TagSearch
          handleSearch={this.filterList}
          buttonTitle="Ajouter une adresse"
          buttonPlusLien={pageMatcher.admin.addresses.annuaire.new.path}
          extraButton={
            <ImportDropDown onImport={onImport} onExport={onExport} />
          }
        />
        <BootstrapTable
          keyField="id"
          data={addresses}
          noDataIndication="Aucune adresse renseignée pour le moment"
          columns={columns}
          bordered={false}
          pagination={paginationFactory(optionsPagination)}
          classes="koba-array"
          filter={filterFactory()}
        />
        {loading && (
          <div className="text-center">
            <Spinner />
          </div>
        )}
      </>
    );
  }
}

export default AddressesList;
