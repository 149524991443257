import Ressource from 'services/Ressource';
import Axios from 'services/Axios';

export default class Categories extends Ressource {
  constructor() {
    super('category');
  }

  // get the address for one category
  getAddress(categoryId) {
    return Axios.get('directory_addresses?category_id=' + categoryId);
  }
}
