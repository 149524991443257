import { Redirect, Route, Switch } from 'react-router-dom';
import Account from './Account';
import Citizens from './Citizens';
import Header from 'components/Header';
import Home from './Home';
import React, { Component } from 'react';
import SideBar from 'components/SideBar';
import Website from './Website';
import { pageMatcher } from 'constants/Global';
import Layout from 'components/Layout/';
import { providePageData } from 'services/Utilities';
import Users from './Account/Users';
import UserForm from './Account/Users/Form';
import Settings from './Account/Settings';
import './index.scss';

/*
  TODO: Remove the state
    - Turn this class into a functional component
    - Make sure that the Header component gets (user + townHallSlug) info
      from localStorage
 */
class Admin extends Component {
  state = {
    userName: localStorage.getItem('currentUserName'),
    userLastName: localStorage.getItem('currentUserLastname'),
    townhallSlug: localStorage.getItem('townhallSlug')
  };

  render() {
    return (
      <>
        <Layout pageTitle={providePageData(this).pageTitle} />
        <Header
          {...this.props}
          firstname={this.state.userName}
          lastname={this.state.userLastName}
        />
        <div className="container-fluid">
          <div className="row">
            <SideBar
              type="townHall"
              townhall={this.state.townhallSlug}
              location={this.props.location}
            />
            <main
              role="main"
              className="main col-md-10 ml-sm-auto col-lg-10 pt-0 px-0"
            >
              <div className="content px-4">
                <Switch>
                  {/* <Redirect
                    from={pageMatcher.root.path}
                    to={pageMatcher.admin.home.stats.path}
                    exact={true}
                  /> */}
                  <Route
                    path={pageMatcher.admin.account.path}
                    render={() => <Account {...this.props} />}
                  />
                  <Route
                    path={pageMatcher.superAdmin.users.single.path}
                    render={props => <UserForm {...this.props} {...props} />}
                  />
                  <Route
                    path={pageMatcher.superAdmin.users.path}
                    render={() => <Users {...this.props} />}
                  />
                  <Route
                    path={pageMatcher.superAdmin.settings.path}
                    render={() => <Settings {...this.props} />}
                  />
                  <Route
                    path={pageMatcher.admin.home.path}
                    render={() => <Home {...this.props} />}
                  />
                  <Route
                    path={pageMatcher.admin.citizens.path}
                    render={() => <Citizens {...this.props} />}
                  />
                  <Route
                    path={pageMatcher.admin.website.path}
                    render={() => <Website {...this.props} />}
                  />
                </Switch>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default Admin;
