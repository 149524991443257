import React, { Component } from 'react';
import JobOfferForm from '../Form';
import JobOfferService, { validateJob } from 'services/JobOffers';
import { parseResponseForPublishBar } from 'services/Utilities';
import PublishBar from 'components/PublishBar';
import { pageMatcher } from 'constants/Global';

class NewJobOffer extends Component {
  state = {
    job: {
      title: '',
      kind: '',
      description: '',
      document: {
        title: '',
        data: ''
      }
    },
    showSaved: false,
    errorMessage: '',
    loading: false
  };

  jobOfferService = new JobOfferService();

  handleDeletePDF = () => {
    this.setState(state => ({
      job: {
        ...state.job,
        delete_document: true,
        document: { title: '', data: '' }
      }
    }));
  };

  handleSubmit = e => {
    e.preventDefault();

    const { job } = this.state;
    // gestion des messages d'erreur.
    const localError = validateJob(job);

    if (localError) {
      parseResponseForPublishBar(localError, this, 'marginBottom');
    } else {
      if (job.delete_document || !job.document.data) {
        delete job.document;
      }

      this.jobOfferService.postForm(job).then(({ data: response }) => {
        parseResponseForPublishBar(response, this, 'marginBottom');
        this.props.history.push(pageMatcher.admin.content.jobOffers.path);
      });
    }
  };

  handleChange = event => {
    const target = event.target;
    const { name, value } = target;

    this.setState(prevState => ({
      job: {
        ...prevState.job,
        [name]: value
      }
    }));
  };

  handleDocumentChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState(prevState => ({
      job: {
        ...prevState.job,
        document: {
          ...prevState.job.document,
          [name]: value
        }
      }
    }));
  };

  handleOnHTMLChange = html => {
    this.setState(prevState => ({
      job: {
        ...prevState.job,
        description: html
      }
    }));
  };

  handleFileChange = file => {
    this.setState(prevState => ({
      job: {
        ...prevState.job,
        document: {
          ...prevState.job.document,
          data: file
        }
      }
    }));
  };

  render() {
    return (
      <>
        <JobOfferForm
          {...this.state}
          handleDeletePDF={this.handleDeletePDF}
          handleChange={this.handleChange}
          handleDocumentChange={this.handleDocumentChange}
          onHTMLChange={this.handleOnHTMLChange}
          handleFileChange={this.handleFileChange}
          required={true}
        />
        <PublishBar
          state={'published'}
          handleSubmit={this.handleSubmit}
          buttonDisabled={this.state.loading}
          goBackLink={pageMatcher.admin.content.jobOffers.path}
          goBackLinkTitle="Retourner à la liste"
        />
      </>
    );
  }
}

export default NewJobOffer;
