import React, { PureComponent } from 'react';
import RSSFlowForm from 'services/RSSFlowForm';
import RssFlows from 'services/RssFlows';
import { RssForm } from './Form';
import Submenu from 'components/Submenu';
import {
  deleteItemFromArray,
  parseResponseForPublishBar
} from 'services/Utilities';
import PublishBar from 'components/PublishBar';
import { pageMatcher } from 'constants/Global';
import { ModalLoading } from 'components/ModalLoading';

class Rss extends PureComponent {
  state = {
    rssFlows: [],
    rssFlowsToDelete: [],
    errorMessage: '',
    showSaved: false,
    loading: true
  };

  RSSFlowForm = new RSSFlowForm();
  RssFlows = new RssFlows();
  subMenuData = [pageMatcher.admin.rss];

  componentDidMount() {
    this.getRssFlows();
  }

  getRssFlows = () => {
    this.RssFlows.get().then(({ data }) => {
      const rssFlows = data.reduce((acc, rssFlow) => [...acc, rssFlow], []);
      this.setState({ rssFlows, loading: false });
    });
  };

  closeStatusMessage = () => {
    this.setState({ errorMessage: '', showSaved: false, loading: false });
  };

  handleChange = (event, id) => {
    const { value, name } = event.target;
    const updatedRssFlows = [...this.state.rssFlows];
    // use 'for of' instead of map ?
    updatedRssFlows.map(el => {
      if (el.id === id) {
        if (name === 'link') {
          el[name]['data'] = value;
        } else {
          el[name] = value;
        }
        return el;
      }
      return '';
    });
    this.setState({ rssFlows: updatedRssFlows });
  };

  handleDelete = (e, id) => {
    const rssFlows = deleteItemFromArray([...this.state.rssFlows], id);
    if (!(id instanceof Date)) {
      const rssFlowsToDelete = [...this.state.rssFlowsToDelete, id];
      this.setState({ rssFlows, rssFlowsToDelete });
    } else {
      this.setState({ rssFlows });
    }
  };

  handleSubmit = async event => {
    event.preventDefault();

    const { rssFlows, rssFlowsToDelete } = this.state;
    const rssFlowData = rssFlows.reduce(
      (acc, { title, link: { data: link }, id }) => [
        ...acc,
        { title, link, id }
      ],
      []
    );

    this.RSSFlowForm.saveMultiple(rssFlowData, rssFlowsToDelete).then(
      response => {
        // When deleting, empty string comes out of the 204 response from the server.
        const rssFlows = response
          .map(({ data }) => data)
          .filter(value => typeof value === 'object');
        parseResponseForPublishBar(response, this, true);
        this.setState({ rssFlows });
      }
    );

    this.setState({ loading: false });
  };

  addFluxRss = () => {
    const newFlow = {
      id: new Date(),
      title: '',
      link: {
        data: ''
      },
      isURLValid: true,
      hasTitle: true,
      showSaved: false,
      errorMessage: ''
    };
    const rssFlows = [...this.state.rssFlows, newFlow];
    this.setState({ rssFlows });
  };

  render() {
    const { loading, rssFlows } = this.state;

    return (
      <div>
        <Submenu subMenuData={this.subMenuData} />
        <form onSubmit={this.handleSubmit}>
          <div className="card-koba">
            <div className="col-12 col-xl-9">
              {rssFlows.map((rssFlow, index) => (
                <RssForm
                  key={rssFlow.id}
                  isValid={true}
                  {...this.props}
                  rssFlow={rssFlow}
                  handleChange={this.handleChange}
                  handleDelete={this.handleDelete}
                />
              ))}
              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    disabled={loading}
                    className="btn btn-sm btn-outline-primary"
                    onClick={this.addFluxRss}
                  >
                    Ajouter un flux
                    <i className="ml-2 fal fa-plus " />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <PublishBar
          state={'published'}
          handleSubmit={this.handleSubmit}
          buttonDisabled={loading}
        />
        <ModalLoading loading={loading} />
      </div>
    );
  }
}

export default Rss;
