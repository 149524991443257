import React from 'react';
import { FormGroup, Input, Checkbox } from 'components/Form';

export const ChoiceForm = ({ form, path = [] }) => {
  const labelPath = path.concat('label');
  const allowFreeTextPath = path.concat('allow_free_text');

  return (
    <>
      <FormGroup
        path={labelPath}
        label={`Texte du choix`}
        required={true}
        form={form}
      >
        <Input path={labelPath} aria-label="texte" />
      </FormGroup>
      <FormGroup path={allowFreeTextPath} form={form}>
        <Checkbox
          path={allowFreeTextPath}
          label="Autoriser un complément de réponse en texte libre ?"
        />
      </FormGroup>
      <hr />
    </>
  );
};
