import DeleteConfirm from './DeleteConfirm';
import React from 'react';
import { Collapse } from 'reactstrap';

export default props => {
  const {
    type,
    text,
    question,
    answer,
    collapse,
    collapsed,
    exited,
    handleExit,
    handleDelete,
    handleChange,
    handleSubmit
  } = props;

  return (
    <div className={'Bubble clearfix ' + type}>
      <div className="Bubble-content rounded p-4">
        {text}
        {type === 'left' && (
          <div className="small text-muted">
            {question.author_firstname} {question.author_lastname}
          </div>
        )}
        {type === 'left' && (
          <>
            {!collapsed && exited && (
              <div className="Bubble-comment-btn text-center rounded">
                <div className="row align-items-end w-100">
                  <DeleteConfirm handleDelete={handleDelete} />
                  <div
                    className="col pointer h-100 d-flex justify-content-end align-items-center flex-column"
                    onClick={collapse}
                  >
                    <i className="fal fa-comment" title="Répondre" />
                    <p>Répondre</p>
                  </div>
                </div>
              </div>
            )}
            <Collapse onExited={handleExit} isOpen={collapsed}>
              <div className="Bubble-comment mt-3">
                <textarea
                  name="answer"
                  value={answer || question.answer || ''}
                  onChange={handleChange}
                  className="form-control mb-3"
                  placeholder="Votre réponse"
                  rows="4"
                />
                <div className="row">
                  <div className="col-6">
                    <button
                      onClick={collapse}
                      className="btn btn-outline-secondary btn-block"
                    >
                      Fermer
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={handleSubmit}
                      className="btn btn-outline-secondary btn-block"
                    >
                      Répondre
                    </button>
                  </div>
                </div>
              </div>
            </Collapse>
          </>
        )}
      </div>
    </div>
  );
};
