import React from 'react';
import { Modal } from 'reactstrap';
import './index.scss';

export const ModalLoading = ({ loading }) => (
  <Modal isOpen={loading} className="lds-ellipsisL">
    <div />
    <div />
    <div />
    <div />
  </Modal>
);
