import React from 'react';

export default ({ title, className, iconClassName, onClick }) => {
  return (
    <button
      title={title || "bouton d'action"}
      type="button"
      className={
        'btn table-action-btn btn-action btn-action-lg ' + (className || '')
      }
      onClick={onClick}
    >
      <i className={iconClassName} />
    </button>
  );
};
