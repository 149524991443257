import React from 'react';
import { Link } from 'react-router-dom';
import Toggle from '../Toggle';

// TODO : Refacto publish bar instead
const FormButtons = ({
  id,
  state,
  goBackLink,
  goBackLinkTitle,
  handleStatusChange
}) => {
  return (
    <div className="publish-bar">
      <div
        className={
          'card-save p-2 p-md-3 d-flex flex-row ' +
          (id ? 'bg-published' : 'bg-draft')
        }
      >
        {handleStatusChange && (
          <div>
            <Toggle
              checked={state === 'published'}
              name="state"
              onChange={handleStatusChange}
            />
            <span className="font-italic align-middle ml-3">
              {state === 'published' ? 'En Ligne' : 'Hors Ligne'}
            </span>
          </div>
        )}
        <Link to={goBackLink} tabIndex={-1}>
          <button
            className={'btn ml-2 ' + (id ? 'btn-light' : 'btn-outline-primary')}
            type="button"
          >
            {goBackLinkTitle || 'Retour'}
          </button>
        </Link>
        <button
          type="submit"
          className={'btn ml-2 ' + (id ? 'btn-light' : 'btn-outline-primary')}
        >
          Enregistrer
        </button>
      </div>
    </div>
  );
};

export default FormButtons;
