import { reverseObjectKeyVals } from './Utilities';

const resourceTypeTitles = {
  article: 'Articles',
  event: 'Evénements',
  news: 'Actualités',
  kiosk: 'Kiosque',
  photo_album: 'Albums photos',
  category: 'Annuaire',
  survey: 'Sondage',
  job: 'Emplois',
  online_procedure: 'Procédures en ligne'
};

const linkableTypeToResourceType = {
  Article: 'article',
  Event: 'event',
  PhotoAlbum: 'photo_album',
  News: 'news',
  Kiosk: 'kiosks',
  Category: 'category',
  OnlineProcedure: 'online_procedure',
  Job: 'job',
  Survey: 'survey'
};

const resourceTypeToLinkableType = reverseObjectKeyVals(
  linkableTypeToResourceType
);

const apiTypeToResourceType = {
  articles: 'article',
  events: 'event',
  news: 'news',
  kiosks: 'kiosk',
  photo_albums: 'photo_album',
  annuaire: 'category',
  services: 'online_procedure',
  job: 'job',
  survey: 'survey'
};

const resourceTypeToApiType = reverseObjectKeyVals(apiTypeToResourceType);

/**
 * Depending on the type of link sent the api format is quite inconsistent
 * Here we map to the simpler and standard ModalSelectLink format (see the ModalSelectLink component)
 */
export const mapLinkDataOnApiRead = ({
  link = {},
  linkable,
  linkable_type,
  datable,
  datable_type,
  article,
  event,
  news,
  kiosk,
  photo_album,
  annuaire,
  procedures,
  emploi,
  sondages
}) => {
  const linkType = linkable_type || datable_type;
  if (!linkType) return null;
  const linkSpec =
    (link && Object.keys(link).length && link) || linkable || datable || {};
  if (linkSpec && linkType !== 'Link' && linkSpec.id) {
    return {
      internal: true,
      resourceType: linkableTypeToResourceType[linkType] || '',
      id: linkSpec.id,
      title: linkSpec.title
    };
  } else if (linkSpec.internal) {
    const resourceType = Object.entries(apiTypeToResourceType).find(
      ([apiType]) => linkSpec.data.startsWith(`/${apiType}`)
    )[1];
    return {
      internal: true,
      resourceType,
      title: resourceTypeTitles[resourceType]
    };
  } else if (
    article ||
    event ||
    news ||
    kiosk ||
    photo_album ||
    annuaire ||
    procedures ||
    emploi ||
    sondages
  ) {
    return {
      internal: true,
      resourceType:
        (article && 'article') ||
        (event && 'event') ||
        (news && 'news') ||
        (kiosk && 'kiosk') ||
        (photo_album && 'photo_album') ||
        (annuaire && 'annuaire') ||
        (procedures && 'procedures') ||
        (emploi && 'emploi') ||
        (sondages && 'sondages'),
      id:
        (article && article.id) ||
        (event && event.id) ||
        (news && news.id) ||
        (kiosk && kiosk.id) ||
        (photo_album && photo_album.id) ||
        (annuaire && annuaire.id) ||
        (procedures && procedures.id) ||
        (emploi && emploi.id) ||
        (sondages && sondages.id)
    };
  } else {
    return {
      internal: false,
      title: linkSpec.title,
      url: linkSpec.data
    };
  }
};

/**
 * Depending on the type of link sent the api format is quite inconsistent
 * Here we map to the simpler and standard ModalSelectLink format (see the ModalSelectLink component)
 */
export const mapLinkDataOnApiWrite = ({
  internal,
  resourceType,
  id,
  title,
  url,
  slug
}) => {
  if (internal && id) {
    return {
      [resourceType]: { id },
      url: `/${resourceTypeToApiType[resourceType]}/${slug || id}`,
      linkable_type: resourceTypeToLinkableType[resourceType],
      datable_type: resourceTypeToLinkableType[resourceType]
    };
  } else if (internal) {
    return {
      link: {
        internal,
        title: resourceTypeTitles[resourceType],
        data: `/${resourceTypeToApiType[resourceType]}`
      },
      linkable_type: 'Link',
      datable_type: 'Link'
    };
  } else {
    return {
      link: {
        internal,
        title,
        data: url
      },
      linkable_type: 'Link',
      datable_type: 'Link'
    };
  }
};
