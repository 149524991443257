import Axios from './Axios';
import moment from 'moment';
import Ressource from './Ressource';

class Kiosk extends Ressource {
  constructor() {
    super('kiosk');
  }

  formatKiosk = param => {
    if (param instanceof Array) {
      return param.map(obj => this.getFormattedKiosk(obj));
    }

    if (param instanceof Object) {
      return this.getFormattedKiosk(param);
    }
  };

  getFormattedKiosk = obj => {
    const currentMonth = moment(obj.publication_date).month() + 1;

    return {
      ...obj,
      publication_date_year: moment(obj.publication_date).year(),
      ...(currentMonth > 10
        ? { publication_date_month: currentMonth }
        : { publication_date_month: `0${currentMonth}` }),
      ...(obj.file && { file_url: URL.createObjectURL(obj.file) }),
      ...(obj.image && { image_url: URL.createObjectURL(obj.image) }),
      ...(obj.state === 'enabled' ? { state: true } : { state: false })
    };
  };

  getKiosks = (pageNumber, dataPerPage) => {
    const params = pageNumber
      ? `kiosks?page=${pageNumber}&per_page=${dataPerPage}&`
      : 'kiosks?';

    return Axios.get(params + 'scopes[by_updated_at]');
  };

  getKiosk = id => Axios.get(`kiosks/${id}`);

  patch = (data, id) => Axios.patch(`kiosks/${id}`, { kiosk: data });

  toggleActive = (id, state) => {
    switch (state) {
      case 'enabled':
      case true:
        return this.disable(id);
      case false:
      case 'disabled':
        return this.enable(id);
      default:
        return null;
    }
  };

  enable = id => Axios.patch(`kiosks/${id}/enable`);
  disable = id => Axios.patch(`kiosks/${id}/disable`);
  archive = id => Axios.patch(`kiosks/${id}/archive`);
  delete = id => Axios.delete(`kiosks/${id}`);
}

export default Kiosk;
