import MenuService, {
  mapLinkDataOnApiRead,
  mapLinkDataOnApiWrite,
  validate
} from 'services/Menu';
import ModalSelectLink from 'components/ModalSelectLink';
import Nestable from 'react-nestable';
import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import { alertMessage, parseResponseForPublishBar } from 'services/Utilities';
import Submenu from 'components/Submenu';
import PublishBar from 'components/PublishBar';
import { Popconfirm } from 'antd';
import 'antd/lib/popconfirm/style/css.js';
import { pageMatcher } from 'constants/Global';
import './index.scss';

// TODO: Will be needed in the refactoring process
// const mapping = {
//   title: "text",
//   url: "page",
// };
//
// const renameKeys = (keysMap, obj) => Object
//   .keys(obj)
//   .reduce((acc, key) => ({
//     ...acc,
//     ...{ [keysMap[key] || key]: obj[key] }
//   }), {});

class Menu extends Component {
  state = {
    addItemValue: '',
    currentModalItemId: {},
    items: [],
    itemsData: [],
    defaultCollapsed: false,
    leafs: []
  };
  subMenuData = [pageMatcher.admin.menu];
  nextId = 1000;
  Menu = new MenuService();

  // TODO: Refactor all of this mess.
  parseDataToNestable = pastItems => {
    let id = 1;
    let items = cloneDeep(pastItems);
    if (items) {
      items.some(function iter(object, _i, _a) {
        object.id = id++;
        object.text = object.title;
        delete object.title;
        if (object.url) {
          object.page = object.url;
          delete object.url;
        }
        return object.children && object.children.some(iter);
      });
    }

    return items;
  };

  // Partial solution that doesnt handle recursivity issues
  // parseDataToNestable = pastItems => {
  //   let id = 1;
  //   let items = [...pastItems];
  //   if (items) {
  //     return items.reduce((acc, item) => ([
  //       ...acc,
  //       renameKeys(mapping, item)
  //     ]), []);
  //   }
  //
  //   return items;
  // };

  parseNestableToData = pastItems => {
    let items = cloneDeep(pastItems);
    if (items) {
      items.some(function iter(object, i, a) {
        object.title = object.text;
        delete object.text;
        if (object.page) {
          object.url = object.page;
          delete object.page;
        }
        return object.children && object.children.some(iter);
      });
    }
    return items;
  };

  // TODO : Explore https://www.npmjs.com/package/react-nestable#usage
  handleMenuChange = async (items, _item = null) => {
    await this.setState({
      items,
      itemsData: this.parseNestableToData(items)
    });
  };

  handleNewChange = event => {
    this.setState({ addItemValue: event.target.value });
  };

  handleNewItem = event => {
    event.preventDefault();

    const { addItemValue, items: prevItems } = this.state;

    if (addItemValue) {
      const items = [
        { id: ++this.nextId, text: addItemValue, children: [] },
        ...prevItems
      ];
      this.setState({ items, addItemValue: '' }, () =>
        this.handleMenuChange(this.state.items)
      );
    }
  };

  handleUpdateItem = (event, item) => {
    let data = this.state.items;
    data.some(function iter(o, i, a) {
      if (o.id === item.id) {
        o.text = event.target.value;
        return true;
      }
      return o.children && o.children.some(iter);
    });
    this.setState({ items: data }, () =>
      this.handleMenuChange(this.state.items)
    );
  };

  handleDeleteItem = item => {
    let id = item.id;
    let data = this.state.items;
    data.some(function iter(o, i, a) {
      if (o.id === id) {
        a.splice(i, 1);
        return true;
      }
      return o.children && o.children.some(iter);
    });

    this.setState({ items: data }, () =>
      this.handleMenuChange(this.state.items)
    );
  };

  getMenu = () => {
    this.Menu.get().then(({ data: response }) => {
      if (response && response.data) this.setState({ edit: true });

      if (
        response &&
        response.data &&
        response.data.body &&
        response.data.body.children
      ) {
        let data = response.data.body.children;
        this.setState({
          items: this.parseDataToNestable(data),
          itemsData: this.data
        });
      }
    });
  };

  getMenuInitData = () => {
    return {
      title: 'menu',
      data: {
        body: {
          title: 'root',
          children: this.state.itemsData
        }
      }
    };
  };

  postMenu = () => {
    let data = this.getMenuInitData();
    this.Menu.post(data)
      .then(({ data: response }) =>
        parseResponseForPublishBar(response, this, 'marginBottom')
      )
      .then(() => {
        this.getMenu();
        this.setState({ loading: false });
      });
  };

  putMenu = () => {
    let data = this.getMenuInitData();
    this.Menu.put(null, data)
      .then(({ data: response }) =>
        parseResponseForPublishBar(response, this, 'marginBottom')
      )
      .then(() => {
        this.getMenu();
        this.setState({ loading: false });
      });
  };

  toggleModalSelectLink = item => {
    if (item) this.setState({ currentModalItem: item });
    this.setState({ ModalSelectLinkActive: !this.state.ModalSelectLinkActive });
  };

  findItemByIDAndDo = (id, items, action) => {
    let iter;
    if (items) {
      return items.map(
        (iter = (item, _i, _a) => {
          if (item.id === id) {
            action(item);
            return item;
          }
          return item.children && item.children.map(iter);
        })
      );
    }
  };

  handleSelectLink = value => {
    const { items } = this.state;
    const { currentModalItem } = this.state;

    this.findItemByIDAndDo(currentModalItem.id, items, item => {
      const previousText = item.text;
      Object.assign(item, value, {
        ...(previousText && { text: previousText })
      });
    });
    this.handleMenuChange(items);
    this.toggleModalSelectLink();
  };

  handleSubmit = event => {
    event.preventDefault();
    const { items } = this.state;

    if (validate(items))
      this.handleMenuChange(items).then(() => {
        this.setState({ loading: true, showSaved: false, errorMessage: '' });
        if (this.state.edit) this.putMenu();
        else this.postMenu();
      });
    else {
      alertMessage(
        'warning',
        'Une rubrique ne contient pas de destination',
        this,
        'marginBottom'
      );
    }
  };

  componentDidMount() {
    this.getMenu();
  }

  closeStatusMessage = () => {
    this.setState({ errorMessage: '', showSaved: false, loading: false });
  };

  deleteLink = item => {
    if (item.page) {
      let data = this.state.items;
      data.some(function iter(value, _index, _array) {
        if (value.id === item.id) {
          delete value.page;
          return true;
        }
        return value.children && value.children.some(iter);
      });

      this.handleMenuChange(data);
    }
  };

  render() {
    const renderItem = ({ item, collapseIcon }) => {
      const hide = item.children.length ? 'hidden' : 'visible';
      const frontofficeUrl = localStorage.getItem('frontofficeUrl');
      let url = item.page;
      if (url && !url.startsWith('http')) url = `${frontofficeUrl}${url}`;

      return (
        <div className="d-flex flex-row item">
          <div className="col pr-0 menu-name">
            <div className="input-group">
              <button type="button" className="grab btn">
                <i className="fa fa-bars" title="Déplacer" />
              </button>
              {collapseIcon && collapseIcon}
              <input
                type="text"
                className="form-control"
                value={item.text}
                onChange={e => this.handleUpdateItem(e, item)}
              />
            </div>
          </div>
          <div className="col-auto pr-0" style={{ visibility: hide }}>
            <button
              type="button"
              onClick={() => this.toggleModalSelectLink(item)}
              className="btn btn-dark"
            >
              <i className="fa fa-link" title="Ajouter un lien" />
            </button>
          </div>
          <div className="col-5 pr-0" style={{ visibility: hide }}>
            <div className="alert mb-0 target text-truncate d-flex flex-row justify-content-between">
              <div className="text-truncate pr-3">{item.page}</div>
              <div onClick={() => this.deleteLink(item)}>
                <i className="fal fa-times" title="Supprimer le lien" />
              </div>
            </div>
          </div>
          <div className="col-auto pr-0">
            {url && (
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="btn"
              >
                <i className="far fa-eye" title={`Consulter cette page`} />
              </a>
            )}
            <Popconfirm
              title="Etes-vous sûr de vouloir supprimer?"
              onConfirm={() => this.handleDeleteItem(item)}
              okText="Oui"
              cancelText="Annuler"
            >
              <button type="button" className="btn">
                <i
                  className="far fa-trash-alt"
                  title={`Supprimer l'élément ${item.text}`}
                />
              </button>
            </Popconfirm>
          </div>
        </div>
      );
    };

    const collapseIcon = ({ isCollapsed }) =>
      isCollapsed ? (
        <i className="fal fa-chevron-right mr-2 collapse-icon" title="Ouvrir" />
      ) : (
        <i className="fal fa-chevron-down mr-2 collapse-icon" title="Fermer" />
      );

    const {
      defaultCollapsed,
      currentModalItem,
      ModalSelectLinkActive,
      addItemValue,
      items,
      loading
    } = this.state;

    return (
      <>
        <Submenu subMenuData={this.subMenuData} />
        <div className="card-koba mt-3 mb-3" id="build-menu">
          <form name="loginForm" onSubmit={this.handleNewItem} className="">
            <label className="mb-4">Ajoutez un élément de menu</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="item"
                autoComplete="off"
                aria-describedby="item"
                placeholder="Titre de la Rubrique"
                aria-label="Titre de la Rubrique"
                name="item"
                value={addItemValue}
                onChange={this.handleNewChange}
              />
              <div className="input-group-append">
                <button className="btn btn-dark" type="submit">
                  Ajouter
                </button>
              </div>
            </div>
          </form>
          {items.length > 0 && (
            <div>
              <label className="my-4">Editez et ordonnez votre menu</label>
              <Nestable
                maxDepth={4}
                items={items}
                renderItem={renderItem}
                onChange={this.handleMenuChange}
                collapsed={defaultCollapsed}
                renderCollapseIcon={collapseIcon}
                ref={el => (this.refNestable = el)} // TODO: Never used, delete it
              />
            </div>
          )}
        </div>

        <ModalSelectLink
          onCancel={() => this.toggleModalSelectLink()}
          isOpen={ModalSelectLinkActive}
          value={currentModalItem && mapLinkDataOnApiRead(currentModalItem)}
          onSelect={value =>
            this.handleSelectLink(mapLinkDataOnApiWrite(value))
          }
          noTitle={true}
        />
        <PublishBar
          state={'published'}
          handleSubmit={this.handleSubmit}
          buttonDisabled={loading}
        />
      </>
    );
  }
}

export default Menu;
