const SET_MESSAGE = 'SET_MESSAGE';
const initState = {
  statusIsVisible: false,
  statusType: '',
  statusMessage: '',
  marginBottom: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        statusIsVisible: action.statusIsVisible,
        statusType: action.statusType,
        statusMessage: action.statusMessage,
        marginBottom: action.marginBottom
      };
    default:
      return state;
  }
};
